//Dependencies
import clsx from 'clsx';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, Fragment, useRef, useContext, useMemo } from 'react';
//Core
import { Collapse, Stack, Typography } from '@mui/material';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Accordion from '@material-ui/core/Accordion';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { makeStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import green from '@material-ui/core/colors/green';

//Icons
import Close from '@material-ui/icons/Close';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import HelpOutline from '@material-ui/icons/HelpOutline';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import QueryBuilderOutlined from '@material-ui/icons/QueryBuilderOutlined';
import ReportProblem from '@material-ui/icons/ReportProblemOutlined';
import {
    AirplaneTicketOutlined,
    BusinessOutlined,
    DirectionsOutlined,
    ExpandLess,
    HourglassBottomOutlined,
    LooksOneOutlined
} from '@mui/icons-material';
import { flatten } from 'lodash';

//Common
import GetCookie from '../Common/Functions/GetCookie';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
//Components
import CartTransferNote from './CartTransferNote';
import TransferDialog from '../Transfers/Material/TransferDialog';
import CartTransferManualBooking from './CartTransferManualBooking';
import CartTransferManualCancellationRules from './CartTransferManualCancellationRules';
import CartDeleteModal from './CartDeleteModal';
import {
    CartConstructionProductsTableItemProviderQuotationStatus
} from './CartConstructionProductsTableItemProviderQuotationStatus';
import {
    CartPackageItemChangeProviderQuotationStatusButtons
} from './CartPackageItemChangeProviderQuotationStatusButtons';
import { CartConstructionProviderQuotationStatusLayout } from './CartConstructionProviderQuotationStatusLayout';
//Actions
import GetPricesId from '../Cart/Functions/Margin/GetPricesId';
import { TransferCart } from '../Itinerary/objects/transferCart';
import { Currency } from '../../Reducers/objects/currency';
import { ProductPrice } from '../Itinerary/objects/productPrice';
import { StatusBooking } from '../Itinerary/objects/statusBooking';
import { AppState } from '../../Reducers/Reducers';
import { isProductPackaged } from './utils/isProductPackaged';
import { ProviderContext } from './utils/providerContext';
import { useCartProducts } from '../Itinerary/network/cartProducts';

type Props = {
    transfer: TransferCart
}

export const CartTransferCardDetails = withRouter<Props>(
    function CartTransferCardDetails({ transfer }): JSX.Element {
        const classes = useStyles();
        const { t, i18n } = useTranslation();
        const dispatch = useDispatch();
        const { enqueueSnackbar } = useSnackbar();

        const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

        const locale = useSelector((state: AppState) => {
            return state.user.locales.find((item) => {
                return item.language_code === i18n.language;
            })?.id ?? 1;
        });
        const user = useSelector((state: AppState) => state.user?.user);
        const currency = useSelector((state: AppState) => state.trip.currency);
        const trip = useSelector((state: AppState) => state.trip.data_trip);
        const language = useSelector((state: AppState) => state.header.tmp_language);
        const currency_list = useSelector((state: AppState) => state.base.currency_list);
        const see_new_price = useSelector((state: AppState) => state.cart.see_new_price);
        const [showNote, setShowNote] = useState(false);
        const [showManualCancellationRules, setShowManualCancellationRules] = useState(false);
        const [showManualBooking, setShowManualBooking] = useState(false);
        const [onRequest, setOnRequest] = useState(false);
        const [catalogPrice, setCatalogPrice] = useState(false);
        const [margin, setMargin] = useState<{
            id?: number,
            ids?: {
                room_id: number,
                price_id: number
            }[],
            rate_type: 'PER' | 'FIX',
            purchase: number,
            purchase_converted: number,
            purchase_currency?: Currency,
            factor_used: string,
            value: number,
            static_value?: number,
            markup: boolean,
            percent: number,
            static_percent?: number,
            selling: number,
            selling_currency?: Currency,
            custom_rate?: boolean,
            custom_rate_type?: string
        } | null>(null);
        const [surcom, setSurcom] = useState<{
            value?: number,
            static?: number,
            purchase: number,
            selling: number,
            percent: number,
            currency: Currency | null
        } | null>(null);
        const [openCancellation, setOpenCancellation] = useState(false);
        const [expanded, setExpanded] = useState(true);
        const [deleteModal, setDeleteModal] = useState(false);
        const [marginValue, setMarginValue] = useState<number | null>(null);
        const [marginPercent, setMarginPercent] = useState<number | null>(null);

        const manualBookingRef = useRef<HTMLDivElement>(null);
        const providerContext = useContext(ProviderContext);
        const cart = useCartProducts();
        const onlineOriginFlight = useMemo(() => {
            return flatten(
                cart.flights.map((item) => {
                    if (item.type === 'normal') {
                        return item.flight.outbounds;
                    }
                }).filter((item): item is NonNullable<typeof item> => !!item)
            ).find((outbound) => {
                const lastLeg = outbound.legs[outbound.legs.length - 1];
                return lastLeg &&
                    window.moment.utc(lastLeg.arrival_time).startOf('day').format('yyyy-MM-DD') === window.moment.utc(transfer.start_date).startOf('day').format('yyyy-MM-DD');
            });
        }, [cart.flights]);
        const offlineOriginFlight = useMemo(() => {
            return flatten(
                cart.flights.map((item) => {
                    if (item.type === 'manual') {
                        return item.flight.flight_segment;
                    }
                }).filter((item): item is NonNullable<typeof item> => !!item)
            ).find((outbound) => {
                return window.moment.utc(outbound.end_date).startOf('day').format('yyyy-MM-DD') === window.moment.utc(transfer.start_date).startOf('day').format('yyyy-MM-DD');
            });
        }, [cart.flights]);
        const onlineDestinationFlight = useMemo(() => {
            return flatten(
                cart.flights.map((item) => {
                    if (item.type === 'normal') {
                        return item.flight.outbounds;
                    }
                }).filter((item): item is NonNullable<typeof item> => !!item)
            ).find((outbound) => {
                const lastLeg = outbound.legs[outbound.legs.length - 1];
                return lastLeg &&
                    window.moment.utc(lastLeg.departure_time).startOf('day').format('yyyy-MM-DD') === window.moment.utc(transfer.end_date).startOf('day').format('yyyy-MM-DD');
            });
        }, [cart.flights]);
        const offlineDestinationFlight = useMemo(() => {
            return flatten(
                cart.flights.map((item) => {
                    if (item.type === 'manual') {
                        return item.flight.flight_segment;
                    }
                }).filter((item): item is NonNullable<typeof item> => !!item)
            ).find((outbound) => {
                return window.moment.utc(outbound.start_date).startOf('day').format('yyyy-MM-DD') === window.moment.utc(transfer.end_date).startOf('day').format('yyyy-MM-DD');
            });
        }, [cart.flights]);
        const destinationAccommodation = useMemo(() => {
            return cart.accommodations.find((item) => {
                return window.moment.utc(
                    item.accommodation.start_date
                ).startOf('day').isSame(
                    window.moment.utc(transfer.end_date).startOf('day'),
                    'day'
                );
            })
        }, [cart.accommodations]);
        const originAccommodation = useMemo(() => {
            return cart.accommodations.find((item) => {
                return window.moment.utc(
                    item.accommodation.end_date
                ).startOf('day').isSame(
                    window.moment.utc(transfer.start_date).startOf('day'),
                    'day'
                );
            })
        }, [cart.accommodations]);

        const scrollIntoManuaBookingView = () => {
            manualBookingRef.current?.scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
        };

        const showCancellationDetail = () => {
            dispatch({ type: 'CART_TOGGLE_CANCELLATION_DETAIL', payload: { room: transfer, currency: getPrice(transfer.prices).currency } });
        };

        const onManualBooking = () => {
            setShowManualBooking(!showManualBooking);
        };

        const onAddNote = () => {
            setShowNote(!showNote);
        };

        const onDeleteNote = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${transfer.is_custom ? 'custom-products' : 'transfers'}/${transfer.id}/`,
                data: {
                    custom_information: null
                }
            }).then(response => {
                dispatch({ type: 'TRANSFER_EDIT_CART_BY_ID', payload: response.data });
                enqueueSnackbar(t('cart-material.note-deleted'), { variant: 'success' });
            }).catch(error => {
                console.log(error);
                enqueueSnackbar(t('cart-material.note-not-deleted'), { variant: 'error' });
            });
        };

        const onManualCancellationRules = () => {
            setShowManualCancellationRules(!showManualCancellationRules);
        };

        const onDeleteManualCancellationRules = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${transfer.is_custom ? 'custom-products' : 'transfers'}/${transfer.id}/`,
                data: {
                    no_refund: true,
                    cancel_condition_text: null
                }
            }).then(response => {
                dispatch({ type: 'TRANSFER_EDIT_CART_BY_ID', payload: response.data });
                setShowManualCancellationRules(!showManualCancellationRules);
            }).catch(error => {
                console.log(error);
                setShowManualCancellationRules(!showManualCancellationRules);
            });
        };

        const onDelete = () => {
            const { headers } = CheckBeforeRequest();
            if (transfer.booking_status && transfer.booking_status.status_booking === 'CANCELLED') {
                axios({
                    method: 'PATCH',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${transfer.is_custom ? 'custom-products' : 'transfers'}/${transfer.id}/`,
                    data: {
                        is_displayed: false
                    }
                }).then((response) => {
                    dispatch({ type: 'TRANSFER_EDIT_CART_BY_ID', payload: response.data });
                    enqueueSnackbar(t('cart-material.transfer-deleted'), { variant: 'success' });
                }).catch(error => {
                    console.log(error);
                    enqueueSnackbar(t('cart-material.tranfer-not-deleted'), { variant: 'error' });
                });
            } else {
                axios({
                    method: 'DELETE',
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${transfer.is_custom ? 'custom-products' : 'transfers'}/${transfer.id}/`
                }).then(() => {
                    dispatch({ type: 'TRANSFER_REMOVE_FROM_CART_BY_ID', payload: transfer.id });
                    enqueueSnackbar(t('cart-material.transfer-deleted'), { variant: 'success' });
                }).catch(error => {
                    console.log(error);
                    enqueueSnackbar(t('cart-material.tranfer-not-deleted'), { variant: 'error' });
                });
            }
        };

        const showBookingDetail = () => {
            dispatch({ type: 'CART_TOGGLE_BOOKING_DETAIL', payload: transfer });
        };

        const priceToDisplay = (price: ProductPrice) => {
            return {
                cost: parseFloat(price.selling_price),
                currency: currency_list.find(currency => currency.id === price.selling_currency)
            };
        };

        const getPrice = (prices: ProductPrice[]) => {
            if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_tva) {
                        return priceToDisplay(prices[i]!);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_surcom) {
                        return priceToDisplay(prices[i]!);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.master_price) {
                        return priceToDisplay(prices[i]!);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.owner === user?.client) {
                        return priceToDisplay(prices[i]!);
                    }
                }
            } else {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_tva) {
                        return priceToDisplay(prices[i]!);
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.owner === user?.client) {
                        return priceToDisplay(prices[i]!);
                    }
                }
            }
            return {
                cost: 0,
                currency: currency
            };
        };

        const getMargin = (
            tmp_margin: NonNullable<typeof margin>,
            tmp_surcom: NonNullable<typeof surcom>,
            prices: ProductPrice[]
        ) => {
            if (user?.client_full?.type === 1 || user?.client_full?.type === 3 || quotation_code === 'verdie') {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.is_surcom) {
                        tmp_surcom.purchase += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_surcom.selling += parseFloat(prices[i]!.selling_price);
                        tmp_surcom.currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency) ?? null;
                        tmp_surcom.percent += parseFloat(prices[i]!.percentage_between);
                        break;
                    }
                }
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.master_price) {
                        tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_margin.factor_used = prices[i]!.factor_used;
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                        tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                        tmp_margin.custom_rate = prices[i]!.custom_rate;
                        tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                        tmp_margin.id = prices[i]!.id;
                        break;
                    }
                }
                if (tmp_margin.id === null) {
                    for (let i = 0; i < prices.length; i++) {
                        if (prices[i]!.owner === user?.client) {
                            tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                            tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                            tmp_margin.factor_used = prices[i]!.factor_used;
                            tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                            tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                            tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                            tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                            tmp_margin.custom_rate = prices[i]!.custom_rate;
                            tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                            tmp_margin.id = prices[i]!.id;
                            break;
                        }
                    }
                }
            } else {
                for (let i = 0; i < prices.length; i++) {
                    if (prices[i]!.owner === user?.client) {
                        tmp_margin.purchase += parseFloat(prices[i]!.purchase_price);
                        tmp_margin.purchase_converted += parseFloat(prices[i]!.purchase_price) * parseFloat(prices[i]!.factor_used);
                        tmp_margin.factor_used = prices[i]!.factor_used;
                        tmp_margin.purchase_currency = currency_list.find(currency => currency.id === prices[i]!.purchase_currency);
                        tmp_margin.selling += parseFloat(prices[i]!.selling_price);
                        tmp_margin.selling_currency = currency_list.find(currency => currency.id === prices[i]!.selling_currency);
                        tmp_margin.percent += parseFloat(prices[i]!.percentage_between);
                        tmp_margin.custom_rate = prices[i]!.custom_rate;
                        tmp_margin.custom_rate_type = prices[i]!.custom_rate_type ?? undefined;
                        tmp_margin.id = prices[i]!.id;
                        break;
                    }
                }
            }
        };

        const toggleDeleteModal = () => {
            setDeleteModal(!deleteModal);
        };

        const confirmCustomInformationRecalc = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${transfer.is_custom ? 'custom-products' : 'transfers'}/${transfer.id}/`,
                data: {
                    moved_with_recalc_custom_information: false
                }
            }).then(response => {
                dispatch({ type: 'TRANSFER_EDIT_CART_BY_ID', payload: response.data });
            }).catch(error => {
                console.log(error);
            });
        };

        const confirmCancelConditionRecalc = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${transfer.is_custom ? 'custom-products' : 'transfers'}/${transfer.id}/`,
                data: {
                    moved_with_recalc_cancel_condition_text: false
                }
            }).then(response => {
                dispatch({ type: 'TRANSFER_EDIT_CART_BY_ID', payload: response.data });
            }).catch(error => {
                console.log(error);
            });
        };

        const handleSeeNewPrice = () => {
            dispatch({
                type: 'CART_SEE_NEW_PRICE_MODAL',
                payload: {
                    see_new_price: !see_new_price,
                    data: see_new_price ? null : {
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${transfer.is_custom ? 'custom-products' : 'transfers'}/${transfer.id}/`,
                        id: transfer.id,
                        old_price: transfer.prices[GetPricesId(transfer.prices, user?.client, user, true)],
                        new_price: transfer.provider_wanted_price,
                        product: transfer,
                        custom_dispatch: 'TRANSFER_EDIT_CART_BY_ID'
                    }
                }
            });
        };

        useEffect(() => {
            if (showManualBooking) {
                scrollIntoManuaBookingView();
            }
        });

        useEffect(() => {
            if (marginValue && marginValue !== margin?.value) {
                let tmp_margin = margin ?
                    { ...margin } :
                    null;
                if (tmp_margin) {
                    tmp_margin.value = marginValue;
                    tmp_margin.rate_type = 'FIX';
                    if (tmp_margin.markup) {
                        tmp_margin.percent = parseFloat(((tmp_margin.value / tmp_margin.purchase_converted) * 100).toFixed(2));
                    } else {
                        tmp_margin.percent = parseFloat(((tmp_margin.value / tmp_margin.selling) * 100).toFixed(2));
                    }
                    setMarginPercent(tmp_margin.percent);
                    setMargin(tmp_margin);
                }
            }
        }, [marginValue]);

        useEffect(() => {
            if (marginPercent && marginPercent !== margin?.percent) {
                let tmp_margin = margin ?
                    { ...margin } :
                    null;
                if (tmp_margin) {
                    tmp_margin.percent = marginPercent;
                    tmp_margin.rate_type = 'PER';
                    if (tmp_margin.markup) {
                        tmp_margin.value = parseFloat((tmp_margin.purchase_converted * (tmp_margin.percent / 100)).toFixed(2));
                    } else {
                        tmp_margin.value = parseFloat((tmp_margin.selling * (tmp_margin.percent / 100)).toFixed(2));
                    }
                    setMarginValue(tmp_margin.value);
                    setMargin(tmp_margin);
                }
            }
        }, [marginPercent]);

        useEffect(() => {
            if (showNote) {
                const element = document.getElementById(`transfer-${transfer.id}-note`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
                }
            }
        });

        useEffect(() => {
            if (showManualCancellationRules) {
                const element = document.getElementById(`transfer-${transfer.id}-manual-cancellation-rules`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
                }
            }
        });

        useEffect(() => {
            let total_cost: {
                cost: number,
                currency?: Currency
            }[] = [];
            let on_request = false;
            let catalog_price = false;
            let tmp_margin: NonNullable<typeof margin> = {
                factor_used: '0',
                markup: false,
                purchase: 0,
                purchase_converted: 0,
                selling: 0,
                value: 0,
                percent: 0,
                rate_type: 'PER',
                ids: []
            };
            let tmp_surcom: NonNullable<typeof surcom> = {
                purchase: 0,
                selling: 0,
                value: 0,
                percent: 0,
                currency: null
            };
            if (transfer.on_request) {
                on_request = true;
            }
            if (transfer.catalog_price) {
                catalog_price = true;
            }
            let price = getPrice(transfer.prices);
            getMargin(tmp_margin, tmp_surcom, transfer.prices);
            let matched = false;
            total_cost.map(currency_cost => {
                if (currency_cost.currency?.id === price.currency?.id) {
                    matched = true;
                    currency_cost.cost += price.cost;
                }
            });
            if (!matched) {
                total_cost.push({
                    cost: price.cost,
                    currency: price.currency
                });
            }
            tmp_margin.value = parseFloat((tmp_margin.selling - tmp_margin.purchase_converted).toFixed(2));
            tmp_margin.static_value = tmp_margin.value;
            tmp_margin.markup = Math.abs(tmp_margin.percent - (100 - (tmp_margin.purchase_converted / tmp_margin.selling) * 100)) > Math.abs(tmp_margin.percent - ((tmp_margin.value / tmp_margin.purchase_converted) * 100));
            tmp_margin.static_percent = tmp_margin.percent;
            tmp_surcom.static = tmp_surcom.value;
            tmp_surcom.value = tmp_surcom.selling - tmp_surcom.purchase;
            setMargin(tmp_margin);
            setSurcom(tmp_surcom);
            setOnRequest(on_request);
            setCatalogPrice(catalog_price);
        }, [transfer]);

        return (
            <>
                <Grid
                    id={`product-${transfer.id}`}
                    className={classes.scrollMargin}
                    container
                    item
                    xs
                    direction={"column"}
                    spacing={2}
                >
                    {
                        providerContext.module === 'quotation' &&
                        <Grid item xs={12}>
                            <Typography sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                                {t('cart-material.provider-transfer-associated-products')}
                            </Typography>
                            {
                                onlineOriginFlight &&
                                destinationAccommodation &&
                                <Typography variant="body2">
                                    {
                                        t(
                                            'cart-material.provider-transfer-associated-origin-flight',
                                            {
                                                flight: onlineOriginFlight.legs[onlineOriginFlight.legs.length - 1]?.operating_airline?.commercial_name,
                                                flightNumber: onlineOriginFlight.legs[onlineOriginFlight.legs.length - 1]?.flight_number,
                                                date: window.moment.utc(
                                                    onlineOriginFlight.legs[onlineOriginFlight.legs.length - 1]?.arrival_time
                                                ).format('LLL')
                                            }
                                        )
                                    }
                                </Typography>
                            }
                            {
                                offlineOriginFlight &&
                                destinationAccommodation &&
                                <Typography variant="body2">
                                    {
                                        t(
                                            'cart-material.provider-transfer-associated-origin-flight',
                                            {
                                                flight: offlineOriginFlight.airline.commercial_name,
                                                flightNumber: offlineOriginFlight.flight_number,
                                                date: window.moment.utc(offlineOriginFlight.end_date).format('LLL')
                                            }
                                        )
                                    }
                                </Typography>
                            }
                            {
                                (onlineOriginFlight || offlineOriginFlight) &&
                                destinationAccommodation &&
                                <Typography variant="body2">
                                    {
                                        t(
                                            'cart-material.provider-transfer-associated-hotel',
                                            {
                                                name: destinationAccommodation.type === 'normal' ?
                                                    destinationAccommodation.accommodation.localization?.find((item) => {
                                                        return item.locale === locale;
                                                    })?.name ??
                                                    destinationAccommodation.accommodation.hotel[0]?.name ??
                                                    '' :
                                                    destinationAccommodation.accommodation.localization?.find((item) => {
                                                        return item.locale === locale;
                                                    })?.name ??
                                                    destinationAccommodation.accommodation.name,
                                                address: (() => {
                                                    if (destinationAccommodation.type === 'normal') {
                                                        let address = '';
                                                        const info = destinationAccommodation.accommodation.hotel[0];
                                                        if (info?.city_name) {
                                                            address = info.city_name + (info.destination_name && info.destination_name !== info.city_name ? `, ${info.destination_name}` : '');
                                                        } else if (info?.destination_name) {
                                                            address = info.destination_name;
                                                        }
                                                        return address;
                                                    }
                                                    const stepDestination = destinationAccommodation.accommodation.start_destination?.data?.localization.find((item) => {
                                                        return item.locale === locale;
                                                    })?.name ?? destinationAccommodation.accommodation.start_destination?.data?.international_name ?? '';
                                                    return stepDestination.length > 0 ?
                                                        `${destinationAccommodation.accommodation.address ?? ''} (${stepDestination})` :
                                                        destinationAccommodation.accommodation.address ?? '';
                                                })()
                                            }
                                        )
                                    }
                                </Typography>
                            }
                            {
                                originAccommodation &&
                                (onlineDestinationFlight || offlineDestinationFlight) &&
                                <Typography variant="body2">
                                    {
                                        t(
                                            'cart-material.provider-transfer-associated-hotel',
                                            {
                                                name: originAccommodation.type === 'normal' ?
                                                    originAccommodation.accommodation.localization?.find((item) => {
                                                        return item.locale === locale;
                                                    })?.name ??
                                                    originAccommodation.accommodation.hotel[0]?.name ??
                                                    '' :
                                                    originAccommodation.accommodation.localization?.find((item) => {
                                                        return item.locale === locale;
                                                    })?.name ??
                                                    originAccommodation.accommodation.name,
                                                address: (() => {
                                                    if (originAccommodation.type === 'normal') {
                                                        let address = '';
                                                        const info = originAccommodation.accommodation.hotel[0];
                                                        if (info?.city_name) {
                                                            address = info.city_name + (info.destination_name && info.destination_name !== info.city_name ? `, ${info.destination_name}` : '');
                                                        } else if (info?.destination_name) {
                                                            address = info.destination_name;
                                                        }
                                                        return address;
                                                    }
                                                    const stepDestination = originAccommodation.accommodation.start_destination?.data?.localization.find((item) => {
                                                        return item.locale === locale;
                                                    })?.name ?? originAccommodation.accommodation.start_destination?.data?.international_name ?? '';
                                                    return stepDestination.length > 0 ?
                                                        `${originAccommodation.accommodation.address ?? ''} (${stepDestination})` :
                                                        originAccommodation.accommodation.address ?? '';
                                                })()
                                            }
                                        )
                                    }
                                </Typography>
                            }
                            {
                                originAccommodation &&
                                onlineDestinationFlight &&
                                <Typography variant="body2">
                                    {
                                        t(
                                            'cart-material.provider-transfer-associated-departure-flight',
                                            {
                                                flight: onlineDestinationFlight.legs[onlineDestinationFlight.legs.length - 1]?.operating_airline?.commercial_name +
                                                    ' - ' +
                                                    onlineDestinationFlight.legs[onlineDestinationFlight.legs.length - 1]?.flight_number,
                                                flightNumber: onlineDestinationFlight.legs[onlineDestinationFlight.legs.length - 1]?.flight_number,
                                                date: window.moment.utc(
                                                    onlineDestinationFlight.legs[0]?.departure_time
                                                ).format('LLL')
                                            }
                                        )
                                    }
                                </Typography>
                            }
                            {
                                originAccommodation &&
                                offlineDestinationFlight &&
                                <Typography variant="body2">
                                    {
                                        t(
                                            'cart-material.provider-transfer-associated-departure-flight',
                                            {
                                                flight: offlineDestinationFlight.airline.commercial_name,
                                                flightNumber: offlineDestinationFlight.flight_number,
                                                date: window.moment.utc(offlineDestinationFlight.start_date).format('LLL')
                                            }
                                        )
                                    }
                                </Typography>
                            }
                        </Grid>
                    }
                    {
                        providerContext.module === 'booking' &&
                        <Grid item xs={12}>
                            {
                                transfer.transport_type &&
                                <Stack direction="row" spacing={1}>
                                    <AirplaneTicketOutlined fontSize="small" />
                                    <Typography variant="caption">
                                        {
                                            t(
                                                'cart-material.provider-booking-linked-transport',
                                                {
                                                    name: t(`transfers.transport_type_${transfer.transport_type.toLowerCase()}`)
                                                }
                                            )
                                        }
                                    </Typography>
                                </Stack>
                            }
                            {
                                transfer.transport_time &&
                                <Stack direction="row" spacing={1}>
                                    <HourglassBottomOutlined fontSize="small" />
                                    <Typography variant="caption">
                                        {
                                            t(
                                                'cart-material.provider-booking-transport-time',
                                                {
                                                    time: transfer.transport_time
                                                }
                                            )
                                        }
                                    </Typography>
                                </Stack>
                            }
                            {
                                transfer.transport_code &&
                                <Stack direction="row" spacing={1}>
                                    <LooksOneOutlined fontSize="small" />
                                    <Typography variant="caption">
                                        {
                                            t(
                                                'cart-material.provider-booking-transport-code',
                                                {
                                                    code: transfer.transport_code
                                                }
                                            )
                                        }
                                    </Typography>
                                </Stack>
                            }
                            {
                                transfer.direction &&
                                <Stack direction="row" spacing={1}>
                                    <DirectionsOutlined fontSize="small" />
                                    <Typography variant="caption">
                                        {
                                            t(
                                                'cart-material.provider-booking-transport-direction',
                                                {
                                                    direction: transfer.direction === 'dpt' ?
                                                        t('transfers.direction_departure') :
                                                        t('transfers.direction_arrival')
                                                }
                                            )
                                        }
                                    </Typography>
                                </Stack>
                            }
                            {
                                transfer.transport_company &&
                                <Stack direction="row" spacing={1}>
                                    <BusinessOutlined fontSize="small" />
                                    <Typography variant="caption">
                                        {
                                            t(
                                                'cart-material.provider-booking-transport-company',
                                                {
                                                    name: transfer.transport_company
                                                }
                                            )
                                        }
                                    </Typography>
                                </Stack>
                            }
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Grid container direction="row" justify={'space-between'} alignItems={'center'}>
                            {
                                user?.client_full?.type !== 2 &&
                                <Grid item xs={12}>
                                    <CartConstructionProviderQuotationStatusLayout
                                        status={
                                            <CartConstructionProductsTableItemProviderQuotationStatus
                                                item={transfer}
                                            />
                                        }
                                        buttons={
                                            <CartPackageItemChangeProviderQuotationStatusButtons
                                                item={{
                                                    ...transfer,
                                                    providerId: transfer.provider,
                                                    stackNumber: transfer.stack_number,
                                                    type: 'transfers',
                                                    isCustom: transfer.is_custom
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid container item xs spacing={2}>
                        <Grid item xs>
                            <Grid container>
                                <Grid className={classes.gridContent} item xs>
                                    {
                                        !isProductPackaged({
                                            product: transfer,
                                            stackInfos: trip?.stack_info ?? null
                                        }) &&
                                        transfer.is_cancellable &&
                                        <div className={classes.containerMargin}>
                                            <CheckCircleOutline className={classes.roomIcon} />
                                            <Typography className={classes.inlineBlock}>
                                                {
                                                    (
                                                        (transfer.charges?.length ?? 0) > 0 ||
                                                        parseFloat(transfer.cancellation_amount ?? '0') > 0
                                                    ) ?
                                                        t('cart-material.cancellable-with-conditions') :
                                                        t('cart-material.cancellable')
                                                }
                                            </Typography>
                                            {
                                                (
                                                    (transfer.charges?.length ?? 0) > 0 ||
                                                    parseFloat(transfer.cancellation_amount ?? '0') > 0
                                                ) &&
                                                <HelpOutline onClick={showCancellationDetail} className={classes.cancellationInfo} />
                                            }
                                        </div>
                                    }
                                    {
                                        transfer.description &&
                                        <div className={classes.containerMargin}>
                                            <Typography>{t('cart-material.description')} :</Typography>
                                            <Typography align={'justify'}>{transfer.description}</Typography>
                                        </div>
                                    }
                                    {
                                        transfer.pickup_check_url && transfer.pickup_check_url && transfer.time_before_pickup_check && transfer.time_before_pickup_check &&
                                        <Grid className={classes.containerMargin}>
                                            <Typography>{t('transfers.check_pickup')} <a href={transfer.pickup_check_url}>{transfer.pickup_check_url}</a></Typography>
                                            <Typography>{t('transfers.after')} {transfer.time_before_pickup_check} {t('global.hours')}</Typography>
                                        </Grid>
                                    }
                                    {
                                        transfer.customer_waiting_time && transfer.customer_waiting_time &&
                                        <div className={classes.containerMargin}>
                                            <Grid container alignItems="center" >
                                                <Grid item> <QueryBuilderOutlined className={classes.detailIcon} /> </Grid>
                                                <Grid item>
                                                    <Typography variant="body2">
                                                        {
                                                            transfer.customer_waiting_time === '0 minutes' ?
                                                                t('transfers.no_waiting_time') :
                                                                t('transfers.waiting_time_customer') + transfer.customer_waiting_time
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    }
                                    {
                                        transfer.supplier_waiting_time_domestic && transfer.supplier_waiting_time_domestic && (
                                            <div className={classes.containerMargin}>
                                                <Grid container alignItems="center">
                                                    <Grid item> <QueryBuilderOutlined className={classes.detailIcon} /> </Grid>
                                                    <Grid item>
                                                        <Typography variant="body2">
                                                            {
                                                                t('transfers.supplier_max_waiting_time_short') + transfer.supplier_waiting_time_domestic + ' (' + t('transfers.domestic') + ')'
                                                            }
                                                        </Typography>

                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )
                                    }
                                    {
                                        transfer.supplier_waiting_time_international && transfer.supplier_waiting_time_international && (
                                            <div className={classes.containerMargin}>
                                                <Grid container alignItems="center">
                                                    <Grid item> <QueryBuilderOutlined className={classes.detailIcon} /> </Grid>
                                                    <Grid item>
                                                        <Typography variant="body2">
                                                            {
                                                                t('transfers.supplier_max_waiting_time_short') + transfer.supplier_waiting_time_international + ' (' + t('transfers.international') + ')'
                                                            }
                                                        </Typography>

                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )
                                    }
                                    {/*
                                  isProductPackaged  !isProductPackaged({
                    product: props.tran                    stackInfos: trip?.stack_info ?? null
                }) && transfer.cancellation_amount && transfer.cancellation_date && transfer.cancellation_currency && (
                                        <Grid container alignItems={'center'} className={ classes.containerMargin }>
                                            <Grid item> <CheckCircleOutline className={ classes.detailIcon }/> </Grid>
                                            <Grid item style={{verticalAlign: 'text-bottom'}}> <Typography variant='body2'> {t('global.cancelable')} </Typography> </Grid>
                                            <Grid item>
                                                <HelpOutline className={ classes.helpIcon } onClick={() => {
                                                    let cancellation_rules = {
                                                        rules: [{
                                                            'from_when': transfer.cancellation_date,
                                                            'amount': transfer.cancellation_amount,
                                                            'currency': transfer.cancellation_currency
                                                        }],
                                                        currency_list: currency_list
                                                    };
                                                    setCardCancellation(cancellation_rules);
                                                    setOpenCancellation(true);
                                                }} />
                                            </Grid>
                                    </Grid>
                                    )*/
                                    }
                                    <TransferDialog openDialog={openCancellation} setOpenDialog={setOpenCancellation} cardDescription={null} cardCancellation={null} />
                                </Grid>
                            </Grid>
                            <Grid className={classes.bookingContainer} item xs>
                                {
                                    !isProductPackaged({
                                        product: transfer,
                                        stackInfos: trip?.stack_info ?? null
                                    }) &&
                                    <Fragment>
                                        {
                                            catalogPrice &&
                                            <Typography className={classes.price}>
                                                {t('cart-material.catalog-price')}
                                            </Typography>
                                        }
                                        {
                                            transfer.booking_status?.status_booking !== StatusBooking.CONFIRMED &&
                                            onRequest &&
                                            <Typography>{t('cart-material.on-request')}</Typography>
                                        }
                                        {
                                            transfer.price_change &&
                                            (transfer.price_change.price_variation !== 0 || !transfer.price_change.is_available) &&
                                            <Grid container justify={'flex-end'} alignItems={'center'}>
                                                {
                                                    transfer.price_change.is_available &&
                                                    <Grid item>
                                                        {transfer.price_change.price_variation > 0 ? <ArrowUpward className={classes.red} /> : <ArrowDownward className={classes.green} />}
                                                    </Grid>
                                                }
                                                <Grid item>
                                                    <Typography className={transfer.price_change.price_variation > 0 || !transfer.price_change.is_available ? classes.red : classes.green}>
                                                        {
                                                            !transfer.price_change.is_available ?
                                                                t('cart-material.product-unavailable') :
                                                                transfer.price_change.price_variation.toLocaleString(
                                                                    language,
                                                                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                                )
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Fragment>
                                }
                                {
                                    transfer.booking_status && transfer.booking_status.status_booking === 'CANCELLED' && transfer.cancellation_charged_amount &&
                                    <Typography className={clsx(classes.booking, classes.error)}>
                                        {t('flight_search.cancel_fee')} :{' '}
                                        {
                                            new Intl.NumberFormat(
                                                language,
                                                {
                                                    style: 'currency',
                                                    currency: currency_list.find((currency) => currency.id === transfer.prices[GetPricesId(transfer.prices, user?.client, user, true)]?.purchase_currency)?.iso_code,
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }
                                            ).format(transfer.cancellation_charged_amount)}
                                    </Typography>
                                }
                                {transfer.booking_status && <Typography className={classes.bookingDetail} onClick={showBookingDetail}>{t('cart-material.booking-detail')}</Typography>}
                            </Grid>
                        </Grid>
                        {
                            transfer.booking_status && transfer.booking_status.status_booking === 'UNAVAILABLE' &&
                            <Grid item xs={12}>
                                <Alert className={classes.errorAlert} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'error'}>
                                    {t('cart-material.booking-unavailable')} : {transfer.booking_status.item_reference}
                                </Alert>
                            </Grid>
                        }
                        {
                            transfer.booking_status &&
                            (transfer.booking_status.status_booking === 'ERROR' || transfer.booking_status.status_booking === 'UNAVAILABLE') &&
                            transfer.booking_errors && transfer.booking_errors.length > 0 &&
                            <Grid item xs={12}>
                                <Alert className={classes.errorAlert} classes={{ icon: classes.alertIcon }} variant={'filled'} severity={'error'}>
                                    <AlertTitle>{t('cart-material.error')} :</AlertTitle>
                                    {
                                        transfer.booking_errors.map((error) => (
                                            <Typography key={`booking-error-${error.created_date}`}
                                            >
                                                {window.moment.utc(error.created_date).format('DD/MM/YYYY')} : {error.detail}
                                            </Typography>
                                        ))
                                    }
                                </Alert>
                            </Grid>
                        }
                        {
                            showManualBooking &&
                            <Grid item xs={12} style={{ marginTop: 15 }} ref={manualBookingRef}>
                                <CartTransferManualBooking transfer={transfer} onManualBooking={onManualBooking} />
                            </Grid>
                        }
                        {
                            !providerContext.module &&
                            <>
                                {
                                    showNote ? (
                                        <Grid item xs={12}>
                                            <CartTransferNote transfer={transfer} onAddNote={onAddNote} />
                                        </Grid>
                                    ) : transfer.custom_information && (
                                        <Grid item xs={12}>
                                            <Alert className={classes.cancelConditionAlert} variant={'filled'} severity={'info'} action={
                                                <Grid container direction={'column'} spacing={2} alignItems={'flex-end'}>
                                                    <Grid item>
                                                        <Grid container spacing={2} alignItems={'center'}>
                                                            <Grid item>
                                                                <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={onAddNote}>
                                                                    {t('global.edit')}
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Close className={classes.deleteCustomInformation} onClick={onDeleteNote} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item style={{ width: '100%' }}>
                                                        {
                                                            transfer.moved_with_recalc_custom_information &&
                                                            <Tooltip title={t('cart-material.recalc-product-note')}>
                                                                <div
                                                                    className={clsx(classes.traverlerModification, classes.delete)}
                                                                    onClick={confirmCustomInformationRecalc}
                                                                    style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}
                                                                >
                                                                    <Grid container alignItems={'center'} justify={'space-between'}>
                                                                        <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                                        <Grid item>{t('cart-material.recalc')}</Grid>
                                                                        <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                                    </Grid>
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            }>
                                                <AlertTitle>{t('cart-material.note')} :</AlertTitle>
                                                {transfer.custom_information}
                                            </Alert>
                                        </Grid>
                                    )
                                }
                            </>
                        }
                        {
                            transfer.is_custom === false && transfer.important_notice && (
                                <Grid id={`transfer-${transfer.id}-note`} item xs={12}>
                                    <Accordion className={classes.additionalInformation}>
                                        <AccordionSummary expandIcon={<ExpandMore className={classes.additionalInformationExpandIcon} />}>
                                            <InfoOutlined className={classes.additionalInformationInfoIcon} /><Typography>{t('cart-material.additional-information')} :</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.additionalInformationDetails}>
                                            {
                                                transfer.important_notice.split('*').map((description, description_index) => {
                                                    return (
                                                        <Typography key={description_index}> {description} </Typography>
                                                    );
                                                })
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            )
                        }
                        {
                            user?.client_full?.type !== 2 && transfer.provider_wanted_price &&
                            <Grid item xs={12}>
                                <Alert variant={'filled'} severity={'error'} action={
                                    <Button color={"inherit"} size={'small'} onClick={handleSeeNewPrice}>{t('global.see')}</Button>
                                }>
                                    <AlertTitle>{t('cart-material.provider-wanted-price')}</AlertTitle>
                                </Alert>
                            </Grid>
                        }
                        {
                            transfer.remark_booking && (
                                <Grid item xs={12} style={{ marginTop: 10 }}>
                                    <Alert className={`${classes.remarkExpand}`} variant={'filled'} severity={'info'} action={
                                        <Grid container direction={'column'} alignItems={'flex-end'}>
                                            <Grid item>
                                                {
                                                    !expanded && (
                                                        <ExpandMore className={`${classes.deleteCustomInformation}`} onClick={() => {
                                                            setExpanded(!expanded);
                                                        }} />
                                                    )
                                                }
                                                {
                                                    expanded && (
                                                        <ExpandLess className={`${classes.deleteCustomInformation}`} onClick={() => {
                                                            setExpanded(!expanded);
                                                        }} />
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    }>
                                        <AlertTitle>{t('cart-material.remark-booking')} :</AlertTitle>
                                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                                            <span>{transfer.remark_booking}</span>
                                        </Collapse>
                                    </Alert>
                                </Grid>
                            )
                        }
                        {
                            showManualCancellationRules ?
                                <Grid id={`transfer-${transfer.id}-manual-cancellation-rules`} item xs={12}>
                                    <CartTransferManualCancellationRules transfer={transfer} onManualCancellationRules={onManualCancellationRules} />
                                </Grid> : transfer.cancel_condition_text &&
                                <Grid item xs={12}>
                                    <Alert className={classes.cancelConditionAlert} variant={'filled'} severity={'info'} action={
                                        <Grid container direction={'column'} spacing={2} alignItems={'flex-end'}>
                                            <Grid item>
                                                <Grid container spacing={2} alignItems={'center'}>
                                                    {
                                                        //!['verdie'].includes(quotation_code) &&
                                                        <Fragment>
                                                            <Grid item>
                                                                <Button className={classes.editCustomInformation} color={'inherit'} size={'small'} onClick={onManualCancellationRules}>
                                                                    {t('global.edit')}
                                                                </Button>
                                                            </Grid>
                                                            <Grid item>
                                                                <Close className={classes.deleteCustomInformation} onClick={onDeleteManualCancellationRules} />
                                                            </Grid>
                                                        </Fragment>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item style={{ width: '100%' }}>
                                                {
                                                    transfer.moved_with_recalc_cancel_condition_text &&
                                                    <Tooltip title={t('cart-material.recalc-product-cancel-condition')}>
                                                        <div
                                                            className={clsx(classes.traverlerModification, classes.delete)}
                                                            onClick={confirmCancelConditionRecalc}
                                                            style={{ fontSize: 13, color: 'white', background: '#ff9800', border: '2px solid #ff9800' }}
                                                        >
                                                            <Grid container alignItems={'center'} justify={'space-between'}>
                                                                <Grid item><ReportProblem style={{ verticalAlign: 'sub', fontSize: 18 }} /></Grid>
                                                                <Grid item>{t('cart-material.recalc')}</Grid>
                                                                <Grid item><Close style={{ verticalAlign: 'middle', fontSize: 18 }} /></Grid>
                                                            </Grid>
                                                        </div>
                                                    </Tooltip>
                                                }
                                            </Grid>
                                        </Grid>
                                    }>
                                        <AlertTitle>
                                            {
                                                !['verdie'].includes(quotation_code) ?
                                                    t('cart-material.manual-cancellation-rules') :
                                                    t('cart-material.provider-cancellation-rules')
                                            } : {transfer.no_refund ? t('cart-material.not-cancellable') : t('cart-material.cancellable-with-conditions')}
                                        </AlertTitle>
                                        {transfer.cancel_condition_text === t('cart-material.not-cancellable') ? '' : transfer.cancel_condition_text}
                                    </Alert>
                                </Grid>
                        }
                    </Grid>
                    <CartDeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} callback={onDelete} />
                </Grid>
            </>
        );
    }
);

const useStyles = makeStyles((theme) => ({
    scrollMargin: {
        scrollMarginTop: '75px'
    },
    disableBorder: {
        border: 'none !important'
    },
    gridImage: {
        width: 250
    },
    gridContent: {
        padding: '0 15px'
    },
    gridPrice: {
        width: 100
    },
    image: {
        borderRadius: 16
    },
    selector: {
        margin: '16px 0 8px 0'
    },
    variant: {
        margin: '16px 0 8px 0'
    },
    updateQuotationStatus: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    addNote: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    manualCancellationRules: {
        textDecoration: 'underline',
        marginBottom: 4,
        cursor: 'pointer'
    },
    containerMargin: {
        marginBottom: 4
    },
    roomIcon: {
        float: 'left',
        marginRight: 4
    },
    inlineBlock: {
        display: 'inline-block'
    },
    cancellationInfo: {
        display: 'inline-block',
        verticalAlign: 'bottom',
        marginLeft: 4,
        cursor: 'pointer'
    },
    transferName: {
        fontSize: 20,
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    hotelInPropertyCollapsed: {
        float: 'left',
        cursor: 'pointer',
        marginRight: 4
    },
    hotelInPropertyIconCollapsed: {
        verticalAlign: 'text-top'
    },
    pin: {
        float: 'left',
        marginRight: 4
    },
    calendar: {
        float: 'left',
        marginRight: 4
    },
    composition: {
        float: 'left',
        marginRight: 4,
        cursor: 'pointer'
    },
    service: {
        float: 'left',
        marginRight: 4
    },
    contractPack: {
        color: '#E6592F',
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    bookingContainer: {
        paddingLeft: 15
    },
    price: {
        textAlign: 'right',
        fontSize: 32,
        fontWeight: 'bold'
    },
    red: {
        textAlign: 'right',
        color: 'red'
    },
    green: {
        textAlign: 'right',
        color: 'green'
    },
    needToBook: {
        color: '#4caf50',
        textAlign: 'right'
    },
    traverlerModification: {
        borderRadius: 16,
        padding: '1px 6px'
    },
    booking: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '4px 0',
        textTransform: 'uppercase'
    },
    bookingCollapsed: {
        borderRadius: 16,
        fontWeight: 700,
        padding: '4px 12px',
        margin: '8px 0 0 0',
        textTransform: 'uppercase'
    },
    bookingDetail: {
        textDecoration: 'underline',
        fontSize: 12,
        textAlign: 'right',
        marginBottom: 4,
        cursor: 'pointer'
    },
    booked: {
        border: '2px solid #4caf50',
        color: '#4caf50'
    },
    pending: {
        border: '2px solid #ff9800',
        color: '#ff9800'
    },
    cached: {
        float: 'left',
        marginRight: 8,
        cursor: 'pointer'
    },
    manualBookingHand: {
        float: 'left',
        marginRight: 8
    },
    cancelled: {
        border: '2px solid black',
        color: 'black'
    },
    error: {
        border: '2px solid #f44336',
        color: '#f44336'
    },
    unavailable: {
        color: '#f44336'
    },
    priceUnavailable: {
        fontSize: 20
    },
    deleteContainer: {
        textAlign: 'right'
    },
    delete: {
        cursor: 'pointer'
    },
    prices: {
        textAlign: 'right'
    },
    errorAlert: {
        marginTop: 8
    },
    alertIcon: {
        alignItems: 'center'
    },
    infoCustomInformation: {
        border: '1px solid #FF9800',
        color: 'white',
        background: '#FF9800',
        marginRight: 5
    },
    editCustomInformation: {
        border: '1px solid'
    },
    deleteCustomInformation: {
        cursor: 'pointer',
        marginLeft: 8
    },
    marginEdit: {
        textDecoration: 'underline',
        color: '#E6592F',
        cursor: 'pointer'
    },
    validateButton: {
        backgroundColor: '#E6592F',
        color: 'white'
    },
    detailIcon: {
        float: 'left',
        marginRight: 4
    },
    helpIcon: {
        display: 'inline-block',
        verticalAlign: 'bottom',
        marginLeft: 4,
        cursor: 'pointer'
    },
    additionalInformation: {
        backgroundColor: '#2196f3',
        color: 'white'
    },
    additionalInformationExpandIcon: {
        color: 'white'
    },
    additionalInformationInfoIcon: {
        marginRight: 8
    },
    additionalInformationDetails: {
        display: 'block'
    },
    hideMargin: {
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    hideProduct: {
        background: "#eeee",
        border: "solid 2px #E6592F",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    inQuotation: {
        border: "solid 2px #43D67D",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    isOption: {
        border: "solid 2px #eeee",
        padding: 15,
        borderRadius: 10,
        marginBottom: 20
    },
    progress: {
        color: green[500],
        position: 'absolute',
        top: "16%",
        left: "43%",
        marginLeft: -12
    },
    checkboxContainer: {
        position: 'relative',
        marginTop: '-5px'
    },
    cancelButton: {
        border: '1px solid',
        backgroundColor: 'white',
        color: '#E6592F'
    },
    cancelConditionAlert: {
        "whiteSpace": 'pre-wrap',
        "&& .MuiAlert-action": {
            minWidth: 140
        }
    },
    editTime: {
        "cursor": 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    remarkExpand: {
        "&& .MuiAlert-action": {
            display: "block !important",
            width: '18%',
            [theme.breakpoints.down('md')]: {
                width: '21%'
            }
        }
    },
}));
