import { flatten, isNumber } from "lodash";
import { transformStepInputsToGroups } from "../../Itinerary/utils/transformStepInputsToGroups";
import { sortItinerary } from "../../Itinerary/utils/sortItinerary";
import { checkIfOnRoad } from "./checkIfOnRoad";
import { findProductAssociatedItineraryStep } from "./findProductAssociatedItineraryStep";
import { AccommodationCart } from "../../Itinerary/objects/accommodationCart";
import { CarCart } from "../../Itinerary/objects/carCart";
import { Itinerary } from "../../Itinerary/objects/itinerary";
import { PoiCart } from "../../Itinerary/objects/poiCart";
import { TransferCart } from "../../Itinerary/objects/transferCart";
import { ManualProduct } from "../../../Reducers/objects/manualProduct";
import { ManualProductPoiCart } from "../../Itinerary/objects/manualProductPoiCart";
import { ManualProductAccommodationCart } from "../../Itinerary/objects/manualProductAccommodationCart";
import { ManualProductTransferCart } from "../../Itinerary/objects/manualProductTransferCart";
import { isProductPackaged } from "./isProductPackaged";
import { TripVersion } from "../../Menu/MaterialTripList/objects/tripVersion";

type ProductType = 'accommodation' | 'car' | 'poi' | 'transfer' | 'manual'

type Options<T extends ProductType> = (
    T extends 'accommodation' ?
    { product: AccommodationCart } :
    T extends 'car' ?
    { product: CarCart } :
    T extends 'poi' ?
    { product: PoiCart } :
    T extends 'transfer' ?
    { product: TransferCart } :
    T extends 'manual' ?
    { product: ManualProduct } :
    never
) & {
    stackInfos: TripVersion['stack_info'],
    itinerary: Itinerary[]
}

type Return<T extends ProductType> = T extends 'accommodation' ?
    ReturnType<typeof findItineraryErrorForAccommodation> :
    T extends 'car' ?
    ReturnType<typeof findItineraryErrorForCar> :
    T extends 'poi' ?
    ReturnType<typeof findItineraryErrorForPoi> :
    T extends 'transfer' ?
    ReturnType<typeof findItineraryErrorForTransfer> :
    T extends 'manual' ?
    ReturnType<typeof findItineraryErrorForManualProduct> :
    never

export function findProductItineraryError<T extends ProductType>(
    type: T,
    options: Options<T>
): Return<T> {
    if (
        isProductPackaged({
            product: options.product,
            stackInfos: options.stackInfos,
            connected: true
        })
    ) {
        return { found: true, iti_error: null } as Return<T>;
    }

    switch (type) {
        case 'accommodation': return findItineraryErrorForAccommodation(
            options.product as AccommodationCart,
            options.itinerary,
            options.stackInfos
        ) as any;
        case 'car': return findItineraryErrorForCar(
            options.product as CarCart,
            options.itinerary,
            options.stackInfos
        ) as any;
        case 'poi': return findItineraryErrorForPoi(
            options.product as PoiCart,
            options.itinerary,
            options.stackInfos
        ) as any;
        case 'transfer': return findItineraryErrorForTransfer(
            options.product as TransferCart,
            options.itinerary,
            options.stackInfos
        ) as any;
        case 'manual': return findItineraryErrorForManualProduct(
            options.product as ManualProduct,
            options.itinerary,
            options.stackInfos
        ) as any;
    }
    throw new Error('Unknown product.');
}

function findItineraryErrorForAccommodation(
    product: AccommodationCart | ManualProductAccommodationCart,
    itinerary: Itinerary[],
    stackInfos: TripVersion['stack_info']
) {
    const groups = transformStepInputsToGroups(itinerary);
    const searchableItinerary = isProductPackaged({
        product,
        stackInfos,
        connected: true
    }) ?
        flatten(
            groups.filter((item) => {
                return item[0] &&
                    (
                        isNumber(item[0].circuit) ||
                        isNumber(item[0].iti_type)
                    ) &&
                    item[0].circuit === product.from_circuit;
            })
        ) :
        itinerary;
    const steps = searchableItinerary.filter((item) => {
        return item.step_type === 'STEP';
    }).sort(sortItinerary);
    const associatedStep = findProductAssociatedItineraryStep({
        type: 'start',
        itinerary: steps,
        product,
        granularity: 'days'
    });

    if (associatedStep) {
        return {
            found: true,
            iti_error: !(
                (
                    window.moment.utc(product.start_date ?? undefined).isSameOrAfter(window.moment.utc(associatedStep.start_date), 'd') ||
                    window.moment.utc(product.start_date ?? undefined).isSameOrAfter(window.moment.utc(associatedStep.start_date).subtract(8, 'hours'), 'd')
                ) &&
                (
                    (
                        window.moment.utc(product.end_date ?? undefined).isValid() ?
                            window.moment.utc(product.end_date ?? undefined) :
                            window.moment.utc(product.start_date ?? undefined)
                    ).isSameOrBefore(window.moment.utc(associatedStep.end_date), 'd') ||
                    (
                        window.moment.utc(product.end_date ?? undefined).isValid() ?
                            window.moment.utc(product.end_date ?? undefined) :
                            window.moment.utc(product.start_date ?? undefined)
                    ).isSameOrBefore(window.moment.utc(associatedStep.end_date).add(8, 'hours'), 'd')
                )
            ) ?
                associatedStep :
                null
        };
    }

    const hasDestinationOutsidePackage = !!findProductAssociatedItineraryStep({
        type: 'start',
        itinerary: itinerary.filter((item) => {
            return item.step_type === 'STEP';
        }).sort(sortItinerary),
        product,
        granularity: 'days'
    });

    return { found: hasDestinationOutsidePackage, iti_error: null };
}

function findItineraryErrorForCar(
    product: CarCart,
    itinerary: Itinerary[],
    stackInfos: TripVersion['stack_info']
) {
    const groups = transformStepInputsToGroups(itinerary);
    const searchableItinerary = isProductPackaged({
        product,
        stackInfos,
        connected: true
    }) ?
        flatten(
            groups.filter((item) => {
                return item[0] &&
                    (
                        isNumber(item[0].circuit) ||
                        isNumber(item[0].iti_type)
                    ) &&
                    item[0].circuit === product.from_circuit;
            })
        ) :
        itinerary;
    const steps = searchableItinerary.filter((item) => {
        return item.step_type === 'STEP';
    }).sort(sortItinerary);
    const associatedSteps = {
        start: findProductAssociatedItineraryStep({
            type: 'start',
            itinerary: steps,
            product,
            granularity: 'days'
        }),
        end: findProductAssociatedItineraryStep({
            type: 'end',
            itinerary: steps,
            product,
            granularity: 'days'
        })
    };

    let startHasError = false;

    if (associatedSteps.start) {
        startHasError = !(
            window.moment.utc(product.start_date).isSameOrAfter(window.moment.utc(associatedSteps.start.start_date), 'd') &&
            window.moment.utc(product.start_date).isSameOrBefore(window.moment.utc(associatedSteps.start.end_date), 'd')
        );
    }

    let endHasError = false;

    if (associatedSteps.end) {
        endHasError = !(
            (
                window.moment.utc(product.end_date).isValid() ?
                    window.moment.utc(product.end_date) :
                    window.moment.utc(product.start_date)
            ).isSameOrAfter(window.moment.utc(associatedSteps.end.start_date), 'd') &&
            (
                window.moment.utc(product.end_date).isValid() ?
                    window.moment.utc(product.end_date) :
                    window.moment.utc(product.start_date)
            ).isSameOrBefore(window.moment.utc(associatedSteps.end.end_date), 'd')
        );
    }

    const completeItinerary = itinerary.filter((item) => {
        return item.step_type === 'STEP';
    }).sort(sortItinerary);

    const hasStartDestinationOutsidePackage = !!findProductAssociatedItineraryStep({
        type: 'start',
        itinerary: completeItinerary,
        product,
        granularity: 'days'
    });

    const hasEndDestinationOutsidePackage = !!findProductAssociatedItineraryStep({
        type: 'end',
        itinerary: completeItinerary,
        product,
        granularity: 'days'
    });

    return {
        found: {
            start: associatedSteps.start ?
                !!associatedSteps.start :
                hasStartDestinationOutsidePackage,
            end: associatedSteps.end ?
                !!associatedSteps.end :
                hasEndDestinationOutsidePackage
        },
        iti_error: {
            start: startHasError ? associatedSteps.start : null,
            end: endHasError ? associatedSteps.end : null
        }
    };
}

function findItineraryErrorForPoi(
    product: PoiCart | ManualProductPoiCart,
    itinerary: Itinerary[],
    stackInfos: TripVersion['stack_info']
) {
    const groups = transformStepInputsToGroups(itinerary);
    const searchableItinerary = isProductPackaged({
        product,
        stackInfos,
        connected: true
    }) ?
        flatten(
            groups.filter((item) => {
                return item[0] &&
                    (
                        isNumber(item[0].circuit) ||
                        isNumber(item[0].iti_type)
                    ) &&
                    item[0].circuit === product.from_circuit;
            })
        ) :
        itinerary;
    const steps = searchableItinerary.filter((item) => {
        return item.step_type === 'STEP';
    }).sort(sortItinerary);
    let associatedStep = findProductAssociatedItineraryStep({
        type: 'start',
        itinerary: steps,
        product,
        granularity: 'seconds'
    });
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    if (associatedStep) {
        if (
            checkIfOnRoad({
                type: 'poi',
                itinerary: steps,
                step: associatedStep,
                product
            })
        ) {
            return { found: true, iti_error: null };
        }

        const index = steps.findIndex((item) => {
            return item.id === associatedStep!.id;
        });
        let previousStep = steps[index - 1];
        previousStep = previousStep?.destination?.id === product.end_destination?.id ?
            previousStep :
            undefined;

        if (quotationCode !== 'cercledesvoyages') {
            previousStep = undefined;
        }

        return {
            found: true,
            iti_error: !(
                window.moment.utc(product.start_date).isSameOrAfter(window.moment.utc(associatedStep.start_date)) &&
                window.moment.utc(product.start_date).isSameOrBefore(window.moment.utc(associatedStep.end_date))
            ) ?
                previousStep ?? associatedStep :
                null
        };
    }

    const hasDestinationOutsidePackage = !!findProductAssociatedItineraryStep({
        type: 'start',
        itinerary: itinerary.filter((item) => {
            return item.step_type === 'STEP';
        }).sort(sortItinerary),
        product,
        granularity: 'seconds'
    });

    return { found: hasDestinationOutsidePackage, iti_error: null };
}

function findItineraryErrorForTransfer(
    product: TransferCart | ManualProductTransferCart,
    itinerary: Itinerary[],
    stackInfos: TripVersion['stack_info']
) {
    const groups = transformStepInputsToGroups(itinerary);
    const searchableItinerary = isProductPackaged({
        product,
        stackInfos,
        connected: true
    }) ?
        flatten(
            groups.filter((item) => {
                return item[0] &&
                    (
                        isNumber(item[0].circuit) ||
                        isNumber(item[0].iti_type)
                    ) &&
                    item[0].circuit === product.from_circuit;
            })
        ) :
        itinerary;
    const steps = searchableItinerary.filter((item) => {
        return item.step_type === 'STEP';
    }).sort(sortItinerary);
    const associatedStep = findProductAssociatedItineraryStep({
        type: 'start',
        itinerary: steps,
        product,
        granularity: 'seconds'
    });

    if (associatedStep) {
        if (
            checkIfOnRoad({
                type: 'transfer',
                itinerary: steps,
                step: associatedStep,
                product
            })
        ) {
            return { found: true, iti_error: null };
        }
        return {
            found: true,
            iti_error: !window.moment.utc(product.start_date).isBetween(
                window.moment.utc(associatedStep.start_date),
                window.moment.utc(associatedStep.end_date),
                'minutes',
                '[]'
            ) ?
                associatedStep :
                null
        };
    }

    const hasDestinationOutsidePackage = !!findProductAssociatedItineraryStep({
        type: 'start',
        itinerary: itinerary.filter((item) => {
            return item.step_type === 'STEP';
        }).sort(sortItinerary),
        product,
        granularity: 'seconds'
    });

    return { found: hasDestinationOutsidePackage, iti_error: null };
}

function findItineraryErrorForManualProduct(
    product: ManualProduct,
    itinerary: Itinerary[],
    stackInfos: TripVersion['stack_info']
) {
    switch (product.product_type) {
        case 0:
        case 7: return findItineraryErrorForAccommodation(
            product as ManualProductAccommodationCart,
            itinerary,
            stackInfos
        );
        case 1:
        case 12: return findItineraryErrorForPoi(
            product as ManualProductPoiCart,
            itinerary,
            stackInfos
        );
        case 4: return findItineraryErrorForTransfer(
            product as ManualProductTransferCart,
            itinerary,
            stackInfos
        );
        default: return {
            found: true,
            iti_error: null
        };
    }
}
