import { ReloadCartModalSetStep, ToggleReloadCartModalAndSetStep } from "../../../Actions/Itinerary";
import { SetInfoReloaded } from "../../../Actions/Cart";

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import {SetDataTrip} from "../../../Actions/Trip";

export default (type, dispatch, start_date, end_date, compo, id_step, days, old_step_arr, new_step_arr, is_insert, infos_reloaded, ignore_products, adaltePackages) => {
    const { pass_check, headers } = CheckBeforeRequest();
    if (pass_check) {
//        dispatch(ToggleReloadCartModalAndSetStep(true, 1));
        let url = `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/update_version/`;
        let request = null;

        if (type === "DATE") {
            url += "?type=date";
            request = {
                old_start_date: moment.utc(start_date).format("YYYY-MM-DD"),
                old_end_date: moment.utc(end_date).format("YYYY-MM-DD"),
                ignore_products: ignore_products !== null ? ignore_products : undefined
            };
        }
        if (type === "COMP") {
            url += "?type=composition";
            request = {
                id: compo,
                circuits_info: {
                    ADALTE: adaltePackages.map((adaltePackage) => ({
                        trip_stack_info_id: adaltePackage.stackInfoId,
                        adalte_rooms: adaltePackage.rooms.map((room) => ({
                            ...room,
                            pickup_code: adaltePackage.pickups,
                            chosen_language_code: adaltePackage.languageCode
                        })),
                        chosen_pickup: adaltePackage.pickups,
                        chosen_language_code: adaltePackage.languageCode
                    })),
                    TRAVEL_EXCHANGE: []
                }
            };
        }
        if (type === "STEP") {
            url += "?type=step";
            request = {
                id: id_step,
                days: days,
                old_start_date: start_date !== null ? moment.utc(start_date).format("YYYY-MM-DD") : null,
                old_end_date: end_date !== null ? moment.utc(end_date).format("YYYY-MM-DD") : null,
                insert: is_insert
            };
        }
        if (type === "DRAGDROP") {
            url += "?type=dragdrop";
            request = {
                old: old_step_arr,
                new: new_step_arr
            };
        }
        if (type === "CHECK") {
            url += "?type=check";
            if (infos_reloaded !== null) {
                request = {
                    flight: infos_reloaded
                }
            }
        }
        if (type === "DYNAMIC") {
            url += "?type=dynamic";
            if (infos_reloaded !== null) {
                request = {
                    cars: infos_reloaded.cars,
                    flight: infos_reloaded.flight
                }
            }
        }
        if (type !== "STEP" && type !== "DRAGDROP") {
            dispatch({
                type: "RECALC_SET_TYPE",
                payload: type
            });
            $.ajax({
                method: "PATCH",
                url: url,
                headers: headers,
                data: request !== null ? JSON.stringify(request) : "",
                success: function (data) {
                    console.log('data update version:', data);
                    $.ajax({
                        method: "GET",
                        url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/?token=${GetCookie("trip_token")}`,
                        headers: headers,
                        success: function (data) {
                            dispatch(SetDataTrip(data));
                        }
                    });
                    dispatch({type: "RECALC_SET_INFOS", payload: data});
//                    dispatch(SetInfoReloaded(data, type));
//                    dispatch(ReloadCartModalSetStep(2));
                },
                error: function (error) {
                    console.log(error);
                    dispatch({type: "RECALC_SET_ERROR"});
//                    dispatch(ReloadCartModalSetStep(3));
                }
            });
        }
    }
};

