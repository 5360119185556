import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { makeStyles, useTheme } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';

import { TripListSendMailForm } from "./TripListSendMailForm";
import { LoadingBackDrop } from '../../Common/LoadingBackdrop';

import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetHistoryText from "./Functions/GetHistoryText";
import { useMailTemplateAutocomplete } from "./MailVisualEditor/network/mailTemplateAutocomplete";
import { useMailTemplateRecipients } from "./MailVisualEditor/network/mailTemplateRecipients";

const useStyles = makeStyles(() => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400: {
        fontWeight: 400
    },
    fontWeight700: {
        fontWeight: 700
    },
    fontWeight900: {
        fontWeight: 900
    },
    fontSize12: {
        fontSize: 12
    },
    fontSize14: {
        fontSize: 14
    },
    fontSize20: {
        fontSize: 20
    },
    fontSize24: {
        fontSize: 24
    },
    upperCase: {
        textTransform: "uppercase"
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textLeft: {
        textAlign: "left"
    },
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    genericButton: {
        color: "#FFFFFF",
        backgroundColor: "#2C98F0"
    },
    genericPadding: {
        padding: "20px"
    },
    genericBorder: {
        border: "0.5px solid rgba(0, 0, 0, 0.25)",
        boxSizing: "border-box"
    },
    formControlRoot1: {
        "width": "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '18.5px 14px;'
        },
        "&& .mui-jss-MuiSvgIcon-root": {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 18,
            zIndex: -1
        },
        "& .mui-jss-MuiInputLabel-outlined": {
	    	transform: 'translate(19px, 19px) scale(1)'
	    },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
	    	transform: 'translate(14px, -6px) scale(0.75)'
	    }
    },
    spacer: {
        padding: "10px 0px"
    },
    heightContent: {
        height: 200
    }
}));

const TripListWebsite = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(768));

    const trip_info = useSelector(store => store.menu.trip_info);
    const user = useSelector(store => store.user.user);

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const [showHistory, setShowHistory] = useState(false);
    const [emailSent, setEmailSent] = useState([]);
    const [usersColors, setUsersColors] = useState([]);
    const [loading, setLoading] = useState(false);

    const websiteUrl = useRef(null);
    useMailTemplateAutocomplete();
    useMailTemplateRecipients();

    let url = `${window.origin}/${window.url_name}/summary-quotation/${trip_info.id}/${trip_info.current_version}/${trip_info.token}`;
    let cdv_url = `${window.origin}/quotation/trip?trip=${trip_info.id}&token=${trip_info.token}`;
    const client_map = ["", "TO", t("menu.trip_list.agency"), "MASTER"];

    useEffect(() => {
        refreshHistory();
    }, [trip_info]);

    useEffect(() =>{
        if (emailSent.length !== 0) {
            let temp_users_colors = [];
            emailSent.map((action) => {
                if (!temp_users_colors.some(item => item.user_id === action.user.id)) {
                    let userColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
                    let temp_user_color = { user_id: action.user.id, color: userColor };
                    temp_users_colors.push(temp_user_color);
                }
            });
            setUsersColors(temp_users_colors);
        }
    }, [emailSent]);

    function copyToClipboard() {
        let textField = document.createElement('textarea');
        textField.innerText = url;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    }


    const copyCdvUrl = () => {
        let textField = document.createElement('textarea');
        textField.innerText = cdv_url;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    };

    const refreshHistory = () => {
        let version = trip_info.current_version;
        let { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "GET",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${trip_info.id}/versions/${version}/history/`
            }).then(function (response) {
                let temp_history = [];
                //eslint-disable-next-line @typescript-eslint/no-unused-vars
                for (const [date, actions] of Object.entries(response.data)) {
                    actions.map((action) => {
                        if (action.action === 15) {
                            temp_history.push(action);
                        }
                    });
                }
                setEmailSent(temp_history);
            });
        }
    };

    return (
        <>
            <Grid style={{ padding: "20px 0px" }}>
                <div className={`${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${!mobile ? classes.fontSize24 : classes.fontSize20} ${classes.upperCase} ${classes.letterSpacing}`}>
                    { t("menu.trip_list.client_website") }
                </div>
                <Grid>
                    <Grid className={`${classes.textRight} ${classes.spacer}`}>
                        <Button variant="contained" className={classes.genericButton} onClick={() => setShowHistory(!showHistory)}>
                            { t("menu.trip_list.history") }
                        </Button>
                    </Grid>
                    {
                        emailSent.length !== 0 && showHistory && (
                            <Grid className={classes.genericBorder} style={{ margin: 20, paddingBottom: 14 }}>
                                {
                                    emailSent.map((action) => {
                                        let visibility = action.visibility;
                                        let text = GetHistoryText(action, t, trip_info, null, null, null, user);
                                        let userColor = "";
                                        for (let user_index = 0; user_index < usersColors.length; user_index++) {
                                            if (usersColors[user_index].user_id === action.user.id) {
                                                userColor = usersColors[user_index].color;
                                            }
                                        }
                                        if (visibility === "PUBLIC" || (visibility === "TO" && (client === 1 || client === 3)) || (visibility === "AGENCY" && (client === 2 || client === 3))) {
                                            return (
                                                <Grid container alignItems="center" justify="flex-start" key={`${action.id}`} style={{ paddingTop: 14 }}>
                                                    <Grid item className={classes.textCenter} style={ !mobile ? { paddingLeft: 23 } : { paddingLeft: 0 } }>
                                                        {
                                                            action.user.avatar !== null && (
                                                                <Avatar variant="square" src={ action.user.avatar.thumbnail_little !== null ? action.user.avatar.thumbnail_little : action.user.avatar.url } />
                                                            )
                                                        }
                                                        {
                                                            action.user.avatar === null && (
                                                                <Avatar style={{ backgroundColor: userColor, color: "#FFFFFF" }} variant="square"> <PersonIcon style={{ fontSize: 46 }} /> </Avatar>
                                                            )
                                                        }
                                                    </Grid>
                                                    <Grid item className={classes.textLeft} style={{ paddingLeft: 7 }}>
                                                        <span className={`${classes.genericText} ${classes.fontSize14} ${classes.fontWeight700}`} style={{ color: "#000000" }}>
                                                            {`${action.user.first_name} ${action.user.last_name} ${action.user.trigram !== null ? "(" + action.user.trigram + ")" : ""} / ${action.user.client.name} ${client_map[action.user.client.type]} `}
                                                        </span>
                                                        <span className={`${classes.genericText} ${classes.fontWeight700} ${classes.fontSize12}`}>
                                                            {moment(action.trigger_date).format("LT")}
                                                        </span>
                                                        <div className={`${classes.genericText} ${classes.fontWeight400} ${classes.fontSize14}`}>
                                                            {text}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                    })
                                }
                            </Grid>
                        )
                    }
                    {
                        !['cercledesvoyages', 'tripadekua', 'americavoyages', 'touristforum', 'volonline', 'terreslointaines', 'continentsinsolites', 'africaspirit'].includes(quotation_code) && (
                            <Grid container alignItems="center" justify="space-between" className={classes.spacer}>
                                <Grid item md={2} xs={12} className={`${mobile ? classes.spacer : ""} ${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.upperCase} ${classes.letterSpacing}`}>
                                    { t("menu.trip_list.client_website_url") }
                                </Grid>
                                <Grid item md={9} xs={12} ref={websiteUrl} className={`${mobile ? classes.spacer : ""} ${classes.genericBorder} ${classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${classes.fontSize12} ${classes.letterSpacing}`} style={{ lineBreak: "anywhere" }}>
                                    { url }
                                </Grid>
                                <Grid item md={1} xs={12} className={`${mobile ? classes.spacer : ""} ${classes.textRight}`}>
                                    <Tooltip title={t("menu.trip_list.copy_url")}>
                                        <Button variant="contained" className={classes.genericButton} onClick={copyToClipboard}>
                                            { t("global.copy") }
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        ['cercledesvoyages', 'americavoyages', 'touristforum'].includes(quotation_code) && (
                            <Grid container alignItems="center" justify="space-between" className={classes.spacer}>
                                <Grid item md={2} xs={12} className={`${mobile ? classes.spacer : ""} ${classes.textCenter} ${classes.genericText} ${classes.fontWeight900} ${classes.fontSize12} ${classes.upperCase} ${classes.letterSpacing}`}>
                                    { t("menu.trip_list.client_website_url") }
                                </Grid>
                                <Grid item md={9} xs={12} ref={websiteUrl} className={`${mobile ? classes.spacer : ""} ${classes.genericBorder} ${classes.textCenter} ${classes.genericText} ${classes.fontWeight700} ${classes.fontSize12} ${classes.letterSpacing}`} style={{ lineBreak: "anywhere" }}>
                                    { cdv_url }
                                </Grid>
                                <Grid item md={1} xs={12} className={`${mobile ? classes.spacer : ""} ${classes.textRight}`}>
                                    <Tooltip title={t("menu.trip_list.copy_url")}>
                                        <Button variant="contained" className={classes.genericButton} onClick={copyCdvUrl}>
                                            { t("global.copy") }
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
            <TripListSendMailForm
                actions={['TRIP_CUSTOM', 'SEND_QUOTATION', 'SEND_CONTRACT']}
                setLoading={setLoading}
                checkMinisiteTag
                checkLeadTraveler={ !(quotation_code === "volonline") }
                autofill
            />
            <LoadingBackDrop open={loading} />
        </>
    );
};

export default React.memo(TripListWebsite);
