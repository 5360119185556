import React, { useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
    Alert,
    Box,
    Button,
    CardContent,
    Chip,
    Collapse,
    IconButton,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {
    ArrowRightAltOutlined,
    CloudCircle,
    CloudOff,
    DateRangeOutlined,
    Edit,
    ErrorOutline,
    ExploreOutlined,
    Info,
    PanToolOutlined,
    PeopleOutlineOutlined,
    ReportProblemOutlined,
    RoomOutlined,
    VisibilityOutlined
} from "@mui/icons-material";
import { EcoOutlined } from "@material-ui/icons";
import { flatten, isNumber } from "lodash";
import axios from "axios";
import { CartProductCard, CartProductCardProps } from "./CartProductCard";
import { CartConstructionProductsTableItemMargin } from "./CartConstructionProductsTableItemMargin";
import { CartConstructionProductsTableItemStatus } from "./CartConstructionProductsTableItemStatus";
import { CartProductCardName } from "./CartProductCardName";
import { CartPoiIcon } from "./CartPoiIcon";
import { CartProductCardProviderLogo } from "./CartProductCardProviderLogo";
import { CartProductIconText } from "./CartProductIconText";
import { CartPoiCarDetails } from "./CartPoiCardDetails";
import { CartClientTimeModal } from "./CartClientTimeModal";
import { ProviderQuotationPriceEdit } from "../ProviderQuotation/providerQuotationPriceEdit";
import { ProviderBookingReferenceEdit } from "../ProviderBooking/providerBookingReferenceEdit";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { ProviderContext } from "./utils/providerContext";
import { getPoiPicture } from "./utils/getPoiPicture";
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import { transformStepInputsToGroups } from "../Itinerary/utils/transformStepInputsToGroups";
import { checkIfOnRoad } from "./utils/checkIfOnRoad";
import { findProductAssociatedItineraryStep } from "./utils/findProductAssociatedItineraryStep";
import { findProductItineraryError } from "./utils/findProductItineraryError";
import { findPictureUrl } from "./utils/findPictureUrl";
import { insertDivider } from "./utils/insertDivider";
import { isProductPackaged } from "./utils/isProductPackaged";
import { isProductSamePackage } from "./utils/isProductSamePackage";
import { useGetPrice } from "./utils/getPrice";
import { useShowError } from "../Utils/showError";
import { useProductBookingStatusChange } from "../ProviderBooking/network/productBookingStatusChange";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from "../Common/Functions/GetCookie";
import { PoiCart } from "../Itinerary/objects/poiCart";
import { StatusBooking } from "../Itinerary/objects/statusBooking";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    poi: PoiCart
} & Pick<CartProductCardProps, 'alwaysOpen'>

const DEFAULT_PICTURE = '/Img/destination_default.jpg';

export const CartPoiCard = withRouter<Props>(
    function CartPoiCard(props): JSX.Element | null {
        const { t, i18n } = useTranslation();
        const dispatch = useDispatch();
        const user = useSelector((state: AppState) => state.user.user);
        const locale = useSelector((state: AppState) => {
            return state.user.locales.find((item) => {
                return item.language_code === i18n.language;
            })?.id ?? 1;
        });
        const itinerary_list = useSelector((state: AppState) => state.itinerary.itinerary_list);
        const providers = useSelector((state: AppState) => state.trip.providers);
        const trip = useSelector((state: AppState) => state.trip.data_trip);
        const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
        const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
        const manualProviders = useSelector((state: AppState) => state.trip.manual_providers);
        const [editTime, setEditTime] = useState(false);
        const [editPrice, setEditPrice] = useState(false);
        const [editBookingReference, setEditBookingReference] = useState(false);
        const [loading, setLoading] = useState(false);
        const pictures = useMemo(() => {
            const pictures = getPoiPicture(props.poi);

            return pictures.length > 0 ?
                pictures.map((picture) => {
                    return findPictureUrl(picture);
                }) :
                [DEFAULT_PICTURE];
        }, [props.poi]);
        const provider = useMemo(() => {
            return providers.concat(manualProviders).find((item) => {
                return item.provider.id === props.poi.provider;
            })?.provider;
        }, [props.poi, providers, manualProviders]);
        const { found, iti_error } = useMemo(() => {
            return findProductItineraryError(
                'poi',
                {
                    product: props.poi,
                    itinerary,
                    stackInfos: trip?.stack_info ?? null
                }
            );
        }, [
            itinerary,
            props.poi,
            trip
        ]);
        const isOnRoad = useMemo(() => {
            const groups = transformStepInputsToGroups(itinerary_list);
            const searchableItinerary = isProductPackaged({
                product: props.poi,
                stackInfos: trip?.stack_info ?? null
            }) ?
                flatten(
                    groups.filter((item) => {
                        return item[0] &&
                            (
                                isNumber(item[0].circuit) ||
                                isNumber(item[0].iti_type)
                            ) &&
                            item[0].circuit === props.poi.from_circuit;
                    })
                ) :
                itinerary;
            const steps = searchableItinerary.filter((item) => {
                return item.step_type === 'STEP';
            }).sort(sortItinerary);
            const associatedStep = findProductAssociatedItineraryStep({
                type: 'start',
                itinerary: steps,
                product: props.poi,
                granularity: 'seconds'
            });

            if (associatedStep) {
                return checkIfOnRoad({
                    type: 'poi',
                    itinerary: steps,
                    step: associatedStep,
                    product: props.poi
                });
            }

            return false;
        }, [props.poi, itinerary_list]);
        const getPrice = useGetPrice();
        const totalCost = getPrice(props.poi.prices);
        const showError = useShowError();
        const changeBookingStatus = useProductBookingStatusChange({
            onTrigger() {
                setLoading(true);
            },
            onSuccess(product) {
                dispatch({
                    type: 'POI_EDIT_CART_BY_ID',
                    payload: product
                });
            },
            onError(error) {
                console.error(error);
                showError(error);
            },
            onFinally() {
                setLoading(false);
            }
        });
        const providerContext = useContext(ProviderContext);
        const variantName = (
            // eslint-disable-next-line no-nested-ternary
            props.poi.cust_variant &&
                !isNumber(props.poi.cust_variant) ?
                props.poi.cust_variant.localization?.find((item) => {
                    return item.locale === locale;
                })?.title ??
                props.poi.cust_variant.name :
                (
                    props.poi.variant && !isNumber(props.poi.variant) ?
                        props.poi.variant.localization?.find((item) => {
                            return item.locale === locale;
                        })?.title ??
                        props.poi.variant.name :
                        props.poi.rate_name ??
                        null
                )
        );
        const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

        const confirmTravelerModification = () => {
            const { headers } = CheckBeforeRequest();
            axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${props.poi.id}/`,
                data: {
                    traveler_modification: null
                }
            }).then((response) => {
                dispatch({ type: 'POI_EDIT_CART_BY_ID', payload: response.data });
            }).catch((error) => {
                console.log(error);
            });
        };

        const onShowBookingDetail = () => {
            dispatch({ type: 'CART_TOGGLE_BOOKING_DETAIL', payload: props.poi });
        };

        const onGoToPoi = () => {
            dispatch({
                type: 'POI_SET_DATE_AND_GROUP',
                payload: {
                    date: window.moment.utc(props.poi.start_date).format("YYYY-MM-DD"),
                    group: [props.poi.group_passenger]
                }
            });
            itinerary_list.map(step => {
                if (step.destination && props.poi.start_destination && step.destination.id === props.poi.start_destination.id) {
                    if (window.moment.utc(props.poi.start_date).isSameOrAfter(window.moment.utc(step.start_date), 'd') && window.moment.utc(props.poi.end_date).isSameOrBefore(window.moment.utc(step.end_date), 'd')) {
                        const bounds = JSON.parse(step.destination.data?.bounds?.replace(/'/g, '"') ?? '{}');
                        dispatch({
                            type: "MATERIAL_SET_ACTIVE_DESTINATION",
                            payload: {
                                active: step.id,
                                bounds: {
                                    north: bounds.north,
                                    east: bounds.east,
                                    south: bounds.south,
                                    west: bounds.west
                                },
                                zoom: step.destination.data?.zoom_level ?? 4,
                                start_date: step.start_date.split('T')[0],
                                end_date: step.end_date.split('T')[0],
                                destination: step,
                                reset: true
                            }
                        });
                    }
                }
            });
            props.router.push(`/${window.url_name}/apps/poi-card/${props.poi.is_custom ? encodeURIComponent(props.poi.custom_product?.id ?? '-1') : encodeURIComponent(props.poi.custom_product?.product_code ?? 'none')}`);
        };

        const onChangeBookingReference = (reference: string) => {
            if (
                providerContext.module === 'booking' &&
                providerContext.tripId &&
                providerContext.version
            ) {
                changeBookingStatus({
                    tripId: providerContext.tripId,
                    version: providerContext.version,
                    productId: props.poi.id,
                    ref: reference,
                    status: props.poi.booking_status?.status_booking ?? null,
                    isManual: false,
                    providerToken: providerContext.providerToken
                });
            }
        }

        return (
            <>
                <CartProductCard
                    type="poi"
                    item={props.poi}
                    mainContent={
                        <Stack>
                            <CartProductCardName
                                type="poi"
                                content={
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        columnGap={1}
                                        sx={{
                                            cursor: !providerContext.module ? 'pointer' : undefined
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                textDecoration: !providerContext.module ? 'underline' : undefined
                                            }}
                                            component="span"
                                        >
                                            {
                                                (() => {
                                                    const poiLocalization = props.poi.localization.find((item) => {
                                                        return item.locale === locale;
                                                    });
                                                    if (poiLocalization) {
                                                        return poiLocalization.name;
                                                    }
                                                    const localization = props.poi.custom_product?.localization?.find((item) => {
                                                        return item.locale === locale;
                                                    });
                                                    if (localization) {
                                                        return (localization?.title.length ?? 0) > 0 ?
                                                            localization?.title :
                                                            props.poi.custom_product?.title;
                                                    }
                                                    return props.poi.custom_product?.title;
                                                })()
                                            }
                                            {
                                                variantName &&
                                                ` (${variantName})`
                                            }
                                        </Box>
                                        {
                                            props.poi.custom_product?.label_eco &&
                                            <EcoOutlined
                                                viewBox="4 4 16 16"
                                                style={{
                                                    color: '#4caf50',
                                                    fontSize: '1em',
                                                    marginTop: '-7.5px'
                                                }}
                                            />
                                        }
                                    </Stack>
                                }
                                modifiedName={
                                    (() => {
                                        const poiLocalization = props.poi.localization.find((item) => {
                                            return item.locale === locale;
                                        });
                                        if (poiLocalization) {
                                            return poiLocalization.name;
                                        }
                                        const localization = props.poi.custom_product?.localization?.find((item) => {
                                            return item.locale === locale;
                                        });
                                        if (localization) {
                                            return (localization?.title.length ?? 0) > 0 ?
                                                localization?.title ?? '' :
                                                props.poi.custom_product?.title ?? '';
                                        }
                                        return props.poi.custom_product?.title ?? '';
                                    })()
                                }
                                providerName={
                                    props.poi.custom_product?.localization?.find((item) => {
                                        return item.locale === locale;
                                    })?.title ??
                                    props.poi.custom_product?.title ??
                                    ''
                                }
                                localization={props.poi.localization}
                                customLocalization={
                                    (
                                        props.poi.custom_product?.localization?.findIndex((item) => {
                                            return item.locale === locale;
                                        }) ?? -1
                                    ) >= 0 ?
                                        props.poi.custom_product?.localization?.map((item) => {
                                            return {
                                                locale: item.locale,
                                                name: item.title,
                                                description: item.short_description,
                                                cancel_text_condition: ''
                                            };
                                        }) :
                                        [
                                            {
                                                locale,
                                                name: props.poi.custom_product?.title ?? '',
                                                description: props.poi.custom_product?.short_description ?? '',
                                                cancel_text_condition: ''
                                            }
                                        ]
                                }
                                id={props.poi.id}
                                startIcon={
                                    props.poi.poi_type ?
                                        CartPoiIcon :
                                        VisibilityOutlined
                                }
                                onClick={
                                    !providerContext.module ?
                                        onGoToPoi :
                                        undefined
                                }
                                isCustom={props.poi.is_custom}
                                isProposition={props.poi.provider_created}
                            />
                            {
                                props.poi.poi_type &&
                                <CartProductIconText
                                    text={t('global.poi')}
                                    startIcon={Info}
                                    color="#FF9C37"
                                />
                            }
                            {
                                !isOnRoad &&
                                <CartProductIconText
                                    text={
                                        <>
                                            {
                                                props.poi.start_destination?.data?.localization.find((item) => {
                                                    return item.locale === locale;
                                                })?.name ??
                                                props.poi.start_destination?.data?.international_name ??
                                                ''
                                            }
                                        </>
                                    }
                                    startIcon={RoomOutlined}
                                />
                            }
                            <CartProductIconText
                                text={
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <div>
                                            {
                                                !window.moment.utc(props.poi.start_date).isSame(props.poi.end_date) &&
                                                t(
                                                    'cart-material.cart-construction-products-table-date',
                                                    {
                                                        from: window.moment.utc(props.poi.start_date).format('LLL'),
                                                        to: window.moment.utc(props.poi.end_date).format('LLL')
                                                    }
                                                )
                                            }
                                            {
                                                window.moment.utc(props.poi.start_date).isSame(props.poi.end_date) &&
                                                window.moment.utc(props.poi.start_date).format('LLL')
                                            }
                                        </div>
                                        {
                                            (
                                                !window.moment.utc(props.poi.provider_start_date).isSame(window.moment.utc(props.poi.start_date), 'minutes') ||
                                                !window.moment.utc(props.poi.provider_end_date).isSame(window.moment.utc(props.poi.end_date), 'minutes')
                                            ) &&
                                            <Tooltip title={t('cart-material.cart-construction-dates-changed-hint')}>
                                                <PanToolOutlined sx={{ fontSize: '1.2em' }} />
                                            </Tooltip>
                                        }
                                        {
                                            (
                                                props.poi.is_custom ||
                                                props.poi.booking_status
                                            ) &&
                                            (
                                                quotation_code !== 'visiteurs' ||
                                                user?.client_full?.type !== 2
                                            ) &&
                                            <IconButton size="small" onClick={() => setEditTime(true)}>
                                                <Edit fontSize="inherit" />
                                            </IconButton>
                                        }
                                    </Stack>
                                }
                                startIcon={DateRangeOutlined}
                            />
                            {
                                isOnRoad &&
                                <CartProductIconText
                                    text={
                                        t(
                                            'cart-material.cart-construction-on-road',
                                            {
                                                from: (
                                                    isOnRoad[0].destination?.data?.localization.find((item) => {
                                                        return item.locale === locale;
                                                    })?.name ??
                                                    isOnRoad[0].destination?.data?.name ??
                                                    isOnRoad[0].destination?.data?.international_name
                                                )?.split(',')[0],
                                                to: (
                                                    isOnRoad[1].destination?.data?.localization.find((item) => {
                                                        return item.locale === locale;
                                                    })?.name ??
                                                    isOnRoad[1].destination?.data?.name ??
                                                    isOnRoad[1].destination?.data?.international_name
                                                )?.split(',')[0]
                                            }
                                        )
                                    }
                                    startIcon={ExploreOutlined}
                                    color="#FF8100"
                                />
                            }
                            <CartProductIconText
                                text={
                                    [
                                        t(
                                            'roadbook.adults-count',
                                            {
                                                count: props.poi.group_passenger.travelers_list.filter((traveler) => {
                                                    const result = window.moment.utc(tripEndDate).diff(
                                                        window.moment.utc(traveler.birth_date),
                                                        'years'
                                                    );
                                                    return result >= 18;
                                                }).length
                                            }
                                        ),
                                        (() => {
                                            const childrenCount = props.poi.group_passenger.travelers_list.filter((traveler) => {
                                                return window.moment.utc(tripEndDate).diff(
                                                    window.moment.utc(traveler.birth_date),
                                                    'years'
                                                ) < 18;
                                            }).length;
                                            return childrenCount > 0 ?
                                                t(
                                                    'roadbook.children-count',
                                                    { count: childrenCount }
                                                ) :
                                                null;
                                        })()
                                    ].filter((item) => item).join(', ')
                                }
                                startIcon={PeopleOutlineOutlined}
                            />
                        </Stack>
                    }
                    sideContent={
                        <Stack spacing={1} alignItems="center">
                            <Typography variant="caption" sx={{ opacity: 0.6 }}>
                                {t('cart-material.cart-construction-amount')}
                            </Typography>
                            <ProviderQuotationPriceEdit
                                productId={props.poi.id}
                                price={
                                    (
                                        providerContext.module ?
                                            totalCost.purchaseCost :
                                            totalCost.cost
                                    ).toString()
                                }
                                open={editPrice}
                                bothEntitiesAccepted={
                                    props.poi.provider_accepted &&
                                    props.poi.agent_accepted
                                }
                                providerWantsModification={props.poi.provider_want_modification}
                                onOpen={() => setEditPrice(true)}
                                onClose={() => setEditPrice(false)}
                            >
                                <Typography fontWeight="bold" textAlign="center">
                                    {
                                        new Intl.NumberFormat(
                                            i18n.language,
                                            {
                                                style: 'currency',
                                                currency: providerContext.module ?
                                                    totalCost.purchaseCurrency?.iso_code ?? 'EUR' :
                                                    totalCost.currency?.iso_code ?? 'EUR'
                                            }
                                        ).format(
                                            providerContext.module ?
                                                totalCost.purchaseCost :
                                                totalCost.cost
                                        )
                                    }
                                    {
                                        !providerContext.module &&
                                        props.poi.price_change &&
                                        props.poi.price_change.price_variation !== 0 &&
                                        <Stack direction="row" alignItems="center" justifyContent="center">
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    color: props.poi.price_change.price_variation < 0 ? 'green' : 'red',
                                                    fontSize: '10px'
                                                }}
                                            >
                                                {
                                                    new Intl.NumberFormat(
                                                        i18n.language,
                                                        {
                                                            style: 'currency',
                                                            currency: totalCost.currency?.iso_code ?? 'EUR'
                                                        }
                                                    ).format(props.poi.price_change.price_variation)
                                                }
                                            </Typography>
                                            <ArrowRightAltOutlined
                                                sx={{
                                                    transform: props.poi.price_change.price_variation < 0 ?
                                                        'rotateZ(90deg)' :
                                                        'rotateZ(-90deg)',
                                                    color: 'green',
                                                    fontSize: '14px'
                                                }}
                                            />
                                        </Stack>
                                    }
                                </Typography>
                            </ProviderQuotationPriceEdit>
                            {
                                !props.poi.poi_type &&
                                <CartProductCardProviderLogo
                                    logoUrl={
                                        provider?.logo?.url ??
                                        provider?.logo?.thumbnail_big ??
                                        provider?.logo?.thumbnail_medium ??
                                        provider?.logo?.thumbnail_little ??
                                        null
                                    }
                                    name={provider?.name ?? null}
                                />
                            }
                        </Stack>
                    }
                    footer={(providerActions, providerInfo) => (
                        <div>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                {
                                    insertDivider(
                                        [
                                            !isProductPackaged({
                                                product: props.poi,
                                                stackInfos: trip?.stack_info ?? null,
                                                connected: true
                                            }) &&
                                            <>
                                                <CartConstructionProductsTableItemStatus
                                                    type="poi"
                                                    item={props.poi}
                                                    bookingProcessState={props.poi.booking_process_state}
                                                    bookingStatus={props.poi.booking_status}
                                                    agencyNeedToBook={props.poi.agency_need_to_book}
                                                />
                                                {
                                                    !trip?.stack_info?.find((item) => {
                                                        return isProductSamePackage(
                                                            {
                                                                stack_number: item.stack_number,
                                                                stack_info_id: item.is_custom && !props.poi.stack_info_id ?
                                                                    props.poi.stack_info_id :
                                                                    item.id
                                                            },
                                                            {
                                                                stack_number: props.poi.stack_number,
                                                                stack_info_id: props.poi.stack_info_id
                                                            }
                                                        );
                                                    })?.booking_status?.item_reference &&
                                                    (
                                                        props.poi.booking_status?.status_booking === StatusBooking.CONFIRMED ||
                                                        props.poi.booking_status?.status_booking === StatusBooking.PENDING ||
                                                        props.poi.booking_status?.status_booking === StatusBooking.WAITING
                                                    ) &&
                                                    <ProviderBookingReferenceEdit
                                                        open={editBookingReference}
                                                        productId={props.poi.id}
                                                        reference={props.poi.booking_status?.item_reference ?? ''}
                                                        onOpen={() => setEditBookingReference(true)}
                                                        onClose={() => setEditBookingReference(false)}
                                                        onSave={onChangeBookingReference}
                                                    >
                                                        <Typography fontWeight={100} fontSize="0.75rem">
                                                            {
                                                                t(
                                                                    'cart-material.cart-construction-reference',
                                                                    { ref: props.poi.booking_status?.item_reference }
                                                                )
                                                            }
                                                        </Typography>
                                                    </ProviderBookingReferenceEdit>
                                                }
                                            </>,
                                            providerActions,
                                            !providerContext.module &&
                                            !isProductPackaged({
                                                product: props.poi,
                                                stackInfos: trip?.stack_info ?? null,
                                                connected: true
                                            }) &&
                                            (
                                                user?.client_full?.type !== 2 ||
                                                props.poi.creator?.client?.type === user.client_full.type
                                            ) &&
                                            <>
                                                {
                                                    !props.poi.is_custom ?
                                                        <Chip
                                                            label={t('cart-material.flux')}
                                                            size="small"
                                                            sx={{
                                                                backgroundColor: '#2ACAEA',
                                                                color: '#fff'
                                                            }}
                                                            icon={<CloudCircle color="inherit" />}
                                                        /> :
                                                        <Chip
                                                            size="small"
                                                            label={t('cart-material.offline')}
                                                            sx={{
                                                                backgroundColor: '#6A329F',
                                                                color: '#fff'
                                                            }}
                                                            icon={<CloudOff color="inherit" />}
                                                        />
                                                }
                                            </>,
                                            user?.client_full?.type !== 2 &&
                                            props.poi.traveler_modification &&
                                            <Chip
                                                color="warning"
                                                label={
                                                    props.poi.traveler_modification === 'SET_IN_TRIP' ?
                                                        t('cart-material.add-to-cart-traveler') :
                                                        t('cart-material.add-to-option-traveler')
                                                }
                                                size="small"
                                                icon={<ErrorOutline color="inherit" />}
                                                onDelete={confirmTravelerModification}
                                            />,
                                            props.poi.booking_status?.status_booking !== StatusBooking.CONFIRMED &&
                                            props.poi.on_request &&
                                            <Typography variant="caption">
                                                {t('cart-material.on-request')}
                                            </Typography>,
                                            !isProductPackaged({
                                                product: props.poi,
                                                stackInfos: trip?.stack_info ?? null
                                            }) &&
                                            !props.poi.is_cancellable &&
                                            <Box sx={{ whiteSpace: 'nowrap' }}>
                                                <CartProductIconText
                                                    text={t('cart-material.not-cancellable')}
                                                    startIcon={ReportProblemOutlined}
                                                    color="#FF8100"
                                                />
                                            </Box>
                                        ]
                                    )
                                }
                            </Stack>
                            {
                                !props.poi.poi_type &&
                                props.poi.booking_status &&
                                <Button
                                    sx={{
                                        padding: 0,
                                        textTransform: 'none',
                                        color: '#000',
                                        textDecoration: 'underline'
                                    }}
                                    onClick={onShowBookingDetail}
                                >
                                    {t('cart-material.cart-construction-details-and-cancellation')}
                                </Button>
                            }
                            {providerInfo}
                        </div>
                    )}
                    alerts={
                        <Collapse in={!found || !!iti_error || (!!props.poi.questions && props.poi.questions.filter((question) => question.required).length > 0)} unmountOnExit>
                            <CardContent
                                sx={{
                                    borderTop: !found || !!iti_error ?
                                        '1px solid rgba(0, 0, 0, 0.25)' :
                                        undefined
                                }}
                            >
                                <Stack spacing={1}>
                                    {
                                        iti_error &&
                                        <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                                            {t('cart-material.itinerary-error-1')}{' '}
                                            {
                                                iti_error.destination?.data?.localization.find((item) => {
                                                    return item.locale === locale;
                                                })?.name ??
                                                iti_error.destination?.data?.name ??
                                                iti_error.destination?.data?.international_name
                                            } {t('global.from')}{' '}
                                            {window.moment.utc(iti_error.start_date).format('DD/MM/YYYY HH:mm')}{' '}
                                            {t('global.to')} {window.moment.utc(iti_error.end_date).format('DD/MM/YYYY HH:mm')}.{' '}
                                            {t('cart-material.itinerary-error-2')}.
                                        </Alert>
                                    }
                                    {
                                        !found &&
                                        <Alert variant="filled" severity="error" sx={{ backgroundColor: '#E74432' }}>
                                            {t('cart-material.no-destination-found')}
                                        </Alert>
                                    }
                                    {
                                        (!!props.poi.questions && props.poi.questions.filter((question) => question.required).length > 0) &&
                                        <Alert variant="filled" severity="warning">
                                            {t<string>('cart-material.required-questions')}
                                        </Alert>
                                    }
                                </Stack>
                            </CardContent>
                        </Collapse>
                    }
                    margin={<MarginWrapper poi={props.poi} />}
                    pictures={pictures}
                    alwaysOpen={props.alwaysOpen}
                >
                    <CartPoiCarDetails poi={props.poi} />
                </CartProductCard>
                {
                    user?.client_full?.type !== 2 &&
                    editTime &&
                    <CartClientTimeModal
                        type="normal"
                        product={props.poi}
                        onClose={() => setEditTime(false)}
                    />
                }
                <LoadingBackDrop open={loading} />
            </>
        );
    }
);

type MarginWrapperProps = {
    poi: PoiCart
}

function MarginWrapper(props: MarginWrapperProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <Collapse
            in={
                seeAllProductsMargins &&
                (
                    !isProductPackaged({
                        product: props.poi,
                        stackInfos: trip?.stack_info ?? null
                    }) ||
                    !props.poi.is_stack_price
                )
            }
        >
            <CardContent
                sx={{
                    borderTop: seeAllProductsMargins ?
                        '1px solid rgba(0, 0, 0, 0.25)' :
                        undefined
                }}
            >
                <CartConstructionProductsTableItemMargin
                    type="poi"
                    item={props.poi}
                />
            </CardContent>
        </Collapse>
    );
}
