import axios from "axios";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { Flight } from "../../Itinerary/network/flight";
import { CarCart } from "../../Itinerary/objects/carCart";
import { AccommodationCart } from "../../Itinerary/objects/accommodationCart";
import { ManualProduct } from "../../../Reducers/objects/manualProduct";
import { AssistanceCart } from "../../Itinerary/objects/assistanceCart";
import { CartConstructionProductsTableItemProps } from "../CartConstructionProductsTableItem";

type EditRequestOptions<T> = {
    tripId: number,
    version: number,
    tripToken?: string | null,
    providerToken?: string | null,
    id: number,
    data: T
}

export async function makeFlightEditRequest<T>(options: EditRequestOptions<T>): Promise<Flight | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.patch(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/flight/${options.id}/`,
            options.data,
            {
                headers,
                params: {
                    token: options.tripToken,
                    provider_token: options.providerToken
                }
            }
        );
        return response.data;
    }

    return null;
}

export async function makeCarEditRequest<T>(options: EditRequestOptions<T>): Promise<CarCart | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.patch(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/car/${options.id}/`,
            options.data,
            {
                headers,
                params: {
                    token: options.tripToken,
                    provider_token: options.providerToken
                }
            }
        );
        return response.data;
    }

    return null;
}

export async function makeAccommodationEditRequest<T>(options: EditRequestOptions<T>): Promise<AccommodationCart | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.patch(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/accommodation/${options.id}/`,
            options.data,
            {
                headers,
                params: {
                    token: options.tripToken,
                    provider_token: options.providerToken
                }
            }
        );
        return response.data;
    }

    return null;
}

export async function makeTransferEditRequest<T>(options: EditRequestOptions<T>): Promise<AccommodationCart | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.patch(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/transfers/${options.id}/`,
            options.data,
            {
                headers,
                params: {
                    token: options.tripToken,
                    provider_token: options.providerToken
                }
            }
        );
        return response.data;
    }

    return null;
}

export async function makeAssistanceEditRequest<T>(options: EditRequestOptions<T>): Promise<AssistanceCart | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.patch(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/assistance/${options.id}/`,
            options.data,
            {
                headers,
                params: {
                    token: options.tripToken,
                    provider_token: options.providerToken
                }
            }
        );
        return response.data;
    }

    return null;
}

export async function makeManualProductEditRequest<T>(options: EditRequestOptions<T>): Promise<ManualProduct | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.patch(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/manual-products/${options.id}/`,
            options.data,
            {
                headers,
                params: {
                    token: options.tripToken,
                    provider_token: options.providerToken
                }
            }
        );
        return response.data;
    }

    return null;
}

export async function makeCustomProductEditRequest<T>(options: EditRequestOptions<T>): Promise<unknown | null> {
    const { pass_check, headers } = CheckBeforeRequest();

    if (pass_check) {
        const response = await axios.patch(
            `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.version}/custom-products/${options.id}/`,
            options.data,
            {
                headers,
                params: {
                    token: options.tripToken,
                    provider_token: options.providerToken
                }
            }
        );
        return response.data;
    }

    return null;
}

type Product = {
    type: CartConstructionProductsTableItemProps['type'],
    id: number,
    isCustom: boolean
}

type RequestOptions = {
    tripId: number,
    version: number,
    tripToken?: string | null,
    product: Product,
    data: unknown
}

export async function makeProductEditRequest(options: RequestOptions) {
    switch (options.product.type) {
        case 'flight': {
            return await makeFlightEditRequest({
                tripId: options.tripId,
                version: options.version,
                tripToken: options.tripToken,
                id: options.product.id,
                data: options.data
            });
        }
        case 'car': {
            return await makeCarEditRequest({
                tripId: options.tripId,
                version: options.version,
                tripToken: options.tripToken,
                id: options.product.id,
                data: options.data
            });
        }
        case 'accommodation': {
            return await makeAccommodationEditRequest({
                tripId: options.tripId,
                version: options.version,
                tripToken: options.tripToken,
                id: options.product.id,
                data: options.data
            });
        }
        case 'transfer': {
            if (options.product.isCustom) {
                return await makeCustomProductEditRequest({
                    tripId: options.tripId,
                    version: options.version,
                    tripToken: options.tripToken,
                    id: options.product.id,
                    data: options.data
                });
            }
            return await makeTransferEditRequest({
                tripId: options.tripId,
                version: options.version,
                tripToken: options.tripToken,
                id: options.product.id,
                data: options.data
            });
        }
        case 'poi': {
            return await makeCustomProductEditRequest({
                tripId: options.tripId,
                version: options.version,
                tripToken: options.tripToken,
                id: options.product.id,
                data: options.data
            });
        }
        case 'assistance': {
            return await makeAssistanceEditRequest({
                tripId: options.tripId,
                version: options.version,
                tripToken: options.tripToken,
                id: options.product.id,
                data: options.data
            });
        }
        default: {
            return await makeManualProductEditRequest({
                tripId: options.tripId,
                version: options.version,
                tripToken: options.tripToken,
                id: options.product.id,
                data: options.data
            });
        }
    }
}
