import { useDrag } from "react-dnd";
import { isNumber } from "lodash";
import { ItineraryStepDragObject } from "../objects/itineraryStepDragObject";
import { Block } from "../objects/block";
import { TripBlock } from "../objects/tripBlock";
import { ItineraryState } from "../objects/itineraryState";

type Options = {
    circuitId: number | null,
    circuitSource: string | null,
    circuit: Block | ItineraryState['blocks']['circuits']['cache'][number][number] | null,
    typicalTrip: TripBlock | null,
    version: number,
    tripId: number,
    draggableProducts: number[],
    dates: {
        start_date: string,
        isPeriod: boolean
    }[],
    selectedSteps: number[],
    selectedVariant: number | null,
    selectedVariantDate: {
        [variant: number]: string
    }
}

export function useBlockDrag(options: Options) {
    const startDate = isNumber(options.selectedVariant) ?
        options.selectedVariantDate[options.selectedVariant] :
        null;
    const date = options.dates.find((item) => {
        return item.start_date === startDate;
    });
    return useDrag(() => ({
        type: 'block',
        item: {
            drag: 'add',
            data: {
                circuitId: options.circuitId ?? null,
                circuitVersion: options.version,
                circuitVariant: options.selectedVariant,
                circuitDate: !startDate || !date || date.isPeriod ?
                    'PERIOD' :
                    startDate,
                circuitSource: options.circuitSource,
                circuit: options.circuit,
                typicalTrip: options.typicalTrip,
                tripId: options.tripId,
                tripVersion: options.version,
                steps: options.selectedSteps,
                products: isNumber(options.circuitId) ?
                    options.draggableProducts :
                    undefined
            }
        } as ItineraryStepDragObject,
        collect() {
            return {};
        }
    }), [
        options.circuit,
        options.circuitId,
        options.circuitSource,
        options.typicalTrip,
        options.tripId,
        options.version,
        options.selectedSteps,
        options.selectedVariant,
        options.selectedVariantDate,
        options.draggableProducts
    ]);
}
