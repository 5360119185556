/*eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
    flatten,
    groupBy,
    isNumber,
    isString,
    uniqBy
} from "lodash";
import { transformItineraryToDestinationGroups } from "../utils/transformItineraryToDestinationGroups";
import { transformItineraryContentToDestinationGroups } from "../utils/transformItineraryContentToDestinationGroups";
import { transformItineraryGroupsToContent } from "../utils/transformItineraryGroupsToContent";
import { lcsArrayDiff } from "../utils/lcsArrayDiff";
import { sortItinerary } from "../utils/sortItinerary";
import { Itinerary } from "../objects/itinerary";
import { ItineraryContentStep } from "../objects/itineraryContentStep";
import { ItineraryContents } from "../../CartMaterial/objects/itineraryContents";
import { ItineraryByDay } from "../objects/itineraryByDay";
import { ItineraryByStep } from "../objects/itineraryByStep";
import { AppState } from "../../../Reducers/Reducers";

export function useItineraryContent(version: number): ItineraryContentStep[] {
    const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
    const itineraryByDay = useSelector((state: AppState) => {
        return version ?
            state.itinerarySlice.content[version] :
            undefined;
    });

    return useMemo(() => {
        if (version && itineraryByDay?.state === 'success') {
            return extractSteps(version, itinerary, itineraryByDay.data);
        }
        return [];
    }, [version, itinerary, itineraryByDay]);
}

function extractSteps(
    version: number,
    itinerary: Itinerary[],
    itineraryContent: ItineraryContents
): ItineraryContentStep[] {
    if (itineraryContent.mode === 'by-day') {
        return extractStepsForContentByDay(
            version,
            itinerary,
            itineraryContent.content
        );
    }
    return extractStepsForContentByStep(
        version,
        itinerary,
        itineraryContent.content
    );
}

export function extractStepsForContentByDay(
    version: number,
    itinerary: Itinerary[],
    itineraryByDay: ItineraryByDay[]
): ItineraryContentStep[] {
    //do not take start or end step
    const steps = itinerary.filter((step) => {
        return step.step_type === 'STEP' && isNumber(step.destination?.id);
    }).sort(sortItinerary) ?? [];

    let contents = itineraryByDay;

    // handle case where texts were created before last pregir fixes
    const circuits = uniqBy(
        steps.filter((step) => {
            return isNumber(step.circuit) && isNumber(step.circuit_trip_version);
        }).map((step) => {
            return {
                circuit: step.circuit!,
                circuit_trip_version: step.circuit_trip_version!
            };
        }),
        (item) => `${item.circuit}-${item.circuit_trip_version}`
    );
    if (
        steps.every((step) => {
            return isNumber(step.circuit) && isNumber(step.circuit_trip_version);
        }) &&
        circuits.length === 1 &&
        itineraryByDay.filter((content) => {
            return isNumber(content.circuit) && isNumber(content.circuit_trip_version);
        }).length === 0
    ) {
        contents = itineraryByDay.map((item) => {
            return {
                ...item,
                circuit: circuits[0]?.circuit ?? null,
                circuit_trip_version: circuits[0]?.circuit_trip_version ?? null
            };
        });
    }

    const destinations = steps.map((step) => {
        return step.destination!;
    });

    const stepGroups = transformItineraryToDestinationGroups(
        steps.map((step) => ({
            destination: step.destination!.id,
            circuit: step.circuit,
            circuit_trip_version: step.circuit_trip_version,
            iti_type: step.iti_type,
            start_date: step.start_date,
            end_date: step.end_date
        }))
    );
    let contentGroups = transformItineraryContentToDestinationGroups<ItineraryByDay>(
        contents.map((item) => ({
            days: item.day,
            destinations: (item.destinations?.length ?? 0) === 0 && item.destination ?
                [item.destination.id] :
                item.destinations?.map((item) => item.id) ?? [],
            circuit: item.circuit,
            circuit_trip_version: item.circuit_trip_version,
            iti_type: item.iti_type,
            data: item
        }))
    );

    // perform array diff
    const a = contentGroups.map((group) => {
        const lastItem = group[group.length - 1];
        return {
            destinations: lastItem?.destinations ?? [],
            circuit: lastItem?.circuit ?? null,
            circuit_trip_version: lastItem?.circuit_trip_version ?? null,
            iti_type: lastItem?.iti_type ?? null
        };
    });
    const b = stepGroups.map((group) => {
        const lastItem = group[group.length - 1];
        return {
            destinations: group.map((item) => {
                return item.destination;
            }),
            circuit: lastItem?.circuit ?? null,
            circuit_trip_version: lastItem?.circuit_trip_version ?? null,
            iti_type: lastItem?.iti_type ?? null
        };
    });
    const contentDiff = lcsArrayDiff(
        a,
        b,
        (a, b) => {
            return a.destinations[a.destinations.length - 1] === b.destinations[b.destinations.length - 1] &&
                a.circuit === b.circuit &&
                a.circuit_trip_version === b.circuit_trip_version &&
                a.iti_type === b.iti_type;
        }
    );

    let stepIndex = 0;
    let contentIndex = 0;

    let result = flatten(
        contentDiff.map((step): ItineraryContentStep[] | undefined => {
            switch (step.type) {
                case 'deleted': {
                    contentIndex++;
                    break;
                }
                case 'inserted': {
                    const correspondingStep = stepGroups[stepIndex++] ?? [];
                    const correspondingContent = contentGroups[contentIndex];
                    const correspondingStepLastItem = correspondingStep[correspondingStep.length - 1];
                    const correspondingContentFirstItem = correspondingContent?.[0];
                    const nextStepGroup = stepGroups[stepIndex];
                    const isNextCommonDay = (nextStepGroup?.length ?? 0) > 1 ||
                        (
                            nextStepGroup?.length === 1 &&
                            correspondingStep[correspondingStep.length - 1]?.destination === nextStepGroup[0]?.destination
                        );
                    const stepDaysCount = correspondingStep.reduce((prev, current) => {
                        return prev + current.days.length;
                    }, 0);
                    const contentDaysCount = (correspondingContent?.length ?? 0);

                    if (isNextCommonDay && contentDaysCount > stepDaysCount) {
                        for (let i = 0; i < contentDaysCount - stepDaysCount; i++) {
                            const beforeLastContent = correspondingContent?.[(correspondingContent?.length ?? 0) - 1];
                            const lastContent = correspondingContent?.[(correspondingContent?.length ?? 0) - 1];
                            if (beforeLastContent?.destinations[0] !== lastContent?.destinations[0]) {
                                correspondingContent?.pop();
                            }
                        }
                    }

                    if (
                        correspondingStep.length > 1 &&
                        correspondingContentFirstItem &&
                        correspondingContentFirstItem.destinations[0] === correspondingStepLastItem?.destination &&
                        correspondingContentFirstItem.circuit === correspondingStepLastItem?.circuit &&
                        correspondingContentFirstItem.circuit_trip_version === correspondingStepLastItem?.circuit_trip_version &&
                        correspondingContentFirstItem.iti_type === correspondingStepLastItem?.iti_type
                    ) {
                        const result = transformItineraryGroupsToContent({
                            stepGroup: correspondingStep,
                            contentGroup: correspondingContent,
                            destinationsData: destinations
                        });

                        contentGroups = contentGroups.map((group, index) => {
                            if (index === contentIndex) {
                                return group.map((step, index) => {
                                    return {
                                        ...step,
                                        days: index === 0 ?
                                            step.days.slice(1) :
                                            step.days
                                    };
                                }).filter((step) => {
                                    return step.days.length > 0;
                                });
                            }
                            return group;
                        }).filter((group) => {
                            return group.length > 0;
                        });

                        return result.map((item) => {
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            const { destination, ...data } = item;
                            return {
                                mode: 'by-day',
                                content: {
                                    ...data,
                                    localization: item.localization.map((localization) => {
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        const { id, ...data } = localization;
                                        return data;
                                    })
                                }
                            };
                        });
                    }
                    return [
                        {
                            mode: 'by-day',
                            content: {
                                contract: null,
                                destinations: correspondingStep.map((step) => {
                                    return destinations.find((destination) => {
                                        return destination.id === step.destination;
                                    })!;
                                }),
                                day: correspondingStep[0]!.days,
                                title: '',
                                short_description: '',
                                long_description: '',
                                localization: [],
                                picture: null,
                                pictures: [],
                                trip_version: version,
                                interval: [],
                                distance: '0',
                                circuit: correspondingStepLastItem?.circuit ?? null,
                                circuit_trip_version: correspondingStepLastItem?.circuit_trip_version ?? null,
                                iti_type: correspondingStepLastItem?.iti_type ?? null
                            }
                        }
                    ];
                }
                case 'common': {
                    const correspondingStep = stepGroups[stepIndex++] ?? [];
                    const correspondingStepLastItem = correspondingStep[correspondingStep.length - 1];
                    const correspondingContent = contentGroups[contentIndex++] ?? [];
                    const nextStepGroup = stepGroups[stepIndex];
                    const isNextCommonDay = (nextStepGroup?.length ?? 0) > 1 ||
                        (
                            nextStepGroup?.length === 1 &&
                            correspondingStep[correspondingStep.length - 1]?.destination === nextStepGroup[0]?.destination
                        );
                    const stepDaysCount = correspondingStep.reduce((prev, current) => {
                        return prev + current.days.length;
                    }, 0);
                    const contentDaysCount = correspondingContent.length;

                    if (isNextCommonDay && contentDaysCount > stepDaysCount) {
                        for (let i = 0; i < contentDaysCount - stepDaysCount; i++) {
                            correspondingContent?.pop();
                        }
                    }

                    const result = transformItineraryGroupsToContent({
                        stepGroup: correspondingStep,
                        contentGroup: correspondingContent,
                        destinationsData: destinations
                    });

                    if (result.length === 0) {
                        return [
                            {
                                mode: 'by-day',
                                content: {
                                    contract: null,
                                    destinations: correspondingStep.map((step) => {
                                        return destinations.find((destination) => {
                                            return destination.id === step.destination;
                                        })!;
                                    }),
                                    day: correspondingStep[0]!.days,
                                    title: '',
                                    short_description: '',
                                    long_description: '',
                                    localization: [],
                                    picture: null,
                                    pictures: [],
                                    trip_version: version,
                                    interval: [],
                                    distance: '0',
                                    circuit: correspondingStepLastItem?.circuit ?? null,
                                    circuit_trip_version: correspondingStepLastItem?.circuit_trip_version ?? null,
                                    iti_type: correspondingStepLastItem?.iti_type ?? null
                                }
                            }
                        ];
                    }

                    return result.map((item) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { destination, ...data } = item;
                        return {
                            mode: 'by-day',
                            content: {
                                ...data,
                                localization: item.localization.map((localization) => {
                                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                    const { id, ...data } = localization;
                                    return data;
                                })
                            }
                        };
                    });
                }
            }
        }).filter((item): item is NonNullable<typeof item> => {
            return !!item;
        })
    );

    // fix days
    let currentDay = 1;
    result = result.map((item) => {
        const result = {
            mode: 'by-day' as 'by-day',
            content: {
                ...(item.content as ItineraryByDay),
                day: (item.content as ItineraryByDay).day.map((_, index) => {
                    return currentDay + index;
                })
            }
        };
        currentDay += (item.content as ItineraryByDay).day.length;
        return result;
    });

    // dissociate days by default
    if (contents.length === 0) {
        return flatten(
            result.map((item) => {
                if (item.mode === 'by-day' && item.content.day.length > 1) {
                    return item.content.day.map((day) => ({
                        ...item,
                        content: {
                            ...item.content,
                            day: [day]
                        }
                    }));
                }
                return [item];
            })
        );
    }

    return result;
}

export function extractStepsForContentByStep(
    version: number,
    itinerary: Itinerary[],
    itineraryByStep: ItineraryByStep[]
): ItineraryContentStep[] {
    const content = itineraryByStep.filter((item) => {
        return (
            isString(item.trip_version) ?
                parseInt(item.trip_version) :
                item.trip_version
        ) === version;
    }).map((item, index) => {
        return {
            ...item,
            order: index + 1
        };
    });
    //do not take start or end step
    const steps = itinerary.filter((step) => {
        return step.step_type === 'STEP';
    }) ?? [];

    const grouppedContent = groupBy(
        content,
        (item) => item.destination?.id
    );

    const result = steps.map((step, index): ItineraryContentStep => {
        const result = grouppedContent[step.destination?.id ?? -1]?.shift();

        if (result) {
            return {
                mode: 'by-step',
                content: result
            };
        }

        return {
            mode: 'by-step',
            content: {
                contract: null,
                destination: step.destination,
                title: '',
                short_description: '',
                long_description: '',
                localization: [],
                picture: null,
                pictures: [],
                trip_version: version,
                interval: [],
                distance: '0',
                order: index + 1
            }
        };
    });

    const resultIds: number[] = [];
    return result.map((item) => {
        if (item.content.id && !resultIds.includes(item.content.id)) {
            resultIds.push(item.content.id);
            return item;
        }

        return {
            ...item,
            content: {
                ...item.content,
                id: undefined
            }
        } as ItineraryContentStep;
    });
}
