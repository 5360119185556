import { isEqual } from "lodash";
import { Itinerary } from "../objects/itinerary";
import { ItineraryInput } from "../objects/itineraryState";

export function areStepsEqual(
    a: ItineraryInput | Itinerary,
    b: ItineraryInput | Itinerary,
    ignoreTransport = false
): boolean {
    const aData = {
        step_order: a.step_order,
        step_type: a.step_type,
        city_name: a.city_name,
        country_name: a.country_name,
        circuit: a.circuit,
        circuit_trip_version: a.circuit_trip_version,
        circuit_start_date: a.circuit_start_date ?
            window.moment.utc(a.circuit_start_date, 'YYYY-MM-DD').format('YYYY-MM-DD') :
            null,
        variant: a.variant,
        iti_type: a.iti_type ?? null,
        r2r_json: ignoreTransport ? null : a.r2r_json ? a.r2r_json : {},
        destination: a.destination && 'id' in a.destination ? a.destination?.id : a.destination?.destination_id,
        start_date: window.moment.utc(a.start_date).format('DD/MM/YYYY h:mm'),
        end_date: window.moment.utc(a.end_date).format('DD/MM/YYYY h:mm')
    };
    const bData = {
        step_order: b.step_order,
        step_type: b.step_type,
        city_name: b.city_name,
        country_name: b.country_name,
        circuit: b.circuit,
        circuit_trip_version: b.circuit_trip_version,
        circuit_start_date: b.circuit_start_date ?
            window.moment.utc(b.circuit_start_date, 'YYYY-MM-DD').format('YYYY-MM-DD') :
            null,
        variant: b.variant,
        iti_type: b.iti_type ?? null,
        r2r_json: ignoreTransport ? null : b.r2r_json ? b.r2r_json : {},
        destination: b.destination && 'id' in b.destination ? b.destination?.id : b.destination?.destination_id,
        start_date: window.moment.utc(b.start_date).format('DD/MM/YYYY h:mm'),
        end_date: window.moment.utc(b.end_date).format('DD/MM/YYYY h:mm')
    };
    return isEqual(aData, bData);
}
