import React, { useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Button,
    CardContent,
    Chip,
    Collapse,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import {
    ArrowRightAltOutlined,
    CloudCircle,
    CloudOff,
    DateRangeOutlined,
    ErrorOutline,
    PeopleOutlineOutlined,
    ReportProblemOutlined,
    SupportAgentOutlined
} from "@mui/icons-material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { CartProductCard, CartProductCardProps } from "./CartProductCard";
import { CartProductIconText } from "./CartProductIconText";
import { CartConstructionProductsTableItemStatus } from "./CartConstructionProductsTableItemStatus";
import { CartConstructionProductsTableItemMargin } from "./CartConstructionProductsTableItemMargin";
import { CartProductCardName } from "./CartProductCardName";
import { CartAssistanceCardDetails } from "./CartAssistanceCardDetails";
import { CartProductCardProviderLogo } from "./CartProductCardProviderLogo";
import { ProviderQuotationPriceEdit } from "../ProviderQuotation/providerQuotationPriceEdit";
import { ProviderBookingReferenceEdit } from "../ProviderBooking/providerBookingReferenceEdit";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { ProviderContext } from "./utils/providerContext";
import { getAssistancePicture } from "./utils/getAssistancePicture";
import { findPictureUrl } from "./utils/findPictureUrl";
import { insertDivider } from "./utils/insertDivider";
import { isProductPackaged } from "./utils/isProductPackaged";
import { isProductSamePackage } from "./utils/isProductSamePackage";
import { useGetPrice } from "./utils/getPrice";
import { useShowError } from "../Utils/showError";
import { useCartTotalPrices } from './utils/cartTotalPrices';
import { useProductBookingStatusChange } from "../ProviderBooking/network/productBookingStatusChange";
import GetCookie from "../Common/Functions/GetCookie";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { StatusBooking } from "../Itinerary/objects/statusBooking";
import { AssistanceCart } from "../Itinerary/objects/assistanceCart";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    assistance: AssistanceCart,
} & Pick<CartProductCardProps, 'alwaysOpen'>

const DEFAULT_PICTURE = '/Img/hotel.png';

export function CartAssistanceCard(props: Props): JSX.Element | null {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const language = useSelector((state: AppState) => state.header.tmp_language);
    const locale = useSelector((state: AppState) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1;
    });
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manualProviders = useSelector((state: AppState) => state.trip.manual_providers);
    const travelers = useSelector((state: AppState) => state.trip.travelers);
    const prices = useCartTotalPrices();
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const user = useSelector((state: AppState) => state.user?.user);
    const pictures = useMemo(() => {
        const pictures = getAssistancePicture(props.assistance);

        return pictures.length > 0 ?
            pictures.map((picture) => {
                return findPictureUrl(picture);
            }) :
            [DEFAULT_PICTURE];
    }, [props.assistance]);
    const provider = useMemo(() => {
        return providers.concat(manualProviders).find((item) => {
            return item.provider.id === props.assistance.provider;
        })?.provider;
    }, [props.assistance, providers, manualProviders]);
    const showError = useShowError();
    const changeBookingStatus = useProductBookingStatusChange({
        onTrigger() {
            setLoading(true);
        },
        onSuccess(product) {
            dispatch({
                type: 'CART_ASSISTANCE_EDIT_BY_ID',
                payload: product
            });
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });
    const [editPrice, setEditPrice] = useState(false);
    const [editBookingReference, setEditBookingReference] = useState(false);
    const [loading, setLoading] = useState(false);
    const getPrice = useGetPrice();
    const totalCost = getPrice(props.assistance.prices);
    const providerContext = useContext(ProviderContext);

    const confirmTravelerModification = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${["gritchen", "intermundial", "assurever"].includes(provider?.code) ? 'insurance' : 'assistance'}/${props.assistance.id}/`,
            data: {
                traveler_modification: null
            }
        }).then((response) => {
            dispatch({ type: 'POI_EDIT_CART_BY_ID', payload: response.data });
        }).catch((error) => {
            console.log(error);
        });
    };

    const onShowBookingDetail = () => {
        dispatch({ type: 'CART_TOGGLE_BOOKING_DETAIL', payload: props.assistance });
    };

    const onUpdateAssistance = () => {
        const { headers } = CheckBeforeRequest();
        axios({
            method: 'PATCH',
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/${["gritchen", "intermundial", "assurever"].includes(provider?.code) ? 'insurance' : 'assistance'}/${props.assistance.id}/update_booking/`,
            data: props.assistance.product_type === 8 ? {
                contract_reference: props.assistance.contract_reference,
                trip_price: Math.round((((prices.totalCartCost ?? 0) - getPrice(props.assistance.prices).cost) / (travelers?.length ?? 1) * props.assistance.group_passenger.travelers.length)) * (["gritchen", "intermundial", "assurever"].includes(provider?.code) ? 100 : 1)
            } : undefined
        }).then(response => {
            dispatch({ type: 'CART_ASSISTANCE_EDIT_BY_ID', payload: response.data });
            enqueueSnackbar(t('cart-material.assistance-updated'), { variant: 'success' });
        }).catch(error => {
            console.log(error);
            enqueueSnackbar(t('cart-material.assistance-updated-error'), { variant: 'error' });
        });
    };

    const onChangeBookingReference = (reference: string) => {
        if (
            providerContext.module === 'booking' &&
            providerContext.tripId &&
            providerContext.version
        ) {
            changeBookingStatus({
                tripId: providerContext.tripId,
                version: providerContext.version,
                productId: props.assistance.id,
                ref: reference,
                status: props.assistance.booking_status?.status_booking ?? null,
                isManual: false,
                providerToken: providerContext.providerToken
            });
        }
    }

    return (
        <>
            <CartProductCard
                type="assistance"
                item={props.assistance}
                mainContent={
                    <Stack>
                        <CartProductCardName
                            type="assistance"
                            content={
                                props.assistance.localization.find((item) => {
                                    return item.locale === locale;
                                })?.name ??
                                props.assistance.contract_name ??
                                t('cart-material.assistance')
                            }
                            modifiedName={
                                props.assistance.localization.find((item) => {
                                    return item.locale === locale;
                                })?.name ??
                                props.assistance.contract_name ??
                                t('cart-material.assistance')
                            }
                            providerName={
                                props.assistance.contract_name ??
                                t('cart-material.assistance')
                            }
                            localization={props.assistance.localization}
                            id={props.assistance.id}
                            isCustom={props.assistance.is_custom}
                            startIcon={SupportAgentOutlined}
                            isProposition={props.assistance.provider_created}
                        />
                        <CartProductIconText
                            text={
                                t(
                                    'cart-material.cart-construction-products-table-date',
                                    {
                                        from: window.moment.utc(props.assistance.start_date).format('DD MMMM'),
                                        to: window.moment.utc(props.assistance.end_date).format('DD MMMM')
                                    }
                                )
                            }
                            startIcon={DateRangeOutlined}
                        />
                        <CartProductIconText
                            text={
                                [
                                    t(
                                        'roadbook.adults-count',
                                        {
                                            count: props.assistance.group_passenger.travelers_list?.filter((traveler) => {
                                                const result = window.moment.utc().diff(
                                                    window.moment.utc(traveler.birth_date),
                                                    'years'
                                                );
                                                return result >= 18;
                                            }).length ?? 0
                                        }
                                    ),
                                    (() => {
                                        const childrenCount = props.assistance.group_passenger.travelers_list?.filter((traveler) => {
                                            const result = window.moment.utc().diff(
                                                window.moment.utc(traveler.birth_date),
                                                'years'
                                            );
                                            return result < 18;
                                        }).length ?? 0;
                                        return childrenCount > 0 ?
                                            t(
                                                'roadbook.children-count',
                                                { count: childrenCount }
                                            ) :
                                            null;
                                    })()
                                ].filter((item) => item).join(', ')
                            }
                            startIcon={PeopleOutlineOutlined}
                        />
                    </Stack>
                }
                sideContent={
                    <Stack spacing={1} alignItems="center">
                        <Typography variant="caption" sx={{ opacity: 0.6 }}>
                            {t('cart-material.cart-construction-accommodation-stay-amount')}
                        </Typography>
                        <ProviderQuotationPriceEdit
                            productId={props.assistance.id}
                            price={
                                (
                                    providerContext.module ?
                                        totalCost.purchaseCost :
                                        totalCost.cost
                                ).toString()
                            }
                            open={editPrice}
                            bothEntitiesAccepted={
                                props.assistance.provider_accepted &&
                                props.assistance.agent_accepted
                            }
                            providerWantsModification={props.assistance.provider_want_modification}
                            onOpen={() => setEditPrice(true)}
                            onClose={() => setEditPrice(false)}
                        >
                            <Typography fontWeight="bold" textAlign="center">
                                {
                                    new Intl.NumberFormat(
                                        i18n.language,
                                        {
                                            style: 'currency',
                                            currency: providerContext.module ?
                                                totalCost.purchaseCurrency?.iso_code ?? 'EUR' :
                                                totalCost.currency?.iso_code ?? 'EUR'
                                        }
                                    ).format(
                                        providerContext.module ?
                                            totalCost.purchaseCost :
                                            totalCost.cost
                                    )
                                }
                                {
                                    !providerContext.module &&
                                    props.assistance.price_change &&
                                    props.assistance.price_change.price_variation !== 0 &&
                                    <Stack direction="row" alignItems="center" justifyContent="center">
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: props.assistance.price_change.price_variation < 0 ? 'green' : 'red',
                                                fontSize: '10px'
                                            }}
                                        >
                                            {
                                                new Intl.NumberFormat(
                                                    language,
                                                    {
                                                        style: 'currency',
                                                        currency: totalCost.currency?.iso_code ?? 'EUR'
                                                    }
                                                ).format(props.assistance.price_change.price_variation)
                                            }
                                        </Typography>
                                        <ArrowRightAltOutlined
                                            sx={{
                                                transform: props.assistance.price_change.price_variation < 0 ?
                                                    'rotateZ(90deg)' :
                                                    'rotateZ(-90deg)',
                                                color: 'green',
                                                fontSize: '14px'
                                            }}
                                        />
                                    </Stack>
                                }
                            </Typography>
                        </ProviderQuotationPriceEdit>
                        <CartProductCardProviderLogo
                            logoUrl={
                                provider?.logo?.url ??
                                provider?.logo?.thumbnail_big ??
                                provider?.logo?.thumbnail_medium ??
                                provider?.logo?.thumbnail_little ??
                                null
                            }
                            name={provider?.name ?? null}
                        />
                    </Stack>
                }
                footer={(providerActions, providerInfo) => (
                    <div>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            {
                                insertDivider(
                                    [
                                        !isProductPackaged({
                                            product: props.assistance,
                                            stackInfos: trip?.stack_info ?? null,
                                            connected: true
                                        }) &&
                                        <>
                                            <CartConstructionProductsTableItemStatus
                                                type="assistance"
                                                item={props.assistance}
                                                bookingProcessState={props.assistance.booking_process_state}
                                                bookingStatus={props.assistance.booking_status}
                                                agencyNeedToBook={props.assistance.agency_need_to_book}
                                            />
                                            {
                                                !trip?.stack_info?.find((item) => {
                                                    return isProductSamePackage(
                                                        {
                                                            stack_number: item.stack_number,
                                                            stack_info_id: item.is_custom && !props.assistance.stack_info_id ?
                                                                props.assistance.stack_info_id :
                                                                item.id
                                                        },
                                                        {
                                                            stack_number: props.assistance.stack_number,
                                                            stack_info_id: props.assistance.stack_info_id
                                                        }
                                                    );
                                                })?.booking_status?.item_reference &&
                                                (
                                                    props.assistance.booking_status?.status_booking === StatusBooking.CONFIRMED ||
                                                    props.assistance.booking_status?.status_booking === StatusBooking.PENDING ||
                                                    props.assistance.booking_status?.status_booking === StatusBooking.WAITING
                                                ) &&
                                                <ProviderBookingReferenceEdit
                                                    open={editBookingReference}
                                                    productId={props.assistance.id}
                                                    reference={props.assistance.booking_status?.item_reference ?? ''}
                                                    onOpen={() => setEditBookingReference(true)}
                                                    onClose={() => setEditBookingReference(false)}
                                                    onSave={onChangeBookingReference}
                                                >
                                                    <Typography fontWeight={100} fontSize="0.75rem">
                                                        {
                                                            t(
                                                                'cart-material.cart-construction-reference',
                                                                { ref: props.assistance.booking_status?.item_reference }
                                                            )
                                                        }
                                                    </Typography>
                                                </ProviderBookingReferenceEdit>
                                            }
                                        </>,
                                        providerActions,
                                        !providerContext.module &&
                                        !isProductPackaged({
                                            product: props.assistance,
                                            stackInfos: trip?.stack_info ?? null,
                                            connected: true
                                        }) &&
                                        (
                                            user?.client_full?.type !== 2 ||
                                            props.assistance.creator?.client?.type === user.client_full.type
                                        ) &&
                                        <>
                                            {
                                                !props.assistance.is_custom ?
                                                    <Chip
                                                        label={t('cart-material.flux')}
                                                        size="small"
                                                        sx={{
                                                            backgroundColor: '#2ACAEA',
                                                            color: '#fff'
                                                        }}
                                                        icon={<CloudCircle color="inherit" />}
                                                    /> :
                                                    <Chip
                                                        size="small"
                                                        label={t('cart-material.offline')}
                                                        sx={{
                                                            backgroundColor: '#6A329F',
                                                            color: '#fff'
                                                        }}
                                                        icon={<CloudOff color="inherit" />}
                                                    />
                                            },
                                        </>,
                                        user?.client_full?.type !== 2 &&
                                        props.assistance.traveler_modification &&
                                        <Chip
                                            color="warning"
                                            label={
                                                props.assistance.traveler_modification === 'SET_IN_TRIP' ?
                                                    t('cart-material.add-to-cart-traveler') :
                                                    t('cart-material.add-to-option-traveler')
                                            }
                                            size="small"
                                            icon={<ErrorOutline color="inherit" />}
                                            onDelete={confirmTravelerModification}
                                        />,
                                        !isProductPackaged({
                                            product: props.assistance,
                                            stackInfos: trip?.stack_info ?? null,
                                            connected: true
                                        }) &&
                                        !props.assistance.is_cancellable &&
                                        <Box sx={{ whiteSpace: 'nowrap' }}>
                                            <CartProductIconText
                                                text={t('cart-material.not-cancellable')}
                                                startIcon={ReportProblemOutlined}
                                                color="#FF8100"
                                            />
                                        </Box>,
                                        props.assistance.product_type !== 8 &&
                                        props.assistance.booking_status &&
                                        props.assistance.booking_status.status_booking === 'CONFIRMED' &&
                                        props.assistance.booking_process_state !== 'CANCEL' &&
                                        <>
                                            <Tooltip
                                                title={t('cart-material.assistance-update-helper')}
                                                arrow
                                            >
                                                <ReportProblemOutlined sx={{ color: '#FF8100' }} />
                                            </Tooltip>
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                sx={{
                                                    "color": '#FF8100',
                                                    "borderColor": '#FF8100',
                                                    '&:hover': {
                                                        borderColor: '#FF8100'
                                                    }
                                                }}
                                                onClick={onUpdateAssistance}
                                            >
                                                {t('cart-material.assistance-update')}
                                            </Button>
                                        </>,
                                        props.assistance.product_type === 8 &&
                                        (props.assistance.booking_status === null || props.assistance.booking_status.status_booking !== "CANCELLED") &&
                                        (
                                            props.assistance.trip_price > Math.round((((prices.totalCartCost ?? 0) - getPrice(props.assistance.prices).cost) / (travelers?.length ?? 1) * props.assistance.group_passenger.travelers.length)) + 1 ||
                                            props.assistance.trip_price < Math.round((((prices.totalCartCost ?? 0) - getPrice(props.assistance.prices).cost) / (travelers?.length ?? 1) * props.assistance.group_passenger.travelers.length)) - 1
                                        ) &&
                                        <>
                                            <Tooltip
                                                title={
                                                    <>
                                                        {t('cart-material.insurance-update-helper')}
                                                        <br />
                                                        {t('cart-material.insurance-update-helper-selling-price')} :{' '}
                                                        {new Intl.NumberFormat(language).format(props.assistance.trip_price)}
                                                    </>
                                                }
                                                arrow
                                            >
                                                <ReportProblemOutlined sx={{ color: '#FF8100' }} />
                                            </Tooltip>
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                sx={{
                                                    "color": '#FF8100',
                                                    "borderColor": '#FF8100',
                                                    '&:hover': {
                                                        borderColor: '#FF8100'
                                                    }
                                                }}
                                                onClick={onUpdateAssistance}
                                            >
                                                {t('cart-material.insurance-update')}
                                            </Button>
                                        </>
                                    ]
                                )
                            }
                        </Stack>
                        {
                            props.assistance.booking_status &&
                            (props.assistance.product_type !== 8 || ["gritchen", "intermundial", "assurever"].includes(provider?.code)) &&
                            <Button
                                sx={{
                                    padding: 0,
                                    textTransform: 'none',
                                    color: '#000',
                                    textDecoration: 'underline'
                                }}
                                onClick={onShowBookingDetail}
                            >
                                {t('cart-material.cart-construction-details-and-cancellation')}
                            </Button>
                        }
                        {providerInfo}
                    </div>
                )}
                margin={<MarginWrapper assistance={props.assistance} />}
                pictures={pictures}
                alwaysOpen={props.alwaysOpen}
            >
                <CartAssistanceCardDetails assistance={props.assistance} />
            </CartProductCard>
            <LoadingBackDrop open={loading} />
        </>
    );
}


type MarginWrapperProps = {
    assistance: AssistanceCart
}

function MarginWrapper(props: MarginWrapperProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const seeAllProductsMargins = useSelector((state: AppState) => state.cartConstruction.seeProductsMargins);

    return (
        <Collapse
            in={
                seeAllProductsMargins &&
                (
                    !isProductPackaged({
                        product: props.assistance,
                        stackInfos: trip?.stack_info ?? null
                    }) ||
                    !props.assistance.is_stack_price
                )
            }
        >
            <CardContent
                sx={{
                    borderTop: seeAllProductsMargins ?
                        '1px solid rgba(0, 0, 0, 0.25)' :
                        undefined
                }}
            >
                <CartConstructionProductsTableItemMargin
                    type="assistance"
                    item={props.assistance}
                />
            </CardContent>
        </Collapse>
    );
}
