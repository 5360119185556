//---- Dependencies ----//
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";

import {
    Checkbox,
    FormControlLabel
} from '@mui/material';

import CloseIcon from '@material-ui/icons/Close';

import MaterialTripInfo from './MaterialTripInfo';
import MaterialPassengerInfo from './MaterialPassengerInfo';
import CheckBeforeRequest from '../Common/CheckBeforeRequest';
import axios from "axios";
import GetCookie from "../Common/Functions/GetCookie";
import { Button } from '@material-ui/core';
import { validateRoomTypes } from './Functions/validateRoomTypes';
import DuplicateTrip from './Functions/DuplicateTrip';
import { useSnackbar } from "notistack";
import CreateNewTrip from './Functions/CreateNewTrip';
import clsx from "clsx";
import { SetItineraryTypeDetailsDataAndDayAndNight } from '../../Actions/ItineraryType';
import i18n from '../../i18n';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const main_color = JSON.parse(localStorage.getItem("config")).main_color;

const useStyles = makeStyles(theme => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    dialogSize: {
        "& .mui-jss-MuiDialog-paper": {
            width: 500
        },
        "& .mui-jss-MuiDialog-paperWidthSm": {
            maxWidth: 500
        }
    },
    customButton: {
        "background": `${main_color}`,
        //padding: "15px 40px",
        "textAlign": "center",
        "color": "#fff",
        "fontSize": 20,
        "textTransform": "none",
        "&:hover": {
            background: `${main_color}CA`
        }
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        left: "91%"
    },
    buttonProgress1: {
        color: green[500],
        position: "absolute",
        left: "46%"
    }
}));

const MaterialDuplicateDialog = ({ duplicationOpen, setDuplicationOpen, isFrom, duration_day, hotels, flights, poi, assurances, cars, selectedVariant, selectedOptions, versionToShow, variantToShow, router }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { enqueueSnackbar } = useSnackbar();

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;

    const itinerary_type_details_data = useSelector(store => store.itinerary_type.itinerary_type_details_data);
    const itinerary_type_data = useSelector(store => store.itinerary_type.itinerary_type_data);
    const version = useSelector(store => store.itinerary_type.version);
    const user = useSelector(store => store.user.user);
    const current_currency = useSelector(store => store.trip.currency);
    const destination = useSelector(store => store.header.destination);
    const active_page = useSelector(store => store.menu.active_page);
    const trip_list_ordering = useSelector(store => store.menu.trip_list_ordering);
    const trip_list_search = useSelector(store => store.menu.trip_list_search);
    const trip_list_filter = useSelector(store => store.menu.trip_list_filter);
    const periods_results = useSelector(store => store.itinerary_type.periods_results);
    const price_data = useSelector(store => store.itinerary_type.price_data);
    const flight_circuit = useSelector(store => store.itinerary_type.flight_circuit);
    const itinerary = useSelector(store => store.itinerary_type.itinerary);
    const providers = useSelector(store => store.trip.providers);
    const [agencyFilter, setAgencyFilter] = useState(null);
    const [pgiRef, setPgiRef] = useState('');
    const [tripName, setTripName] = useState('');
    const [note, setNote] = useState("");

    const [startDate, setStartDate] = useState(moment().add(1, 'days'));
    const [rangeDate, setRangeDate] = useState([null, null]);
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [adults, setAdults] = useState([]);
    const [children, setChildren] = useState([]);
    const [travelers, setTravelers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [update, setUpdate] = useState(false);
    const [withApplicant, setWithApplicant] = useState(true);
    const [tempContactLead, setTempContactLead] = useState(null);
    const [tempTravelers, setTempTravelers] = useState([]);
    const [moreInfo, setMoreInfo] = useState([]);
    const [infoIncomplete, setInfoIncomplete] = useState([]);
    const [currency, setCurrency] = useState(current_currency);
    const [budget, setBudget] = useState("");
    const [trip_type, setTripType] = useState(null);
    const [dateError, setDateError] = useState(false);
    const [dateErrorStart, setDateErrorStart] = useState(false);
    const [dateErrorEnd, setDateErrorEnd] = useState(false);
    const [isValide, setIsValide] = useState(false);
    const [startDestination, setStartDestination] = useState(null);
    const [returnDestination, setReturnDestination] = useState(null);
    const [selectNight, setSelectNight] = useState('');
    const [periodId, setPeriodId] = useState(null);
    const [singleRoom, setSingleRoom] = useState(0);
    const [twinRoom, setTwinRoom] = useState(0);
    const [twinShare, setTwinShare] = useState(0);
    const [tripleRoom, setTripleRoom] = useState(0);
    const [quadRoom, setQuadRoom] = useState(0);
    const [travelExchangePrices, setTravelExchangePrices] = useState(null);
    const [selectedTravelExchangePrices, setSelectedTravelExchangePrices] = useState(null);
    const [mealPlanChoice, setMealPlanChoise] = useState([]);
    const [priceOptions, setPriceOptions] = useState([]);
    const [currencyIso, setCurrencyIso] = useState('');
    const [selectedTravelExchangeOptions, setSelectedTravelExchangeOptions] = useState([]);
    const [mealPlan, setMealPlan] = useState('');
    const [selectDate, setSelectDate] = useState('');
    const [allowedDepartureAirport, setAllowedDepartureAirport] = useState([]);
    const [allowedArrivalAirport, setAllowedArrivalAirport] = useState([]);
    const [disallowedDepartureAirport, setDisallowedDepartureAirport] = useState([]);
    const [disallowedArrivalAirport, setDisallowedArrivalAirport] = useState([]);
    const [startAirport, setStartAirport] = useState('');
    const [arrivalAirport, setArrivalAirport] = useState('');
    const [allowedCabin, setAllowedCabin] = useState([]);
    const [cabin, setCabin] = useState(null);
    const [currentFlightCircuit, setCurrentFlightCircuit] = useState(null);
    const [adaltePackages, setAdaltePackages] = useState([]);
    const [isHoneymoon, setIsHoneymoon] = useState(false);
    const [isWeddingAnniversary, setIsWeddingAnniversary] = useState(false);

    let end_date = moment.utc(startDate).add(duration_day, "d");
    useEffect(() => {
        if (duplicationOpen) {
            addTravelers('ADULT', 2);
        }
    }, [duplicationOpen]);
    // useEffect(() => {
    //     createTempTravelers();
    // }, [tempTravelers.length]);
    useEffect(() => {
        if (update) {
            createTempTravelers();
        }
    }, [update]);
    useEffect(() => {
        if (periods_results !== null) {
            let period = periods_results.find(el => el.trip_version === versionToShow && el.variant === variantToShow && (moment(startDate).isSameOrAfter(moment(el.start_date), "day") && moment(startDate).isSameOrBefore(moment(el.end_date), "day") && el.start_days !== undefined && (el.start_days.length === 0 || el.start_days.includes(moment(startDate).weekday()))));
            if (period !== undefined) {
                setPeriodId(period);
            }
        }
        if (flight_circuit.length !== 0) {
            let tmp_flight_circuit = flight_circuit.filter((flight) => {
                return flight.period.filter((period) => {
                    return moment(startDate).isSameOrAfter(moment(period.start_date)) && moment(startDate).isSameOrBefore(moment(period.end_date))
                });
            });
            if (tmp_flight_circuit.length !== 0) {
                const cabin = {
                    ECONOMY: 0,
                    PREMIUM_ECONOMY: 1,
                    BUSINESS: 2,
                    FIRST: 3
                }
                let allowed_departure_airport = [];
                let allowed_arrival_airport = [];
                let disallowed_departure_airport = [];
                let disallowed_arrival_airport = [];
                let allowed_cabin = [];
                tmp_flight_circuit.map((flight) => {
                    allowed_departure_airport = [...allowed_departure_airport, ...flight.allowed_departure_airport];
                    allowed_arrival_airport = [...allowed_arrival_airport, ...flight.allowed_arrival_airport];
                    disallowed_departure_airport = [...disallowed_departure_airport, ...flight.disallowed_departure_airport];
                    disallowed_arrival_airport = [...disallowed_arrival_airport, ...flight.disallowed_arrival_airport];
                    if (flight.cabin !== null) {
                        allowed_cabin = [...allowed_cabin, ...flight.cabin];
                    }
                });
                allowed_departure_airport = allowed_departure_airport.filter((item, index, array) => array.findIndex(item1 => (item1.id === item.id)) === index);
                allowed_cabin = allowed_cabin.filter((item, index, array) => array.findIndex(item1 => (item1 === item)) === index).sort((a, b) => cabin[a] - cabin[b]);
                setCabin(allowed_cabin[0]);
                setAllowedDepartureAirport(allowed_departure_airport);
                setAllowedArrivalAirport(allowed_arrival_airport);
                setDisallowedDepartureAirport(disallowed_departure_airport);
                setDisallowedArrivalAirport(disallowed_arrival_airport);
                setAllowedCabin(allowed_cabin);
                setCurrentFlightCircuit(tmp_flight_circuit);
            }
        }
    }, [startDate]);

    const createTempTravelers = () => {
        let temp_travelers = [];
        let numero_adult = 1;
        let numero_teen = 1;
        let numero_child = 1;
        let numero_baby = 1;
        let temp_more_info = [];
        let temp_info_incomplete = [];

        tempTravelers.map((traveler, traveler_index) => {
            let temp_traveler = Object.assign({}, traveler);
            let age = moment.utc(end_date).diff(moment.utc(traveler.birth_date), "y");
            if (age > 12 && age < 18) {
                temp_traveler.age_label = `${t("flight_search.ado")} ${numero_teen} (${age})`;
                numero_teen += 1;
            } else if (age > 2 && age <= 12) {
                temp_traveler.age_label = `${t("flight_search.child")} ${numero_child} (${age})`;
                numero_child += 1;
            } else if (age <= 2) {
                temp_traveler.age_label = `${t("flight_search.baby")} ${numero_baby} (${age})`;
                numero_baby += 1;
            } else if (age >= 18) {
                temp_traveler.age_label = t("flight_search.adult") + " " + numero_adult;
                numero_adult += 1;
            }

            if (groups.length !== 0) {
                for (let groupe_index = 0; groupe_index < groups.length; groupe_index++) {
                    if (groups[groupe_index].travelers.includes(traveler.id)) {
                        temp_traveler.room_label = t("accommodation.room_composition_room") + (groupe_index + 1);
                    }
                }
            }
            if (tempContactLead !== null) {
                if (tempContactLead.id === temp_traveler.id) {
                    temp_traveler.temp_lead = true;
                } else {
                    temp_traveler.temp_lead = false;
                }
            } else {
                temp_traveler.temp_lead = false;
            }
            temp_traveler.default_name = false;
            if (traveler.first_name === t('global.first_name') || traveler.first_name === t("trip.traveler")) {
                temp_traveler.first_name = traveler.first_name;
                temp_traveler.default_name = true;
            }
            if (traveler.last_name === t('global.last_name') || traveler.last_name.includes(t("global.adult")) || traveler.last_name.includes(t("global.child"))) {
                temp_traveler.last_name = traveler.last_name;
                temp_traveler.default_name = true;
            }

            if (traveler.identity_doc_number === null) {
                temp_traveler.identity_doc_number = "";
            }
            if (traveler.identity_doc_country_emission === null) {
                temp_traveler.identity_doc_country_emission = "";
            }
            //if(traveler.identity_doc_expiration === null){
            //temp_traveler.identity_doc_expiration = moment().format("YYYY-MM-DD");
            //}
            if (traveler.country === null) {
                temp_traveler.country = "";
            }
            if (traveler.nationality === null) {
                temp_traveler.nationality = "";
            }
            if (traveler.email === null) {
                temp_traveler.email = "";
            }
            temp_traveler.title_name = !temp_traveler.default_name ? `${temp_traveler.first_name} ${temp_traveler.last_name} - ${temp_traveler.email}` : '';
            temp_traveler.temp_phone = temp_traveler.phone_country_code + temp_traveler.phone_number;
            temp_traveler.real_index = traveler_index;

            temp_travelers.push(temp_traveler);
            temp_more_info.push(false);
            temp_info_incomplete.push(false);
        });

        //temp_travelers.sort((a, b) => {
        //let da = new Date(a.birth_date);
        //let db = new Date(b.birth_date);
        //return (da - db);
        //});
        setTempTravelers(temp_travelers);
        setMoreInfo(temp_more_info);
        setInfoIncomplete(temp_info_incomplete);
        setUpdate(false);
    };
    const addTravelers = (type, nb) => {
        const { headers } = CheckBeforeRequest();
        let traveler_request = [];
        for (let i = 0; i < nb; i++) {
            traveler_request.push(
                axios({
                    method: "POST",
                    headers: headers,
                    url: `${API_HREF}client/${window.id_owner}/travelers/`,
                    data: {
                        first_name: t("global.first_name"),
                        last_name: t("global.last_name"),
                        birth_date: (type === "ADULT" ? moment.utc().subtract(30, "y").format("YYYY-MM-DD") : moment.utc().subtract(5, "y").format("YYYY-MM-DD"))
                    }
                })
            );
        }
        axios.all([...traveler_request]).then(axios.spread((...responses) => {
            let new_travelers = [...tempTravelers];
            let new_groups = [...groups];
            let tmp_responses = responses.sort((a, b) => {
                return a.data.id - b.data.id;
            });
            for (let i = 0; i < nb; i++) {
                let data = Object.assign({}, tmp_responses[i].data);
                data.room = null;
                new_travelers.push(data);
                // if (itinerary_type_details_data?.is_custom === undefined || (itinerary_type_details_data?.is_custom && itinerary_type_details_data.contracts.find(contract => contract.provider?.provider?.code === 'travel_exchange') === undefined)) {
                if (new_groups.length === 0) {
                    new_groups.push({
                        travelers: [data.id],
                        travelers_list: [data],
                        twin_room: false
                    });
                } else if (i === 1) {
                    new_groups[0].travelers.push(data.id);
                    new_groups[0].travelers_list.push(data);
                }
                setGroups(new_groups);
                // }
                if (i === 0) {
                    setTempContactLead(data);
                }
            }
            setTempTravelers(new_travelers);
            setUpdate(true);
        })).catch(function (error) {
            console.log('error traveler:', error);
            //TODO: show snackbar error
        });
    };
    const removeTravelers = (id) => {
        let new_travelers = [];
        let new_groups = [...groups];
        for (let i = 0; i < tempTravelers.length; i++) {
            if (tempTravelers[i].id !== id) {
                new_travelers.push(tempTravelers[i]);
            } else {
                let room_index = groups.findIndex(el => el.travelers.includes(id));
                if (room_index !== undefined && room_index !== -1) {
                    for (let j = 0; j < new_groups[room_index].travelers.length; j++) {
                        if (new_groups[room_index].travelers[j] === id) {
                            new_groups[room_index].travelers.splice(j, 1);
                            break;
                        }
                    }
                    for (let j = 0; j < new_groups[room_index].travelers_list.length; j++) {
                        if (new_groups[room_index].travelers_list[j].id === id) {
                            new_groups[room_index].travelers_list.splice(j, 1);
                            break;
                        }
                    }
                    if (new_groups[room_index].travelers_list.length === 0) {
                        new_groups.splice(room_index, 1);
                    }
                }
            }
        }
        setGroups(new_groups);
        setTempTravelers(new_travelers);
        //setTravelers(new_travelers);
        setUpdate(true);
    };
    const handleClose = () => {
        if (isFrom === 'tripList') {
            dispatch(SetItineraryTypeDetailsDataAndDayAndNight(null, 0, 0));
        }
        setTripName('');
        setOpenDatePicker(false);
        setTempTravelers([]);
        setGroups([]);
        setTempContactLead(null);
        setDuplicationOpen(!duplicationOpen);
    };

    const onCheckAdaltePackages = () => {
        let arePackagesValid = true;

        for (let i = 0; i < adaltePackages.length; i++) {
            const item = adaltePackages[i];
            const [valid, errors] = validateRoomTypes(
                item.rooms,
                item.languageCode,
                item.pickups,
                (groups?.length ?? 0),
                t('home.modal.room-type-validation-required'),
            );
            if (!valid) {
                setAdaltePackages((state) => {
                    return state.map((adaltePackage, index) => {
                        if (index === i) {
                            return {
                                ...adaltePackage,
                                errors
                            };
                        }
                        return adaltePackage;
                    });
                });
                arePackagesValid = false;
            }
        }

        return arePackagesValid;
    }

    const handleValidate = () => {
        if (itinerary_type_details_data?.source === 'ADALTE' && !onCheckAdaltePackages()) {
            return;
        }

        let error = false;
        if (isFrom !== "createNewTrip") {
            let trip_id = (isFrom !== "tripList" && isFrom !== "ItineraryType") ? itinerary_type_details_data.trip.id : itinerary_type_details_data.id;
            let is_by_day_view = (isFrom !== "tripList" && isFrom !== "ItineraryType") ? itinerary_type_details_data.trip.is_by_day_view : itinerary_type_details_data.is_by_day_view;
            let trip_version = "";
            if (isFrom !== "tripList" && isFrom !== "ItineraryType") {
                trip_version = versionToShow !== undefined ? versionToShow : itinerary_type_details_data.trip.current_version;
            } else {
                trip_version = isFrom === "tripList" ? version : itinerary_type_details_data.current_version;
            }
            if (groups.length === 0) {
                enqueueSnackbar(t('header.no_empty_room'), { variant: "error" });
                error = true;
            } else {
                groups.map((group) => {
                    if (group.travelers.length === 0) {
                        enqueueSnackbar(t('header.no_empty_room'), { variant: "error" });
                        error = true;
                    }
                });
            }
            if (quotation_code === "continentsinsolites" && agencyFilter === null) {
                enqueueSnackbar(t('home.modal.agency-required'), { variant: "error" });
                error = true;
            }
            if (itinerary_type_details_data.select_date_by_datepicker !== undefined && !itinerary_type_details_data.select_date_by_datepicker && (selectDate === '' || moment(startDate).format('L') !== moment(selectDate).format('L'))) {
                error = true;
                setDateError(true);
            } else {
                setDateError(false);
            }
            if (!error) {
                setIsValide(true);
                DuplicateTrip(agencyFilter, pgiRef, tripName, tempTravelers, tempContactLead, withApplicant, groups, trip_id, trip_version, startDate, user, selectedVariant, selectedOptions, isFrom, active_page, trip_list_ordering, trip_list_search, trip_list_filter, startDestination, returnDestination, selectNight, dispatch, router, enqueueSnackbar, t, isFrom === 'ItineraryType' ? itinerary_type_details_data.data?.[0]?.destination?.id ?? -1 : null, isFrom === 'ItineraryType' ? itinerary_type_details_data.data?.[0]?.destination?.parent ?? -1 : null, periodId, itinerary_type_details_data.is_custom, end_date, selectedTravelExchangePrices, selectedTravelExchangeOptions, mealPlan, flight_circuit, startAirport, arrivalAirport, itinerary, cabin, providers, adaltePackages[0]?.rooms, is_by_day_view);
            }
        } else {
            let re = new RegExp(/^[a-z0-9]+$/i);
            if (rangeDate[0] === null) {
                error = true;
                enqueueSnackbar(t("home.modal.start_date_err"), { variant: "error" });
                setDateErrorStart(true);
            } else {
                setDateErrorStart(false);
            }
            if (rangeDate[1] === null) {
                error = true;
                enqueueSnackbar(t("home.modal.end_date_err"), { variant: "error" });
                setDateErrorEnd(true);
            } else {
                setDateErrorEnd(false);
            }
            if (adults.length === 0) {
                error = true;
                enqueueSnackbar(t("trip.empty_room_or_no_adult_error"), { variant: "error" });
            }
            groups.map((group) => {
                if (group.travelers.length === 0) {
                    enqueueSnackbar(t('header.no_empty_room'), { variant: "error" });
                    error = true;
                }
            });
            if (quotation_code === "marcovasco" && !(re.test(pgiRef) && pgiRef.length >= 6 && pgiRef.length <= 8)) {
                enqueueSnackbar(t('home.modal.pgi_error'), { variant: "error" });
                error = true;
            }
            if (quotation_code === "continentsinsolites" && agencyFilter === null) {
                enqueueSnackbar(t('home.modal.agency-required'), { variant: "error" });
                error = true;
            }
            if (!error) {
                setIsValide(true);
                CreateNewTrip(rangeDate, agencyFilter, pgiRef, tripName, groups, tempTravelers, tempContactLead, withApplicant, currency, budget, itinerary_type_data, user, trip_type, dispatch, router, enqueueSnackbar, t);
            }
        }
    };
    const handleTravelExchangeChange = (option_id) => (event) => {
        let tmp_array = [...selectedTravelExchangeOptions];
        if (tmp_array.includes(option_id)) {
            tmp_array = tmp_array.filter(item => item !== option_id);
        } else {
            tmp_array.push(option_id);
        }
        setSelectedTravelExchangeOptions(tmp_array);
    }
    useEffect(() => {
        if (itinerary_type_details_data !== null) {
            if (itinerary_type_details_data.reference !== null) {
                setPgiRef(itinerary_type_details_data.reference);
            }
            if (itinerary_type_details_data.name !== null) {
                setTripName(itinerary_type_details_data.name);
            }
        }
    }, [itinerary_type_details_data]);
    // useEffect(() => {
    //     if (duplicationOpen && variantToShow !== undefined) {
    //         let find_price = price_data.find((data) => data.variant === variantToShow);
    //         if (find_price !== undefined) {
    //             console.log('find_price:', find_price);
    //             if (find_price.terrestrial_price !== undefined && find_price.terrestrial_price.length !== 0 && find_price.terrestrial_price[0].travel_exchange_price_options !== undefined && find_price.terrestrial_price[0].travel_exchange_prices !== undefined) {
    //                 let travel_exchange_price_options = find_price.terrestrial_price[0].travel_exchange_price_options;
    //                 if (travel_exchange_price_options.length !== 0) {
    //                     setPriceOptions(travel_exchange_price_options);
    //                 }
    //                 if (find_price.terrestrial_price[0].travel_exchange_prices.length !== 0) {
    //                     setCurrencyIso(find_price.terrestrial_price[0].travel_exchange_prices[0].currency);
    //                 }
    //             }
    //         }
    //     }
    // }, [duplicationOpen]);

    useEffect(() => {
        if (itinerary_type_details_data?.source === 'ADALTE') {
            setAdaltePackages([
                {
                    circuitId: itinerary_type_details_data.id,
                    version: versionToShow,
                    startDate: window.moment.utc(startDate),
                    rooms: [],
                    errors: {
                        rooms: {}
                    }
                }
            ]);
        }
    }, [
        itinerary_type_details_data,
        versionToShow,
        startDate,
    ]);

    return (
        <Dialog open={duplicationOpen} PaperProps={{ style: { borderRadius: fullScreen ? 0 : 16 } }} fullWidth disableEnforceFocus disableEscapeKeyDown onClose={handleClose} fullScreen={fullScreen} maxWidth={fullScreen ? "xs" : "md"}>
            <DialogTitle>
                <Grid container>
                    <Grid item>
                        <Typography variant={"h4"} className={classes.genericText}>{isFrom === "TripList" ? t("menu.trip_list.duplicate_trip") : (isFrom === "createNewTrip" ? t("home.modal.options_trip") : t("menu.trip_list.use_trip"))}</Typography>
                    </Grid>
                    <Grid item style={{ position: "absolute", top: 9, right: 6 }}>
                        <IconButton edge={"start"} onClick={handleClose}><CloseIcon /></IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ overflowX: "hidden" }}>
                <Grid container direction={"column"} spacing={4}>
                    <Grid item style={{ width: "100%" }}>
                        <MaterialTripInfo
                            tripName={tripName}
                            pgiRef={pgiRef}
                            isFrom={isFrom}
                            setAgencyFilter={setAgencyFilter}
                            agencyFilter={agencyFilter}
                            startDate={startDate}
                            rangeDate={rangeDate}
                            setStartDate={setStartDate}
                            setRangeDate={setRangeDate}
                            setPgiRef={setPgiRef}
                            setTripName={setTripName}
                            setNote={setNote}
                            openDatePicker={openDatePicker}
                            setOpenDatePicker={setOpenDatePicker}
                            currency={currency}
                            setCurrency={setCurrency}
                            setBudget={setBudget}
                            setTripType={setTripType}
                            trip_type={trip_type}
                            dateError={dateError}
                            dateErrorStart={dateErrorStart}
                            dateErrorEnd={dateErrorEnd}
                            variantToShow={variantToShow}
                            versionToShow={versionToShow}
                            startDestination={startDestination}
                            setStartDestination={setStartDestination}
                            returnDestination={returnDestination}
                            setReturnDestination={setReturnDestination}
                            selectNight={selectNight}
                            setSelectNight={setSelectNight}
                            selectDate={selectDate}
                            setSelectDate={setSelectDate}
                            allowedDepartureAirport={allowedDepartureAirport}
                            allowedArrivalAirport={allowedArrivalAirport}
                            disallowedDepartureAirport={disallowedDepartureAirport}
                            disallowedArrivalAirport={disallowedArrivalAirport}
                            startAirport={startAirport}
                            setStartAirport={setStartAirport}
                            arrivalAirport={arrivalAirport}
                            setArrivalAirport={setArrivalAirport}
                            allowedCabin={allowedCabin}
                            cabin={cabin}
                            setCabin={setCabin}
                            currentFlightCircuit={currentFlightCircuit}
                            isHoneymoon={isHoneymoon}
                            setIsHoneymoon={setIsHoneymoon}
                            isWeddingAnniversary={isWeddingAnniversary}
                            setIsWeddingAnniversary={setIsWeddingAnniversary}
                            travelExchangePrices={travelExchangePrices}
                        />
                    </Grid>
                    {
                        travelExchangePrices?.length > 0 &&
                        <Grid item xs={12}>
                            <Grid container direction={"column"}>
                                <Typography variant={"h6"} className={classes.genericText}> {t("home.modal.room-type-choice")} </Typography>
                                <FormControl variant={"outlined"} fullWidth>
                                    <Select
                                        id={`meal-plan`}
                                        value={selectedTravelExchangePrices?.id}
                                        //onChange={handleChange}
                                        MenuProps={{ disablePortal: true }}
                                        className={classes.formControlRoot1}
                                        onChange={(e) => {

                                            const price = travelExchangePrices.find((item) => item.id === parseInt(e.target.value));
                                            let meal_plan_choice = [];
                                            let default_meal = '';
                                            if (price.standard_board !== null) {
                                                switch (price.standard_board) {
                                                    case 'half_board':
                                                        default_meal = t('accommodation_filters.half_board');
                                                        break;
                                                    case 'full_board':
                                                        default_meal = t('accommodation_filters.all_inclusive');
                                                        break;
                                                    case 'b_and_b':
                                                        default_meal = t('accommodation_filters.breakfast_included');
                                                        break;
                                                    case 'inclusive_board':
                                                        default_meal = t('accommodation_filters.inclusive_board');
                                                        break;
                                                }
                                                meal_plan_choice.push({ type: default_meal, price: 0 });
                                                if (price.full_board_price !== null) {
                                                    meal_plan_choice.push({ type: t('accommodation_filters.all_inclusive'), price: price.full_board_price });
                                                }
                                                if (price.bed_and_breakfast_price !== null) {
                                                    meal_plan_choice.push({ type: t('accommodation_filters.breakfast_included'), price: price.bed_and_breakfast_price });
                                                }
                                                if (price.inclusive_board_price !== null) {
                                                    meal_plan_choice.push({ type: t('accommodation_filters.inclusive_board'), price: price.inclusive_board_price });
                                                }
                                                if (price.half_board_price !== null) {
                                                    meal_plan_choice.push({ type: t('accommodation_filters.half_board'), price: price.half_board_price });
                                                }
                                                setMealPlanChoise(meal_plan_choice);
                                                setMealPlan(default_meal);
                                            }
                                            setPriceOptions([]);
                                            setSelectedTravelExchangePrices(price);
                                        }}
                                    >
                                        {
                                            travelExchangePrices.map((item) => {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    }
                    {
                        mealPlanChoice.length !== 0 &&
                        <Grid item xs={12}>
                            <Grid container direction={"column"}>
                                <Typography variant={"h6"} className={classes.genericText}> {t("accommodation_filters.meal_plan")} </Typography>
                                <Grid container className={classes.paddingPassenger} spacing={2}>
                                    <Grid item xs={3}>
                                        <FormControl variant={"outlined"} fullWidth>
                                            <Select
                                                id={`meal-plan`}
                                                value={mealPlan}
                                                //onChange={handleChange}
                                                MenuProps={{ disablePortal: true }}
                                                className={classes.formControlRoot1}
                                                onChange={(e) => {
                                                    setMealPlan(e.target.value);
                                                }}
                                            >
                                                {
                                                    mealPlanChoice.map((choice, index) => {
                                                        return (
                                                            <MenuItem key={index} value={choice.type}>{`${choice.type} ${parseFloat(choice.price) !== 0 ? `(+${new Intl.NumberFormat(i18n.language, { style: 'currency', currency: itinerary_type_details_data?.trip.currency.iso_code ?? 'EUR' }).format(parseFloat(choice.price))})` : ''}`}</MenuItem>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <br />
                            </Grid>
                        </Grid>
                    }
                    {
                        console.log("selectedTravelExchangePrices : ", selectedTravelExchangePrices)
                    }
                    {
                        console.log("priceOptions : ", priceOptions)
                    }
                    {
                        priceOptions.filter((option) => option.parent === selectedTravelExchangePrices?.parent).length > 0 &&
                        <Grid item xs={12}>
                            <Grid container direction={"column"}>
                                {
                                    priceOptions.filter((option) => option.parent === selectedTravelExchangePrices?.parent).map((option, option_index) => {
                                        return (
                                            <Grid item key={option_index}>
                                                <Grid container direction={"column"} spacing={0}>
                                                    <Grid item className={`${classes.font12} ${classes.justify} ${classes.truncText}`} style={{ marginLeft: 30 }}>
                                                        <FormControlLabel control={<Checkbox sx={{
                                                            "color": `${main_color}`,
                                                            '&.Mui-checked': {
                                                                color: `${main_color}`,
                                                            },
                                                        }} checked={selectedTravelExchangeOptions.includes(option.id)} onChange={handleTravelExchangeChange(option.id)} />} label={option.name} />
                                                    </Grid>
                                                    {
                                                        option.price !== undefined && option.price !== null && option.price !== '' && (
                                                            <Grid item style={{ textAlign: "right" }}>
                                                                <span className={`${classes.alignRight} ${classes.floatRight}`}>
                                                                    {`(+${Intl.NumberFormat(i18n.language, { style: "currency", currency: itinerary_type_details_data?.trip.currency.iso_code ?? 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(parseFloat(option.price))}/${option.price_type === 'group' ? t('poi.group') : t('global.person_short')})`}
                                                                </span>
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    }
                    <Grid item style={{ width: "100%" }}>
                        <MaterialPassengerInfo
                            setChildren={setChildren}
                            setAdults={setAdults}
                            setTravelers={setTravelers}
                            travelers={travelers}
                            adults={adults}
                            children={children}
                            addTravelers={addTravelers}
                            groups={groups}
                            setGroups={setGroups}
                            startDate={startDate}
                            end_date={end_date}
                            removeTravelers={removeTravelers}
                            update={update}
                            setUpdate={setUpdate}
                            withApplicant={withApplicant}
                            setWithApplicant={setWithApplicant}
                            tempContactLead={tempContactLead}
                            setTempContactLead={setTempContactLead}
                            tempTravelers={tempTravelers}
                            setTempTravelers={setTempTravelers}
                            moreInfo={moreInfo}
                            setMoreInfo={setMoreInfo}
                            infoIncomplete={infoIncomplete}
                            versionToShow={versionToShow}
                            variantToShow={variantToShow}
                            singleRoom={singleRoom}
                            setSingleRoom={setSingleRoom}
                            twinRoom={twinRoom}
                            setTwinRoom={setTwinRoom}
                            twinShare={twinShare}
                            setTwinShare={setTwinShare}
                            tripleRoom={tripleRoom}
                            setTripleRoom={setTripleRoom}
                            quadRoom={quadRoom}
                            setQuadRoom={setQuadRoom}
                            travelExchangePrices={travelExchangePrices}
                            selectedTravelExchangePrices={selectedTravelExchangePrices}
                            setSelectedTravelExchangePrices={setSelectedTravelExchangePrices}
                            setMealPlanChoise={setMealPlanChoise}
                            setTravelExchangePrices={setTravelExchangePrices}
                            mealPlan={mealPlan}
                            setMealPlan={setMealPlan}
                            adaltePackages={adaltePackages}
                            setAdaltePackages={setAdaltePackages}
                            adalteIncludeAge
                            setPriceOptions={setPriceOptions}
                            setCurrencyIso={setCurrencyIso}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} fullWidth={fullScreen} disabled={isValide || (itinerary_type_details_data?.source === 'TRAVEL_EXCHANGE' && !selectedTravelExchangePrices)} color={"primary"} className={classes.customButton} onClick={handleValidate}>{t('home.modal.start')}</Button>
                {
                    isValide && (
                        <CircularProgress size={24} className={clsx({ [classes.buttonProgress]: !fullScreen, [classes.buttonProgress1]: fullScreen })} />
                    )
                }
            </DialogActions>
        </Dialog>
    );
};
export default React.memo(MaterialDuplicateDialog);
