import { cloneDeep, isNumber } from "lodash";
import { ItineraryInput } from "../objects/itineraryState";
import { BlockReducedPackage } from "../objects/blockReducedPackage";

type ItineraryForNightsCount = NonNullable<NonNullable<NonNullable<BlockReducedPackage['trip']>[number]['data']>[number]['itineraries']>[number];

export class StepsDatesManager {
    private tripStartDate: string;
    private tripEndDate: string;

    constructor(
        tripStartDate: string,
        tripEndDate: string
    ) {
        this.tripStartDate = tripStartDate;
        this.tripEndDate = tripEndDate;
    }

    public recomputeDates(a: ItineraryInput, b: ItineraryInput): [ItineraryInput, ItineraryInput] {
        const current = cloneDeep(a);
        const next = cloneDeep(b);

        let nextStartDate = window.moment.utc(current.end_date).add(current.r2r_json?.duration ?? 0, 'minutes');
        let nextNightsCount = this.countStepNights(next);

        if (
            isNumber(next.circuit) &&
            (
                current.circuit !== next.circuit ||
                current.circuit_trip_version !== next.circuit_trip_version ||
                current.id === next.id
            ) &&
            next.circuit_start_date &&
            !window.moment.utc(next.circuit_start_date, 'YYYY-MM-DD').startOf('day').isSame(
                nextStartDate.clone().startOf('day'),
                'days'
            )
        ) {
            const currentEndDate = window.moment.utc(current.end_date);
            nextStartDate = window.moment.utc(next.circuit_start_date, 'YYYY-MM-DD');
            nextStartDate.set({
                hour: currentEndDate.get('hour'),
                minute: currentEndDate.get('minute'),
                second: currentEndDate.get('second')
            });
        }

        if (this.isLateArrival(nextStartDate.toISOString())) {
            --nextNightsCount;
        } else if (this.isLateDeparture(next.end_date)) {
            ++nextNightsCount;
        }

        const nextEndDate = nextStartDate.clone().startOf('day').add(
            nextNightsCount,
            'days'
        );

        const nextInitialEndDate = window.moment.utc(next.end_date);
        nextEndDate.set('hours', nextInitialEndDate.get('hours'));
        nextEndDate.set('minutes', nextInitialEndDate.get('minutes'));
        nextEndDate.set('seconds', nextInitialEndDate.get('seconds'));

        if (
            nextEndDate.isBefore(nextStartDate)
        ) {
            const delta = nextStartDate.clone().endOf('day').diff(nextStartDate, 'seconds');
            nextEndDate.set('hours', nextStartDate.get('hours'));
            nextEndDate.set('minutes', nextStartDate.get('minutes'));
            nextEndDate.set('seconds', nextStartDate.get('seconds'));
            nextEndDate.add(delta * 3 / 4, 'seconds');
        }

        if (next.step_type !== 'END') {
            next.start_date = nextStartDate.toISOString();
            next.end_date = nextEndDate.toISOString();
            //end step should always have same start date and end date
        } else {
            next.start_date = nextStartDate.toISOString();
            next.end_date = nextStartDate.toISOString();
        }

        return [current, next];
    }

    public recomputeDatesInReverse(a: ItineraryInput, b: ItineraryInput): [ItineraryInput, ItineraryInput] {
        const current = cloneDeep(a);
        const next = cloneDeep(b);

        const prevEndDate = window.moment.utc(next.start_date).subtract(current.r2r_json?.duration ?? 0, 'minutes');
        let prevNightsCount = this.countStepNights(current);

        if (this.isLateArrival(next.start_date)) {
            ++prevNightsCount;
        } else if (this.isLateDeparture(prevEndDate.toISOString())) {
            --prevNightsCount;
        }

        const prevStartDate = prevEndDate.clone().startOf('day').subtract(
            prevNightsCount,
            'days'
        );

        const prevInitialStartDate = window.moment.utc(current.start_date);
        prevStartDate.set('hours', prevInitialStartDate.get('hours'));
        prevStartDate.set('minutes', prevInitialStartDate.get('minutes'));
        prevStartDate.set('seconds', prevInitialStartDate.get('seconds'));

        if (prevEndDate.isBefore(prevStartDate)) {
            const delta = prevEndDate.clone().endOf('day').diff(prevEndDate, 'seconds');
            prevStartDate.set('hours', prevEndDate.get('hours'));
            prevStartDate.set('minutes', prevEndDate.get('minutes'));
            prevStartDate.set('seconds', prevEndDate.get('seconds'));
            prevStartDate.subtract(delta * 3 / 4, 'seconds');
        }

        if (current.step_type !== 'START') {
            current.start_date = prevStartDate.toISOString();
            current.end_date = prevEndDate.toISOString();
            //start step should always have start date with time at 00:00:00
        } else {
            current.start_date = prevEndDate.clone().startOf('day').toISOString();
            next.end_date = prevEndDate.toISOString();
        }

        return [current, next];
    }

    public countTripNights(steps: ItineraryInput[]): number {
        const tripDaysCount = window.moment.utc(this.tripEndDate).startOf('day').diff(
            window.moment.utc(this.tripStartDate).startOf('day'),
            'days'
        );
        const nightsOnTransportCount = steps.reduce((prev, current, index, array) => {
            const next = array[index + 1];

            if (next) {
                const currentEndDate = window.moment.utc(current.end_date).startOf('day');
                const nextStartDate = window.moment.utc(next.start_date);

                if (this.isLateArrival(nextStartDate.toISOString())) {
                    let diff = nextStartDate.startOf('day').diff(currentEndDate, 'days');
                    diff = diff >= 0 ? diff : 1;
                    return prev + diff - 1;
                } else if (this.isLateDeparture(next.end_date)) {
                    let diff = nextStartDate.startOf('day').diff(currentEndDate, 'days');
                    diff = diff >= 0 ? diff : -1;
                    return prev + diff + 1;
                }

                return prev + nextStartDate.startOf('day').diff(currentEndDate, 'days');
            }

            return prev;
        }, 0);

        return tripDaysCount - nightsOnTransportCount;
    }

    public countTotalNights(steps: ItineraryForNightsCount[]): number {
        return steps.filter((step) => {
            return step.step_type === 'STEP';
        }).reduce((prev, current) => {
            return prev + this.countStepNights(current);
        }, 0);
    }

    public countStepNights(step: ItineraryForNightsCount): number {
        const start = window.moment.utc(step.start_date);
        const end = window.moment.utc(step.end_date).startOf('day');

        if (this.isLateArrival(start.toISOString())) {
            return end.diff(start.startOf('day'), 'days') + 1;
        } else if (this.isLateDeparture(step.end_date)) {
            return end.diff(start.startOf('day'), 'days') - 1;
        }

        return end.diff(start.startOf('day'), 'days');
    }

    public computeNewTripDateRange(steps: ItineraryInput[], toBeAddedHoursCount: number): [string, string] | null {
        const lastStep = steps[steps.length - 1];
        if (this.countTotalNights(steps) + toBeAddedHoursCount / 24 > this.countTripNights(steps) && lastStep) {
            const endDate = window.moment.utc(lastStep.end_date).add(toBeAddedHoursCount, 'hours');
            return [this.tripStartDate, endDate.toISOString()];
        }
        return null;
    }

    public areDatesAllValid(steps: ItineraryInput[]): boolean {
        for (let i = 0; i < steps.length - 1; i++) {
            const current = steps[i]!;
            const next = steps[i + 1]!;
            if (!this.areDatesValidBetween(current, next)) {
                return false;
            }
        }
        return true;
    }

    public isLateArrival(date: string): boolean {
        return window.moment.utc(date).get('hour') < 2;
    }

    public isLateDeparture(date: string): boolean {
        return window.moment.utc(date).get('hour') < 2;
    }

    public areDatesValidBetween(
        current: ItineraryInput,
        next: ItineraryInput,
        ignoreBloc = false
    ): boolean {
        const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
        let delta = Math.abs(
            window.moment.utc(next.start_date).diff(
                window.moment.utc(current.end_date),
                'minutes'
            )
        );

        if (quotationCode === 'volonline') {
            delta = window.moment.utc(next.start_date).diff(
                window.moment.utc(current.end_date),
                'minutes'
            );
        }

        const potentialStartDate = window.moment.utc(current.end_date).add(
            current.r2r_json?.duration ?? 0,
            'minutes'
        );

        if (
            !ignoreBloc &&
            isNumber(next.circuit) &&
            (
                current.circuit !== next.circuit ||
                current.circuit_trip_version !== next.circuit_trip_version
            ) &&
            next.circuit_start_date &&
            window.moment.utc(next.circuit_start_date, 'YYYY-MM-DD').startOf('day').isSame(
                window.moment.utc(next.start_date).startOf('day'),
                'days'
            ) &&
            !potentialStartDate.startOf('day').isSame(
                window.moment.utc(next.start_date).startOf('day'),
                'days'
            )
        ) {
            return true;
        }

        //this is needed to prevent infinite loop
        if (
            (
                !isNumber(current.r2r_json?.duration) &&
                (
                    (
                        quotationCode === 'volonline' &&
                        Math.abs(delta) > 5
                    ) ||
                    (
                        quotationCode !== 'volonline' &&
                        delta > 5
                    )
                )
            ) ||
            (
                isNumber(current.r2r_json?.duration) &&
                (
                    quotationCode === 'volonline' ||
                    current.r2r_json?.duration >= 0
                ) &&
                Math.abs(delta - current.r2r_json?.duration) > 5
            ) ||
            (
                current.step_type === 'START' &&
                !current.city_name &&
                delta !== 0
            ) ||
            (
                next.step_type === 'END' &&
                !next.city_name &&
                delta !== 0
            ) ||
            (
                !ignoreBloc &&
                isNumber(next.circuit) &&
                (
                    current.circuit !== next.circuit ||
                    current.circuit_trip_version !== next.circuit_trip_version
                ) &&
                next.circuit_start_date &&
                !window.moment.utc(next.circuit_start_date, 'YYYY-MM-DD').startOf('day').isSame(
                    window.moment.utc(next.start_date).startOf('day'),
                    'days'
                )
            )
        ) {
            return false;
        }

        return true;
    }
}
