import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCartProducts } from "../../Itinerary/network/cartProducts";
import { ManualProduct } from "../../../Reducers/objects/manualProduct";
import { AppState } from "../../../Reducers/Reducers";
import { useGetPrice } from "../../CartMaterial/utils/getPrice";
import { TableCell, TableRow } from "@mui/material";
import GetPricesId from "../../Cart/Functions/Margin/GetPricesId";
import GetProvider from "../../Common/Functions/GetProvider";
import GetProductType from "../TripList/Functions/GetProductType";
import { Currency } from "../../../Reducers/objects/currency";
import ConvertPrice from "../../Cart/Functions/Manual/ConvertPrice";
import { isProductSamePackage } from "../../CartMaterial/utils/isProductSamePackage";
import HasPermission from "../../Common/Functions/HasPermission";
import { Check, Close } from '@mui/icons-material';
import { useGetTaxesPrice } from '../../CartMaterial/utils/getTaxesPrice';

type Props = ({
    cart: {
        [key: string]: Omit<
            ReturnType<typeof useCartProducts> & {manualProducts: ManualProduct[]},
            'trains' | 'ferries' | 'cruises' | 'insurances'
        >
    },
    cartDisplay: 'product' | 'chronological',
    selectable?: false
})
export function RenderPackage(props: Props): JSX.Element {
    return (
        <>
            {
                Object.keys(props.cart).map((key) => {
                    const stackNumber = key.split('-')[0] ? parseInt(key.split('-')[0]!) : null;
                    const stackInfoId = key.split('-')[1] ? parseInt(key.split('-')[1]!) : null;
                    if (props.cart[key]) {
                        return (
                            <Row
                                key={key}
                                stackNumber={stackNumber}
                                stackInfoId={stackInfoId}
                                cart={props.cart[key]}
                            />
                        );
                    }
                    return null;
                })
            }
        </>
    );
}

type RowProps = {
    stackNumber: number | null,
    stackInfoId: number | null
    cart: Omit<
        ReturnType<typeof useCartProducts> & {manualProducts: ManualProduct[]},
        'trains' | 'ferries' | 'cruises' | 'insurances'
    >
}

function Row(props: RowProps): JSX.Element {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const currency = useSelector((state: AppState) => state.trip.currency);
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
    const user = useSelector((state: AppState) => state.user.user);
    const currency_list = useSelector((state: AppState) => state.base.currency_list);
    const getPrice = useGetPrice();

    const quotation_code = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;
    
    const owner_id = useState(user ? user.client : null);
    const [price, setPrice] = useState(null);
    const [allCart, setAllCart] = useState([]);
    const flightPrices = trip?.prices_flight?.filter((item) => {
        return isProductSamePackage(
            item,
            {
                stack_number: props.stackNumber,
                stack_info_id: props.stackInfoId
            }
        );
    }) ?? [];
    const terrestrialPrices = trip?.prices_terrestrial?.filter((item) => {
        return isProductSamePackage(
            item,
            {
                stack_number: props.stackNumber,
                stack_info_id: props.stackInfoId
            }
        );
    }) ?? [];
    const stackPricesProduct = trip?.prices_stack_product ?? [];
    useEffect(() => {
        let purchase_price_flight = 0;
        let selling_price_flight = 0;
        let currency_buy_flight = null;
        let currency_sell_flight = null;
        let converted_price_flight = 0;
        let factor_used_terrestrial = null;

        let purchase_price_terrestrial = 0;
        let selling_price_terrestrial = 0;
        let currency_buy_terrestrial = null;
        let currency_sell_terrestrial = null;
        let converted_price_terrestrial = 0;
        let factor_used_flight = null;

        let purchase_price_stack = 0;
        let selling_price_stack = 0;
        let currency_buy_stack = null;
        let currency_sell_stack = null;
        let converted_price_stack = 0;
        let factor_used_stack = null;
        
        let tmp_provider = null;
        if (flightPrices !== undefined && flightPrices.length !== 0) {
            purchase_price_flight = parseFloat(flightPrices[GetPricesId(flightPrices, owner_id, user, true)].purchase_price);
            selling_price_flight = parseFloat(flightPrices[GetPricesId(flightPrices, owner_id, user, quotation_code !== "americavoyages")].selling_price);
            currency_buy_flight = currency_list.find((currency: Currency) => {
                return currency.id === flightPrices[GetPricesId(flightPrices, owner_id, user, true)].purchase_currency; 
            });
            currency_sell_flight = currency_list.find((currency: Currency) => {
                return currency.id === flightPrices[GetPricesId(flightPrices, owner_id, user, quotation_code !== "americavoyages")].selling_currency; 
            });
            converted_price_flight = parseFloat(ConvertPrice(purchase_price_flight, currency, currency_buy_flight, parseFloat(flightPrices[GetPricesId(flightPrices, owner_id, user, true)].factor_used)));
            factor_used_flight = parseFloat(flightPrices[GetPricesId(flightPrices, owner_id, user, true)].factor_used).toFixed(2);
        }
        if (terrestrialPrices !== undefined && terrestrialPrices.length !== 0) {
            purchase_price_terrestrial = parseFloat(terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, true)].purchase_price);
            selling_price_terrestrial = parseFloat(terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, quotation_code !== "americavoyages")].selling_price);
            currency_buy_terrestrial = currency_list.find((currency: Currency) => {
                return currency.id === terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, true)].purchase_currency; 
            });
            currency_sell_terrestrial = currency_list.find((currency: Currency) => {
                return currency.id === terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, quotation_code !== "americavoyages")].selling_currency; 
            });
            converted_price_terrestrial = parseFloat(ConvertPrice(purchase_price_terrestrial, currency, currency_buy_terrestrial, parseFloat(terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, true)].factor_used)));
            factor_used_terrestrial = parseFloat(terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, true)].factor_used).toFixed(2);
            tmp_provider = GetProvider(providers, terrestrialPrices[0].provider);
            if (tmp_provider === null) {
                tmp_provider = GetProvider(manual_providers, terrestrialPrices[0].provider);
            }
        }
        if (stackPricesProduct !== undefined && stackPricesProduct.length !== 0) {
            purchase_price_stack = parseFloat(stackPricesProduct[GetPricesId(stackPricesProduct, owner_id, user, true)].purchase_price);
            selling_price_stack = parseFloat(stackPricesProduct[GetPricesId(stackPricesProduct, owner_id, user, quotation_code !== "americavoyages")].selling_price);
            currency_buy_stack = currency_list.find((currency: Currency) => {
                return currency.id === stackPricesProduct[GetPricesId(stackPricesProduct, owner_id, user, true)].purchase_currency; 
            });
            currency_sell_stack = currency_list.find((currency: Currency) => {
                return currency.id === stackPricesProduct[GetPricesId(stackPricesProduct, owner_id, user, quotation_code !== "americavoyages")].selling_currency; 
            });
            converted_price_stack = parseFloat(ConvertPrice(purchase_price_stack, currency, currency_buy_stack, parseFloat(stackPricesProduct[GetPricesId(stackPricesProduct, owner_id, user, true)].factor_used)));
            factor_used_stack = parseFloat(stackPricesProduct[GetPricesId(stackPricesProduct, owner_id, user, true)].factor_used).toFixed(2);
        }
        const total = {
            currency_buy_flight: currency_buy_flight,
            currency_sell_flight: currency_sell_flight,
            converted_price_flight: converted_price_flight,
            selling_price_flight: selling_price_flight,
            purchase_price_flight: purchase_price_flight,
            factor_used_flight: factor_used_flight,

            currency_buy_terrestrial: currency_buy_terrestrial,
            currency_sell_terrestrial: currency_sell_terrestrial,
            converted_price_terrestrial: converted_price_terrestrial,
            selling_price_terrestrial: selling_price_terrestrial,
            purchase_price_terrestrial: purchase_price_terrestrial,
            factor_used_terrestrial: factor_used_terrestrial,

            currency_buy_stack: currency_buy_stack,
            currency_sell_stack: currency_sell_stack,
            converted_price_stack: converted_price_stack,
            selling_price_stack: selling_price_stack,
            purchase_price_stack: purchase_price_stack,
            factor_used_stack: factor_used_stack,

            provider: tmp_provider !== null ? tmp_provider.provider.name : '-'
        };
        let all_cart = [];
        if (props.cart.accommodations.length !== 0) {
            props.cart.accommodations.map((accommodation) => {
                all_cart.push(accommodation.accommodation);
            });
        }
        if (props.cart.assistances.length !== 0) {
            props.cart.assistances.map((assistances) => {
                all_cart.push(assistances.assistance);
            });
        }
        if (props.cart.cars.length !== 0) {
            props.cart.cars.map((cars) => {
                all_cart.push(cars.car);
            });
        }
        if (props.cart.flights.length !== 0) {
            props.cart.flights.map((flights) => {
                all_cart.push(flights.flight);
            });
        }
        if (props.cart.manualProducts.length !== 0) {
            props.cart.manualProducts.map((manualProducts) => {
                all_cart.push(manualProducts.manualProduct);
            });
        }
        if (props.cart.pois.length !== 0) {
            props.cart.pois.map((pois) => {
                all_cart.push(pois.poi);
            });
        }
        if (props.cart.transfers.length !== 0) {
            props.cart.transfers.map((transfers) => {
                all_cart.push(transfers.transfer);
            });
        }
        setPrice(total);
        setAllCart(all_cart);
    }, [trip, getPrice, props.stackNumber, providers, manual_providers]);
    if (price !== null) {
        return (
            <>
                {
                    terrestrialPrices.length !== 0 && price.selling_price_terrestrial !== 0 && price.purchase_price_terrestrial !== 0 &&
                    <DisplayRow price={price} stackNumber={props.stackNumber} type={'terrestrial'}/>   
                }
                {
                    flightPrices.length !== 0 && price.selling_price_flight !== 0 && price.purchase_price_flight !== 0 &&
                    <DisplayRow price={price} stackNumber={props.stackNumber} type={'flight'}/>   
                }
                {
                    stackPricesProduct.length !== 0 && price.selling_price_stack !== 0 && price.purchase_price_stack !== 0 &&
                    <DisplayRow price={price} type={'stack'}/>
                }
                {
                    allCart.map((item, item_index) => {
                        return (
                            <DisplayProduct key={item_index} product={item} last_item={item_index === allCart.length - 1}/>
                        )
                    })
                }
            </>
        );
    }
}

type DisplayRowProps = {
    price: {
        currency_buy_flight: Currency,
        currency_sell_flight: Currency,
        converted_price_flight: number,
        selling_price_flight: number,
        purchase_price_flight: number,
        factor_used_flight: null|number

        currency_buy_terrestrial: Currency,
        currency_sell_terrestrial: Currency,
        converted_price_terrestrial: number,
        selling_price_terrestrial: number,
        purchase_price_terrestrial: number,
        factor_used_terrestrial: null|number

        currency_buy_stack: Currency,
        currency_sell_stack: Currency,
        converted_price_stack: number,
        selling_price_stack: number,
        purchase_price_stack: number,
        factor_used_stack: null|number

        provider: string,
    },
    type: string,
    stackNumber?: number
}
function DisplayRow(props: DisplayRowProps): JSX.Element {
    const { price, stackNumber, type } = props;
    const { t } = useTranslation();

    const language = useSelector((state: AppState) => state.header.tmp_language);
    const currency = useSelector((state: AppState) => state.trip.currency);

    return (
        <TableRow sx={{
            borderTop: '2px solid #2F80ED',
            borderLeft: '2px solid #2F80ED',
            borderRight: '2px solid #2F80ED',
            "& .mui-jss-MuiTableCell-root": {
                padding: "10px 0px 10px 16px !important",
                fontSize: '12px'
            }
        }}>
            <TableCell align={"left"}>-</TableCell>
            <TableCell align={"left"}>
                <span style={{ fontSize: 12, color: "#000000DE" }}>{t<string>('providers.package')}</span>
            </TableCell>
            <TableCell align={"left"}>
                <span style={{ fontSize: 12, color: "#000000DE" }}>{price.provider}</span>
            </TableCell>
            <TableCell align={"left"}>
                <span style={{ fontSize: 12, color: "#000000DE" }}>
                    {
                        stackNumber !== undefined && t<string>(
                            'cart-material.cart-construction-package-name',
                            { name: stackNumber }
                        ) + ' - ' + (type === 'terrestrial' ? t<string>('cart-material.terrestrial') : t<string>('cart-material.flight'))
                    }
                </span>
            </TableCell>
            <TableCell align={"left"}>-</TableCell>
            <TableCell align={"left"}>-</TableCell>
            {
                stackNumber !== undefined ?
                <>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#000000DE" }}>{new Intl.NumberFormat(language).format(type === 'terrestrial' ? price.purchase_price_terrestrial.toFixed(2) : price.purchase_price_flight.toFixed(2))}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#000000DE" }}>{type === 'terrestrial' ? price.currency_buy_terrestrial.symbol : price.currency_buy_flight.symbol}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#000000DE" }}>{type === 'terrestrial' ? price.factor_used_terrestrial : price.factor_used_flight}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#000000DE" }}>{type === 'terrestrial' ? price.converted_price_terrestrial : price.converted_price_flight}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#000000DE" }}>{currency.symbol}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#000000DE" }}>{type === 'terrestrial' ? new Intl.NumberFormat(language).format(price.selling_price_terrestrial.toFixed(2)) : new Intl.NumberFormat(language).format(price.selling_price_flight.toFixed(2))}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#000000DE" }}>{type === 'terrestrial' ? price.currency_sell_terrestrial.symbol : price.currency_sell_flight.symbol}</span>
                    </TableCell>
                </> :
                <>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#000000DE" }}>{new Intl.NumberFormat(language).format(price.purchase_price_stack.toFixed(2))}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#000000DE" }}>{price.currency_buy_stack.symbol}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#000000DE" }}>{price.factor_used_stack}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#000000DE" }}>{price.converted_price_stack}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#000000DE" }}>{currency.symbol}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#000000DE" }}>{new Intl.NumberFormat(language).format(price.selling_price_stack.toFixed(2))}</span>
                    </TableCell>
                    <TableCell align={"left"}>
                        <span style={{ fontSize: 12, color: "#000000DE" }}>{price.currency_sell_stack.symbol}</span>
                    </TableCell>
                </>
            }
        </TableRow>
    );
}

type DisplayProductProps = {
    product: any,
    last_item: boolean
}
function DisplayProduct(props: DisplayProductProps): JSX.Element {
    const { t } = useTranslation();
    const providers = useSelector(store => store.trip.providers);
    const manual_providers = useSelector(store => store.trip.manual_providers);
    const permissions = useSelector(store => store.user.permissions);
    const user = useSelector(store => store.user.user);
    const finance_charges = useSelector(store => store.menu.finance_charges);
    const list_currency = useSelector(store => store.base.currency_list);
    const currency = useSelector(store => store.trip.currency);
    const language = useSelector(store => store.header.tmp_language);
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const owner_id = useState(user ? user.client : null);
    const getTaxesPrice = useGetTaxesPrice();
    const getPrice = useGetPrice();

    let product_type = GetProductType(props.product.product_type);
    let provider = null;
    if (props.product.provider !== undefined && props.product.provider !== null && providers.length !== 0 && (manual_providers.length !== 0 || (manual_providers.length !== 0 || ['marcovasco'].includes(quotation_code)))) {
        let tmp_provider = GetProvider(providers, props.product.provider);
        if (tmp_provider === null) {
            tmp_provider = GetProvider(manual_providers, props.product.provider);
        }
        provider = tmp_provider.provider.name;
    }
    if (props.product.provider_name !== undefined && props.product.provider_name !== null) {
        provider = props.product.provider_name;
    }
    let name = "-";
    if (props.product.name !== undefined && props.product.name !== null) {
        name = props.product.name;
    } else {
        if (props.product.product_type === 0) {
            name = t("accommodation.hotel") + " : " + props.product.hotel[0].name;
        }
        if (props.product.product_type === 6) {
            if (props.product.segments !== undefined && props.product.segments !== null) {
                name = props.product.segments[0].marketing_airline.commercial_name + " : " + (props.product.segments[0].origin !== null && props.product.segments[0].destination !== null ? props.product.segments[0].origin.airport_code + " - " + props.product.segments[0].destination.airport_code : props.product.segments[0].origin_station.station_code + " - " + props.product.segments[0].destination_station.station_code);
            }
        }
        if (props.product.product_type === 11 || props.product.product_type === 12) {
            if (props.product.custom_product !== undefined) {
                name = props.product.custom_product.title;
            }
        }
        if (props.product.product_type === 8) {
            name = !!props.product.contract_name ? props.product.contract_name : t('cart-material.assistance')
        }
        if (props.product.product_type === 4) {
            if (props.product.custom_product !== undefined) {
                name = props.product.custom_product.title;
            }
        }
    }


    let price = parseFloat(props.product.prices[GetPricesId(props.product.prices, owner_id, user, true)].purchase_price);
    let selling_price = parseFloat(props.product.prices[GetPricesId(props.product.prices, owner_id, user, quotation_code !== "americavoyages")].selling_price);
    let currency_buy = list_currency.find((currency) => {
        return currency.id === props.product.prices[GetPricesId(props.product.prices, owner_id, user, true)].purchase_currency
    });
    let currency_sell = list_currency.find((currency) => {
        return currency.id === props.product.prices[GetPricesId(props.product.prices, owner_id, user, quotation_code !== "americavoyages")].selling_currency
    });
    let currency_real_purchase = props.product.purchase_currency !== undefined && list_currency.find((currency) => {
        return currency.id === props.product.purchase_currency
    });
    if (props.product.product_type === 6) {
        const master_price = props.product.prices.find((price) => price.master_price);
        const provider = providers.concat(manual_providers).find((item) => {
            return item.provider.id === item.provider;
        })?.provider;
        let seatsCost = 0;
        let option_cost = 0;
        let otherOptionsCost = 0;
        let option_on_flight = [];
        if (provider?.code !== 'amadeus_rest') {
            let outbounds = props.product.segments || props.product.outbounds || [];
            outbounds.map(outbound => {
                let option_on_group = [];
                if (outbound.legs !== undefined) {
                    outbound.legs.map(leg => {
                        for (const seat of leg.seats ?? []) {
                            let seatPrice = 0;
                            if ((seat.prices?.length ?? 0) > 0) {
                                seatPrice = getPrice(seat.prices).cost;
                            } else {
                                seatPrice = parseFloat(seat.price ?? '0');
                            }
                            seatsCost += seatPrice;
                        }

                        if (leg.paid_options && leg.paid_options.length > 0) {
                            leg.paid_options.map(option => {
                                let optionPrice = 0;
                                if ((option.prices?.length ?? 0) > 0) {
                                    optionPrice = getPrice(option.prices, undefined, undefined).cost;
                                } else {
                                    optionPrice = parseFloat(option.price);
                                }
                                if (option.option_on_flight) {
                                    if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                        option_cost += optionPrice;
                                        option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                    }
                                } else if (option.option_on_group) {
                                    if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                        option_cost += optionPrice;
                                        option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                    }
                                } else {
                                    option_cost += optionPrice;
                                }
                            });
                        }
                    });
                } else {
                    for (const seat of outbound.seats ?? []) {
                        let seatPrice = 0;
                        if ((seat.prices?.length ?? 0) > 0) {
                            seatPrice = getPrice(seat.prices).cost;
                        } else {
                            seatPrice = parseFloat(seat.price ?? '0');
                        }
                        seatsCost += seatPrice;
                    }

                    if (outbound.paid_options && outbound.paid_options.length > 0) {
                        outbound.paid_options.map(option => {
                            let optionPrice = 0;
                            if ((option.prices?.length ?? 0) > 0) {
                                optionPrice = getPrice(option.prices, undefined, undefined).cost;
                            } else {
                                optionPrice = parseFloat(option.price);
                            }
                            if (option.option_on_flight) {
                                if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                    option_cost += optionPrice;
                                    option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                }
                            } else if (option.option_on_group) {
                                if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                    option_cost += optionPrice;
                                    option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                }
                            } else {
                                option_cost += optionPrice;
                            }
                        });
                    }
                }
            });
        }
        else {
            seatsCost = props.product.paid_option_price.filter((item) => {
                return item.service_type === 'SEATS';
            }).reduce((prev, current) => {
                return prev + getPrice(current.prices).cost;
            }, 0);
            otherOptionsCost = props.product.paid_option_price.filter((item) => {
                return item.service_type !== 'SEATS';
            }).reduce((prev, current) => {
                return prev + getPrice(current.prices, undefined, undefined).cost;
            }, 0);
        }
        price += parseFloat(ConvertPrice(parseFloat(seatsCost) + parseFloat(otherOptionsCost) + parseFloat(option_cost), currency, currency_buy, master_price.factor_used));
        selling_price += parseFloat(ConvertPrice(parseFloat(seatsCost) + parseFloat(otherOptionsCost) + parseFloat(option_cost), currency, currency_buy, master_price.factor_used));
        if (props.product.taxes !== undefined && props.product.taxes !== null) {
            price += getTaxesPrice(props.product.taxes);
            // price += parseFloat(ConvertPrice(parseFloat(item.taxes), currency, currency_buy, master_price.factor_used));
            selling_price += parseFloat(ConvertPrice(getTaxesPrice(props.product.taxes), currency, currency_buy, parseFloat(props.product.prices[GetPricesId(props.product.prices, owner_id, user, true)].factor_used)));
        }

        // if (props.product.taxes !== undefined && props.product.taxes !== null) {
        //     price += getTaxesPrice(props.product.taxes);
        //     // price += getTaxesPrice(ConvertPrice(getTaxesPrice(item.taxes), currency, currency_buy, master_price.factor_used));
        //     selling_price += parseFloat(ConvertPrice(getTaxesPrice(props.product.taxes), currency, currency_buy, parseFloat(props.product.prices[GetPricesId(props.product.prices, owner_id, user, true)].factor_used)));
        // }
    }
    let converted_price = parseFloat(ConvertPrice(price, currency, currency_buy, parseFloat(props.product.prices[GetPricesId(props.product.prices, owner_id, user, true)].factor_used)));

    return (
        <TableRow sx={{
            borderBottom: props.last_item ? '2px solid #2F80ED' : undefined,
            borderLeft: '2px solid #2F80ED',
            borderRight: '2px solid #2F80ED',
            "& .mui-jss-MuiTableCell-root": {
                padding: "10px 0px 10px 16px !important",
                fontSize: '12px'
            }
        }}>
            <TableCell align={"left"}>{props.product.start_date !== undefined && props.product.start_date !== null ? moment.utc(props.product.start_date).format("DD/MM/YYYY") + " " + t("global.to") : ""} {props.product.end_date !== undefined && props.product.end_date !== null ? moment.utc(props.product.end_date).format("DD/MM/YYYY") : ""}</TableCell>
            <TableCell align={"left"}>
                <span style={{ fontSize: 12, color: "#000000DE" }}>{t("providers." + product_type)}</span>
            </TableCell>
            <TableCell align={"left"}>
                <span style={{ fontSize: 12, color: "#000000DE" }}>{provider !== null ? provider : "-"}</span>
            </TableCell>
            <TableCell align={"left"}>
                <span style={{ fontSize: 12, color: "#000000DE" }}>{name}</span>
            </TableCell>
            <TableCell align={"left"}/>
            <TableCell align={"left"}/>
            <TableCell align={'left'}>
                <span style={{
                    fontSize: 12,
                    color: '#0000008A'
                }}>{props.product.charge !== undefined ? new Intl.NumberFormat(language).format(props.product.origin_charge.toFixed(2)) : new Intl.NumberFormat(language).format(price.toFixed(2))}</span>
                {/*<span style={{*/}
                {/*    fontSize: 12,*/}
                {/*    color: '#0000008A'*/}
                {/*}}>{props.product.charge !== undefined ? new Intl.NumberFormat(language, {*/}
                {/*    style: 'currency',*/}
                {/*    currency: props.product.origin_charge_currency.iso_code*/}
                {/*}).format(props.product.origin_charge.toFixed(2)) : new Intl.NumberFormat(language, {*/}
                {/*    style: 'currency',*/}
                {/*    currency: currency_buy.iso_code*/}
                {/*}).format(price.toFixed(2))}</span>*/}
            </TableCell>
            <TableCell>
                <span style={{
                    fontSize: 12,
                    color: '#0000008A'
                }}>
                    { props.product.charge !== undefined ? props.product.origin_charge_currency.symbol : currency_buy.symbol }
                </span>
            </TableCell>
            <TableCell align={'left'}>
                <span
                    style={{
                        fontSize: 12,
                        color: '#0000008A'
                    }}>{props.product.charge !== undefined ? parseFloat(props.product.charge_factor_used).toFixed(2) : parseFloat(props.product.prices[GetPricesId(props.product.prices, owner_id, user, true)].factor_used).toFixed(2)}</span>
            </TableCell>
            <TableCell align={'left'}>
                <span style={{
                    fontSize: 12,
                    color: '#0000008A'
                }}>{props.product.charge !== undefined ? new Intl.NumberFormat(language).format(props.product.charge.toFixed(2)) : new Intl.NumberFormat(language).format(converted_price.toFixed(2))}</span>
            </TableCell>
            <TableCell>
                <span style={{
                    fontSize: 12,
                    color: '#0000008A'
                }}>{props.product.charge !== undefined ? props.product.charge_currency.symbol : currency.symbol }</span>
            </TableCell>
            {/*{*/}
            {/*    finance_charges.length !== 0 && HasPermission(permissions, 'view_accountingbook', user) &&*/}
            {/*    <TableCell align={'left'} />*/}
            {/*}*/}
            <TableCell align={'left'}>
                <span style={{ fontSize: 12, color: '#0000008A' }}>{new Intl.NumberFormat(language).format(selling_price.toFixed(2))}</span>
            </TableCell>
            <TableCell>
                <span style={{ fontSize: 12, color: '#0000008A' }}>{currency_sell.symbol}</span>
            </TableCell>
        </TableRow>
    );
}