import { useEffect, useState } from "react";
import axios from "axios";

type ProviderData = {
    locale_code: string | null
}

export function useProviderData(providerId: string | undefined): ProviderData | null {
    const [providerData, setProviderData] = useState<ProviderData | null>(null);

    useEffect(() => {
        if (providerId) {
            (async () => {
                const response = await axios.get<{results: ProviderData[]}>(
                    `${API_HREF}client/${window.id_owner}/custom-providers/`,
                    {
                        params: {
                            provider: providerId
                        }
                    }
                );
                setProviderData(response.data.results[0] ?? null);
            })();
        }
    }, [providerId]);

    return providerData;
}