import { SelectedRoom } from "../RoomTypePicker";

export function validateRoomTypes(
    rooms: SelectedRoom[],
    language_code: string | undefined,
    pickup: string | undefined,
    groupsCount: number,
    errorMessage: string,
): [
    boolean,
    {
        rooms: {
            [index: number]: string
        },
        pickup?: string,
        language?: string,
    }
] {
    let valid = true;
    const errors: ReturnType<typeof validateRoomTypes>[1] = {
        rooms: {},
    };
    if (groupsCount > 0) {
        for (let i = 0; i < groupsCount; i++) {
            if (!rooms[i]?.code) {
                valid = false;
                errors.rooms[i] = errorMessage;
            }
            if (!pickup) {
                valid = false;
                errors.pickup = errorMessage;
            }
            if (!language_code) {
                valid = false;
                errors.language = errorMessage;
            }
        }
    }
    return [valid, errors];
}
