import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CartConstructionState } from "../objects/cartConstructionState";
import { ManualProduct } from "../../../Reducers/objects/manualProduct";

const initialState: CartConstructionState = {
    locale: 1,
    view: 'minimized',
    textsView: 'quotation',
    module: 'both',
    step: 0,
    loading: false,
    savingTexts: false,
    textsMode: 'all-days',
    productsMode: 'all-days',
    productsView: 'cards',
    fetchingTexts: false,
    syncingTexts: false,
    recreatingTextInputs: false,
    autoloadingTexts: false,
    seeProductsMargins: false,
    showPictures: true,
    showItineraryStepper: false,
    showProductsDescriptions: true,
    openProductsDetails: false,
    bookablePackages: [],
    bookableManualProducts: [],
    textsSaveQueue: null,
    filters: {
        status: [
            'DEFAULT',
            'HIDE',
            'HIDE_COUNT',
            'OPTION',
            'VARIANT'
        ]
    },
    isHeaderSticked: {}
};

const slice = createSlice({
    name: 'cartConstruction',
    initialState,
    reducers: {
        changeFiltersStatus(
            state,
            action: PayloadAction<CartConstructionState['filters']['status']>
        ) {
            state.filters.status = action.payload;
        },
        changePackageBooking(
            state,
            action: PayloadAction<CartConstructionState['bookablePackages'][number] & {checked: boolean}>
        ) {
            state.bookablePackages = state.bookablePackages.filter((item) => {
                return item.stack_number !== action.payload.stack_number &&
                       item.stack_info_id !== action.payload.stack_info_id;
            });
            const { checked, ...rest } = action.payload;
            if (checked) {
                state.bookablePackages.push(rest);
            }
        },
        clearBookablePackages(state) {
            state.bookablePackages = [];
        },
        setAutoloadingTexts(
            state,
            action: PayloadAction<CartConstructionState['autoloadingTexts']>
        ): void {
            state.autoloadingTexts = action.payload;
        },
        setBookableManualProducts(
            state,
            action: PayloadAction<CartConstructionState['bookableManualProducts']>
        ): void {
            state.bookableManualProducts = action.payload;
        },
        setFetchingTexts(
            state,
            action: PayloadAction<CartConstructionState['fetchingTexts']>
        ): void {
            state.fetchingTexts = action.payload;
        },
        setIsHeaderSticked(
            state,
            action: PayloadAction<{
                page: string,
                sticked: boolean
            }>
        ): void {
            state.isHeaderSticked[action.payload.page] = action.payload.sticked;
        },
        setLoading(
            state,
            action: PayloadAction<CartConstructionState['loading']>
        ): void {
            state.loading = action.payload;
        },
        setLocale(
            state,
            action: PayloadAction<CartConstructionState['locale']>
        ): void {
            state.locale = action.payload;
        },
        setModule(
            state,
            action: PayloadAction<CartConstructionState['module']>
        ): void {
            state.module = action.payload;
        },
        setOpenProductsDetails(
            state,
            action: PayloadAction<CartConstructionState['openProductsDetails']>
        ): void {
            state.openProductsDetails = action.payload;
        },
        setProductsMode(
            state,
            action: PayloadAction<CartConstructionState['productsMode']>
        ): void {
            state.productsMode = action.payload;
        },
        setProductsView(
            state,
            action: PayloadAction<CartConstructionState['productsView']>
        ): void {
            state.productsView = action.payload;
        },
        setRecreatingTextInputs(
            state,
            action: PayloadAction<CartConstructionState['recreatingTextInputs']>
        ): void {
            state.recreatingTextInputs = action.payload;
        },
        setSavingTexts(
            state,
            action: PayloadAction<CartConstructionState['savingTexts']>
        ): void {
            state.savingTexts = action.payload;
        },
        setSeeProductsMargins(
            state,
            action: PayloadAction<CartConstructionState['seeProductsMargins']>
        ): void {
            state.seeProductsMargins = action.payload;
        },
        setShowPictures(
            state,
            action: PayloadAction<CartConstructionState['showPictures']>
        ): void {
            state.showPictures = action.payload;
        },
        setShowProductsDescriptions(
            state,
            action: PayloadAction<CartConstructionState['showProductsDescriptions']>
        ): void {
            state.showProductsDescriptions = action.payload;
        },
        setStep(
            state,
            action: PayloadAction<CartConstructionState['step']>
        ): void {
            state.step = action.payload;
        },
        setSyncingTexts(
            state,
            action: PayloadAction<CartConstructionState['syncingTexts']>
        ): void {
            state.syncingTexts = action.payload;
        },
        setTextsMode(
            state,
            action: PayloadAction<CartConstructionState['textsMode']>
        ): void {
            state.textsMode = action.payload;
        },
        setTextsSaveQueue(
            state,
            action: PayloadAction<CartConstructionState['textsSaveQueue']>
        ): void {
            state.textsSaveQueue = action.payload;
        },
        setTextsView(
            state,
            action: PayloadAction<CartConstructionState['textsView']>
        ): void {
            state.textsView = action.payload;
        },
        setView(
            state,
            action: PayloadAction<CartConstructionState['view']>
        ): void {
            state.view = action.payload;
        },
        toggleShowItineraryStepper(
            state
        ): void {
            state.showItineraryStepper = !state.showItineraryStepper;
            if (!state.showItineraryStepper) {
                state.productsMode = 'all-days';
            }
        },
        toggleManualProductBooking(
            state,
            action: PayloadAction<ManualProduct>
        ): void {
            const index = state.bookableManualProducts.findIndex((product) => {
                return product.id === action.payload.id;
            });
            if (index >= 0) {
                state.bookableManualProducts.splice(index, 1);
            } else {
                state.bookableManualProducts.push(action.payload);
            }
        }
    }
});

export const {
    changeFiltersStatus,
    changePackageBooking,
    clearBookablePackages,
    setAutoloadingTexts,
    setBookableManualProducts,
    setFetchingTexts,
    setIsHeaderSticked,
    setLoading,
    setLocale,
    setModule,
    setOpenProductsDetails,
    setProductsMode,
    setProductsView,
    setRecreatingTextInputs,
    setSavingTexts,
    setSeeProductsMargins,
    toggleShowItineraryStepper,
    setShowPictures,
    setShowProductsDescriptions,
    setStep,
    setSyncingTexts,
    setTextsMode,
    setTextsSaveQueue,
    setTextsView,
    setView,
    toggleManualProductBooking
} = slice.actions;

export default slice;
