import { useCallback } from "react";
import { FlightBoParams } from "../objects/flightBoParams";

type Callback = (
    dates: {
        startDate?: string,
        endDate?: string
    },
    params: FlightBoParams[]
) => FlightBoParams | undefined

export function useFindFlightsBoParams(): Callback {
    return useCallback((dates, params) => {
        // first sort params based on priority
        const sortedParams = [...params].sort((a, b) => {
            if (a.assigned_trip !== b.assigned_trip) {
                if (a.assigned_trip !== 'ALL') {
                    return -1;
                }
                return 1;
            }

            if (b.period && b.period.length === 0) {
                return -1;
            }

            if (a.period && b.period && a.period.length === 0 && b.period.length > 0) {
                return 1;
            }

            const aPeriod = [...a.period ?? []].sort((a, b) => {
                return window.moment.utc(a.start_date).diff(window.moment.utc(b.start_date), 'seconds') < 0 ? -1 : 1;
            })[0];
            const bPeriod = [...b.period ?? []].sort((a, b) => {
                return window.moment.utc(a.start_date).diff(window.moment.utc(b.start_date), 'seconds') < 0 ? -1 : 1;
            })[0];
            return window.moment.utc(aPeriod?.start_date).diff(window.moment.utc(bPeriod?.start_date), 'seconds') < 0 ? -1 : 1;
        });

        // first try to find params based on flight dates
        // if not found, just take the first params with no period
        return sortedParams.find((item) => {
            return (
                !!item.period?.some((period) => {
                    return window.moment.utc(dates.startDate).isBetween(
                        window.moment.utc(period.start_date),
                        window.moment.utc(period.end_date),
                    ) || window.moment.utc(dates.endDate).isBetween(
                        window.moment.utc(period.start_date),
                        window.moment.utc(period.end_date),
                    );
                })
            ) || (
                    !dates.startDate &&
                    !dates.endDate
                );
        }) ?? sortedParams.find((item) => {
            return !item.period || item.period.length === 0;
        });
    }, []);
}
