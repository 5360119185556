import axios from "axios";
import { isNumber } from "lodash";

let GoogleMapsLoader = require('google-maps');

//---- Actions ----//
import { SetDates, SetTravelerGroups, SetProviders, SetTripData, SetManualProviders } from "../../../Actions/Trip";
import { DestinationChange } from "../../../Actions/Header";
import {
    DestinationListChange,
    SetItinerary,
    SetDepartureReturnDestination,
    SetDepartureDestination,
    SetReturnDestination
} from "../../../Actions/Itinerary";
import { SetFlightCart } from "../../../Actions/Flight";
import { SetCarsCart } from "../../../Actions/CarsSearch";
import { SetCart, SetCurrentGroup } from "../../../Actions/Accommodation";
import { SetManualCart } from "../../../Actions/Cart";
import { SetCurrencyList, SetDefaultPictures } from "../../../Actions/Base";
import { SetPoiCart } from "../../../Actions/Poi";
import { ConcatTransfersCart } from "../../../Actions/Transfers";
import { SetLocales } from "../../../Actions/User";

//---- Functions ----//
import { isProductPackaged } from "../../CartMaterial/utils/isProductPackaged";
import CheckTripLoad from "./CheckTripLoad";
import CheckResponse from "../../Flight/FlightSelected/Functions/CheckResponse";

//---- Commons ----//
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import GetCookie from "../../Common/Functions/GetCookie";
import i18n from "../../../i18n";
import moment from "moment-timezone";

export default (user, dispatch, t, unlock = false, tripInfo) => {
    const { pass_check, headers } = CheckBeforeRequest();
    if ((pass_check || tripInfo) && `${tripInfo?.tripId ?? GetCookie("trip_id")}` !== "" && `${tripInfo?.tripVersion ?? GetCookie("trip_id_version")}` !== "") {
        let trip_data = null;
        let itinerary_data = null;
        let accommodation_data = null;
        let poi_data = null;
        let transfers_data = null;
        let flight_data = null;
        let providers_data = null;
        let manual_providers_data = null;
        let travelers_data = null;
        let cars_data = null;
        let manual_product_data = null;
        let has_stacked_items = false;
        let from_circuit_id = null;
        dispatch({ type: 'TRANSFERS_SET_CART', payload: { cart: [] } });
        //$.ajax({
        //method: "GET",
        //url: `${API_HREF}locales/`,
        //headers: headers,
        //success: function (data) {
        //console.log('data locales:', data);
        //const main_locale = data.results.find((el) => {return el.language_code === i18n.language});
        //dispatch(SetLocales(data.results));
        //dispatch({type: "USER_SET_MAIN_LOCALE", payload: main_locale});
        //},
        //error: function (error) {
        //console.log(error);
        //}
        //});
        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${tripInfo?.tripId ?? GetCookie("trip_id")}/?token=${tripInfo?.tripToken ?? GetCookie("trip_token")}${tripInfo?.providerToken ? '&provider_token=' + tripInfo.providerToken : ''}`,
            headers: headers,
            success: function (data) {
                if (data !== null) {
                    from_circuit_id = data.from_circuit_id;
                    let current_version = data.data.find(version => version.id === data.current_version);
                    let trip_request = [];
                    trip_request.push(
                        axios({
                            method: "GET",
                            headers: headers,
                            url: `${API_HREF}client/${window.id_owner}/flights_bo_params/by_destination/?destination_id=${current_version.destination.id}`
                        })
                    )
                    if (from_circuit_id !== null && current_version !== undefined) {
                        trip_request.push(
                            axios({
                                method: "GET",
                                headers: headers,
                                url: `${API_HREF}client/${window.id_owner}/flights_bo_params/circuit/${from_circuit_id}/`
                            })
                        )
                        if (current_version.contract !== null) {
                            let contract_id =  current_version.contract.id;
                            trip_request.push(
                                axios({
                                    method: "GET",
                                    headers: headers,
                                    url: `${API_HREF}client/${window.id_owner}/circuits/${from_circuit_id}/contracts/${contract_id}/product-period/?margin=true&limit=10000`
                                })
                            );
                            trip_request.push(
                                axios({
                                    method: "GET",
                                    headers: headers,
                                    url: `${API_HREF}client/${window.id_owner}/circuits/${from_circuit_id}/contracts/${contract_id}/prices-terrestrial/?margin=true&limit=10000`
                                })
                            );
                            trip_request.push(
                                axios({
                                    method: "GET",
                                    headers: headers,
                                    url: `${API_HREF}client/${window.id_owner}/circuits/${from_circuit_id}/contracts/${contract_id}/prices-flight/?margin=true&limit=10000`
                                })
                            );
                        }
                    }
                    axios.all([...trip_request]).then(axios.spread((...responses) => {
                        console.log('responses:', responses);
                        const version_start_date = current_version.start_date.split('T')[0];
                        const version_end_date = current_version.end_date.split('T')[0];
                        let circuit_params = responses[1] !== undefined ? responses[1].data : [];
                        let general_params = responses[0].data;
                        dispatch({
                            type: 'FLIGHT_SET_BO_PARAMS',
                            payload: [
                                ...(circuit_params ?? []),
                                ...(general_params ?? [])
                            ]
                        });
                        let flight_param = circuit_params.filter(param => {
                            let allowed_user = (user === null || user.client_full.type === 3 || param.allowed_client_type.includes(user.client_full.type));
                            let period = param.period.find(el => {
                                return moment(version_start_date).isSameOrAfter(moment(el.start_date), 'days') && moment(version_start_date).isBefore(moment(el.end_date), 'days') && moment(version_end_date).isAfter(moment(el.start_date), 'days') && moment(version_end_date).isSameOrBefore(moment(el.end_date), 'days');
                            });
                            let assigned_trip = from_circuit_id === null ? ["ALL", "CUSTOM_MADE"].includes(param.assigned_trip) : ["ALL", "CIRCUIT_PACKAGES"].includes(param.assigned_trip);
                            return allowed_user && period !== undefined && assigned_trip;
                        });
                        if (flight_param.length === 0) {
                            flight_param = circuit_params.filter(param => {
                                let allowed_user = (user === null || user.client_full.type === 3 || param.allowed_client_type.includes(user.client_full.type));
                                let period = param.period.length === 0;
                                let assigned_trip = from_circuit_id === null ? ["ALL", "CUSTOM_MADE"].includes(param.assigned_trip) : ["ALL", "CIRCUIT_PACKAGES"].includes(param.assigned_trip);
                                return allowed_user && period && assigned_trip;
                            });
                        }
                        if (flight_param.length === 0) {
                            flight_param = general_params.filter(param => {
                                let allowed_user = (user === null || user.client_full.type === 3 || param.allowed_client_type.includes(user.client_full.type));
                                let period = param.period.find(el => {
                                    return moment(version_start_date).isSameOrAfter(moment(el.start_date), 'days') && moment(version_start_date).isBefore(moment(el.end_date), 'days') && moment(version_end_date).isAfter(moment(el.start_date), 'days') && moment(version_end_date).isSameOrBefore(moment(el.end_date), 'days');
                                });
                                let assigned_trip = from_circuit_id === null ? ["ALL", "CUSTOM_MADE"].includes(param.assigned_trip) : ["ALL", "CIRCUIT_PACKAGES"].includes(param.assigned_trip);
                                return allowed_user && period !== undefined && assigned_trip;
                            });
                        }
                        if (flight_param.length === 0) {
                            flight_param = general_params.filter(param => {
                                let allowed_user = (user === null || user.client_full.type === 3 || param.allowed_client_type.includes(user.client_full.type));
                                let period = param.period.length === 0;
                                let assigned_trip = from_circuit_id === null ? ["ALL", "CUSTOM_MADE"].includes(param.assigned_trip) : ["ALL", "CIRCUIT_PACKAGES"].includes(param.assigned_trip);
                                return allowed_user && period && assigned_trip;
                            });
                        }
                        console.log('flight_param:', flight_param);
                        dispatch({type: 'ITINERARY_TYPE_SET_FLIGHT_CIRCUIT', payload: flight_param});
                        if (responses.length === 4) {
                            dispatch({type: 'TRIP_SET_CIRCUIT_INFO', payload: {
                                product_period: [...responses[1].data.results],
                                prices_terrestrial: [...responses[2].data],
                                prices_flight: [...responses[3].data]
                            }});
                        }
                    }));
                }
                dispatch({
                    type: "TRIP_SET_ALL_DATA",
                    payload: {
                        data: data
                    }
                });
            },
            error: function (error) {
                console.log(error);
            }
        });

        $.ajax({
            method: "GET",
            url: `${API_HREF}client/${window.id_owner}/trip/${tripInfo?.tripId ?? GetCookie("trip_id")}/versions/${tripInfo?.tripVersion ?? GetCookie("trip_id_version")}/?token=${tripInfo?.tripToken ?? GetCookie("trip_token")}${tripInfo?.providerToken ? '&provider_token=' + tripInfo.providerToken : ''}`,
            headers: headers,
            success: function (data) {
                console.log(data);
                /*
                if (parseInt(tripInfo?.tripVersion ?? GetCookie("trip_id_version")) !== data.trip.current_version) {
                    //TODO: notify user that he is not fetching the current version of the trip and ask him if he want to proceed or access the current version
                } else {
                */
                dispatch(SetDates({
                    startDate: data.start_date,
                    endDate: data.end_date
                }));
                console.log('data.travelers:', data.travelers);

                $.each(data.travelers, function (index_travelers, value_travelers) {
                    let age = moment.utc(data.end_date).diff(moment.utc(value_travelers.birth_date), "y");
                    if (age >= 12) {
                        value_travelers.flight_type = "ADT";
                    } else if (age >= 2) {
                        value_travelers.flight_type = "CNN";
                    } else {
                        value_travelers.flight_type = "INF";
                    }
                    value_travelers.passenger_group = null;
                });
                for (let i = 0; i < data.prices.length; i++) {
                    if (data.prices[i].selling_price === undefined) {
                        data.prices.splice(i, 1);
                        i--;
                    }
                }
                for (let i = 0; i < data.prices_flight.length; i++) {
                    if (data.prices_flight[i].selling_price === undefined) {
                        data.prices_flight.splice(i, 1);
                        i--;
                    }
                }
                for (let i = 0; i < data.prices_stack_product.length; i++) {
                    if (data.prices_stack_product[i].selling_price === undefined) {
                        data.prices_stack_product.splice(i, 1);
                        i--;
                    }
                }
                for (let i = 0; i < data.prices_terrestrial.length; i++) {
                    if (data.prices_terrestrial[i].selling_price === undefined) {
                        data.prices_terrestrial.splice(i, 1);
                        i--;
                    }
                }
                dispatch(SetTripData(data, data.travelers, (data.budget === null ? 0 : parseInt(data.budget)), data.trip.currency, data.contact_traveler, data.contact_traveler_detail, data.trip.trip_reference));
                dispatch(SetCurrentGroup(null));
                dispatch(DestinationChange(data.destination));
                if (parseInt(data.destination.data.type) === 4) {
                    $.ajax({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/destinations/${data.destination.id}/`,
                        success: function (data) {
                            let results = [];
                            results.push(data);
                            dispatch(DestinationListChange(results, false));
                        },
                        error: function (error) {
                            console.log("Error: something went wrong while loading destinations");
                            console.log(error);
                        }
                    });
                } else {
                    $.ajax({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/destinations/?limit=500&ordering=current_version__type&ordering=current_version__localization__name&parent=${data.destination.id}&visibility__in=PUBLIC,PRIVATE_TO`,
                        success: function (data) {
                            console.log(data);
                            dispatch(DestinationListChange(data.results, false));
                        },
                        error: function (error) {
                            console.log("Error: something went wrong while loading destinations");
                            console.log(error);
                        }
                    });
                }
                trip_data = data;
                $.ajax({
                    method: "GET",
                    url: `${API_HREF}client/${window.id_owner}/trip/${tripInfo?.tripId ?? GetCookie("trip_id")}/versions/${tripInfo?.tripVersion ?? GetCookie("trip_id_version")}/itinerary/?token=${tripInfo?.tripToken ?? GetCookie("trip_token")}`,
                    headers: headers,
                    success: function (data) {
                        console.log(data);
                        itinerary_data = data;
                        console.log("itinerary_data up:", itinerary_data);
                        let departure_destination = {
                            city: null,
                            country: null,
                            place_id: null,
                            lat: null,
                            lng: null,
                            start_date: null,
                            end_date: null,
                            retrieve: true
                        };
                        let return_destination = {
                            city: null,
                            country: null,
                            place_id: null,
                            lat: null,
                            lng: null,
                            start_date: null,
                            end_date: null,
                            retrieve: true
                        };
                        /*
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].step_type === "STEP") {
                                console.log("data[i] : ", data[i]);
                                const bounds = JSON.parse(data[i].destination.data.bounds.replace(/'/g,'"'));
                                dispatch({
                                    type: "MATERIAL_SET_ACTIVE_DESTINATION",
                                    payload: {
                                        active: data[i].id,
                                        bounds: {
                                            north: bounds.north,
                                            east: bounds.east,
                                            south: bounds.south,
                                            west: bounds.west,
                                        },
                                        zoom: data[i].destination.data.zoom_level
                                    }
                                });
//                                break;
                            }
                        }
                        */
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].step_type === "START") {
                                departure_destination.place_id = data[i].places_id;
                                departure_destination.start_date = data[i].start_date;
                                departure_destination.end_date = data[i].end_date;
                            } else if (data[i].step_type === "END") {
                                return_destination.place_id = data[i].places_id;
                                return_destination.start_date = data[i].start_date;
                                return_destination.end_date = data[i].end_date;
                            }
                        }
                        let key = "";
                        let config = JSON.parse(localStorage.getItem("config"));
                        for (let i = 0; i < config.keys.length; i++) {
                            if (config.keys[i].identifier === "google_api") {
                                key = config.keys[i].value;
                            }
                        }
                        let completed_departure_destination = null;
                        let completed_return_destination = null;
                        GoogleMapsLoader.KEY = key;
                        GoogleMapsLoader.LIBRARIES = ['geometry', 'places'];
                        GoogleMapsLoader.LANGUAGE = "fr";
                        GoogleMapsLoader.load(function (google) {
                            let geocoder = new google.maps.Geocoder();
                            geocoder.geocode({ placeId: departure_destination.place_id }, function (results, status) {
                                if (status === "OK") {
                                    completed_departure_destination = {
                                        city: null,
                                        country: null,
                                        place_id: departure_destination.place_id,
                                        lat: results[0].geometry.location.lat(),
                                        lng: results[0].geometry.location.lng(),
                                        start_date: departure_destination.start_date,
                                        end_date: departure_destination.end_date,
                                        retrieve: false
                                    };
                                    if (results[0].address_components !== undefined) {
                                        results[0].address_components.forEach(function (c) {
                                            switch (c.types[0]) {
                                                case 'neighborhood': case 'locality':
                                                    completed_departure_destination.city = c.long_name;
                                                    break;
                                                case 'country':
                                                    completed_departure_destination.country = c.long_name;
                                                    break;
                                            }
                                        });
                                    }
                                    dispatch(SetDepartureDestination(completed_departure_destination));
                                    setTimeout(function () {
                                    }, 1000);
                                }
                            });
                            geocoder.geocode({ placeId: return_destination.place_id }, function (results, status) {
                                if (status === "OK") {
                                    completed_return_destination = {
                                        city: null,
                                        country: null,
                                        place_id: return_destination.place_id,
                                        lat: results[0].geometry.location.lat(),
                                        lng: results[0].geometry.location.lng(),
                                        start_date: return_destination.start_date,
                                        end_date: return_destination.end_date,
                                        retrieve: false
                                    };
                                    if (results[0].address_components !== undefined) {
                                        results[0].address_components.forEach(function (c) {
                                            switch (c.types[0]) {
                                                case 'neighborhood': case 'locality':
                                                    completed_return_destination.city = c.long_name;
                                                    break;
                                                case 'country':
                                                    completed_return_destination.country = c.long_name;
                                                    break;
                                            }
                                        });
                                    }
                                    dispatch(SetReturnDestination(completed_return_destination));
                                    setTimeout(function () {
                                    }, 1000);
                                }
                            });
                        });
                        //dispatch(SetDepartureReturnDestination(completed_departure_destination, completed_return_destination));
                        dispatch(SetItinerary(data));

                        /*
                        if (data.length > 1) {
                            dispatch({
                                type:"MATERIAL_UPDATE_BOUNDS",
                                payload: {
                                    bounds: {
                                        north: map.getBounds().getNorthEast().lat(),
                                        east: map.getBounds().getNorthEast().lng(),
                                        south: map.getBounds().getSouthWest().lat(),
                                        west: map.getBounds().getSouthWest().lng()
                                    }
                                }
                            });
                        }*/
                        CheckTripLoad(dispatch, trip_data, itinerary_data, accommodation_data, flight_data, travelers_data, providers_data, cars_data, poi_data, transfers_data, manual_providers_data, manual_product_data);
                    },
                    error: function (error) {
                        console.log(error);
                    }
                });
                $.ajax({
                    method: "GET",
                    url: `${API_HREF}currencies/?limit=200`,
                    headers: headers,
                    success: function (data) {
                        if (data.results.length > 0) {
                            dispatch(SetCurrencyList(data.results));
                        }
                    },
                    error: function (error) {
                        console.log(error);
                    }
                });
                $.ajax({
                    method: "GET",
                    url: `${API_HREF}client/${window.id_owner}/providers/?limit=10000&token=${tripInfo?.tripToken ?? GetCookie("trip_token")}`,
                    headers: headers,
                    success: function (data) {
                        console.log(data);
                        providers_data = data;
                        if (data.results.length > 0) {
                            dispatch(SetProviders(data.results));
                        }
                        CheckTripLoad(dispatch, trip_data, itinerary_data, accommodation_data, flight_data, travelers_data, providers_data, cars_data, poi_data, transfers_data, manual_providers_data, manual_product_data);
                    },
                    error: function (error) {
                        console.log(error);
                    }
                });
                $.ajax({
                    method: "GET",
                    url: `${API_HREF}client/${window.id_owner}/trip/${tripInfo?.tripId ?? GetCookie("trip_id")}/versions/${tripInfo?.tripVersion ?? GetCookie("trip_id_version")}/travelers-group/?token=${tripInfo?.tripToken ?? GetCookie("trip_token")}&ordering=-default&limit=10000`,
                    headers: headers,
                    success: function (data) {
                        console.log(data);
                        travelers_data = data;
                        if (data.results.length > 0) {
                            // let sort_travelers_group = data.results.sort((a, b) => {
                            //     return a.id - b.id;
                            // });
                            // dispatch(SetTravelerGroups(sort_travelers_group));
                            dispatch(SetTravelerGroups(data.results));
                        }
                        CheckTripLoad(dispatch, trip_data, itinerary_data, accommodation_data, flight_data, travelers_data, providers_data, cars_data, poi_data, transfers_data, manual_providers_data, manual_product_data);
                    },
                    error: function (error) {
                        console.log(error);
                    }
                });
                $.ajax({
                    method: "GET",
                    url: `${API_HREF}client/${window.id_owner}/custom-providers/?limit=10000&reduced=true`,
                    headers: headers,
                    success: function (data) {
                        console.log(data);
                        manual_providers_data = data.results;
                        dispatch(SetManualProviders(data.results));
                        CheckTripLoad(dispatch, trip_data, itinerary_data, accommodation_data, flight_data, travelers_data, providers_data, cars_data, poi_data, transfers_data, manual_providers_data, manual_product_data);
                    },
                    error: function (error) {
                        console.log(error);
                    }
                });
                //$.ajax({
                //method: "GET",
                //url: `${API_HREF}client/${window.id_owner}/pictures/?type=DEFAULT_EXCURSIONS,DEFAULT_INSURANCES,DEFAULT_TRANSFERS,DEFAULT_TRAINS`,
                //headers: headers,
                //success: (data) => {
                //dispatch(SetDefaultPictures(data.results));
                //},
                //error: (error) => {
                //console.log(error);
                //}
                //});
                //TODO: add token in url when anonymous trip are handle (?token=" + localStorage.getItem("trip_token"))
                //GET all product
                let request_product = [];
                request_product.push(
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${tripInfo?.tripId ?? GetCookie("trip_id")}/versions/${tripInfo?.tripVersion ?? GetCookie("trip_id_version")}/accommodation/`,
                        params: {
                            token: tripInfo?.tripToken ?? GetCookie("trip_token"),
                            provider_token: tripInfo?.providerToken,
                        }
                    })
                );
                request_product.push(
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${tripInfo?.tripId ?? GetCookie('trip_id')}/versions/${tripInfo?.tripVersion ?? GetCookie('trip_id_version')}/assistance/`,
                        params: {
                            token: tripInfo?.tripToken ?? GetCookie("trip_token"),
                            provider_token: tripInfo?.providerToken,
                        }
                    })
                );
                request_product.push(
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${tripInfo?.tripId ?? GetCookie("trip_id")}/versions/${tripInfo?.tripVersion ?? GetCookie("trip_id_version")}/custom-products/`,
                        params: {
                            token: tripInfo?.tripToken ?? GetCookie("trip_token"),
                            provider_token: tripInfo?.providerToken,
                        }
                    })
                );
                request_product.push(
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${tripInfo?.tripId ?? GetCookie("trip_id")}/versions/${tripInfo?.tripVersion ?? GetCookie("trip_id_version")}/manual-products/`,
                        params: {
                            token: tripInfo?.tripToken ?? GetCookie("trip_token"),
                            provider_token: tripInfo?.providerToken,
                        }
                    })
                );
                request_product.push(
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${tripInfo?.tripId ?? GetCookie("trip_id")}/versions/${tripInfo?.tripVersion ?? GetCookie("trip_id_version")}/flight/`,
                        params: {
                            token: tripInfo?.tripToken ?? GetCookie("trip_token"),
                            provider_token: tripInfo?.providerToken,
                        }
                    })
                );
                request_product.push(
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${tripInfo?.tripId ?? GetCookie("trip_id")}/versions/${tripInfo?.tripVersion ?? GetCookie("trip_id_version")}/transfers/`,
                        params: {
                            token: tripInfo?.tripToken ?? GetCookie("trip_token"),
                            provider_token: tripInfo?.providerToken,
                        }
                    })
                );
                request_product.push(
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${tripInfo?.tripId ?? GetCookie("trip_id")}/versions/${tripInfo?.tripVersion ?? GetCookie("trip_id_version")}/car/`,
                        params: {
                            token: tripInfo?.tripToken ?? GetCookie("trip_token"),
                            provider_token: tripInfo?.providerToken,
                        }
                    })
                );
                request_product.push(
                    axios({
                        method: "GET",
                        headers: headers,
                        url: `${API_HREF}client/${window.id_owner}/trip/${tripInfo?.tripId ?? GetCookie("trip_id")}/versions/${tripInfo?.tripVersion ?? GetCookie("trip_id_version")}/insurance/`,
                        params: {
                            token: tripInfo?.tripToken ?? GetCookie("trip_token"),
                            provider_token: tripInfo?.providerToken,
                        }
                    })
                );
                console.log('trip_data:', trip_data);
                axios.all([...request_product]).then(axios.spread((...responses) => {
                    responses.forEach((response) => {
                        console.log('response:', response);
                        let url = response.config.url;
                        if (url.startsWith('/')) {
                            url = window.location.origin + url;
                        }
                        const configUrl = new URL(url);
                        console.log('configUrl:', configUrl);
                        if (configUrl.pathname.includes("accommodation")) {
                            accommodation_data = response.data;
                            dispatch(SetCart(response.data));
                            response.data.map(room => {
                                if (
                                    isProductPackaged({
                                        product: room,
                                        stackInfos: tripInfo?.stack_info ?? null
                                    })
                                ) {
                                    has_stacked_items = true;
                                }
                                if (room.booking_status !== null && room.booking_status.confirmation_reference === null && room.provider === 121) {
                                    axios({
                                        method: 'POST',
                                        headers: headers,
                                        url: `${API_HREF}client/${window.id_owner}/trip/${tripInfo?.tripId ?? GetCookie('trip_id')}/versions/${tripInfo?.tripVersion ?? GetCookie('trip_id_version')}/booking/check_status/?product_types=${room.product_type}`,
                                        data: {
                                            items: [room.id]
                                        }
                                    }).then(response => {
                                        dispatch({ type: 'ACCOMMODATION_UPDATE_CART', payload: response.data });
                                    }).catch(error => {
                                        console.log(error);
                                    });
                                }
                            });
                        }
                        if (configUrl.pathname.includes("manual-products")) {
                            if (response.data.length > 0) {
                                let products = [];
                                response.data.map((product) => {
                                    if (Object.keys(product).length > 0) {
                                        if (
                                            isProductPackaged({
                                                product: product,
                                                stackInfos: tripInfo?.stack_info ?? null
                                            })
                                        ) {
                                            has_stacked_items = true;
                                        }
                                        products.push(product);
                                    }
                                });
                                dispatch(SetManualCart(products));
                            }
                            manual_product_data = response.data;
                        }
                        if (configUrl.pathname.includes("flight")) {
                            flight_data = response.data;
                            if (response.data.length > 0) {
                                let arr = CheckResponse(response.data, data.end_date);
                                arr.map((el) => {
                                    if (
                                        isProductPackaged({
                                            product: el,
                                            stackInfos: tripInfo?.stack_info ?? null
                                        })
                                    ) {
                                        has_stacked_items = true;
                                    }
                                });
                                dispatch(SetFlightCart(arr));
                            }
                        }
                        if (configUrl.pathname.includes("car")) {
                            cars_data = [...response.data];
                            if (cars_data.length > 0) {
                                cars_data = cars_data.map((item) => {
                                    if (
                                        isProductPackaged({
                                            product: item,
                                            stackInfos: tripInfo?.stack_info ?? null
                                        })
                                    ) {
                                        has_stacked_items = true;
                                    }

                                    item.detail = false;
                                    item.charges.sort((a, b) => {
                                        return ((parseFloat(a.amount) < parseFloat(b.amount)) ? -1 : ((parseFloat(a.amount) > parseFloat(b.amount)) ? 1 : 0));
                                    });

                                    return item;
                                });
                                dispatch(SetCarsCart(cars_data));
                            }
                        }

                        if (configUrl.pathname.includes("transfers")) {
                            let arr = [];
                            let tmp_response = [...response.data];
                            tmp_response.map((item) => {
                                if (
                                    isProductPackaged({
                                        product: item,
                                        stackInfos: tripInfo?.stack_info ?? null
                                    })
                                ) {
                                    has_stacked_items = true;
                                }

                                arr.push(item);
                            });
                            dispatch(ConcatTransfersCart(arr));
                            if (transfers_data === null) {
                                transfers_data = arr;
                            }
                        }
                        if (configUrl.pathname.includes("custom-products")) {
                            let tmp_poi = [];
                            let tmp_transfers = [];
                            let tmp_response = [...response.data];
                            tmp_response.map((item) => {
                                if (
                                    isProductPackaged({
                                        product: item,
                                        stackInfos: tripInfo?.stack_info ?? null
                                    })
                                ) {
                                    has_stacked_items = true;
                                }

                                if (item.product_type === 4) {
                                    tmp_transfers.push(item);
                                } else {
                                    tmp_poi.push(item);
                                }
                            });
                            dispatch(SetPoiCart(tmp_poi));
                            dispatch(ConcatTransfersCart(tmp_transfers));
                            poi_data = tmp_poi;
                            if (transfers_data === null) {
                                transfers_data = tmp_transfers;
                            }
                        }
                        if (configUrl.pathname.includes("assistance") && response.data.length > 0) {
                            dispatch({ type: 'CART_ASSISTANCE_SET_CART', payload: response.data });
                        }
                        if (configUrl.pathname.includes("insurance") && response.data.results.length > 0) {
                            dispatch({ type: 'CART_ASSISTANCE_SET_CART', payload: response.data.results });
                        }
                    });
                    dispatch({ type: "BASE_SET_ALL_CART_DONE", payload: true });
                    dispatch({ type: "CART_SET_HAS_STACKED_ITEMS", payload: has_stacked_items });
                    CheckTripLoad(dispatch, trip_data, itinerary_data, accommodation_data, flight_data, travelers_data, providers_data, cars_data, poi_data, transfers_data, manual_providers_data, manual_product_data);
                }));
            },
            error: function (error) {
                console.log(error);
            }
        });
    } else {
        //TODO: display error while getting trip
    }
};
