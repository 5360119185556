import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Box,
    BoxProps,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Collapse,
    FormControl,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Radio,
    Select,
    SelectChangeEvent,
    Stack,
    styled,
    Tooltip,
    Typography
} from "@mui/material";
import {
    ArrowDropDown,
    ArrowRight,
    DirectionsCar,
    DragIndicator,
    ExpandLess,
    ExpandMore,
    Hotel,
    InfoOutlined,
    LocationOn,
    TransferWithinAStation,
    Visibility
} from "@mui/icons-material";
import { flatten, isNumber, isString, uniq, uniqBy } from "lodash";
import { extendMoment } from "moment-range";
import { ItineraryCircuitTreeViewIcon } from "./itineraryCircuitTreeViewIcon";
import { ItineraryCartTreeViewIcon } from "./itineraryCartTreeViewIcon";
import { ItineraryPackageTreeViewIcon } from "./itineraryPackageTreeViewIcon";
import { ItineraryBlockModal } from "./itineraryBlockModal";
import { getDestinationName } from "./utils/getDestinationName";
import { getBlockPackageData } from "./utils/getBlockPackageData";
import { filterCartProducts } from "./utils/filterCartProducts";
import { useBlockDrag } from "./utils/blockDrag";
import { StepsDatesManager } from "./utils/stepsDatesManager";
import { ItineraryContext } from "./utils/itineraryContext";
import { setBlocksCircuitsCache } from "./redux/reducer";
import { Itinerary } from "./objects/itinerary";
import { BlockType } from "./objects/blockType";
import { PricePeriod } from "./objects/pricePeriod";
import { Block } from "./objects/block";
import { TripBlock } from "./objects/tripBlock";
import { ItineraryState } from "./objects/itineraryState";
import { BlockReducedPackage } from "./objects/blockReducedPackage";
import { AppState } from "../../Reducers/Reducers";
import { Destination } from "./objects/destination";
import { locale } from "moment";

type ItemData = {
    type: BlockType,
    tripId: number,
    circuit: BlockReducedPackage | null,
    typicalTrip: TripBlock | null,
    name: string,
    steps: Itinerary[],
    products: {
        accommodations: NonNullable<Block['trip']>[number]['data'][number]['products'],
        cars: NonNullable<Block['trip']>[number]['data'][number]['products'],
        pois: NonNullable<Block['trip']>[number]['data'][number]['products'],
        flights: NonNullable<Block['trip']>[number]['data'][number]['products'],
        trains: NonNullable<Block['trip']>[number]['data'][number]['products'],
        cruises: NonNullable<Block['trip']>[number]['data'][number]['products'],
        ferries: NonNullable<Block['trip']>[number]['data'][number]['products'],
        assistances: NonNullable<Block['trip']>[number]['data'][number]['products'],
        insurances: NonNullable<Block['trip']>[number]['data'][number]['products'],
        transfers: NonNullable<Block['trip']>[number]['data'][number]['products'],
    },
    version: number,
    versionNo: number,
    versionName: string,
}

export function ItineraryBlocksTreeView(): JSX.Element {
    const { t } = useTranslation();
    const searching = useSelector((state: AppState) => state.itinerarySlice.blocks.searching);

    return (
        <>
            {
                searching &&
                <LinearProgress sx={{ marginTop: -2 }} />
            }
            <TreeView>
                <ParentDestinationTree />
            </TreeView>
        </>
    );
}

function ParentDestinationTree(): React.ReactNode {
    const parentDestinationTree = useSelector((state: AppState) => state.itinerarySlice.parentDestinationTree);

    return parentDestinationTree.map((destination) => (
        <ParentDestinationTreeItem key={destination.id} destination={destination} />
    ));
}

type ParentDestinationTreeItemProps = {
    destination: Destination
}

function ParentDestinationTreeItem(props: ParentDestinationTreeItemProps): JSX.Element {
    const { t, i18n } = useTranslation();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const circuits = useSelector((state: AppState) => state.itinerarySlice.blocks.circuits);
    const typicalTrips = useSelector((state: AppState) => state.itinerarySlice.blocks.typicalTrips);
    const typeFilter = useSelector((state: AppState) => state.itinerarySlice.blocks.filters.types);
    const nightsCountFilter = useSelector((state: AppState) => state.itinerarySlice.blocks.filters.nightsCount);
    const productsFilter = useSelector((state: AppState) => state.itinerarySlice.blocks.filters.products);
    const sortBy = useSelector((state: AppState) => state.itinerarySlice.blocks.sortBy);
    const packageCache = useSelector((state: AppState) => state.itinerarySlice.blocks.circuits.cache);
    const searching = useSelector((state: AppState) => state.itinerarySlice.blocks.searching);
    const [open, setOpen] = useState(true);
    const items: ItemData[] = useMemo(() => {
        if (!tripStartDate || !tripEndDate) {
            return [];
        }

        const datesManager = new StepsDatesManager(
            tripStartDate,
            tripEndDate
        );

        let result = flatten(
            circuits.data[props.destination.id]?.map((item) => {
                const versions = item.trip?.[0]?.data ?? [];
                return versions.map((version, index): typeof items[0] | undefined => {
                    const cache = packageCache[item.id]?.[version.id];
                    if (item.trip?.[0]?.id) {
                        return {
                            type: item.type.toLowerCase() as 'circuit' | 'package',
                            tripId: item.trip[0].id,
                            circuit: item,
                            typicalTrip: null,
                            name: item.name ?? '',
                            steps: cache?.steps.filter((step) => {
                                return step.step_type === 'STEP';
                            }) ?? [],
                            products: {
                                accommodations: cache?.products.filter((item) => {
                                    return item.product_type === 0;
                                }) ?? [],
                                cars: cache?.products.filter((item) => {
                                    return item.product_type === 2;
                                }) ?? [],
                                assistances: cache?.products.filter((item) => {
                                    return item.product_type === 19;
                                }) ?? [],
                                cruises: cache?.products.filter((item) => {
                                    return item.product_type === 9;
                                }) ?? [],
                                ferries: cache?.products.filter((item) => {
                                    return item.product_type === 13;
                                }) ?? [],
                                flights: cache?.products.filter((item) => {
                                    return item.product_type === 6;
                                }) ?? [],
                                insurances: cache?.products.filter((item) => {
                                    return item.product_type === 8;
                                }) ?? [],
                                pois: cache?.products.filter((item) => {
                                    return [1, 12].includes(item.product_type);
                                }) ?? [],
                                trains: cache?.products.filter((item) => {
                                    return item.product_type === 5;
                                }) ?? [],
                                transfers: cache?.products.filter((item) => {
                                    return item.product_type === 4;
                                }) ?? []
                            },
                            version: version.id,
                            versionNo: index + 1,
                            versionName: version.name ?? ''
                        };
                    }
                }).filter(isNotUndefined);
            })
        ).concat(
            flatten(
                typicalTrips.data[props.destination.id]?.map((item) => {
                    const version = item.data[0];

                    if (version) {
                        return [
                            {
                                type: 'typical-trip',
                                typicalTrip: item,
                                tripId: item.id,
                                circuit: null,
                                name: item.name ?? '',
                                steps: version.steps.filter((step) => {
                                    return step.step_type === 'STEP';
                                }),
                                products: {
                                    accommodations: version.products.filter((item) => {
                                        return item.product_type === 0;
                                    }),
                                    cars: version.products.filter((item) => {
                                        return item.product_type === 2;
                                    }),
                                    assistances: version.products.filter((item) => {
                                        return item.product_type === 19;
                                    }),
                                    cruises: version.products.filter((item) => {
                                        return item.product_type === 9;
                                    }),
                                    ferries: version.products.filter((item) => {
                                        return item.product_type === 13;
                                    }),
                                    flights: version.products.filter((item) => {
                                        return item.product_type === 6;
                                    }),
                                    insurances: version.products.filter((item) => {
                                        return item.product_type === 8;
                                    }),
                                    pois: version.products.filter((item) => {
                                        return [1, 12].includes(item.product_type);
                                    }),
                                    trains: version.products.filter((item) => {
                                        return item.product_type === 5;
                                    }),
                                    transfers: version.products.filter((item) => {
                                        return item.product_type === 4;
                                    })
                                },
                                version: version.id,
                                versionNo: 1,
                                versionName: version.name ?? ''
                            }
                        ];
                    }

                    return [];
                })
            )
        );

        //filter items
        result = result.filter((item) => {
            const version = item.circuit?.trip?.[0]?.data?.find((version) => {
                return version.id === item.version;
            });
            const nightsCount = item.type === 'typical-trip' ?
                datesManager.countTotalNights(item.steps) :
                datesManager.countTotalNights(version?.itineraries ?? []);

            const passedCountsFilter = !nightsCountFilter ||
                (
                    nightsCountFilter[0] <= nightsCount &&
                    nightsCount <= nightsCountFilter[1]
                );

            let passedProductsFilter = false;

            const productsLength = item.type === 'typical-trip' ?
                (item.products?.accommodations.length ?? 0) +
                (item.products?.cars.length ?? 0) +
                (item.products?.pois.length ?? 0) +
                (item.products?.transfers.length ?? 0) :
                version?.number_of_products_in_basket ?? 0;

            switch (productsFilter) {
                case 'all': passedProductsFilter = true; break;
                case 'with': passedProductsFilter = productsLength > 0; break;
                case 'without': passedProductsFilter = productsLength === 0; break;
            }

            if (passedCountsFilter && passedProductsFilter) {
                if (item.type === 'circuit' && typeFilter.circuit) {
                    return true;
                } else if (item.type === 'package' && typeFilter.package) {
                    return true;
                } else if (item.type === 'typical-trip' && typeFilter["typical-trip"]) {
                    return true;
                }
            }

            return false;
        });

        //sort items
        if (sortBy) {
            result.sort((a, b) => {
                const aVersion = a.circuit?.trip?.[0]?.data?.find((version) => {
                    return version.id === a.version;
                });
                const bVersion = b.circuit?.trip?.[0]?.data?.find((version) => {
                    return version.id === b.version;
                });
                const aNightsCount = a.type === 'typical-trip' ?
                    datesManager.countTotalNights(a.steps) :
                    datesManager.countTotalNights(aVersion?.itineraries ?? []);

                const bNightsCount = b.type === 'typical-trip' ?
                    datesManager.countTotalNights(b.steps) :
                    datesManager.countTotalNights(bVersion?.itineraries ?? []);

                if (sortBy.direction === 'asc') {
                    return aNightsCount < bNightsCount ? -1 : 1;
                }

                return aNightsCount < bNightsCount ? 1 : -1;
            });
        }

        return result;
    }, [
        circuits.data[props.destination.id]?.map((item) => {
            return item.id;
        }).join(','),
        Object.keys(packageCache).join(',') +
        flatten(
            Object.values(packageCache).map((item) => {
                return Object.keys(item);
            })
        ).join(','),
        typicalTrips.data[props.destination.id]?.map((item) => {
            return item.id;
        }).join(','),
        typeFilter,
        nightsCountFilter,
        productsFilter,
        sortBy,
        tripStartDate,
        tripEndDate
    ]);

    return (
        <>
            <Button
                endIcon={
                    !open ?
                        <ExpandMore /> :
                        <ExpandLess />
                }
                sx={{
                    textTransform: 'none'
                }}
                onClick={() => setOpen((state) => !state)}
                fullWidth
            >
                {
                    (
                        props.destination.data?.localization.find((item) => {
                            return item.locale === locale;
                        })?.name ??
                        props.destination.data?.name ??
                        props.destination.data?.international_name ??
                        ''
                    ).split(',')[0]
                }
            </Button>
            <Collapse in={open}>
                <Box>
                    {
                        !searching && items.length === 0 &&
                        <Typography sx={{ textAlign: 'center' }}>
                            {t('itinerary.no-blocks-found')}
                        </Typography>
                    }
                    <Wrapper items={items} />
                </Box>
            </Collapse>
        </>
    )
}

type WrapperProps = {
    items: ItemData[]
}

const Wrapper = React.memo(
    function Wrapper(props: WrapperProps): React.ReactNode {
        return props.items.length > 0 && props.items.map((item) => (
            <RootItem
                key={`${item.tripId}-${item.version}-${item.circuit?.id}`}
                {...item}
            />
        ))
    }
);

type RootItemProps = ItemData

function RootItem(props: RootItemProps): JSX.Element {
    return (
        <RootItemDraggable {...props} />
    );
}

type ItemProps = {
    circuit: BlockReducedPackage | null,
    version: number | null,
    label: React.ReactNode,
    isChild?: boolean
} & BoxProps

const Item = React.forwardRef<HTMLDivElement, ItemProps>(
    function Item(props, ref): JSX.Element {
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const [open, setOpen] = useState(false);
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState<Error | null>(null);
        const { label, isChild, ...rest } = props;

        const onLoadData = async () => {
            if (props.circuit) {
                if (
                    props.circuit?.trip?.[0]?.id &&
                    props.version &&
                    !open
                ) {
                    setLoading(true);
                    try {
                        setError(null);
                        const data = await getBlockPackageData({
                            circuitId: props.circuit.id,
                            tripId: props.circuit.trip[0].id,
                            version: props.version,
                            contractIds: props.circuit.contracts ?? [],
                            source: props.circuit.source
                        });
                        dispatch(
                            setBlocksCircuitsCache({
                                circuitId: props.circuit.id,
                                source: props.circuit.source,
                                versionId: props.version,
                                data
                            })
                        );
                        setOpen(true);
                        return;
                    } catch (error) {
                        console.error(error);
                        setError(error as Error);
                    } finally {
                        setLoading(false);
                    }
                }

                if (open) {
                    setOpen(false);
                }
            } else {
                setOpen((state) => !state);
            }
        }

        return (
            <Box
                ref={ref}
                component="li"
                {...rest}
                sx={{
                    ...rest.sx,
                    paddingLeft: 1.5,
                    paddingRight: 1.5,
                    cursor: 'pointer'
                }}
            >
                <Box
                    onClick={onLoadData}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Stack
                        direction="row"
                        sx={{
                            "width": '100%',
                            "paddingTop": isChild ? 0.5 : undefined,
                            "paddingBottom": isChild ? 0.5 : undefined,
                            "paddingLeft": isChild ? 1 : undefined,
                            "paddingRight": isChild ? 1 : undefined,
                            '&:hover': {
                                backgroundColor: '#cfcfcf'
                            }
                        }}
                        alignItems="center"
                        spacing={1}
                    >
                        {
                            !isChild &&
                            <>
                                {
                                    open ?
                                        <ArrowRight fontSize="small" /> :
                                        <ArrowDropDown fontSize="small" />
                                }
                            </>
                        }
                        <Box sx={{ width: 'calc(100% - 28px)' }}>
                            {label}
                        </Box>
                    </Stack>
                </Box>
                {
                    open &&
                    !error &&
                    <Box sx={{ marginBottom: 1 }}>
                        {props.children}
                    </Box>
                }
                {
                    loading &&
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size="1em" />
                    </Box>
                }
                {
                    error &&
                    <Alert
                        severity="error"
                        action={
                            <Button
                                size="small"
                                onClick={onLoadData}
                                sx={{
                                    textTransform: 'none'
                                }}
                            >
                                {t('crm.common.retry-button')}
                            </Button>
                        }
                    >
                        {t('crm.login.reset-password-missing-info')}
                    </Alert>
                }
            </Box>
        );
    }
);


function RootItemDraggable(props: ItemData): JSX.Element | null {
    const { t, i18n } = useTranslation();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const isUserTO = useSelector((state: AppState) => state.user.user?.client_full?.type !== 2);
    const cache = useSelector((state: AppState) => {
        if (props.circuit?.id && props.version) {
            return state.itinerarySlice.blocks.circuits.cache[props.circuit.id]?.[props.version];
        }
        return null;
    });
    const [openModal, setOpenModal] = useState(false);
    const [openTextTooltip, setOpenTextTooltip] = useState(false);
    const [openDragTooltip, setOpenDragTooltip] = useState(false);
    const [selectedSteps, setSelectedSteps] = useState<number[]>([]);
    const [selectedVariant, setSelectedVariant] = useState<number | null>(null);
    const [selectedVariantDate, setSelectedVariantDate] = useState<{
        [variant: number]: string
    }>({});
    const variants = useMemo(() => {
        return uniqBy(
            ([] as NonNullable<
                typeof props.products.accommodations |
                typeof props.products.cars |
                typeof props.products.transfers |
                typeof props.products.pois
            >).concat(
                props.products.accommodations
            ).concat(
                props.products.cars
            ).concat(
                props.products.transfers
            ).concat(
                props.products.pois
            ).map((product) => {
                return {
                    variant: product.variant as number | null,
                    version: props.version
                };
            }).concat(
                props.circuit?.variant_name.map((item) => ({
                    variant: item.id,
                    version: item.version
                })) ?? []
            ).filter((item): item is { variant: number, version: number } => {
                return isNumber(item.variant);
            }),
            (item) => `${item.version}-${item.variant}`
        ).filter((item) => {
            return item.version === props.version;
        });
    }, [props.circuit]);
    const context = useContext(ItineraryContext);
    const draggableProducts = useMemo(() => {
        const associatedProducts: NonNullable<ItemChildProps['products']> = {
            accommodations: [],
            cars: [],
            pois: [],
            transfers: [],
            flights: [],
            cruises: [],
            ferries: [],
            trains: [],
            assistances: [],
            insurances: []
        };
        const steps = props.steps.filter((step) => {
            return selectedSteps.includes(step.id);
        });
        for (const step of steps) {
            const filteredStepAssociatedProducts = filterCartProducts(
                {
                    accommodations: props.products.accommodations.map((item) => ({
                        type: 'manual',
                        accommodation: item as any
                    })),
                    cars: props.products.cars.map((item) => ({
                        type: 'manual',
                        car: item as any
                    })),
                    pois: props.products.pois.map((item) => ({
                        type: 'manual',
                        poi: item as any
                    })),
                    transfers: props.products.transfers.map((item) => ({
                        type: 'manual',
                        transfer: item as any
                    })),
                    flights: props.products.flights.map((item) => ({
                        type: 'manual',
                        flight: item as any
                    })),
                    cruises: props.products.cruises.map((item) => ({
                        type: 'manual',
                        cruise: item as any
                    })),
                    ferries: props.products.ferries.map((item) => ({
                        type: 'manual',
                        ferry: item as any
                    })),
                    trains: props.products.trains.map((item) => ({
                        type: 'manual',
                        train: item as any
                    })),
                    assistances: props.products.assistances.map((item) => ({
                        type: 'manual',
                        assistance: item as any
                    })),
                    insurances: props.products.insurances.map((item) => ({
                        type: 'manual',
                        insurance: item as any
                    }))
                },
                {
                    isUserTO,
                    arrivalDate: step.start_date,
                    departureDate: step.end_date,
                    useOnlyDates: true
                }
            );
            const stepAssociatedProducts: NonNullable<ItemChildProps['products']> = {
                accommodations: filteredStepAssociatedProducts.accommodations.map((item) => item.accommodation as any),
                cars: filteredStepAssociatedProducts.cars.map((item) => item.car as any),
                pois: filteredStepAssociatedProducts.pois.map((item) => item.poi as any),
                transfers: filteredStepAssociatedProducts.transfers.map((item) => item.transfer as any),
                flights: filteredStepAssociatedProducts.flights.map((item) => item.flight as any),
                cruises: filteredStepAssociatedProducts.cruises.map((item) => item.cruise as any),
                ferries: filteredStepAssociatedProducts.ferries.map((item) => item.ferry as any),
                trains: filteredStepAssociatedProducts.trains.map((item) => item.train as any),
                assistances: filteredStepAssociatedProducts.assistances.map((item) => item.assistance as any),
                insurances: filteredStepAssociatedProducts.insurances.map((item) => item.insurance as any)
            };
            associatedProducts.accommodations = associatedProducts.accommodations.concat(stepAssociatedProducts.accommodations);
            associatedProducts.cars = associatedProducts.cars.concat(stepAssociatedProducts.cars);
            associatedProducts.pois = associatedProducts.pois.concat(stepAssociatedProducts.pois);
            associatedProducts.transfers = associatedProducts.transfers.concat(stepAssociatedProducts.transfers);
        }
        const ids = associatedProducts.accommodations.filter((item) => {
            return !isNumber(item.variant) || item.variant === selectedVariant;
        }).map((item) => {
            return item.id;
        }).concat(
            associatedProducts.cars.filter((item) => {
                return !isNumber(item.variant) || item.variant === selectedVariant;
            }).map((item) => {
                return item.id;
            }) ?? []
        ).concat(
            associatedProducts.transfers.filter((item) => {
                return !isNumber(item.variant) || item.variant === selectedVariant;
            }).map((item) => {
                return item.id;
            }) ?? []
        ).concat(
            associatedProducts.pois.filter((item) => {
                return !isNumber(item.variant) || item.variant === selectedVariant;
            }).map((item) => {
                return item.id;
            }) ?? []
        );
        return uniq(ids);
    }, [props.products, isUserTO, props.steps, selectedVariant, selectedSteps]);
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [collected, dragHandle, previewHandle] = useBlockDrag({
        circuitId: props.circuit?.id ?? null,
        circuit: cache ?? null,
        circuitSource: props.circuit?.source ?? null,
        typicalTrip: props.typicalTrip,
        draggableProducts,
        dates: [],
        selectedSteps,
        selectedVariant,
        selectedVariantDate,
        tripId: props.tripId,
        version: props.version
    });
    const version = props.circuit?.trip?.[0]?.data?.find((item) => {
        return item.id === props.version;
    });
    const stepsIds = props.steps.map((item) => item.id);
    const datesManager = new StepsDatesManager(
        tripStartDate ?? new Date().toISOString(),
        tripEndDate ?? new Date().toISOString()
    );
    const nightsCount = props.type === 'typical-trip' ?
        datesManager.countTotalNights(props.steps) :
        datesManager.countTotalNights(version?.itineraries ?? []);
    const productsLength = (props.products.accommodations.length ?? 0) +
        (props.products.cars.length ?? 0) +
        (props.products.pois.length ?? 0) +
        (props.products.transfers.length ?? 0);
    const productsCount = props.type === 'typical-trip' ?
        productsLength :
        props.circuit?.trip?.[0]?.data?.find((item) => {
            return item.id === props.version;
        })?.number_of_products_in_basket ?? 0

    const onChangeStepSelection = (id: number, selected: boolean) => {
        setSelectedSteps((state) => {
            const result = state.filter((item) => {
                return item !== id;
            });
            if (selected) {
                result.push(id);
            }
            return result;
        });
    };

    useEffect(() => {
        setSelectedSteps(stepsIds);
    }, [stepsIds.join(',')]);

    useEffect(() => {
        if (variants[0]) {
            setSelectedVariant(variants[0].variant);
        }
    }, [variants]);

    return (
        <>
            <Item
                ref={previewHandle}
                circuit={props.circuit}
                version={props.version}
                label={
                    <Tooltip
                        open={openDragTooltip ? false : openTextTooltip}
                        placement="top"
                        title={
                            t(
                                'itinerary.block-name-with-version',
                                {
                                    number: props.versionNo,
                                    versionName: props.versionName.trim().length > 0 ?
                                        `(${props.versionName})` :
                                        '',
                                    blockName: props.name
                                }
                            )
                        }
                        onOpen={() => setOpenTextTooltip(true)}
                        onClose={() => setOpenTextTooltip(false)}
                        disableInteractive
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                        >
                            {
                                props.type === 'package' &&
                                <ItineraryPackageTreeViewIcon fontSize="inherit" sx={{ fontSize: 12 }} />
                            }
                            {
                                props.type === 'circuit' &&
                                <ItineraryCircuitTreeViewIcon fontSize="inherit" sx={{ fontSize: 12 }} />
                            }
                            {
                                props.type === 'typical-trip' &&
                                <LocationOn fontSize="inherit" sx={{ fontSize: 12, opacity: 0.54 }} />
                            }
                            <Text sx={{ marginLeft: 0.5 }}>
                                {nightsCount}N
                            </Text>
                            {
                                productsCount > 0 &&
                                <ItineraryCartTreeViewIcon
                                    fontSize="inherit"
                                    sx={{ fontSize: 12, marginLeft: 0.5 }}
                                />
                            }
                            <Text
                                sx={{
                                    marginLeft: 1,
                                    width: '100%',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {props.name}
                            </Text>
                            <IconButton
                                sx={{ fontSize: 11, marginLeft: 1 }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setOpenModal(true);
                                }}
                            >
                                <InfoOutlined fontSize="inherit" />
                            </IconButton>
                            {
                                props.type === 'typical-trip' &&
                                <Tooltip
                                    open={openDragTooltip && !context.isDragging}
                                    title={t('itinerary.drag-to-add-block')}
                                    onOpen={() => setOpenDragTooltip(true)}
                                    onClose={() => setOpenDragTooltip(false)}
                                    disableInteractive
                                >
                                    <IconButton
                                        ref={dragHandle}
                                        sx={{ fontSize: 10 }}
                                        onClick={(event) => event.stopPropagation()}
                                    >
                                        <DragIndicator fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Stack>
                    </Tooltip>
                }
            >
                {
                    props.circuit?.id &&
                        (
                            props.type === 'circuit' ||
                            props.type === 'package'
                        ) ?
                        <>
                            {
                                variants.map((item, index) => (
                                    <ItemVariant
                                        key={item.variant}
                                        circuitId={props.circuit?.id ?? null}
                                        circuit={cache ?? null}
                                        circuitSource={props.circuit?.source ?? null}
                                        typicalTrip={props.typicalTrip}
                                        version={item.version}
                                        variantId={item.variant}
                                        variantName={
                                            (() => {
                                                const name = props.circuit?.variant_name.find((variant) => {
                                                    return variant.id === item.variant &&
                                                        variant.version === props.version;
                                                })?.name;
                                                return isString(name) ? name : name?.[locale] ?? t('shared.circuit-variant-num', { no: index + 1 });
                                            })()
                                        }
                                        tripId={props.tripId}
                                        selectedSteps={selectedSteps}
                                        selectedVariantDate={selectedVariantDate}
                                        selected={item.variant === selectedVariant}
                                        draggableProducts={draggableProducts}
                                        onChangeDate={setSelectedVariantDate}
                                        onChangeSelection={(selected) => selected && setSelectedVariant(item.variant)}
                                        openByDefault={variants.length === 1}
                                    >
                                        {
                                            props.steps.map((itinerary) => (
                                                <ItemChild
                                                    key={itinerary.id}
                                                    itinerary={itinerary}
                                                    variant={item.variant}
                                                    products={props.products}
                                                    selected={selectedSteps.findIndex((item) => item === itinerary.id) >= 0}
                                                    onChangeSelection={(selected) => onChangeStepSelection(itinerary.id, selected)}
                                                    disabled={
                                                        item.variant !== selectedVariant ||
                                                        props.circuit?.source !== 'OFFLINE' ||
                                                        props.circuit.product_locked
                                                    }
                                                />
                                            ))
                                        }
                                    </ItemVariant>
                                ))
                            }
                        </> :
                        <>
                            {
                                props.steps.map((itinerary) => (
                                    <ItemChild
                                        key={itinerary.id}
                                        itinerary={itinerary}
                                        products={props.products}
                                        selected={selectedSteps.findIndex((item) => item === itinerary.id) >= 0}
                                        onChangeSelection={(selected) => onChangeStepSelection(itinerary.id, selected)}
                                    />
                                ))
                            }
                        </>
                }
            </Item>
            {
                props.type !== 'typical-trip' &&
                props.circuit &&
                <ItineraryBlockModal
                    type={props.type}
                    open={openModal}
                    circuit={props.circuit}
                    version={props.version}
                    onClose={() => setOpenModal(false)}
                />
            }
            {
                props.type === 'typical-trip' &&
                props.typicalTrip &&
                <ItineraryBlockModal
                    type={props.type}
                    open={openModal}
                    version={props.version}
                    trip={props.typicalTrip}
                    onClose={() => setOpenModal(false)}
                />
            }
        </>
    );
}

type ItemVariantProps = {
    circuitId: number | null,
    circuit: ItineraryState['blocks']['circuits']['cache'][number][number] | null,
    circuitSource: string | null,
    typicalTrip: TripBlock | null,
    tripId: number,
    version: number,
    variantId: number,
    variantName: string,
    children: React.ReactNode,
    selected: boolean,
    selectedVariantDate: {
        [variant: number]: string
    },
    selectedSteps: number[],
    draggableProducts: number[],
    onChangeDate: React.Dispatch<React.SetStateAction<{
        [variant: number]: string;
    }>>,
    onChangeSelection: (selected: boolean) => void,
    openByDefault: boolean
}

function ItemVariant(props: ItemVariantProps): JSX.Element {
    const { t } = useTranslation();
    const tripStartDate = useSelector((state: AppState) => state.trip.start_date);
    const tripEndDate = useSelector((state: AppState) => state.trip.end_date);
    const [open, setOpen] = useState(props.openByDefault);
    const [periods, setPeriods] = useState<PricePeriod[]>([]);
    const fixedDates = useMemo(() => {
        const momentRange = extendMoment(window.moment as any);
        return flatten(
            periods.map((item) => {
                if (
                    !window.moment.utc(item.start_date).startOf('day').isSame(
                        window.moment.utc(item.end_date).startOf('day'),
                        'days'
                    )
                ) {
                    const range = momentRange.range(
                        window.moment.utc(item.start_date),
                        window.moment.utc(item.end_date)
                    );
                    return Array.from(range.by('day')).filter((date) => {
                        let day = parseInt(date.format('d')) - 1;
                        day = day < 0 ? 6 : day;
                        return item.start_days.includes(day);
                    }).map((date) => {
                        return {
                            ...item,
                            isPeriod: item.start_days.length === 7,
                            start_date: date.toISOString(),
                            end_date: date.toISOString(),
                        };
                    })
                };
                return [{ ...item, isPeriod: false }];
            })
        ).filter((item) => {
            return window.moment.utc(item.start_date).startOf('day').isSameOrAfter(
                window.moment.utc().startOf('day')
            );
        }).sort((a, b) => {
            return window.moment.utc(a.start_date).isBefore(
                window.moment.utc(b.start_date)
            ) ?
                -1 :
                1;
        });
    }, [periods]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [collected, dragHandle, previewHandle] = useBlockDrag({
        circuitId: props.circuitId,
        circuit: props.circuit,
        circuitSource: props.circuitSource,
        typicalTrip: props.typicalTrip,
        draggableProducts: props.draggableProducts,
        dates: fixedDates,
        selectedSteps: props.selectedSteps,
        selectedVariant: props.variantId,
        selectedVariantDate: props.selectedVariantDate,
        tripId: props.tripId,
        version: props.version
    });

    const onChange = (event: SelectChangeEvent<string>) => {
        props.onChangeDate((state) => ({
            ...state,
            [props.variantId]: event.target.value
        }));
    };

    useEffect(() => {
        if (tripStartDate && tripEndDate) {
            props.onChangeDate((state) => {
                const date = fixedDates.find((item) => {
                    return window.moment.utc(item.start_date).isBetween(
                        window.moment.utc(tripStartDate),
                        window.moment.utc(tripEndDate),
                        'day',
                        '[]'
                    );
                });
                return {
                    ...state,
                    [props.variantId]: date?.start_date ?? ''
                };
            });
        }
    }, [
        tripStartDate,
        tripEndDate,
        props.variantId,
        fixedDates,
        props.onChangeDate
    ]);

    useEffect(() => {
        if (props.circuit) {
            (async () => {
                let periods = props.circuit?.periods ?? [];
                const periodIds = flatten(
                    props.circuit?.priceTerrestrial?.map((item) => {
                        return item.periods;
                    }).concat(
                        props.circuit.priceFlight?.map((item) => {
                            return item.periods;
                        }) ?? []
                    )
                );
                periods = periods.filter((period) => {
                    return periodIds.includes(period.id);
                });
                setPeriods(
                    periods.filter((item) => {
                        return item.trip_version === props.version &&
                            item.variant === props.variantId &&
                            window.moment.utc(item.end_date).startOf('day').isSameOrAfter(
                                window.moment.utc().startOf('day'),
                                'day'
                            );
                    })
                );
            })();
        }
    }, [
        props.circuit,
        props.version,
        props.variantId
    ]);

    return (
        <Stack ref={previewHandle} sx={{ paddingLeft: 2 }}>
            {
                fixedDates.length === 0 &&
                periods.length > 0 &&
                <Alert severity="info">
                    <Typography variant="caption">
                        {t('itinerary.block-verify-availability-hint')}
                    </Typography>
                </Alert>
            }
            <Stack direction="row" alignItems="center" onClick={() => setOpen(!open)}>
                <Radio
                    sx={{
                        '& .MuiSvgIcon-root': { fontSize: 16 },
                        "padding": 0,
                        "marginRight": 1
                    }}
                    checked={props.selected}
                    onChange={(_, checked) => props.onChangeSelection(checked)}
                    onClick={(event) => event.stopPropagation()}
                />
                <Text sx={{ marginLeft: 0.5 }}>
                    {props.variantName}
                </Text>
                <ExpandMore sx={{ marginLeft: 0.5, fontSize: '1em' }} />
                <Tooltip
                    title={t('itinerary.drag-to-add-block')}
                    disableInteractive
                >
                    <IconButton
                        ref={dragHandle}
                        sx={{ fontSize: 10 }}
                        onClick={(event) => event.stopPropagation()}
                        disabled={!props.selected}
                    >
                        <DragIndicator fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            </Stack>
            <div>
                <Collapse in={open}>
                    {
                        fixedDates.length > 0 &&
                        <FormControl size="small" fullWidth sx={{ marginTop: 2 }}>
                            <InputLabel>
                                {t('global.date')}
                            </InputLabel>
                            <Select
                                label={t('global.date')}
                                value={props.selectedVariantDate[props.variantId] ?? ''}
                                onChange={onChange}
                            >
                                {
                                    fixedDates.map((date) => (
                                        <MenuItem key={date.id} value={date.start_date}>
                                            {
                                                window.moment.utc(
                                                    date.start_date
                                                ).format('L')
                                            }
                                            {
                                                date.on_request &&
                                                <Chip
                                                    size="small"
                                                    color="warning"
                                                    label={t('shared.on-request')}
                                                    sx={{ marginLeft: 1 }}
                                                />
                                            }
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    }
                    {props.children}
                </Collapse>
            </div>
        </Stack>
    );
}

type ItemChildProps = {
    variant?: number,
    itinerary: Itinerary,
    products?: ItemData['products'],
    selected: boolean,
    onChangeSelection: (selected: boolean) => void,
    disabled?: boolean
}

function ItemChild(props: ItemChildProps): JSX.Element {
    const { i18n } = useTranslation();
    const isUserTO = useSelector((state: AppState) => state.user.user?.client_full?.type !== 2);
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const products = useMemo((): NonNullable<typeof props.products> => {
        return {
            accommodations: props.products?.accommodations.filter((item) => {
                return !isNumber(item.variant) || item.variant === props.variant;
            }) ?? [],
            cars: props.products?.cars.filter((item) => {
                return !isNumber(item.variant) || item.variant === props.variant;
            }) ?? [],
            pois: props.products?.pois.filter((item) => {
                return !isNumber(item.variant) || item.variant === props.variant;
            }) ?? [],
            transfers: props.products?.transfers.filter((item) => {
                return !isNumber(item.variant) || item.variant === props.variant;
            }) ?? [],
            flights: [],
            cruises: [],
            ferries: [],
            trains: [],
            assistances: [],
            insurances: []
        };
    }, [props.products]);
    const filteredAssociatedProducts = filterCartProducts(
        {
            accommodations: products.accommodations.map((item) => ({
                type: 'manual',
                accommodation: item as any
            })),
            cars: products.cars.map((item) => ({
                type: 'manual',
                car: item as any
            })),
            pois: products.pois.map((item) => ({
                type: 'manual',
                poi: item as any
            })),
            transfers: products.transfers.map((item) => ({
                type: 'manual',
                transfer: item as any
            })),
            flights: products.flights.map((item) => ({
                type: 'manual',
                flight: item as any
            })),
            cruises: products.cruises.map((item) => ({
                type: 'manual',
                cruise: item as any
            })),
            ferries: products.ferries.map((item) => ({
                type: 'manual',
                ferry: item as any
            })),
            trains: products.trains.map((item) => ({
                type: 'manual',
                train: item as any
            })),
            assistances: products.assistances.map((item) => ({
                type: 'manual',
                assistance: item as any
            })),
            insurances: products.insurances.map((item) => ({
                type: 'manual',
                insurance: item as any
            }))
        },
        {
            isUserTO,
            arrivalDate: props.itinerary.start_date,
            departureDate: props.itinerary.end_date,
            useOnlyDates: true
        }
    );
    const associatedProducts: NonNullable<ItemChildProps['products']> = {
        accommodations: filteredAssociatedProducts.accommodations.map((item) => item.accommodation as any),
        cars: filteredAssociatedProducts.cars.map((item) => item.car as any),
        pois: filteredAssociatedProducts.pois.map((item) => item.poi as any),
        transfers: filteredAssociatedProducts.transfers.map((item) => item.transfer as any),
        flights: filteredAssociatedProducts.flights.map((item) => item.flight as any),
        cruises: filteredAssociatedProducts.cruises.map((item) => item.cruise as any),
        ferries: filteredAssociatedProducts.ferries.map((item) => item.ferry as any),
        trains: filteredAssociatedProducts.trains.map((item) => item.train as any),
        assistances: filteredAssociatedProducts.assistances.map((item) => item.assistance as any),
        insurances: filteredAssociatedProducts.insurances.map((item) => item.insurance as any)
    };
    const productsLength = associatedProducts.accommodations.length +
        associatedProducts.cars.length +
        associatedProducts.pois.length +
        associatedProducts.transfers.length;
    const text = props.itinerary.destination ?
        getDestinationName(locale, props.itinerary.destination) :
        props.itinerary.city_name;

    return (
        <Item
            circuit={null}
            version={null}
            label={
                <Tooltip title={text} disableInteractive>
                    <Stack direction="row" alignItems="center">
                        <Checkbox
                            sx={{
                                '& .MuiSvgIcon-root': { fontSize: 16 },
                                "padding": 0,
                                "marginRight": 1
                            }}
                            checked={props.selected}
                            onChange={(_, checked) => props.onChangeSelection(checked)}
                            onClick={(event) => event.stopPropagation()}
                            disabled={props.disabled}
                        />
                        <LocationOn fontSize="inherit" sx={{ fontSize: 12, opacity: 0.54 }} />
                        <Text sx={{ marginLeft: 0.5 }}>
                            {
                                window.moment.utc(
                                    props.itinerary.end_date
                                ).startOf('day').diff(
                                    window.moment.utc(props.itinerary.start_date).startOf('day'),
                                    'days'
                                )
                            }N
                        </Text>
                        {
                            productsLength > 0 &&
                            <ItineraryCartTreeViewIcon
                                fontSize="inherit"
                                sx={{ fontSize: 12, marginLeft: 0.5 }}
                            />
                        }
                        <Text
                            sx={{
                                marginLeft: 1,
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {text}
                        </Text>
                    </Stack>
                </Tooltip>
            }
            sx={{ marginTop: 1, marginBottom: 1, marginLeft: 1 }}
            isChild
        >
            {
                productsLength > 0 ?
                    <>
                        {
                            associatedProducts.accommodations.map((item) => (
                                <ProductItem
                                    key={item.id}
                                    icon={<Hotel fontSize="inherit" sx={{ fontSize: 12, opacity: 0.54 }} />}
                                    name={
                                        item.localization.find((item) => {
                                            return item.locale === locale;
                                        })?.name ??
                                        item.name
                                    }
                                />
                            ))
                        }
                        {
                            associatedProducts.cars.map((item) => (
                                <ProductItem
                                    key={item.id}
                                    icon={<DirectionsCar fontSize="inherit" sx={{ fontSize: 12, opacity: 0.54 }} />}
                                    name={
                                        item.localization.find((item) => {
                                            return item.locale === locale;
                                        })?.name ??
                                        item.name
                                    }
                                />
                            ))
                        }
                        {
                            associatedProducts.pois.map((item) => (
                                <ProductItem
                                    key={item.id}
                                    icon={<Visibility fontSize="inherit" sx={{ fontSize: 12, opacity: 0.54 }} />}
                                    name={
                                        item.localization.find((item) => {
                                            return item.locale === locale;
                                        })?.name ??
                                        item.name
                                    }
                                />
                            ))
                        }
                        {
                            associatedProducts.transfers.map((item) => (
                                <ProductItem
                                    key={item.id}
                                    icon={<TransferWithinAStation fontSize="inherit" sx={{ fontSize: 12, opacity: 0.54 }} />}
                                    name={
                                        item.localization.find((item) => {
                                            return item.locale === locale;
                                        })?.name ??
                                        item.name
                                    }
                                />
                            ))
                        }
                    </> :
                    null
            }
        </Item>
    );
}

type ProductItemProps = {
    icon: React.ReactNode,
    name: string
}

function ProductItem(props: ProductItemProps): JSX.Element {
    return (
        <Item
            circuit={null}
            version={null}
            label={
                <Tooltip title={props.name} disableInteractive>
                    <Stack direction="row" alignItems="center">
                        {props.icon}
                        <Text
                            sx={{
                                marginLeft: 1,
                                width: '100%',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {props.name}
                        </Text>
                    </Stack>
                </Tooltip>
            }
            sx={{
                marginBottom: 0,
                marginLeft: 1,
                ['.MuiTreeItem-iconContainer']: {
                    display: 'none'
                },
                ['.MuiTreeItem-content']: {
                    paddingTop: 0.5,
                    paddingBottom: 0.5
                }
            }}
            isChild
        />
    );
}

const Text = styled(Typography)(() => ({
    lineHeight: '1em',
    fontSize: 11,
    fontWeight: 'bold',
    opacity: 0.54
}));

const TreeView = styled('ul')(() => ({
    listStyle: 'none',
    paddingLeft: 1,
    paddingRight: 1
}));

function isNotUndefined<K>(item: K | undefined): item is K {
    return Boolean(item);
}
