import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid,
    Button,
    TableContainer,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Chip,
    IconButton,
    Menu,
    MenuItem,
    TableBody
} from "@material-ui/core";
import { makeStyles, styled } from '@material-ui/core/styles';
import i18next from "i18next";
import { detectInvoicesAnomalies } from "./Functions/detectInvoicesAnomalies";
import AddCircle from '@material-ui/icons/AddCircle';
import SaveAltOutlined from '@material-ui/icons/SaveAltOutlined';
import MoreVert from '@material-ui/icons/MoreVert';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import Alert from "@material-ui/lab/Alert";
import PreviewIcon from '@mui/icons-material/Visibility';
import { useSnackbar } from "notistack";
import { Tooltip } from '@mui/material';
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { isAfter } from 'date-fns';
import axios from 'axios';

export function TripListInvoiceList(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const trip_info = useSelector(store => store.menu.trip_info);
    const currency = useSelector(state => state.trip.currency);
    const to_show_version = useSelector(store => store.menu.to_show_version);
    const invoices_from_store = useSelector(store => store.trip.invoices);
    const versions = useSelector((store) => store.menu.trip_info && store.menu.trip_info.data);
    let invoices = props?.crmData?.invoices ? props.crmData.invoices : invoices_from_store;
    const classes = useStyles();
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const version = to_show_version !== null ? to_show_version : trip_info?.current_version;
    const version_number = versions !== null ? versions.map((version) => version.status_contract === 'CONFIRMED' ? version.status_modification : '').indexOf('FIXED_PV') : -1;
    const current_version = (
        (version_number !== -1 || quotation_code === 'cercledesvoyages') &&
        versions !== null ?
            versions.find(item => item.id === version) :
            undefined
    );
    let current_is_confirmed = false;
    if (current_version !== undefined) {
        if (
            current_version.status_contract === 'CONFIRMED' && current_version.status_modification === 'FIXED_PV' ||
            (
                quotation_code === 'cercledesvoyages' &&
                ['CANCELLED_CDV', 'CANCELLED_CGV'].includes(current_version.status_contract)
            )
        ) {
            current_is_confirmed = true;
        }
    }
    const isConfirmedAndAfterToday = isAfter(new Date(), new Date(current_version.start_date)) && current_version.status_modification === 'FIXED_PV'
    const anomalies = isConfirmedAndAfterToday ? [] : detectInvoicesAnomalies(invoices);

    const onAddPartialInvoice = () => {
        const { headers } = CheckBeforeRequest();
        props.setLoading(true);
        axios.post(
            `${API_HREF}client/${window.id_owner}/trip/${trip_info?.id}/versions/${current_version.id}/invoice-history/after_departure_invoice/`,
            {},
            {
                headers: headers
            }
        ).then((response) => {
            dispatch({ type: 'TRIP_SET_INVOICES', payload: null });
            props.setLoading(false);
        }).catch((error) => {
            console.log("error : ", error);
            props.setLoading(false);
        });
    }

    useEffect(() => {
        const version = to_show_version !== null ? to_show_version : trip_info?.current_version;
        const version_number = versions !== null ? versions.map(version => version.status_contract === 'CONFIRMED' ? version.status_modification : '').indexOf('FIXED_PV') : -1;
        const current_version = version_number !== -1 && versions !== null ? versions.find(item => item.id === version) : undefined;
        if (current_version !== undefined) {
            if (current_version.status_contract !== 'CONFIRMED' || current_version.status_modification !== 'FIXED_PV') {
                enqueueSnackbar(t('menu.invoice.not-on-confirmed-version'), { variant: 'warning' });
            }
        }
    }, [to_show_version]);
    return (
        <Container container direction={"column"} justify={"center"} alignItems={"center"} spacing={3}>
            {
                !props?.crmData &&
                <Grid item xs={ 12 } container direction={"row"}>
                    <Grid item xs={ 12 } container justify={"space-around"}>
                        <Grid item>
                            <Button
                                variant={"contained"}
                                className={classes.orangeButton}
                                startIcon={<AddCircle className={classes.orangeIcon} />}
                                onClick={props.onAddInvoice}
                                disabled={ !current_is_confirmed }
                            >
                                {t('menu.invoice.create-invoice')}
                            </Button>
                        </Grid>
                        {
                            isConfirmedAndAfterToday &&
                            <Grid item>
                                <Button
                                    variant={"contained"}
                                    className={classes.orangeButton}
                                    startIcon={<AddCircle className={classes.orangeIcon} />}
                                    onClick={onAddPartialInvoice}
                                    // disabled={ !current_is_confirmed }
                                >
                                    {t('menu.invoice.create-partial-invoice')}
                                </Button>
                            </Grid>
                        }
                        <Grid item>
                            <Button
                                variant={"contained"}
                                className={classes.orangeButton}
                                startIcon={<AddCircle className={classes.orangeIcon} />}
                                onClick={props.onAddCreditNote}
                                disabled={ !current_is_confirmed }
                            >
                                {t('menu.invoice.create-credit-note')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {
                !props?.crmData && !current_is_confirmed &&
                <Grid item xs={ 12 } style={{ width: "100%" }}>
                    <Alert variant={ 'filled' } severity={ 'warning' }>
                        { version_number === -1 && !current_is_confirmed ? t('menu.invoice.no-confirmed-version') : t('menu.invoice.not-on-confirmed-version') }
                    </Alert>
                </Grid>
            }
            {
                !props?.crmData && anomalies.map((anomaly) => (
                    <Grid item xs={ 12 } style={{ width: "100%" }}>
                        <Alert key={anomaly} variant={ 'filled' } severity={ 'warning' }>
                            {t(`menu.invoice.${anomaly}`)}
                        </Alert>
                    </Grid>
                ))
            }
            <Grid item xs={ 12 } style={{ width: "100%" }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.paddingCell}>
                                <TableHeadCell>
                                    {t("menu.invoice.number")}
                                </TableHeadCell>
                                <TableHeadCell>
                                    {t("menu.invoice.type")}
                                </TableHeadCell>
                                <TableHeadCell>
                                    {t("menu.invoice.version-number")}
                                </TableHeadCell>
                                <TableHeadCell>
                                    {t("menu.invoice.created-by")}
                                </TableHeadCell>
                                <TableHeadCell>
                                    {t("menu.invoice.send-date")}
                                </TableHeadCell>
                                <TableHeadCell>
                                    {t("menu.invoice.amount")}
                                </TableHeadCell>
                                <TableHeadCell>
                                    {t("menu.invoice.status")}
                                </TableHeadCell>
                                <TableHeadCell>
                                    {t("menu.invoice.email-sent")}
                                </TableHeadCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                invoices &&
                                invoices.map((invoice) => (
                                    <InvoiceRow key={invoice.id} onClick={() => props.onSee(invoice)}>
                                        <TableCell>
                                            {
                                                invoice.invoice_type === 'INVOICE' ?
                                                    invoice.invoice_number :
                                                    invoice.credit_note_number
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                invoice.invoice_type === 'INVOICE' ?
                                                    t('menu.invoice.invoice') :
                                                    t('menu.invoice.credit-note')
                                            }
                                        </TableCell>
                                        <TableCell>
                                            v{ versions !== null ? versions.map(version => version.id).indexOf(invoice.trip_version) + 1 : 0 }
                                        </TableCell>
                                        <TableCell>
                                            { invoice.creator !== null ? invoice.creator.username : '' }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                invoice.accounting_date ?
                                                    window.moment.utc(invoice.accounting_date).format('DD/MM/YYYY') :
                                                    'N/A'
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                currency !== undefined && currency !== null && currency.iso_code !== undefined ? new Intl.NumberFormat(
                                                    i18next.language,
                                                    {
                                                        style: 'currency',
                                                        currency: currency ? currency.iso_code : 'EUR'
                                                    }
                                                ).format(parseFloat(invoice.amount)) : ''
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                invoice.is_generated ?
                                                    <GeneratedInvoiceChip
                                                        label={t('menu.invoice.generated')}
                                                    /> :
                                                    <Chip label={t('menu.invoice.waiting')} color="secondary" />
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                invoice.is_sent ?
                                                    <SentIcon /> :
                                                    <NotSentIcon />
                                            }
                                        </TableCell>
                                        <TableCell
                                        
                                            onClick={(event) => event.stopPropagation()}
                                        >
                                            { !props.crmData &&
                                            <> 
                                            <Tooltip title={t('menu.invoice.download')}>
                                                <IconButton
                                                    style={{
                                                        opacity: invoice.is_generated ? 1 : 0
                                                    }}
                                                    onClick={
                                                        invoice.is_generated ?
                                                            () => props.onDownload(invoice) :
                                                            undefined
                                                    }
                                                >
                                                    <SaveAltOutlined />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={t('menu.invoice.preview')}>
                                                <IconButton
                                                    style={{
                                                        opacity: invoice.is_generated ? 1 : 0
                                                    }}
                                                    onClick={
                                                        invoice.is_generated ?
                                                            () => props.onPreview(invoice) :
                                                            undefined
                                                    }
                                                >
                                                    <PreviewIcon />
                                                </IconButton>
                                            </Tooltip>
                                            </>
                                            }
                                            <MoreMenu
                                                invoice={invoice}
                                                onSee={props.onSee}
                                                onGenerate={props.onGenerate}
                                                onSend={props.onSend}
                                            />
                                        </TableCell>
                                    </InvoiceRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Container>
    );
}

function MoreMenu(props) {
    const { t } = useTranslation();
    const ref = useRef(null);
    const [open, setOpen] = useState(false);

    const onToggle = () => {
        setOpen((state) => !state);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onSee = () => {
        props.onSee(props.invoice);
        onClose();
    };

    const onGenerate = () => {
        props.onGenerate(props.invoice);
    };

    const onSend = () => {
        props.onSend(props.invoice);
    };

    return (
        <React.Fragment>
            <IconButton ref={ref} onClick={onToggle}>
                <MoreVert />
            </IconButton>
            <Menu anchorEl={ref.current} open={open} onClose={onClose}>
                <MenuItem onClick={onSee}>
                    {t('menu.invoice.see')}
                </MenuItem>
                {
                    props.invoice.is_generated ?
                        <MenuItem onClick={onSend}>
                            {t('menu.invoice.send')}
                        </MenuItem> :
                        <MenuItem onClick={onGenerate}>
                            {t('menu.invoice.generate')}
                        </MenuItem>
                }
            </Menu>
        </React.Fragment>
    );
}

const useStyles = makeStyles(() => ({
    container: {
        maxWidth: 1128,
        paddingTop: 15
    },
    genericText: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        color: '#0000008A'
    },
    absoluteCenter: {
        margin: 0,
        position: 'absolute',
        top: '24%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    fullWidth: {
        width: '100%'
    },
    textJustify: {
        textAlign: 'justify'
    },
    orangeButton: {
        background: "#F7C8BA",
        borderRadius: "20px"
    },
    orangeIcon: {
        color: "#E6592F"
    },
    stickyPaper: {
        position: 'sticky',
        top: 145,
        borderRadius: 16,
        padding: 16
    },
    paddingCell: {
        "& .mui-jss-MuiTableCell-root": {
            fontSize: 12
        }
    }
}));

const Container = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(3)
}));

const InvoiceRow = styled(TableRow)(() => ({
    "cursor": 'pointer',
    '&:hover': {
        backgroundColor: '#D2E6F870'
    }
}));

const TableHeadCell = styled(TableCell)(() => ({
    color: '#E6592F',
    fontWeight: 700
}));

const GeneratedInvoiceChip = styled(Chip)(() => ({
    backgroundColor: '#479E2C',
    color: '#fff'
}));

const SentIcon = styled(Check)(() => ({
    color: '#479E2C'
}));

const NotSentIcon = styled(Close)(() => ({
    color: '#d14136'
}));
