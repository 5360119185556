import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCircuit } from "../../Itinerary/utils/getCircuit";
import GetCookie from "../Functions/GetCookie";
import { AdaltePackageData } from "../../ItineraryType/RoomTypePicker";
import { AppState } from "../../../Reducers/Reducers";

export function useAdaltePackages(): AdaltePackageData[] {
    const trip = useSelector((state: AppState) => state.trip.data_trip);
    const [result, setResult] = useState<AdaltePackageData[]>([]);

    useEffect(() => {
        (async () => {
            try {
                const data = await Promise.all(
                    trip?.stack_info?.map(async (item) => {
                        if (item.circuit) {
                            return {
                                stackInfoId: item.id,
                                identifier: item.identifier,
                                startDate: item.start_date,
                                circuit: await getCircuit(item.circuit)
                            };
                        }
                        return null
                    }) ?? []
                );
                const result = data.filter((item): item is NonNullable<typeof item> => {
                    return item?.circuit?.source === 'ADALTE';
                });
                setResult(
                    result.map((item) => {
                        const adalteData = JSON.parse(item.identifier);
                        return {
                            stackInfoId: item.stackInfoId,
                            circuitId: item.circuit!.id,
                            version: parseInt(GetCookie('trip_id_version')!),
                            startDate: window.moment.utc(item.startDate),
                            rooms: adalteData.adalte_rooms,
                            pickups: adalteData.chosen_pickup,
                            languageCode: adalteData.chosen_language_code,
                            errors: {
                                rooms: {}
                            }
                        };
                    })
                );
            } catch (error) {
                console.error(error);
            }
        })()
    }, [trip]);

    return result;
}