import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDrag, useDrop } from "react-dnd";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import PictureModalCreateForm from "../Menu/MaterialTripList/picture/pictureModalCreateForm";
import { Picture } from "../Menu/MaterialTripList/picture/objects/picture";

type Props = {
    pictures: {
        userDeleted: boolean,
        pictures: Picture[] | null
    },
    onChange: (
        pictures: {
            userDeleted: boolean,
            pictures: Picture[] | null
        }
    ) => void
}

export function CartConstructionContentsFormPictureInput(props: Props): JSX.Element {
    const { t } = useTranslation();
    const [openReorderModal, setOpenReorderModal] = useState(false);
    const [openPicturesModal, setOpenPicturesModal] = useState(false);
    const toBeReplacedPicture = useRef<{id: number, index: number} | null>(null);

    const onAdd = () => {
        setOpenPicturesModal(true);
    };

    const onReplace = (id: number, index: number) => {
        toBeReplacedPicture.current = {
            id,
            index
        };
        onAdd();
    };

    const onChange = (pictures: Picture[]) => {
        if (toBeReplacedPicture.current) {
            props.onChange({
                userDeleted: props.pictures.userDeleted,
                pictures: props.pictures?.pictures?.map((picture, index) => {
                    if (
                        picture.id === toBeReplacedPicture.current?.id &&
                        index === toBeReplacedPicture.current.index
                    ) {
                        return pictures[0] ?? picture;
                    }
                    return picture;
                }) ?? []
            });
        } else {
            props.onChange({
                userDeleted: props.pictures.userDeleted,
                pictures: (props.pictures?.pictures ?? []).concat(pictures) ?? []
            });
        }
        toBeReplacedPicture.current = null;
    };

    const onDelete = (id: number, index: number) => {
        const pictures = props.pictures?.pictures?.filter((item, itemIndex) => {
            return item.id !== id ||
                   itemIndex !== index;
        }) ?? [];
        props.onChange({
            userDeleted: pictures.length === 0,
            pictures
        });
    };

    return (
        <>
            <Button onClick={() => setOpenReorderModal(true)} sx={{ marginBottom: 1.5 }}>
                {t('cart-material.edit-pictures-order')}
            </Button>
            <Button onClick={onAdd} sx={{ marginBottom: 1.5 }}>
                {t('shared.add')}
            </Button>
            <Grid container spacing={2}>
                {
                    props.pictures?.pictures?.map((picture, index) => (
                        <Grid key={`${index}-${picture.id}`} item xs={12} md={4}>
                            <Card>
                                <CardMedia
                                    component={ 'img' }
                                    alt={picture.alt}
                                    height={200}
                                    image={picture.url ?? ''}
                                />
                                <CardContent>
                                    <Typography>{ picture.name }</Typography>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={() => onReplace(picture.id, index)}>
                                        {t('cart-material.cart-construction-replace')}
                                    </Button>
                                    <Button
                                        onClick={() => onDelete(picture.id, index)}
                                    >
                                        { t('shared.delete-picture') }
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
            {
                openPicturesModal &&
                <PictureModalCreateForm
                    onChangePictures={onChange}
                    onClose={() => setOpenPicturesModal(false)}
                />
            }
            {
                openReorderModal &&
                <ChangeOrderModal
                    pictures={props.pictures ?? []}
                    onSave={props.onChange}
                    onClose={() => setOpenReorderModal(false)}
                />
            }
        </>
    );
}


type ChangeOrderModalProps = {
    pictures: {
        userDeleted: boolean,
        pictures: Picture[] | null
    },
    onSave: (
        pictures: {
            userDeleted: boolean,
            pictures: Picture[] | null
        }
    ) => void,  
    onClose: () => void
}

function ChangeOrderModal(props: ChangeOrderModalProps): JSX.Element {
    const { t } = useTranslation();
    const [inputs, setInputs] = useState<{
        userDeleted: boolean,
        pictures: Picture[] | null
    }>({
        userDeleted: false,
        pictures: []
    });
    const onSwap = useCallback(
        (
            draggedItem: {index: number, pictureId: number},
            swappedItem: {index: number, pictureId: number}
        ) => {
            return new Promise<void>((resolve) => {
                setTimeout(() => {
                    setInputs((state) => {
                        if (
                            draggedItem.index !== swappedItem.index &&
                            draggedItem.pictureId !== swappedItem.pictureId
                        ) {
                            const pictures = [...state.pictures ?? []];
                            const item = state.pictures?.[draggedItem.index];
                            pictures.splice(draggedItem.index, 1);
                            pictures.splice(swappedItem.index, 0, item!);
                            resolve();
                            return {
                                ...state,
                                pictures
                            };
                        }
                        resolve();
                        return state;
                    });
                }, 0);
            });
        },
        [setInputs]
    );

    const onSave = () => {
        props.onSave(inputs);
        props.onClose();
    };

    useEffect(() => {
        setInputs(props.pictures ?? []);
    }, [props.pictures]);

    return (
        <Dialog open onClose={props.onClose} fullScreen>
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography fontWeight="bold">
                        {t('cart-material.edit-pictures-order')}
                    </Typography>
                    <IconButton onClick={props.onClose}>
                        <Close />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    {
                        inputs.pictures?.map((picture, index) => (
                            <Grid key={`${index}-${picture.id}`} item xs={12} md={4}>
                                <DraggableItem
                                    index={index}
                                    picture={picture}
                                    onSwap={onSwap}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>
                    {t('shared.cancel')}
                </Button>
                <Button onClick={onSave}>
                    {t('shared.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

type DraggableItemProps = {
    index: number,
    picture: Picture,
    onSwap: (
        draggedItem: {
            index: number,
            pictureId: number
        },
        swappedIndex: {
            index: number,
            pictureId: number
        },
    ) => Promise<void>
}

function DraggableItem(props: DraggableItemProps): JSX.Element {
    const elementRef = useRef<HTMLDivElement | null>(null);
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dragCollected, drag] = useDrag(() => ({
        type: 'cart-construction-contents-form-picture',
        item: { pictureId: props.picture.id, index: props.index }
    }), [props.index, props.picture.id]);
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dropCollected, drop] = useDrop<{pictureId: number, index: number}>(() => ({
        accept: 'cart-construction-contents-form-picture',
        async hover(item) {
            await props.onSwap(
                item,
                { index: props.index, pictureId: props.picture.id }
            );
            item.index = props.index;
        }
    }), [props.index, props.picture.id, props.onSwap]);

    drag(drop(elementRef));

    return (
        <Card ref={elementRef} sx={{ cursor: 'move' }}>
            <CardMedia
                component={ 'img' }
                alt={props.picture.alt}
                height={200}
                image={props.picture.url ?? ''}
            />
            <CardContent>
                <Typography sx={{ minHeight: 24 }}>
                    { props.picture.name}
                </Typography>
            </CardContent>
        </Card>
    );
}
