import { differenceInCalendarDays, isBefore, isSameDay } from "date-fns";
import { getItineraryDayDifference } from "../../Menu/MaterialTripList/utils/getItineraryDifference";
import { Itinerary } from "../objects/itinerary";

type Item = Pick<Itinerary, 'start_date' | 'end_date' | 'circuit_trip_version'> & {
    destination: NonNullable<Itinerary['destination']>['id'],
    circuit: number | null,
    circuit_trip_version: number | null,
    iti_type: number | null,
}

export function transformItineraryToDays(itinerary: Item[]) {
    return itinerary.reduce((prev, step, index) => {
            const days = getItineraryDayDifference(step);
    
            if (index === 0) {
                return [
                    new Array(days.length).fill(null).map((_, index) => {
                        return index + 1;
                    })
                ];
            }
    
            const previousStep = itinerary[index - 1]!;
            const previousStepDates = getItineraryDayDifference(previousStep);
            const previousStepLastDate = previousStepDates[previousStepDates.length - 1];
            const previousStepDays = prev[index - 1]!;
            const previousStepLastDay = previousStepDays[previousStepDays.length - 1];
    
            if (
                days[0] &&
                previousStepLastDate &&
                previousStepLastDay &&
                isSameDay(days[0], previousStepLastDate)
            ) {
                return prev.concat(
                    [
                        new Array(days.length).fill(null).map((_, index) => {
                            return previousStepLastDay + index;
                        })
                    ]
                );
            }
    
            if (
                days[0] &&
                previousStepLastDate &&
                previousStepLastDay &&
                isBefore(days[0], previousStepLastDate)
            ) {
                return prev.concat(
                    [
                        new Array(days.length).fill(null).map((_, index) => {
                            return (previousStepLastDay ?? 0) + index + 1;
                        })
                    ]
                );
            }
    
            let gap = days[0] && previousStepLastDate ?
                differenceInCalendarDays(
                    days[0],
                    previousStepLastDate
                ) :
                0;
            gap -= 1;
    
            return prev.concat(
                [
                    new Array(days.length).fill(null).map((_, index) => {
                        return (previousStepLastDay ?? 0) + index + gap + 1;
                    })
                ]
            );
        }, [] as number[][]);
}