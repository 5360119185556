import { useContext } from "react";
import { useCartProducts } from "../../Itinerary/network/cartProducts";
import { ProviderContext } from "../../CartMaterial/utils/providerContext";
import {
    makeAccommodationEditRequest,
    makeCarEditRequest,
    makeCustomProductEditRequest,
    makeFlightEditRequest,
    makeManualProductEditRequest,
    makeTransferEditRequest
} from "../../CartMaterial/utils/editProductRequests";

type Product = {
    id: number,
    type: keyof ReturnType<typeof useCartProducts> | 'manual',
    isCustom: boolean,
    price: number,
    masterPriceId: number,
    customRate: boolean,
    customRateType: 'FIX' | 'PER',
    customValue: number,
}

type Callback = (
    context: {
        tripId: number,
        version: number,
        tripToken: string | null
    },
    product: Product
) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: (product: Product, newProduct: unknown) => void,
    onError: (error: Error) => void,
    onFinally: () => void
}>

export function useProductPriceChange(options: Options): Callback {
    const providerContext = useContext(ProviderContext);
    return async (context, product) => {
        try {
            if (options.onTrigger) {
                options.onTrigger();
            }
            const data = await makeRequest({
                ...context,
                providerToken: providerContext.module ?
                    providerContext.providerToken :
                    undefined,
                product
            });
            if (options.onSuccess) {
                options.onSuccess(product, data);
            }
        } catch (error: any) {
            if (options.onError) {
                options.onError(error);
            }
        } finally {
            if (options.onFinally) {
                options.onFinally();
            }
        }
    };
}

type RequestOptions = {
    tripId: number,
    version: number,
    product: Product,
    tripToken: string | null,
    providerToken?: string | null,
}

async function makeRequest(options: RequestOptions): Promise<unknown> {
    if (
        (
            options.product.type === 'transfers' ||
            options.product.type === 'pois'
        ) &&
        options.product.isCustom
    ) {
        return await makeCustomProductEditRequest({
            tripId: options.tripId,
            version: options.version,
            tripToken: options.tripToken,
            id: options.product.id,
            providerToken: options.providerToken,
            data: {
                prices: [
                    {
                        id: options.product.masterPriceId,
                        purchase_price: options.product.price.toString(),
                        custom_rate: options.product.customRate,
                        custom_rate_type: options.product.customRateType,
                        custom_value: options.product.customValue
                    }
                ]
            }
        });
    }

    switch (options.product.type) {
        case 'flights': {
            return await makeFlightEditRequest({
                tripId: options.tripId,
                version: options.version,
                tripToken: options.tripToken,
                id: options.product.id,
                providerToken: options.providerToken,
                data: {
                    prices: [
                        {
                            id: options.product.masterPriceId,
                            purchase_price: options.product.price.toString(),
                            custom_rate: options.product.customRate,
                            custom_rate_type: options.product.customRateType,
                            custom_value: options.product.customValue
                        }
                    ]
                }
            });
        }
        case 'cars': {
            return await makeCarEditRequest({
                tripId: options.tripId,
                version: options.version,
                tripToken: options.tripToken,
                id: options.product.id,
                providerToken: options.providerToken,
                data: {
                    prices: [
                        {
                            id: options.product.masterPriceId,
                            purchase_price: options.product.price.toString(),
                            custom_rate: options.product.customRate,
                            custom_rate_type: options.product.customRateType,
                            custom_value: options.product.customValue
                        }
                    ]
                }
            });
        }
        case 'accommodations': {
            return await makeAccommodationEditRequest({
                tripId: options.tripId,
                version: options.version,
                tripToken: options.tripToken,
                id: options.product.id,
                providerToken: options.providerToken,
                data: {
                    prices: [
                        {
                            id: options.product.masterPriceId,
                            purchase_price: options.product.price.toString(),
                            custom_rate: options.product.customRate,
                            custom_rate_type: options.product.customRateType,
                            custom_value: options.product.customValue
                        }
                    ]
                }
            });
        }
        case 'transfers': {
            return await makeTransferEditRequest({
                tripId: options.tripId,
                version: options.version,
                tripToken: options.tripToken,
                id: options.product.id,
                providerToken: options.providerToken,
                data: {
                    prices: [
                        {
                            id: options.product.masterPriceId,
                            purchase_price: options.product.price.toString(),
                            custom_rate: options.product.customRate,
                            custom_rate_type: options.product.customRateType,
                            custom_value: options.product.customValue
                        }
                    ]
                }
            });
        }
        case 'pois': {
            return await makeCustomProductEditRequest({
                tripId: options.tripId,
                version: options.version,
                tripToken: options.tripToken,
                id: options.product.id,
                providerToken: options.providerToken,
                data: {
                    prices: [
                        {
                            id: options.product.masterPriceId,
                            purchase_price: options.product.price.toString(),
                            custom_rate: options.product.customRate,
                            custom_rate_type: options.product.customRateType,
                            custom_value: options.product.customValue
                        }
                    ]
                }
            });
        }
        default: {
            return await makeManualProductEditRequest({
                tripId: options.tripId,
                version: options.version,
                tripToken: options.tripToken,
                id: options.product.id,
                providerToken: options.providerToken,
                data: {
                    prices: [
                        {
                            id: options.product.masterPriceId,
                            purchase_price: options.product.price.toString(),
                            custom_rate: options.product.customRate,
                            custom_rate_type: options.product.customRateType,
                            custom_value: options.product.customValue
                        }
                    ]
                }
            });
        }
    }
}
