import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrop } from "react-dnd";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import {
    Box,
    Collapse,
    Typography
} from "@mui/material";
import {
    PlaylistAdd,
    ShoppingCartOutlined
} from "@mui/icons-material";
import { differenceInCalendarDays } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { Moment } from "moment-timezone";
import {
    MailTemplateVisualEditor
} from "../Menu/MaterialTripList/MailVisualEditor/mailTemplateVisualEditor";
import {
    MailTemplateVisualEditorDragArea
} from "../Menu/MaterialTripList/MailVisualEditor/mailTemplateVisualEditorDragArea";
import { CartConstructionContentsFormItemFloatingAddButton } from "./CartConstructionContentsFormItemFloatingAddButton";
import { CartConstructionAddProductModal } from "./CartConstructionAddProductModal";
import { CartConstructionContentsFormProductsInputItem } from "./CartConstructionContentsFormProductsInputItem";
import { LoadingBackDrop } from "../Common/LoadingBackdrop";
import { useShowError } from "../Utils/showError";
import { useCartProductUpdate } from "./network/cartProductUpdate";
import { getItineraryDayDifference } from "../Menu/MaterialTripList/utils/getItineraryDifference";
import { RegisteredBlocks } from "../Menu/MaterialTripList/MailVisualEditor/utils/registered-blocks";
import { useCartConstructionContentsFormProducts } from "./utils/cartConstructionContentsFormProducts";
import { sortItinerary } from "../Itinerary/utils/sortItinerary";
import GetCookie from "../Common/Functions/GetCookie";
import { addAndSelectVisualEditorBlock } from "../Menu/MaterialTripList/MailVisualEditor/redux/thunks";
import { ItineraryContentItem } from "./objects/itineraryContentItem";
import { CartConstructionProductsTableItemProps } from "./CartConstructionProductsTableItem";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    index: number,
    edit: boolean,
    hideProducts?: boolean,
    startDate: string,
    endDate: string,
    customActions?: Parameters<typeof MailTemplateVisualEditor>[number]['customActions'],
    onContentClick?: Parameters<typeof MailTemplateVisualEditor>[number]['onContentClick']
} & ItineraryContentItem

export type CartConstructionContentsFormProductsInputDragResult = {
    product: CartConstructionProductsTableItemProps
}

export const CartConstructionContentsFormProductsInput = React.memo(
    withRouter<Props>(
        function CartConstructionContentsFormProductsInput(props): JSX.Element {
            const { t } = useTranslation();
            const dispatch = useDispatch();
            const stackNumber = props.params.stackNumber ?
                parseInt(props.params.stackNumber) :
                null;
            const itinerary = useSelector((state: AppState) => state.itinerary.itinerary_list);
            const step = useSelector((state: AppState) => state.cart.step);
            const textsLocale = useSelector((state: AppState) => state.cartConstruction.locale);
            const steps = useMemo(() => {
                return itinerary.filter((item) => {
                    return item.step_type === 'STEP';
                }).sort(sortItinerary);
            }, [itinerary]);
            const [loading, setLoading] = useState(false);
            const [openAddProductModal, setOpenAddProductModal] = useState(false);
            const showError = useShowError();
            const update = useCartProductUpdate({
                onTrigger() {
                    setLoading(true);
                },
                onError(error) {
                    console.error(error);
                    showError(error);
                },
                onFinally() {
                    setLoading(false);
                }
            });
            const [collected, dropHandle] = useDrop<
                CartConstructionContentsFormProductsInputDragResult,
                unknown,
                {
                    canDrop: boolean,
                    isOver: boolean
                }
            >(() => ({
                accept: 'cart-construction-contents-product',
                collect: (monitor) => ({
                    canDrop: monitor.canDrop(),
                    isOver: monitor.isOver()
                }),
                drop(result) {
                    const versionString = GetCookie("trip_id_version");
                    const version = versionString ? parseInt(versionString) : -1;
                    const startDate = window.moment.utc(result.product.item.start_date).startOf('day');
                    const endDate = window.moment.utc(result.product.item.end_date).startOf('day');
                    const firstDate = window.moment.utc(steps[0]?.start_date);
                    let newStartDate: Moment | undefined;
        
                    if (props.mode === 'by-day') {
                        newStartDate = firstDate.clone().startOf('day').add((props.content.day[0] ?? 1) - 1, 'days');
                    } else {
                        const step = steps[props.index];
                        if (step && step.destination?.id === props.content.destination?.id) {
                            newStartDate = window.moment.utc(step.start_date).startOf('day');
                        }
                    }
    
                    if (newStartDate) {
                        update(
                            version,
                            {
                                id: result.product.item.id,
                                type: result.product.type,
                                roomIds: result.product.type === 'accommodation' ?
                                    result.product.item.rooms.map((room) => {
                                        return room.id;
                                    }) :
                                    [],
                                isCustom: result.product.item.is_custom
                            },
                            {
                                client_start_date: newStartDate.format('YYYY-MM-DD'),
                                client_end_date: newStartDate.add(
                                    endDate.diff(startDate, 'days'),
                                    'days'
                                ).format('YYYY-MM-DD')
                            }
                        );
                    }
                }
            }), [props.mode]);
            const contents = useCartConstructionContentsFormProducts(props, props.index, stackNumber);
        
            const onNewBlock = (
                instanceId: string,
                type: string | symbol
            ) => {
                if (type === 'manual-product') {
                    const step = steps.find((item, index) => {
                        if (props.mode === 'by-step') {
                            return props.index === index &&
                                props.content.destination?.id === item.destination?.id;
                        }
                        const daysDifference = getItineraryDayDifference(item);
                        const days = daysDifference.map((item) => {
                            return differenceInCalendarDays(
                                item,
                                utcToZonedTime(steps[0]!.start_date, 'Etc/UTC')
                            ) + 1;                                                                                                                
                        });
                        return days.includes(props.content.day[0]!) &&
                            props.content.destinations?.map((destination) => {
                                return destination.id;
                            }).includes(item.destination?.id ?? -1);
                    });
                    if (step) {
                        dispatch({
                            type: 'CART_MANUAL_PRODUCT_DESTINATION_ASSOCIATED',
                            payload: {
                                value: step.id,
                                itinerary_list: itinerary
                            }
                        });
                    }
        
                    if (props.mode === 'by-step' && step) {
                        dispatch({
                            type: 'CART_SET_MANUAL_PRODUCT_DATES',
                            payload: {
                                start_date: window.moment.utc(step.start_date).format('YYYY-MM-DD'),
                                end_date: window.moment.utc(step.end_date).format('YYYY-MM-DD')
                            }
                        });
                    } else if (props.mode === 'by-day') {
                        const firstDay = window.moment.utc(steps[0]?.start_date).startOf('day');
                        const startDateDay = props.content.day[0] ?? 1;
                        const endDateDay = props.content.day[props.content.day.length - 1] ?? 1;
                        const startDate = firstDay.clone().add(startDateDay - 1, 'days');
                        const endDate = firstDay.clone().add(endDateDay - 1, 'days');
                        dispatch({
                            type: 'CART_SET_MANUAL_PRODUCT_DATES',
                            payload: {
                                start_date: startDate.format('YYYY-MM-DD'),
                                end_date: endDate.format('YYYY-MM-DD')
                            }
                        });
                    }
        
                    dispatch({ type: 'CART_CREATE_NEW_MANUAL_PRODUCT' });
                } else if (type === 'product') {
                    setOpenAddProductModal(true);
                } else {
                    const selectedBlock = RegisteredBlocks.find((block) => block.type === type);
                    if (selectedBlock) {
                        dispatch(
                            addAndSelectVisualEditorBlock({
                                instanceId,
                                locale: textsLocale,
                                blockType: type
                            })
                        );
                    }
                }
            };
        
            return (
                <>
                    <div>
                        {
                            !props.hideProducts &&
                            <>
                                <Box
                                    sx={{
                                        "position": 'relative',
                                        "width": 'calc(100% + 44px)',
                                        "marginLeft": '-44px',
                                        "paddingLeft": '44px',
                                        "paddingBottom": 1.5,
                                        '& .floating-actions-button': {
                                            display: 'none'
                                        },
                                        '&:hover .floating-actions-button': {
                                            display: 'block'
                                        }
                                    }}
                                >
                                    <CartConstructionContentsFormItemFloatingAddButton
                                        extraActions={[
                                            {
                                                type: 'product',
                                                label: 'cart-material.cart-construction-product',
                                                icon: <ShoppingCartOutlined />
                                            },
                                            {
                                                type: 'manual-product',
                                                label: 'cart-material.cart-construction-manual',
                                                icon: <PlaylistAdd />
                                            }
                                        ]}
                                        fromProducts={true}
                                        onAdd={(type) => onNewBlock(
                                            props.mode === 'by-day' ?
                                                `cart-construction-by-day-product-${props.content.id}-0` :
                                                `cart-construction-by-step-product-${props.content.id}-0`,
                                            type
                                        )}
                                    />
                                    <MailTemplateVisualEditor
                                        instanceId={
                                            props.mode === 'by-day' ?
                                                `cart-construction-by-day-product-${props.content.id}-0` :
                                                `cart-construction-by-step-product-${props.content.id}-0`
                                        }
                                        locale={textsLocale}
                                        renderContentArea={() => (
                                            <MailTemplateVisualEditorDragArea /> 
                                        )}
                                        customActions={props.customActions}
                                        onContentClick={props.onContentClick}
                                        forceSelectedState={props.edit}
                                        isIdle={step !== 2}
                                        enableLeftDrag
                                        disableEmptyIndicator
                                        disableAutoCreation
                                        disablePreview
                                        disableHeader
                                        disableHoverInteraction
                                        useCustomDndContext
                                    />
                                </Box>
                                {
                                    contents.map((item, index) => (
                                        <React.Fragment key={item.key}>
                                            <CartConstructionContentsFormProductsInputItem {...item} />
                                            <Box
                                                sx={{
                                                    "position": 'relative',
                                                    "width": 'calc(100% + 44px)',
                                                    "marginLeft": '-44px',
                                                    "paddingLeft": '44px',
                                                    "paddingBottom": 1.5,
                                                    '& .floating-actions-button': {
                                                        display: 'none'
                                                    },
                                                    '&:hover .floating-actions-button': {
                                                        display: 'block'
                                                    }
                                                }}
                                            >
                                                <CartConstructionContentsFormItemFloatingAddButton
                                                    extraActions={[
                                                        {
                                                            type: 'product',
                                                            label: 'cart-material.cart-construction-product',
                                                            icon: <ShoppingCartOutlined />
                                                        },
                                                        {
                                                            type: 'manual-product',
                                                            label: 'cart-material.cart-construction-manual',
                                                            icon: <PlaylistAdd />
                                                        }
                                                    ]}
                                                    onAdd={(type) => onNewBlock(
                                                        props.mode === 'by-day' ?
                                                            `cart-construction-by-day-product-${props.content.id}-${index + 1}` :
                                                            `cart-construction-by-step-product-${props.content.id}-${index + 1}`,
                                                        type
                                                    )}
                                                />
                                                <MailTemplateVisualEditor
                                                    instanceId={
                                                        props.mode === 'by-day' ?
                                                            `cart-construction-by-day-product-${props.content.id}-${index + 1}` :
                                                            `cart-construction-by-step-product-${props.content.id}-${index + 1}`
                                                    }
                                                    locale={textsLocale}
                                                    renderContentArea={() => (
                                                        <MailTemplateVisualEditorDragArea /> 
                                                    )}
                                                    customActions={props.customActions}
                                                    onContentClick={props.onContentClick}
                                                    forceSelectedState={props.edit}
                                                    isIdle={step !== 2}
                                                    enableLeftDrag
                                                    disableEmptyIndicator
                                                    disableAutoCreation
                                                    disablePreview
                                                    disableHeader
                                                    disableHoverInteraction
                                                    useCustomDndContext
                                                />
                                            </Box>
                                        </React.Fragment>
                                    ))
                                }
                            </>
                        }
                        {
                            props.hideProducts &&
                            <Collapse in={collected.canDrop}>
                                <Box
                                    ref={dropHandle}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: 50,
                                        marginTop: 1,
                                        backgroundColor:
                                        //eslint-disable-next-line no-nested-ternary
                                        collected.isOver ? 'rgba(224, 146, 11, 0.5)' :
                                        //eslint-disable-next-line indent
                                        collected.canDrop ? 'rgba(0, 217, 0, 0.2)' :
                                            //eslint-disable-next-line indent
                                            '#D9D9D9',
                                        border: '1px dashed rgba(0, 0, 0, 0.4)'
                                    }}
                                >
                                    <Typography>
                                        {t('cart-material.cart-construction-drop-product-here')}
                                    </Typography>
                                </Box>
                            </Collapse>
                        }
                    </div>
                    <LoadingBackDrop open={loading} />
                    {
                        openAddProductModal &&
                        <CartConstructionAddProductModal
                            contentItem={{
                                data: props,
                                from: props.startDate,
                                to: props.endDate
                            }}
                            onClose={() => setOpenAddProductModal(false)}
                        />
                    }
                </>
            );
        }
    )
);
