import ConvertPrice from "../../../Cart/Functions/Manual/ConvertPrice";
import CalculPercentToValue from "../../../Cart/Functions/Margin/CalculPercentToValue";
import GetPricesId from "../../../Cart/Functions/Margin/GetPricesId";
import { isString } from 'lodash';


export default (item, type, index_item, list_currency, currency, user, owner_id, prices_type) => {
	let prices = null;
	if (prices_type === 0) {
		prices = item.prices_flight;
	}
	else if (prices_type === 1) {
		prices = item.prices_terrestrial;
	} else if (prices_type === 2) {
		prices = item.prices_stack_product;
	}
	else {
		prices = item.prices;
	}
	let my_currency = list_currency.find((currency) => {return currency.id === prices[GetPricesId(prices, owner_id, user, true)].purchase_currency});
	let new_price = 0;
	let total_network_value = 0;
	let total_network_purchase = 0;
	let total_network_selling = 0;
	let total_selling = parseFloat(prices[GetPricesId(prices, owner_id, user)].selling_price);
    new_price = parseFloat(ConvertPrice(parseFloat(prices[GetPricesId(prices, owner_id, user, true)].purchase_price), my_currency, currency, parseFloat(prices[GetPricesId(prices, owner_id, user, true)].factor_used)));
	let price = parseFloat(prices[GetPricesId(prices, owner_id, user, true)].purchase_price);
	// let value = 0;
	// if (prices[GetPricesId(prices, owner_id, user, true)].custom_rate_type === "FIX") {
	// 	value = prices[GetPricesId(prices, owner_id, user, true)].custom_value;
	// } else {
	// 	value = CalculPercentToValue(parseFloat(prices[GetPricesId(prices, owner_id, user, true)].percentage_between), parseFloat(new_price));
	// }
	let value = parseFloat(prices[GetPricesId(prices, owner_id, user, true)].selling_price) - new_price;
	if (item.paid_option_price !== undefined && item.paid_option_price !== null && item.paid_option_price.length > 0) {
		item.paid_option_price.map((option) => {
			let option_new_price = parseFloat(ConvertPrice(parseFloat(option.prices[GetPricesId(option.prices, owner_id, user, true)].purchase_price), my_currency, currency, parseFloat(option.prices[GetPricesId(option.prices, owner_id, user, true)].factor_used)));
			value += parseFloat(option.prices[GetPricesId(option.prices, owner_id, user, true)].selling_price) - option_new_price;
		});
	}
	if (item.taxes && !isString(item.taxes) && item.taxes.length > 0) {
		let option_new_price = parseFloat(ConvertPrice(parseFloat(item.taxes[GetPricesId(item.taxes, owner_id, user, true)].purchase_price), my_currency, currency, parseFloat(item.taxes[GetPricesId(item.taxes, owner_id, user, true)].factor_used)));
		value += parseFloat(item.taxes[GetPricesId(item.taxes, owner_id, user, true)].selling_price) - option_new_price;
	}
	console.log("value : ", value);
	if (prices_type === 0) {
		item.flight = {
			edit_margin: item.flight === undefined ? false : item.flight.edit_margin,
			margin_value: value,
			custom_rate_type: prices[GetPricesId(prices, owner_id, user)].custom_rate_type,
			custom_value: value,
			margin_percent: parseFloat(prices[GetPricesId(prices, owner_id, user, true)].percentage_between / 100)
		};
	}
	else if (prices_type === 1) {
		item.terrestrial = {
			edit_margin: item.terrestrial === undefined ? false : item.terrestrial.edit_margin,
			margin_value: value,
			custom_rate_type: prices[GetPricesId(prices, owner_id, user)].custom_rate_type,
			custom_value: value,
			margin_percent: parseFloat(prices[GetPricesId(prices, owner_id, user, true)].percentage_between / 100)
		};
	}
	else if (prices_type === 2) {
		item.terrestrial = {
			edit_margin: item.terrestrial === undefined ? false : item.terrestrial.edit_margin,
			margin_value: value,
			custom_rate_type: prices[GetPricesId(prices, owner_id, user)].custom_rate_type,
			custom_value: value,
			margin_percent: parseFloat(prices[GetPricesId(prices, owner_id, user, true)].percentage_between / 100)
		};
	}
	if (prices_type === undefined) {
		if (item.edit_margin === undefined) {
		    item.edit_margin = false;
		}
		item.margin_value = value;
		item.custom_rate_type = prices[GetPricesId(prices, owner_id, user)].custom_rate_type;
		item.custom_value = value;
		item.margin_percent = parseFloat(prices[GetPricesId(prices, owner_id, user, true)].percentage_between / 100);
	}

	let total_accommodation_price = parseFloat(prices[GetPricesId(prices, owner_id, user, true)].selling_price);
	let network = prices.find((price) => {
		return price.is_network;
	});
	total_network_value += network ? CalculPercentToValue(parseFloat(network.percentage_between), parseFloat(network.purchase_price)) : 0;
	total_network_purchase += network ? parseFloat(ConvertPrice(parseFloat(network.purchase_price), my_currency, currency, parseFloat(network.factor_used))) : 0;
	total_network_selling += network ? parseFloat(network.selling_price) : 0;
	if (type === "accommodation") {
	    for (let i = 0; i < item.rooms.length; i++) {
	    	let is_network = item.rooms[i].prices.find((has_network) => {
	    		return has_network.is_network;
	    	});
	    	total_network_purchase += is_network ? parseFloat(ConvertPrice(parseFloat(is_network.purchase_price), my_currency, currency, parseFloat(is_network.factor_used))) : 0;
	    	total_network_selling += is_network ? parseFloat(is_network.selling_price) : 0;
	        total_accommodation_price += parseFloat(item.rooms[i].prices[GetPricesId(item.rooms[i].prices, owner_id, user, true)].selling_price);
	        price += parseFloat(item.rooms[i].prices[GetPricesId(item.rooms[i].prices, owner_id, user, true)].purchase_price);
	        total_selling += parseFloat(item.rooms[i].prices[GetPricesId(item.rooms[i].prices, owner_id, user)].selling_price);
            new_price += parseFloat(ConvertPrice(parseFloat(item.rooms[i].prices[GetPricesId(item.rooms[i].prices, owner_id, user, true)].purchase_price), my_currency, currency, parseFloat(item.rooms[i].prices[GetPricesId(item.rooms[i].prices, owner_id, user, true)].factor_used)));
	    }
	    item.margin_value = (total_accommodation_price - parseFloat(new_price)).toFixed(2);
	    total_network_value = network ? (parseFloat(total_network_selling) - parseFloat(total_network_purchase)).toFixed(2) : 0;
	}
	let is_surcom = prices.find((price) => {
	    return price.is_surcom && price.percentage_between !== 0;
	});

	if (user.client_full.type !== 1 && user.client_full.type !== 3 && JSON.parse(localStorage.getItem("config")).quotation_code !== "verdie") {
		is_surcom = prices.find((price) => {
			return Object.keys(price).length !== 0;
		})
	}
	// let value_surcom = (is_surcom !== null && is_surcom !== undefined) ? ((price) / (1 - (parseFloat(is_surcom.percentage_between) / 100))) - (price) : null;
//	let value_surcom = (is_surcom !== null && is_surcom !== undefined) ? ((new_price + parseFloat(value)) / (1 - (parseFloat(is_surcom.percentage_between) / 100))) - (new_price + parseFloat(value)) : null;
	let value_surcom = (is_surcom !== null && is_surcom !== undefined) ? is_surcom.selling_price -  parseFloat(ConvertPrice(parseFloat(is_surcom.purchase_price), my_currency, currency, parseFloat(is_surcom.factor_used))) : null;

	// let value_surcom = surcom - price;
	// let value_surcom = (is_surcom !== null && is_surcom !== undefined) ? CalculPercentToValue( Math.round(parseFloat(is_surcom.percentage_between)), parseFloat(total_selling)) : null;
	// let percent_surcom = (is_surcom !== null && is_surcom !== undefined) ?
	//     (parseFloat(is_surcom.selling_price) - parseFloat(is_surcom.purchase_price)) / (parseFloat(new_price) + parseFloat(item.margin_value) + (parseFloat(is_surcom.selling_price) - parseFloat(is_surcom.purchase_price)))  : "";
	let surcom_currency = (is_surcom !== null && is_surcom !== undefined) ? list_currency.find((currency) => {return currency.id === is_surcom.selling_currency }) : null;
	return (
		{
			my_currency: my_currency,
			new_price: new_price,
			item: item,
			total_accommodation_price: total_accommodation_price,
			is_surcom: is_surcom,
			percent_surcom: parseFloat(prices[GetPricesId(prices, owner_id, user)].percentage_between) / 100,
			value_surcom: value_surcom,
			surcom_currency: surcom_currency,
			price: price,
			network: network,
			total_network_purchase: total_network_purchase,
			total_network_selling: total_network_selling,
			total_network_value: total_network_value
		}
	)
}
