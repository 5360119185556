import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import axios, { AxiosError } from "axios";
import { TFunction } from "i18next";
import GetCookie from "../../Common/Functions/GetCookie";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import { SelectedRoom } from "../../ItineraryType/RoomTypePicker";
import { SetDates, SetTripData } from "../../../Actions/Trip";

type CallbackOptions = {
    sourceTripId: number,
    sourceTripVersion: number,
    targetTripVersion: number,
    startDate: string,
    products?: number[],
    adalteRooms?: SelectedRoom[],
    travelExchangeOptions?: {
        selectedTravelExchangePrices: any,
        selectedTravelExchangeOptions: any,
        mealPlan: any
    }
}

type Callback = (options: CallbackOptions) => Promise<void>

type Options = Partial<{
    onTrigger: () => void,
    onSuccess: () => void,
    onError: (error: AxiosError) => void,
    onFinally: () => void
}>

export function useItineraryDuplicateTripProducts(options: Options): Callback {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return async (callbackOptions) => {
        if (options.onTrigger) {
            options.onTrigger();
        }
        try {
            await makeRequest({
                sourceTripId: callbackOptions.sourceTripId,
                sourceTripVersion: callbackOptions.sourceTripVersion,
                targetTripVersion: callbackOptions.targetTripVersion,
                tripStartDate: callbackOptions.startDate,
                products: callbackOptions.products,
                adalteRooms: callbackOptions.adalteRooms,
                travelExchangeOptions: callbackOptions.travelExchangeOptions,
                t
            });

            const { headers } = CheckBeforeRequest();

            const response = await axios.get(
                `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/?token=${GetCookie("trip_token")}`,
                { headers }
            );
            dispatch({
                type: "TRIP_SET_ALL_DATA",
                payload: {
                    data: response.data
                }
            });

            const tripVersionResponse = await axios.get(
                `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/?token=${GetCookie("trip_token")}`,
                { headers }
            );
            const data = tripVersionResponse.data;
            console.log(data);
            /*
            if (parseInt(tripInfo?.tripVersion ?? GetCookie("trip_id_version")) !== data.trip.current_version) {
                //TODO: notify user that he is not fetching the current version of the trip and ask him if he want to proceed or access the current version
            } else {
            */
            dispatch(SetDates({
                startDate: data.start_date,
                endDate: data.end_date
            }));
            console.log('data.travelers:', data.travelers);

            $.each(data.travelers, function (_, value_travelers: any) {
                let age = window.moment.utc(data.end_date).diff(window.moment.utc(value_travelers.birth_date), "y");
                if (age >= 12) {
                    value_travelers.flight_type = "ADT";
                } else if (age >= 2) {
                    value_travelers.flight_type = "CNN";
                } else {
                    value_travelers.flight_type = "INF";
                }
                value_travelers.passenger_group = null;
            });
            for (let i = 0; i < data.prices.length; i++) {
                if (data.prices[i].selling_price === undefined) {
                    data.prices.splice(i, 1);
                    i--;
                }
            }
            for (let i = 0; i < data.prices_flight.length; i++) {
                if (data.prices_flight[i].selling_price === undefined) {
                    data.prices_flight.splice(i, 1);
                    i--;
                }
            }
            for (let i = 0; i < data.prices_stack_product.length; i++) {
                if (data.prices_stack_product[i].selling_price === undefined) {
                    data.prices_stack_product.splice(i, 1);
                    i--;
                }
            }
            for (let i = 0; i < data.prices_terrestrial.length; i++) {
                if (data.prices_terrestrial[i].selling_price === undefined) {
                    data.prices_terrestrial.splice(i, 1);
                    i--;
                }
            }
            dispatch(SetTripData(data, data.travelers, (data.budget === null ? 0 : parseInt(data.budget)), data.trip.currency, data.contact_traveler, data.contact_traveler_detail, data.trip.trip_reference));

            if (options.onSuccess) {
                options.onSuccess();
            }
        } catch (error: any) {
            if (options.onError) {
                options.onError(error);
            } else {
                throw error;
            }
        } finally {
            if (options.onFinally) {
                options.onFinally();
            }
        }
    };
}

type RequestOptions = {
    sourceTripId: number,
    sourceTripVersion: number,
    targetTripVersion: number,
    tripStartDate: string,
    products?: number[],
    adalteRooms?: SelectedRoom[],
    travelExchangeOptions?: {
        selectedTravelExchangePrices: any,
        selectedTravelExchangeOptions: any,
        mealPlan: any
    },
    t: TFunction
}

async function makeRequest(options: RequestOptions): Promise<void> {
    const { pass_check, headers } = CheckBeforeRequest();
    const url = new URL(
        API_HREF.startsWith('http') ?
            `${API_HREF}client/${window.id_owner}/trip/duplication_to_other_trip/` :
            `${window.location.origin}${API_HREF}client/${window.id_owner}/trip/duplication_to_other_trip/`
    );
    for (const id of options.products ?? []) {
        url.searchParams.append('pid', id.toString());
    }
    url.searchParams.append('id', options.sourceTripId.toString());
    url.searchParams.append('version_id', options.sourceTripVersion.toString());
    url.searchParams.append('target_trip_version', options.targetTripVersion.toString());
    url.searchParams.append('start_date', options.tripStartDate.toString());

    if (options.travelExchangeOptions?.mealPlan) {
        switch (options.travelExchangeOptions.mealPlan) {
            case options.t('accommodation_filters.half_board'): {
                url.searchParams.append('half_board', 'true');
                break;
            }
            case options.t('accommodation_filters.all_inclusive'): {
                url.searchParams.append('full_board', 'true');
                break;
            }
            case options.t('accommodation_filters.breakfast_included'): {
                url.searchParams.append('bed_and_breakfast', 'true');
                break;
            }
            case options.t('accommodation_filters.inclusive_board'): {
                url.searchParams.append('inclusive_board', 'true');
                break;
            }
        }
    }

    if (options.travelExchangeOptions?.selectedTravelExchangePrices) {
        url.searchParams.append('travel_exchange_price_terrestrial_id', options.travelExchangeOptions.selectedTravelExchangePrices.id);
    }

    if (options.travelExchangeOptions?.selectedTravelExchangeOptions) {
        for (const item of options.travelExchangeOptions.selectedTravelExchangeOptions) {
            url.searchParams.append('travel_exchange_price_terrestrial_option_id', item);
        }
    }

    if (pass_check) {
        await axios.post(
            url.toString(),
            { adalte_rooms: options.adalteRooms },
            { headers }
        );
    }
}
