import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSnackbar } from "notistack";
import { makeInvoiceCreateRequest } from "./makeInvoiceCreateRequest";
import { makeInvoiceEditRequest } from "./makeInvoiceEditRequest";
import { fetchInvoice } from "./fetchInvoice";
import { useShowError } from "../../../Utils/showError";
import CheckBeforeRequest from "../../../Common/CheckBeforeRequest";

export function useGenerateInvoice(options) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const locale = useSelector((state) => {
        return state.user.locales.find((item) => {
            return item.language_code === i18n.language;
        })?.id ?? 1;
    });
    const trip_info = useSelector(store => store.menu.trip_info);
    const to_show_version = useSelector(store => store.menu.to_show_version);
    //const version = to_show_version !== null ? to_show_version : trip_info.current_version;
    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const versions = useSelector((store) => store.trip.all_data && store.trip.all_data.data);
    const version_index = versions !== null ? versions.map(version => version.status_contract).indexOf('CONFIRMED') : -1;
    const version = version_index !== -1 ? versions[version_index].id : to_show_version !== null ? to_show_version : trip_info.current_version;
    const showError = useShowError();

    const handleError = (error) => {
        showError(error);
        options.setLoading(false);
    };

    return ({
                invoice,
                detailsInputs,
                productInputs,
                totalWithoutTaxes,
                totalFlightTaxes,
                totalAmount
            }) => {
        const type = invoice ? invoice.invoice_type : options.invoiceType;

        const linkPdf = (existingInvoice) => {
            return new Promise((resolve) => {
                generatePDF({
                    owner: options.owner,
                    client: options.client,
                    locale,
                    invoice: existingInvoice,
                    invoiceType: existingInvoice.invoice_type,
                    tripReference: options.tripReference,
                    versionNumber: invoice && versions !== null ? versions.map(version => version.id).indexOf(invoice.trip_version) + 1 : options.versionNumber,
                    tripId: trip_info.id,
                    trip_info: options.data_trip,
                    startDate: options.startDate,
                    endDate: options.endDate,
                    destination: options.destination,
                    tripName: options.tripName,
                    travelers: options.travelers,
                    detailsInputs: detailsInputs,
                    productInputs: productInputs,
                    currency: options.currency,
                    quotation_code: quotation_code,
                    totalWithoutTaxes,
                    totalFlightTaxes,
                    totalAmount
                }).then(() => {
                    fetchInvoice({
                        id: existingInvoice.id,
                        trip_info,
                        version: existingInvoice.trip_version
                    }).then((finalResponse) => {
                        options.onClose();
                        options.setLoading(false);
                        resolve(finalResponse);
                    }).catch(handleError);
                }).catch(handleError);
            });
        };

        if (invoice) {
            options.setLoading(true);
            makeInvoiceEditRequest({
                trip_info,
                version,
                id: invoice.id,
                data: {
                    company_name: detailsInputs ? detailsInputs.companyName.data !== '' ? detailsInputs.companyName.data : null : undefined,
                    amount: totalAmount ? parseFloat(totalAmount).toFixed(2).toString() : undefined,
                    invoice_product: productInputs ?
                        productInputs.map((input) => ({
                            name: input.description,
                            total_without_tax: input.totalWithoutTax,
                            flight_tax: input.flightTax,
                            total_amount: (
                                parseFloat(input.totalWithoutTax) +
                                parseFloat(input.flightTax)
                            ).toFixed(2).toString()
                        })) :
                        undefined,
                    invoice_date: window.moment.utc(new Date()).format('YYYY-MM-DD'),
                    is_generated: true
                }
            }).then((response) => {
                linkPdf(response.data).then((finalResponse) => {
                    dispatch({ type: 'TRIP_UPDATE_INVOICE', payload: finalResponse.data });
                    enqueueSnackbar(
                        type === 'INVOICE' ?
                            t('menu.invoice.invoice-generated') :
                            t('menu.invoice.credit-note-generated'),
                        { variant: 'success' }
                    );
                });
            }).catch(handleError);
        } else {
            options.setLoading(true);
            makeInvoiceCreateRequest({
                trip_info,
                version,
                data: {
                    name: 'Invoice',
                    document: null,
                    is_generated: true,
                    invoice_type: options.invoiceType,
                    company_name: detailsInputs.companyName.data !== '' ? detailsInputs.companyName.data : null,
                    amount: parseFloat(totalAmount).toFixed(2).toString(),
                    invoice_date: window.moment.utc(new Date()).format('YYYY-MM-DD'),
                    invoice_product: productInputs.map((input) => ({
                        name: input.description,
                        total_without_tax: input.totalWithoutTax,
                        flight_tax: input.flightTax,
                        total_amount: (
                            parseFloat(input.totalWithoutTax) +
                            parseFloat(input.flightTax)
                        ).toFixed(2).toString()
                    }))
                }
            }).then((response) => {
                linkPdf(response.data).then(() => {
                    dispatch({ type: 'TRIP_SET_INVOICES', payload: null });
                    enqueueSnackbar(
                        type === 'INVOICE' ?
                            t('menu.invoice.invoice-generated') :
                            t('menu.invoice.credit-note-generated'),
                        { variant: 'success' }
                    );
                });
            }).catch(handleError);
        }
    };
}

async function generatePDF(options) {
    const { headers } = CheckBeforeRequest();
    const response = await axios.get(
        //eslint-disable-next-line no-undef
        `${API_HREF}client/${window.id_owner}/trip/${options.tripId}/versions/${options.invoice.trip_version}/invoice-history/pay_art_invoice/`,
        {
            responseType: 'blob',
            headers: headers,
            params: {
                invoice_history_id: options.invoice.id,
                locale_id: options.locale
            }
        }
    );
    return response.data;
}
