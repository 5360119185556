import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Grid,
    Stack,
    TextField
} from '@mui/material';
import { LoadingBackDrop } from '../Common/LoadingBackdrop';
import { useCartProductUpdate } from './network/cartProductUpdate';
import { useShowError } from '../Utils/showError';
import GetCookie from '../Common/Functions/GetCookie';
import { StatusBooking } from '../Itinerary/objects/statusBooking';
import { ManualProduct } from '../../Reducers/objects/manualProduct';
import { CarCart } from '../Itinerary/objects/carCart';
import { AssistanceCart } from '../Itinerary/objects/assistanceCart';
import { PoiCart } from '../Itinerary/objects/poiCart';
import { TransferCart } from '../Itinerary/objects/transferCart';
import { Flight } from '../Itinerary/network/flight';

type Props = {
    onRemarkBooking: () => void
} & ({
    type: 'car',
    product: CarCart,
} | {
    type: 'assistance',
    product: AssistanceCart
} | {
    type: 'poi',
    product: PoiCart
} | {
    type: 'transfer',
    product: TransferCart,
} | {
    type: 'flight',
    product: Flight
} | {
    type: 'manual',
    product: ManualProduct
})

export function CartRemarkBooking(props: Props): JSX.Element {
    const versionString = GetCookie("trip_id_version");
    const version = versionString ? parseInt(versionString) : -1;
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [remarkBooking, setRemarkBooking] = useState('');
    const showError = useShowError();
    const update = useCartProductUpdate({
        onTrigger() {
            setLoading(true);
        },
        onError(error) {
            console.error(error);
            showError(error);
        },
        onFinally() {
            setLoading(false);
        }
    });

    const onValidateRemarkBooking = () => {
        update(
            version,
            {
                id: props.product.id,
                type: props.type,
                isCustom: props.type === 'manual' ?
                    false :
                    props.product.is_custom
            },
            {
                remark_booking: remarkBooking.trim().length > 0 ?
                    remarkBooking :
                    null
            }
        );
        props.onRemarkBooking();
    };

    const onCancelRemarkBooking = () => {
        props.onRemarkBooking();
    };

    useEffect(() => {
        setRemarkBooking(props.product.remark_booking ?? '');
    }, [props.product]);

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        onChange={(event) => setRemarkBooking(event.target.value)}
                        value={remarkBooking}
                        rows={8}
                        disabled={props.product.booking_status?.status_booking === StatusBooking.CONFIRMED}
                        multiline
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="flex-end" spacing={1}>
                        <Button onClick={onCancelRemarkBooking}>{t('global.cancel')}</Button>
                        {
                            !(
                                props.product.booking_status !== null &&
                                props.product.booking_status.status_booking === 'CONFIRMED'
                            ) &&
                            <Button onClick={onValidateRemarkBooking}>{t('global.validate')}</Button>
                        }
                    </Stack>
                </Grid>
            </Grid>
            <LoadingBackDrop open={loading} />
        </>
    );
}