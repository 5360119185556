import { ItineraryInput } from "../objects/itineraryState";
import { FlashDestination } from "../objects/flashDestination";
import { FlashGetDestination } from "../objects/flashGetDestination";

type Options = ({
    step: ItineraryInput,
} | {
    step: null,
    tripStartDate: string,
    tripEndDate: string,
}) & {
    order: number | null,
    destination: FlashDestination,
    destinationData: FlashGetDestination | null,
    tripId: number,
    version: number,
    daysCount: number
}

export function createStepFrom(options: Options): ItineraryInput {
    const startDate = options.step ?
        window.moment.utc(options.step.end_date) :
        window.moment.utc(options.tripStartDate);
    let endDate = options.step ?
        window.moment.utc(options.step.end_date).startOf('day').add(
            options.daysCount,
            'days'
        ).set('hour', 9) :
        window.moment.utc(options.tripStartDate).startOf('day').add(
            options.daysCount,
            'days'
        ).set('hour', 9);

    if (!options.step) {
        startDate.set(
            'hours',
            9
        ).set(
            'minutes',
            0
        ).set(
            'seconds',
            0
        );
    }

    if (endDate.isBefore(startDate)) {
        endDate = startDate.clone();
    }

    return {
        id: Math.random().toString(),
        circuit: null,
        circuit_trip_version: null,
        circuit_start_date: null,
        variant: null,
        iti_type: null,
        city_name: null,
        country_name: null,
        destination: {
            ...options.destination,
            cover_pic__url: options.destinationData?.cover_pic__url ?? ''
        },
        distance_transport_km: 0,
        duration_transport_after: 0,
        locked_agency: false,
        modified_date: new Date().toISOString(),
        places_id: 'id',
        r2r_api_version: 'version',
        r2r_json: {},
        selected_transport: '',
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        step_type: 'STEP',
        step_order: options.order,
        trip: options.tripId,
        trip_version: options.version
    };
}
