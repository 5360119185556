import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Collapse,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
    styled
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { flatten, isNumber, mapKeys, mapValues } from "lodash";
import {
    CartConstructionContentsRoadbookFormSave
} from "./CartConstructionContentsRoadbookFormSave";
import {
    CartConstructionContentsRoadbookFormDescriptionInput
} from "./CartConstructionContentsRoadbookFormDescriptionInput";
import { CartConstructionContentsForm } from "./CartConstructionContentsForm";
import { CartConstructionContentsContext } from "./utils/cartConstructionContentsContext";
import { findRoadbook } from "../Menu/MaterialTripList/utils/findRoadbook";
import {
    findTripRoadbookRubrics
} from "../Menu/MaterialTripList/utils/findTripRoadbookRubrics";
import {
    findRoadbookConfiguration
} from "../Menu/MaterialTripList/utils/findRoadbookConfiguration";
import GetCookie from "../Common/Functions/GetCookie";
import {
    restoreVisualEditorBlocksFromHtmlIfEmpty
} from "../Menu/MaterialTripList/MailVisualEditor/redux/thunks";
import { setLoading, setLocale } from "./redux/cartConstructionReducer";
import { Roadbook } from "../Menu/MaterialTripList/objects/roadbook";
import {
    RoadbookConfiguration
} from "../Menu/MaterialTripList/objects/roadbookConfiguration";
import {
    RoadbookConfigurationIdentifier
} from "../Menu/MaterialTripList/objects/roadbookConfigurationIdentifier";
import { AppState } from "../../Reducers/Reducers";

export function CartConstructionContentsRoadbookForm(): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const languages = useSelector((state: AppState) => state.user.locales);
    const tripVersion = useSelector((state: AppState) => state.trip.data_trip);
    const tripId = useSelector((state: AppState) => state.trip.trip_id);
    const textsLocale = useSelector((state: AppState) => state.cartConstruction.locale);
    const [roadbook, setRoadbook] = useState<Roadbook | null>(null);
    const [roadbookConfiguration, setRoadbookConfiguration] = useState<RoadbookConfiguration | null>(null);
    const context = useMemo((): Parameters<typeof CartConstructionContentsContext.Provider>[0]['value'] => {
        const versionString = GetCookie("trip_id_version");
        const version = versionString ? parseInt(versionString) : -1;
        return { mode: 'roadbook', version };
    }, []);
    const [titleInputs, setTitleInputs] = useState<{ [id: number]: { [locale: number]: string } }>({});
    const quotationCode = JSON.parse(localStorage.getItem('config') ?? '{}').quotation_code;

    const onChangeItemTitle = (id: number, value: string) => {
        setTitleInputs((state) => {
            return {
                ...state,
                [id]: {
                    ...state[id],
                    [textsLocale]: value
                }
            };
        });
    };

    useEffect(() => {
        if (tripVersion?.destination?.id) {
            (async () => {
                try {
                    dispatch(setLoading(true));
                    setRoadbook(await findRoadbook(tripVersion.destination!.id));
                    setRoadbookConfiguration(await findRoadbookConfiguration());
                } catch (error) {
                    console.error(error);
                } finally {
                    dispatch(setLoading(false));
                }
            })();
        }
    }, [tripVersion]);

    useEffect(() => {
        if (tripId) {
            (async () => {
                try {
                    dispatch(setLoading(true));
                    const tripRubrics = await findTripRoadbookRubrics(tripId) ?? [];
                    const rubrics = mapValues(
                        mapKeys(
                            flatten(
                                roadbook?.categories.map((category) => {
                                    return category.rubrics.map((rubric) => {
                                        const tripRubric = tripRubrics.find((item) => {
                                            return item.parent === rubric.id;
                                        });
                                        return {
                                            ...rubric,
                                            localization: (tripRubric ?? rubric).localization
                                        };
                                    });
                                }) ?? []
                            ),
                            (item) => item.id
                        ),
                        (item) => {
                            return mapValues(
                                mapKeys(
                                    item.localization,
                                    (item) => item.locale
                                ),
                                (item) => item.title
                            );
                        }
                    );
                    setTitleInputs(rubrics);
                    for (const category of roadbook?.categories ?? []) {
                        for (const rubric of category.rubrics) {
                            const tripRubric = tripRubrics.find((item) => {
                                return item.parent === rubric.id;
                            });
                            for (const localization of (tripRubric ? tripRubric.localization : rubric.localization)) {
                                dispatch(
                                    restoreVisualEditorBlocksFromHtmlIfEmpty({
                                        instanceId: `cart-construction-roadbook-rubric-${tripId}-${rubric.id}`,
                                        locale: localization.locale,
                                        html: localization.description,
                                        quotationCode
                                    })
                                );
                            }
                        }
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    dispatch(setLoading(false));
                }
            })();
        }
    }, [tripId, roadbook]);

    return (
        <>
            <Grid container justifyContent="space-between" sx={{ marginBottom: 2.5 }}>
                <Grid item xs={12} md={4}>
                    <FormControl size="small" fullWidth>
                        <InputLabel>{t('shared.language')}</InputLabel>
                        <Select
                            value={textsLocale}
                            label={t('shared.language')}
                            onChange={(event) => isNumber(event.target.value) && dispatch(setLocale((event.target.value)))}
                        >
                            {
                                languages.map((language) => (
                                    <MenuItem key={language.id} value={language.id}>
                                        {language.full_name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {
                roadbookConfiguration?.modules.map((module) => {
                    switch (module.identifier) {
                        case RoadbookConfigurationIdentifier.EDITO:
                        case RoadbookConfigurationIdentifier.ROADBOOK_CATEGORY: {
                            const category = roadbook?.categories.find((item) => {
                                return item.category.id === module.category?.id;
                            });
                            return category?.rubrics.map((rubric) => {
                                return (
                                    <ItemWrapper
                                        key={rubric.id}
                                        value={(titleInputs[rubric.id] ?? {})[textsLocale] ?? ''}
                                        onChange={(value) => onChangeItemTitle(rubric.id, value)}
                                    >
                                        <CartConstructionContentsRoadbookFormDescriptionInput
                                            id={rubric.id}
                                            locale={textsLocale}
                                            tripId={tripId}
                                        />
                                    </ItemWrapper>
                                );
                            });
                        }
                        case RoadbookConfigurationIdentifier.PROGRAM: {
                            return (
                                <ProgramWrapper>
                                    <CartConstructionContentsContext.Provider key={module.identifier} value={context}>
                                        <CartConstructionContentsForm />
                                    </CartConstructionContentsContext.Provider>
                                </ProgramWrapper>
                            );
                        }
                    }
                })
            }
            <CartConstructionContentsRoadbookFormSave titles={titleInputs} />
        </>
    );
}

type ItemWrapperProps = {
    value: string,
    onChange: (value: string) => void,
    children: React.ReactNode
}

function ItemWrapper(props: ItemWrapperProps): JSX.Element {
    const [open, setOpen] = useState(false);

    return (
        <Box
            sx={{
                border: '1px solid rgba(0, 0, 0, 0.1)',
                marginBottom: 3,
                padding: 1.5
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ cursor: 'pointer' }}
                onClick={() => setOpen((state) => !state)}
            >
                <TitleInput
                    type="text"
                    value={props.value}
                    onChange={(event) => props.onChange(event.target.value)}
                />
                <ExpandMore />
            </Stack>
            <Collapse in={open}>
                {props.children}
            </Collapse>
        </Box>
    );
}

type ProgramWrapperProps = {
    children: React.ReactNode
}

function ProgramWrapper(props: ProgramWrapperProps): JSX.Element {
    const { t } = useTranslation();
    const [open, setOpen] = useState(true);

    return (
        <Box
            sx={{
                border: '1px solid rgba(0, 0, 0, 0.1)',
                marginBottom: 3,
                padding: 1.5
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ cursor: 'pointer' }}
                onClick={() => setOpen((state) => !state)}
            >
                <Typography color="#757575">
                    {t('shared.roadbook-configuration-module.PROGRAM')}
                </Typography>
                <ExpandMore />
            </Stack>
            <Collapse in={open}>
                {props.children}
            </Collapse>
        </Box>
    );
}

const TitleInput = styled('input')(() => ({
    background: 'none',
    border: 'none',
    color: '#757575',
    width: '100%'
}));
