import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Icon,
    Stack,
    Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { CartRemarkBooking } from "./CartRemarkBooking";
import CartAccommodationRemarkBooking from "./CartAccommodationRemarkBooking";
import { AccommodationCart } from "../Itinerary/objects/accommodationCart";
import { Flight } from "../Itinerary/network/flight";
import { TransferCart } from "../Itinerary/objects/transferCart";
import { PoiCart } from "../Itinerary/objects/poiCart";
import { AssistanceCart } from "../Itinerary/objects/assistanceCart";
import { CarCart } from "../Itinerary/objects/carCart";
import { ManualProduct } from "../../Reducers/objects/manualProduct";
import { AppState } from "../../Reducers/Reducers";

type Props = {
    open: boolean,
    onClose: () => void,
    onCloseMenu: () => void,
} & ({
    type: 'car',
    product: CarCart,
} | {
    type: 'assistance',
    product: AssistanceCart
} | {
    type: 'poi',
    product: PoiCart
} | {
    type: 'accommodation',
    product: AccommodationCart,
    rooms: number[]
} | {
    type: 'transfer',
    product: TransferCart,
} | {
    type: 'flight',
    product: Flight
} | {
    type: 'manual',
    product: ManualProduct
})

export function CartConstructionProductsTableSpecificDemandModal(props: Props): JSX.Element {
    const { t } = useTranslation();
    const providers = useSelector((state: AppState) => state.trip.providers);
    const manual_providers = useSelector((state: AppState) => state.trip.manual_providers);
    const provider = useMemo(() => {
        let tmp_provider = null;
        if (props.type === 'accommodation') {
            for (let i = 0; i < providers.length; i++) {
                if (providers[i]?.provider.id === props.product.provider) {
                    tmp_provider = providers[i]!;
                }
            }
            for (let i = 0; i < manual_providers.length; i++) {
                if (manual_providers[i]?.provider.id === props.product.provider) {
                    tmp_provider = manual_providers[i]!;
                }
            }
        }
        return tmp_provider;
    }, [props.product, providers, manual_providers]);

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            onClick={(event) => event.stopPropagation()}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" fontWeight="bold" component="div">
                        {t('cart-material.cart-construction-specific-demand')}
                    </Typography>
                    <Icon onClick={props.onClose}>
                        <Close />
                    </Icon>
                </Stack>
            </DialogTitle>
            <DialogContent>
                {
                    props.type === 'accommodation' &&
                    <CartAccommodationRemarkBooking
                        accommodation={props.product}
                        provider={provider}
                        onRemarkBooking={props.onCloseMenu}
                    />
                }
                {
                    props.type !== 'accommodation' &&
                    <Box sx={{ marginTop: 2.5 }}>
                        <CartRemarkBooking
                            {...props}
                            onRemarkBooking={props.onCloseMenu}
                        />
                    </Box>
                }
            </DialogContent>
        </Dialog>
    );
}
