import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from "@material-ui/core";
import { FormControl, Grid, InputLabel, OutlinedInput } from '@material-ui/core';
import Axios from 'axios';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ActivitiesQuestionsItem } from './ActivitiesQuestionsItem';
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import GetCookie from '../Common/Functions/GetCookie';
import { Autocomplete, Button, CircularProgress, Divider, TextField, Typography } from '@mui/material';
import { green } from '@material-ui/core/colors';
import { useSnackbar } from 'notistack';
import MuiPhoneNumber from "material-ui-phone-number";
import { styled, lighten, darken } from '@mui/system';
import { AppState } from '../../Reducers/Reducers';
import { ViatorLocation } from './ViatorLocation';

const useStyles = makeStyles((theme) => ({
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    fontWeight400:{
        fontWeight: 400
    },
    fontSize12: {
        fontSize: 12
    },
    letterSpacing: {
        letterSpacing: 1.25
    },
    textCenter:{
        textAlign: "center"
    },
    genericPadding:{
        padding: "8px 0px"
    },
    formControlRootSmall: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
            transform: 'translate(20px, 10px) scale(1)'
        },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        }
    },
    formControlRootSmallRed: {
        width: "100%",
        "& .mui-jss-MuiOutlinedInput-input": {
            padding: '7px 20px;'
        },
        "& .mui-jss-MuiOutlinedInput-notchedOutline": {
            borderColor: "red"
        },
        "&& .mui-jss-MuiSvgIcon-root" : {
            color: "black"
        },
        "& .mui-jss-MuiFormLabel-root": {
            fontWeight: 400,
            fontSize: 12,
            zIndex: -1
        },
        "&& .mui-jss-MuiInputLabel-outlined": {
            transform: 'translate(20px, 10px) scale(1)'
        },
        "& .mui-jss-MuiInputLabel-outlined.mui-jss-MuiInputLabel-shrink": {
            transform: 'translate(14px, -6px) scale(0.75)'
        }
    },
    circleBorder:{
        borderRadius: 8
    },
    validateButton: {
        color: "#FFFFFF",
        backgroundColor: "#2C98F0",
        float: "right",
        marginTop: 8
    },
    errorInfo:{
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 900,
        letterSpacing: 1.25,
        color: "red",
        width: "100%",
        fontSize: 10
    },
    orangeButton: {
        backgroundColor: '#E6592F !important',
        color: 'white'
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    marginBottom10: {
        marginBottom: 10
    },
}))

type ActivitiesQuestionsProps = {
    product: any;
    tempTravelers: any;
    setTempTravelers: any;
}

type Location = {
    name: string | null,
    description: string | null,
    google_ref: string | null,
    pick_up_type: string | null,
    provider_type: string | null,
    ref: string | null
}

type ViatorLocation = {
    meeting_type: string | undefined;
    locations: any[];
    start: any[];
    end: any[];
    allow_custom_traveler_pickup: boolean | undefined;
}

type ViatorQuestion = {
    answer_text: any;
    question_code: string;
    question_text: string;
    rules_answer: any;
}

type GooglePlace = {
    address_components: any[];
    adr_address: string;
    formatted_address: string;
    geometry: any;
    html_attributions: any[];
    icon: string;
    id: string;
    name: string;
    place_id: string;
    reference: string;
    scope: string;
    types: string[];
    url: string;
    utc_offset: number;
    vicinity: string;
}

export function ActivitiesQuestions(props: ActivitiesQuestionsProps) : JSX.Element | null {
    const { product, tempTravelers, setTempTravelers } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const providers = useSelector((state: AppState) => state.trip.providers);
    const user = useSelector((state: AppState) => state.user.user);
    const meeting_point = useSelector((state: AppState) => state.poi.meeting_point);
    const pickup_point = useSelector((state: AppState) => state.poi.pickup_point);
    const return_point = useSelector((state: AppState) => state.poi.return_point);
    const manual_pickup = useSelector((state: AppState) => state.poi.manual_pickup);
    const [loading, setLoading] = useState(false);
    const [departureMode, setDepartureMode] = useState<any>(null);
    const [arrivalMode, setArrivalMode] = useState<any>(null);
    const [error, setError] = useState<string[]>([]);
    const [questions, setQuestions] = useState<any[]>([]);
    const [viatorLocation, setViatorLocation] = useState<ViatorLocation | null>(null);
    const provider = useMemo(() => {
        return providers.find((item) => {
            return item.provider.id === product.provider;
        })?.provider;
    }, [product]);
    const [doneLocation, setDoneLocation] = useState<boolean>(false);
    const product_meeting_point = product !== undefined && product.meeting_point !== undefined && product.meeting_point !== null ?
        (typeof product.meeting_point === 'string' ? JSON.parse(product.meeting_point) : product.meeting_point)
        : null;
    const emailLabel = useRef(null);
    const emailLabelWidth = emailLabel.current ? emailLabel.current.clientWidth : 42
    const isJson = (text: string) => {
        try {
            JSON.parse(text);
        } catch (e) {
            return false;
        }
        return true;
    };
    console.log('product:', product);
    let lead_traveler = tempTravelers !== null ? tempTravelers.find((traveler: any) => traveler.id === product.group_passenger.lead_traveler) : null;
    useEffect(() => {
        if (product !== null && provider?.code === "viator") {
            getViatorLocation();
        }
        return () => {
            dispatch({ type: 'POI_SET_MEETING_POINT', payload: null });
            dispatch({ type: 'POI_SET_PICKUP_POINT', payload: null });
            dispatch({ type: 'POI_SET_RETURN_POINT', payload: null });
            dispatch({ type: 'POI_SET_RETURN_POINT', payload: null });
        }
    }, []);

    useEffect(() => {
        if (product.questions.length > 0) {
            let tmp_questions: any[] = [];
            product.questions.map((question: any) => {
                if (question.question_code === "TRANSFER_ARRIVAL_MODE") {
                    if (question.rules_answer !== null && typeof question.rules_answer === 'string' && isJson(question.rules_answer)) {
                        question.rules_answer = JSON.parse(question.rules_answer);
                    }
                    if (question.rules_answer !== null && question.rules_answer.choice.length === 1) {
                        question.answer_text = {value: question.rules_answer.choice[0]}
                    }
                    tmp_questions.push(question);
                    setArrivalMode(question);
                }
            });
            product.questions.map((question: any) => {
                if (question.question_code.includes('PICKUP_POINT')) {
                    tmp_questions.push(question);
                }
            });
            product.questions.map((question: any) => {
                if (question.question_code.includes('TRANSFER_PORT_CRUISE_SHIP')) {
                    tmp_questions.push(question);
                }
            });
            product.questions.map((question: any) => {
                if (question.question_code !== "TRANSFER_ARRIVAL_MODE" && question.question_code.includes('ARRIVAL')) {
                    tmp_questions.push(question);
                }
            });
            product.questions.map((question: any) => {
                if (question.question_code === "TRANSFER_DEPARTURE_MODE") {
                    if (question.rules_answer !== null && typeof question.rules_answer === 'string' && isJson(question.rules_answer)) {
                        question.rules_answer = JSON.parse(question.rules_answer);
                    }
                    if (question.rules_answer !== null && question.rules_answer.choice.length === 1) {
                        question.answer_text = {value: question.rules_answer.choice[0]}
                    }
                    tmp_questions.push(question);
                    setDepartureMode(question);
                }
            });
            product.questions.map((question: any) => {
                if (question.question_code !== "TRANSFER_DEPARTURE_MODE" && question.question_code.includes('DEPARTURE')) {
                    tmp_questions.push(question);
                }
            });
            product.questions.map((question: any) => {
                if (question.question_code !== "PICKUP_POINT" && question.question_code !== "TRANSFER_ARRIVAL_MODE" && question.question_code !== "TRANSFER_DEPARTURE_MODE" && question.question_code !== "SPECIAL_REQUIREMENTS" && !question.question_code.includes('ARRIVAL') && !question.question_code.includes('DEPARTURE') && !question.question_code.includes('TRANSFER_PORT_CRUISE_SHIP')) {
                    tmp_questions.push(question);
                }
            });
            product.questions.map((question: any) => {
                if (question.question_code === "SPECIAL_REQUIREMENTS") {
                    tmp_questions.push(question);
                }
            });
            console.log('tmp_questions:', tmp_questions);
            setQuestions(tmp_questions);
        }
    },[product]);
    useEffect(() => {
        if (viatorLocation !== null) {
            if (product_meeting_point !== undefined && product_meeting_point !== null && (product_meeting_point.start !== null || product_meeting_point.end !== null)) {
                if (viatorLocation.meeting_type === 'PICKUP_AND_MEET_AT_START_POINT' || viatorLocation.meeting_type === 'MEET_EVERYONE_AT_START_POINT') {
                    if (product_meeting_point.location !== null) {
                        dispatch({ type: 'POI_SET_MEETING_POINT', payload: {...product_meeting_point.location} })
                    } else if (product_meeting_point.start !== null) {
                        dispatch({ type: 'POI_SET_MEETING_POINT', payload: {...product_meeting_point.start} })
                    }
                }
                if (viatorLocation.meeting_type === 'PICKUP_AND_MEET_AT_START_POINT' || viatorLocation.meeting_type === 'PICKUP_EVERYONE') {
                    if (product_meeting_point.start !== null) {
                        dispatch({ type: 'POI_SET_PICKUP_POINT', payload: {...product_meeting_point.start} })
                    }
                }
                if (product_meeting_point.end !== null) {
                    dispatch({ type: 'POI_SET_RETURN_POINT', payload: {...product_meeting_point.end} })
                }
            } else {
                if (viatorLocation.locations.length === 1 && meeting_point === null) {
                    dispatch({ type: 'POI_SET_MEETING_POINT', payload: {...viatorLocation.locations[0]} })
                }
                if (viatorLocation.start.length === 1 && pickup_point === null) {
                    dispatch({ type:  'POI_SET_PICKUP_POINT', payload: {...viatorLocation.start[0]} })
                }
                if (viatorLocation.end.length === 1 && return_point === null) {
                    dispatch({ type:  'POI_SET_RETURN_POINT', payload: {...viatorLocation.end[0]} })
                }
            }
        }
    }, [viatorLocation]);
    useEffect(() => {
        if (doneLocation && viatorLocation !== null) {
            let google_place = checkGoogleLocation();
            Promise.all(google_place).then(res => {
                console.log('res:', res);
                setViatorPlace(res);
                setDoneLocation(false);
            }).catch(err => {
                console.log(err);
                setDoneLocation(false);
            });
        }
    }, [doneLocation]);
    const setViatorPlace = (places: GooglePlace[]) => {
        let tmp_viator = {...viatorLocation};
        let tmp_start: Location[] = [];
        let tmp_end: Location[] = [];
        let tmp_locations: Location[] = [];
        console.log('tmp_viator:', tmp_viator);
        let copy_data: ViatorLocation = {
            start: [],
            locations: [],
            end: [],
            meeting_type: tmp_viator.meeting_type,
            allow_custom_traveler_pickup: tmp_viator.allow_custom_traveler_pickup
        };
        if (viatorLocation !== null) {
            if (viatorLocation.start.length !== 0) {
                viatorLocation.start.map(item => {
                    let tmp_item = {...item};
                    if (tmp_item.provider_type === "GOOGLE") {
                        let find_place = places.find(place => place.reference === tmp_item.google_ref);
                        if (find_place !== undefined) {
                            tmp_item.address = find_place.formatted_address;
                            tmp_item.name = find_place.name;
                            tmp_item.country_name = find_place.address_components.find(component => component.types.includes("country")).long_name;
                            tmp_item.latitude = find_place.geometry.location.lat();
                            tmp_item.longitude = find_place.geometry.location.lng();
                            tmp_item.pick_up_type = "OTHER";
                            tmp_start.push(tmp_item);
                        }
                    } else {
                        if (tmp_item.ref !== undefined && tmp_item.ref !== null) {
                            tmp_start.push(tmp_item);
                        }
                    }
                });
            }
            if (viatorLocation.end.length !== 0) {
                viatorLocation.end.map(item => {
                    let tmp_item = {...item};
                    if (tmp_item.provider_type === "GOOGLE") {
                        let find_place = places.find(place => place.reference === tmp_item.google_ref);
                        if (find_place !== undefined) {
                            tmp_item.address = find_place.formatted_address;
                            tmp_item.name = find_place.name;
                            tmp_item.country_name = find_place.address_components.find(component => component.types.includes("country")).long_name;
                            tmp_item.latitude = find_place.geometry.location.lat();
                            tmp_item.longitude = find_place.geometry.location.lng();
                            tmp_item.pick_up_type = "OTHER";
                            tmp_end.push(tmp_item);
                        }
                    } else {
                        if (tmp_item.ref !== undefined && tmp_item.ref !== null) {
                            tmp_end.push(tmp_item);
                        }
                    }
                });
            }
            if (viatorLocation.locations.length !== 0) {
                viatorLocation.locations.map(item => {
                    let tmp_item = {...item};
                    if (tmp_item.provider_type === "GOOGLE") {
                        let find_place = places.find(place => place.reference === tmp_item.google_ref);
                        if (find_place !== undefined) {
                            tmp_item.address = find_place.formatted_address;
                            tmp_item.name = find_place.name;
                            tmp_item.country_name = find_place.address_components.find(component => component.types.includes("country")).long_name;
                            tmp_item.latitude = find_place.geometry.location.lat();
                            tmp_item.longitude = find_place.geometry.location.lng();
                            tmp_item.pick_up_type = "OTHER";
                            tmp_locations.push(tmp_item);
                        }
                    } else {
                        if (tmp_item.ref !== undefined && tmp_item.ref !== null) {
                            tmp_locations.push(tmp_item);
                        }
                    }
                });
            }
        }
        if (tmp_locations.length === 1) {
            dispatch({ type: 'POI_SET_MEETING_POINT', payload: {...tmp_locations[0]} })
        }
        if (tmp_start.length === 1) {
            dispatch({ type:  'POI_SET_PICKUP_POINT', payload: {...tmp_start[0]} })
        }
        if (tmp_end.length === 1) {
            dispatch({ type:  'POI_SET_RETURN_POINT', payload: {...tmp_end[0]} })
        }
        copy_data.end = [...tmp_end];
        copy_data.locations = [...tmp_locations];
        copy_data.start = [...tmp_start];
        setViatorLocation({...copy_data});

        setDoneLocation(false);
    };
    const checkGoogleLocation = () => {
        let google_request: any[] = [];
        if (viatorLocation !== null) {
            if (viatorLocation.start.length !== 0) {
                viatorLocation.start.map(item => {
                    let tmp_item = {...item};
                    if (tmp_item.provider_type === "GOOGLE") {
                        google_request.push(getGoogleLocation(tmp_item.google_ref));
                    }
                })
            }
            if (viatorLocation.end.length !== 0) {
                viatorLocation.end.map(item => {
                    let tmp_item = {...item};
                    if (tmp_item.provider_type === "GOOGLE") {
                        google_request.push(getGoogleLocation(tmp_item.google_ref));
                    }
                })
            }
            if (viatorLocation.locations.length !== 0) {
                viatorLocation.locations.map(item => {
                    let tmp_item = {...item};
                    if (tmp_item.provider_type === "GOOGLE") {
                        google_request.push(getGoogleLocation(tmp_item.google_ref));
                    }
                })
            }
        }
        return google_request;
    }
    const getGoogleLocation = (place_id: string) => {
        let detail_request = {
            placeId: place_id
        }
        let detail_service = new google.maps.places.PlacesService(document.createElement('div'));
        return new Promise(function(resolve, reject) {
            detail_service.getDetails(detail_request, (place, status) => {
                if (status == google.maps.places.PlacesServiceStatus.OK) {
                    resolve(place);
                } else {
                    reject(status);
                }
            });

        });
    }
    const getViatorLocation = () => {
        const { headers } = CheckBeforeRequest();
        Axios({
            method: "GET",
            headers: headers,
            url: `${API_HREF}client/${window.id_owner}/custom-products/list_meeting_point_viator/?product=${product.custom_product.product_code}`
        }).then(res => {
            if (res.data !== undefined && res.data !== null) {
                let data = {...res.data};
                if (data.allow_custom_traveler_pickup) {
                    if (data.end.length !== 0) {
                        data.end.unshift({
                            name: t('poi.questions.cant_see_pickup'),
                            pick_up_type: "MANUAL",
                            ref: "ADD_PICKUP_MANUALLY",
                            provider_type: "MANUAL",
                            description: null,
                            google_ref: null
                        });
                    }
                    if (data.start.length !== 0) {
                        data.start.unshift({
                            name: t('poi.questions.cant_see_pickup'),
                            pick_up_type: "MANUAL",
                            ref: "ADD_PICKUP_MANUALLY",
                            provider_type: "MANUAL",
                            description: null,
                            google_ref: null
                        });
                    }
                    if (data.locations.length !== 0) {
                        data.locations.unshift({
                            name: t('poi.questions.cant_see_pickup'),
                            pick_up_type: "MANUAL",
                            ref: "ADD_PICKUP_MANUALLY",
                            provider_type: "MANUAL",
                            description: null,
                            google_ref: null
                        });
                    }
                }
                setViatorLocation({...data});
                setDoneLocation(true);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    const editProduct = (tmp_product: any) => {
        dispatch({ type: 'EDIT_ITEM_TO_BOOK', payload: {product: tmp_product} });
    }
    const patchActivity = () => {
        const { headers } = CheckBeforeRequest();
        let tmp_questions = [...product.questions];
        // return an array of string with the question_code of the mandatory questions with answer_text null or value empty
        let tmp_mandatory_question: string[] = [];
        if (departureMode !== null && departureMode.answer_text !== null && departureMode.answer_text.value !== '') {
            switch (departureMode.answer_text.value) {
                case "AIR":
                    tmp_questions.map((question: any) => {
                        let rules_tojson = typeof question.rules_answer !== 'string' && isJson(question.rules_answer) ? JSON.parse(question.rules_answer) : question.rules_answer;
                        if (rules_tojson.required === "CONDITIONAL" && ["TRANSFER_AIR_DEPARTURE_AIRLINE", "TRANSFER_AIR_DEPARTURE_FLIGHT_NO", "TRANSFER_DEPARTURE_TIME", "TRANSFER_DEPARTURE_DROP_OFF", "PICKUP_POINT"].includes(question.question_code) &&(question.answer_text === null || question.answer_text.value === '')) {
                            tmp_mandatory_question.push(question.question_code);
                        }
                    });
                    break;
                case "SEA":
                    tmp_questions.map((question: any) => {
                        let rules_tojson = typeof question.rules_answer !== 'string' && isJson(question.rules_answer) ? JSON.parse(question.rules_answer) : question.rules_answer;
                        if (rules_tojson.required === "CONDITIONAL" && ["TRANSFER_PORT_DEPARTURE_TIME", "TRANSFER_PORT_CRUISE_SHIP", "TRANSFER_DEPARTURE_DROP_OFF", "PICKUP_POINT"].includes(question.question_code) &&(question.answer_text === null || question.answer_text.value === '')) {
                            tmp_mandatory_question.push(question.question_code);
                        }
                    });
                    break;
                case "RAIL":
                    tmp_questions.map((question: any) => {
                        let rules_tojson = typeof question.rules_answer !== 'string' && isJson(question.rules_answer) ? JSON.parse(question.rules_answer) : question.rules_answer;
                        if (rules_tojson.required === "CONDITIONAL" && ["TRANSFER_RAIL_DEPARTURE_LINE", "TRANSFER_RAIL_DEPARTURE_STATION", "TRANSFER_DEPARTURE_TIME", "TRANSFER_DEPARTURE_DROP_OFF"].includes(question.question_code) &&(question.answer_text === null || question.answer_text.value === '')) {
                            tmp_mandatory_question.push(question.question_code);
                        }
                    });
                    break;
            }
        }
        if (arrivalMode !== null && arrivalMode.answer_text !== null && arrivalMode.answer_text.value !== '') {
            switch (arrivalMode.answer_text.value) {
                case "AIR":
                    tmp_questions.map((question: any) => {
                        let rules_tojson = typeof question.rules_answer === 'string' && isJson(question.rules_answer) ? JSON.parse(question.rules_answer) : question.rules_answer;
                        if (rules_tojson.required === "CONDITIONAL" && ["TRANSFER_AIR_ARRIVAL_AIRLINE", "TRANSFER_AIR_ARRIVAL_FLIGHT_NO", "TRANSFER_ARRIVAL_TIME", "TRANSFER_ARRIVAL_DROP_OFF", "PICKUP_POINT"].includes(question.question_code) &&(question.answer_text === null || question.answer_text.value === '')) {
                            tmp_mandatory_question.push(question.question_code);
                        }
                    });
                    break;
                case "SEA":
                    tmp_questions.map((question: any) => {
                        let rules_tojson = typeof question.rules_answer === 'string' && isJson(question.rules_answer) ? JSON.parse(question.rules_answer) : question.rules_answer;
                        if (rules_tojson.required === "CONDITIONAL" && ["TRANSFER_PORT_ARRIVAL_TIME", "TRANSFER_PORT_CRUISE_SHIP", "TRANSFER_ARRIVAL_DROP_OFF", "PICKUP_POINT"].includes(question.question_code) &&(question.answer_text === null || question.answer_text.value === '')) {
                            tmp_mandatory_question.push(question.question_code);
                        }
                    });
                    break;
                case "RAIL":
                    tmp_questions.map((question: any) => {
                        let rules_tojson = typeof question.rules_answer === 'string' && isJson(question.rules_answer) ? JSON.parse(question.rules_answer) : question.rules_answer;
                        if (rules_tojson.required === "CONDITIONAL" && ["TRANSFER_RAIL_ARRIVAL_LINE", "TRANSFER_RAIL_ARRIVAL_STATION", "TRANSFER_ARRIVAL_TIME", "TRANSFER_ARRIVAL_DROP_OFF"].includes(question.question_code) &&(question.answer_text === null || question.answer_text.value === '')) {
                            tmp_mandatory_question.push(question.question_code);
                        }
                    });
                    break;

            }
        }
        tmp_questions.map((question: any) => {
            let rules_tojson = typeof question.rules_answer === 'string' && isJson(question.rules_answer) ? JSON.parse(question.rules_answer) : question.rules_answer;
            if (rules_tojson.required === "MANDATORY" && !["AGEBAND", "FULL_NAMES_FIRST", "FULL_NAMES_LAST", "DATE_OF_BIRTH", "PASSPORT_PASSPORT_NO", "DATE_OF_BIRTH", "PASSPORT_EXPIRY", "PASSPORT_NATIONALITY"].includes(question.question_code) &&(question.answer_text === null || question.answer_text.value === '')) {
                tmp_mandatory_question.push(question.question_code);
            }
            if (question.question_code === "PICKUP_POINT") {
                if (pickup_point !== null && pickup_point.pick_up_type !== 'MANUAL') {
                    question.answer_text = JSON.stringify({value: `${pickup_point.ref}`, units: "LOCATION_REFERENCE"})
                } else if (meeting_point !== null && meeting_point.pick_up_type !== 'MANUAL') {
                    question.answer_text = JSON.stringify({value: `${meeting_point.ref}`, units: "LOCATION_REFERENCE"})
                } else if (manual_pickup !== null) {
                    question.answer_text = JSON.stringify({value: `${manual_pickup}`, units: "FREETEXT"})
                } else {
                    question.answer_text = JSON.stringify(question.answer_text)
                }
            } else if (question.question_code === "TRANSFER_ARRIVAL_MODE") {
                if (question.answer_text !== null && question.answer_text.value !== '') {
                    if (typeof question.answer_text !== 'string') {
                        question.answer_text = JSON.stringify(question.answer_text);
                    }
                } else {
                    question.answer_text = null;
                }
                if (typeof question.rules_answer !== 'string') {
                    question.rules_answer = JSON.stringify(question.rules_answer);
                }
            } else if (question.question_code === "TRANSFER_DEPARTURE_MODE") {
                if (question.answer_text !== null && question.answer_text.value !== '') {
                    if (typeof question.answer_text !== 'string') {
                        question.answer_text = JSON.stringify(question.answer_text);
                    }
                } else {
                    question.answer_text = null;
                }
                if (typeof question.rules_answer !== 'string') {
                    question.rules_answer = JSON.stringify(question.rules_answer);
                }
            } else {
                let question_rules_tojson = question.rules_answer !== null ? (typeof question.rules_answer === 'string' && isJson(question.rules_answer) ? JSON.parse(question.rules_answer) : question.rules_answer) : null;
                if (question.answer_text !== null && question.answer_text.value !== '') {
                    if (typeof question.answer_text !== 'string') {
                        question.answer_text = JSON.stringify(question.answer_text);
                    }
                } else {
                    question.answer_text = null;
                }
            }
        });
        let check_lead_traveler = false;
        if (product.group_passenger.lead_traveler !== null && lead_traveler !== undefined) {
            if (lead_traveler !== undefined && lead_traveler !== null && lead_traveler.email !== null && lead_traveler.email !== ''  && lead_traveler.phone_number !== null && lead_traveler.phone_number !== '') {
                check_lead_traveler = true;
            }
        }
        if (tmp_mandatory_question.length > 0) {
            setError(tmp_mandatory_question);
            return;
        } else {
            if (!check_lead_traveler && lead_traveler !== undefined && lead_traveler !== null) {
                if (lead_traveler !== undefined && lead_traveler !== null && (lead_traveler.email === null || lead_traveler.email === '')) {
                    tmp_mandatory_question.push("EMAIL");
                }
                if (lead_traveler !== undefined && (lead_traveler.phone_number === null || lead_traveler.phone_number === '')) {
                    tmp_mandatory_question.push("PHONE_NUMBER");
                }
                enqueueSnackbar(t<string>("cart-material.error-mail-phone"), {
                    variant: "error",
                });
                return;
            }
            setError(tmp_mandatory_question);
            setLoading(true);
            // tmp_questions.map((question: any) => {
            // if (question.answer_text) {
            //     question.answer_text = JSON.stringify(question.answer_text);
            // }
            // });
            let tmp_meeting_point = {
                start: null,
                end: null,
                location: null
            };
            if (meeting_point !== null) {
                tmp_meeting_point.location = Object.assign({}, meeting_point);
            }
            if (pickup_point !== null) {
                tmp_meeting_point.start = Object.assign({}, pickup_point);
            }
            if (return_point !== null) {
                tmp_meeting_point.end = Object.assign({}, return_point);
            }
            // let lead_traveler = tempTravelers.find((traveler: any) => traveler.id === product.group_passenger.lead_traveler);
            // let request = {
            //     email: lead_traveler.email !== "" ? lead_traveler.email : null,
            //     phone_country_code: lead_traveler.phone_country_code,
            //     phone_number: lead_traveler.phone_number,
            // };
            // Axios({
            //     method: "PATCH",
            //     url: `${API_HREF}client/${window.id_owner}/travelers/${product.group_passenger.lead_traveler.id}/`,
            //     headers: headers,
            //     data: JSON.stringify(request)
            // }).
            console.log('tmp_questions:', tmp_questions);
            Axios({
                method: 'PATCH',
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/custom-products/${product.id}/`,
                data: {
                    questions: tmp_questions,
                    meeting_point: JSON.stringify(tmp_meeting_point)
                }
            }).then(response => {
                setLoading(false);
                enqueueSnackbar(t<string>("cart-material.patch-success"), {
                    variant: "success",
                });
                console.log('activity response.data:', response.data);
                dispatch({type: 'MATERIAL_POI_CLOSE_QUESTIONS'});
                dispatch({type: 'POI_EDIT_CART_BY_ID', payload: response.data});
            }).catch(error => {
                setLoading(false);
                dispatch({type: 'MATERIAL_POI_CLOSE_QUESTIONS'});
                // check error not empty
                let tmp_error: any = [];
                if (error.response !== undefined && error.response.data !== undefined && error.response.data !== null && error.response.data.questions !== undefined) {
                    let tmp_errors = error.response.data.questions;
                    tmp_errors.map((error: any, index: number) => {
                        if (Object.keys(error).length > 0) {
                            let question_code =
                                tmp_error.push({
                                    error: error.answer_text,
                                    question_code: product.questions[index].question_code.toLowerCase(),
                                })
                        }
                    });
                }
                tmp_error.map((error: any) => {
                    tmp_mandatory_question.push(error.question_code);
                    enqueueSnackbar(`${error.error.join(' - ')}` , {
                        variant: "error",
                    });
                });
                setError(tmp_mandatory_question);
            });
        }
    }
    const handleEmail = (event: any) => {
        let tmp_travelers = [...tempTravelers];
        let tmp_traveler = tmp_travelers.find((traveler_tmp: any) => traveler_tmp.id === product.group_passenger.lead_traveler);
        if (tmp_traveler !== undefined) {
            tmp_traveler.email = event.target.value;
        }
        setTempTravelers(tmp_travelers);
    }
    const handlePhoneNumber = ((value: string, country_data: any) => {
        let tmp_travelers = [...tempTravelers];
        let tmp_traveler = tmp_travelers.find((traveler_tmp: any) => traveler_tmp.id === product.group_passenger.lead_traveler);
        if (tmp_traveler !== undefined) {
            tmp_traveler.temp_phone = value;
            // country_data has the country code
            tmp_traveler.phone_country_code = "+" + country_data.dialCode;
            tmp_traveler.phone_number = value.replace(`+${country_data.dialCode}`, "")
        }
        // replace tmp_traveler in tmp_travelers
        setTempTravelers(tmp_travelers);
    });
    console.log('error:', error);
    return (
        <Grid container direction={'column'}>
            {
                lead_traveler !== undefined && lead_traveler !== null &&
                <Grid container direction={'column'} spacing={2} style={{marginTop: 5}} alignItems={"flex-end"}>
                    <Grid item xs={6} style={{width: '100%'}}>
                        <FormControl fullWidth variant="outlined" className={classes.formControlRootSmall}>
                            <InputLabel ref={emailLabel} id={`email-label-${product.group_passenger.lead_traveler}`}>
                                {t<string>("global.email")}
                            </InputLabel>
                            <OutlinedInput
                                name="email"
                                id="email-input"
                                labelWidth={emailLabelWidth}
                                className={classes.circleBorder}
                                value={lead_traveler.email}
                                onChange={handleEmail}
                                autoComplete="off"
                                error={error.includes("EMAIL")}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} style={{width: '100%'}}>
                        <MuiPhoneNumber
                            defaultCountry={lead_traveler.nationality !== null && lead_traveler.nationality !== "" ? lead_traveler.nationality.country_code.toLowerCase() : (user?.lang !== null && user?.lang !== "en") ? user?.lang.split<string>('-')[0] : "gb" }
                            value={lead_traveler.temp_phone}
                            className={classes.formControlRootSmall}
                            onChange={(value: string, country_data: any) => {handlePhoneNumber(value, country_data)}}
                            variant="outlined"
                            label={t<string>("global.phone")}
                            InputProps={{ className: classes.circleBorder }}
                            disableAreaCodes
                            fullWidth
                        />
                    </Grid>
                </Grid>
            }
            <Grid item>
                {
                    // viatorLocation !== null && (viatorLocation.start.length > 0 || viatorLocation.end.length > 0 || viatorLocation.locations.length > 0) && questions.find(question => (question.question_code === 'TRANSFER_ARRIVAL_MODE' || question.question_code === 'TRANSFER_DEPARTURE_MODE')) === undefined && (
                    //     <ViatorLocation location={viatorLocation} type={null} questions={product.questions} departureMode={departureMode} arrivalMode={arrivalMode}/>
                    // )
                }
                {
                    questions.map((question: ViatorQuestion) => {
                        if (provider?.code === "civitatis") {
                            return (
                                <ActivitiesQuestionsItem key={`question-${question.id}`} question={question} product={product} error={error} editProduct={editProduct} location={viatorLocation} arrivalMode={arrivalMode} departureMode={departureMode}/>
                            );
                        }
                        let tmp_question = {...question};
                        let tmp_arrival_mode = arrivalMode !== null ? {...arrivalMode} : null;
                        let tmp_departure_mode = departureMode !== null ? {...departureMode} : null;
                        if (tmp_arrival_mode !== null && typeof tmp_arrival_mode.rules_answer === 'string') {
                            tmp_arrival_mode.rules_answer = JSON.parse(tmp_arrival_mode.rules_answer);
                        }
                        if (tmp_departure_mode !== null && typeof tmp_departure_mode.rules_answer === 'string') {
                            tmp_departure_mode.rules_answer = JSON.parse(tmp_departure_mode.rules_answer);
                        }
                        if (typeof tmp_question.answer_text === "string") {
                            tmp_question.answer_text = JSON.parse(tmp_question.answer_text);
                        }
                        if (!["AGEBAND", "FULL_NAMES_FIRST", "FULL_NAMES_LAST", "DATE_OF_BIRTH"].includes(question.question_code)) {
                            if (!["SPECIAL_REQUIREMENTS", "TRANSFER_DEPARTURE_MODE", "TRANSFER_DEPARTURE_DATE", "TRANSFER_ARRIVAL_MODE",
                                "TRANSFER_DEPARTURE_TIME", "TRANSFER_DEPARTURE_PICKUP", "TRANSFER_AIR_DEPARTURE_AIRLINE", "TRANSFER_AIR_DEPARTURE_FLIGHT_NO", "TRANSFER_DEPARTURE_TIME",
                                "TRANSFER_DEPARTURE_DROP_OFF", "TRANSFER_RAIL_DEPARTURE_LINE", "TRANSFER_RAIL_DEPARTURE_STATION", "TRANSFER_DEPARTURE_TIME", "TRANSFER_DEPARTURE_DROP_OFF",
                                "TRANSFER_PORT_DEPARTURE_TIME"].includes(question.question_code)) {
                                if (tmp_arrival_mode !== null) {
                                    if (tmp_arrival_mode.answer_text !== null) {
                                        // let tmp_answer = typeof tmp_arrival_mode.answer_text === "string" ? JSON.parse(tmp_arrival_mode.answer_text) : tmp_mode.answer_text.value;
                                        if (typeof tmp_arrival_mode.answer_text === "string") {
                                            tmp_arrival_mode.answer_text = JSON.parse(tmp_arrival_mode.answer_text);
                                        } else {
                                            tmp_arrival_mode.answer_text = tmp_arrival_mode.answer_text;
                                        }
                                        if (tmp_arrival_mode.answer_text.value === 'AIR' && ["TRANSFER_AIR_ARRIVAL_AIRLINE", "TRANSFER_AIR_ARRIVAL_FLIGHT_NO", "TRANSFER_ARRIVAL_TIME", "TRANSFER_ARRIVAL_DROP_OFF", "PICKUP_POINT"].includes(question.question_code)) {
                                            return (
                                                <ActivitiesQuestionsItem key={`question-${question.id}`} question={tmp_question} product={product} error={error} editProduct={editProduct} location={viatorLocation} arrivalMode={arrivalMode} departureMode={departureMode}/>
                                            )
                                        }
                                        if (tmp_arrival_mode.answer_text.value === 'RAIL' && ["TRANSFER_RAIL_ARRIVAL_LINE", "TRANSFER_RAIL_ARRIVAL_STATION", "TRANSFER_ARRIVAL_TIME", "TRANSFER_ARRIVAL_DROP_OFF", "PICKUP_POINT"].includes(question.question_code)) {
                                            return (
                                                <ActivitiesQuestionsItem key={`question-${question.id}`} question={tmp_question} product={product} error={error} editProduct={editProduct} location={viatorLocation} arrivalMode={arrivalMode} departureMode={departureMode}/>
                                            )
                                        }
                                        if (tmp_arrival_mode.answer_text.value === 'SEA' && ["TRANSFER_PORT_ARRIVAL_TIME", "TRANSFER_PORT_CRUISE_SHIP", "TRANSFER_ARRIVAL_DROP_OFF", "PICKUP_POINT"].includes(question.question_code)) {
                                            return (
                                                <ActivitiesQuestionsItem key={`question-${question.id}`} question={tmp_question} product={product} error={error} editProduct={editProduct} location={viatorLocation} arrivalMode={arrivalMode} departureMode={departureMode}/>
                                            )
                                        }
                                        if (tmp_arrival_mode.answer_text.value === 'OTHER' && ["PICKUP_POINT"].includes(question.question_code)) {
                                            return (
                                                <ActivitiesQuestionsItem key={`question-${question.id}`} question={tmp_question} product={product} error={error} editProduct={editProduct} location={viatorLocation} arrivalMode={arrivalMode} departureMode={departureMode}/>
                                            )
                                        }
                                        if (tmp_arrival_mode.answer_text.value === 'OTHER' && ["PICKUP_POINT"].includes(question.question_code)) {
                                            return (
                                                <ActivitiesQuestionsItem key={`question-${question.id}`} question={tmp_question} product={product} error={error} editProduct={editProduct} location={viatorLocation} arrivalMode={arrivalMode} departureMode={departureMode}/>
                                            )
                                        }
                                        // if (["PICKUP_POINT", "TRANSFER_ARRIVAL_PICKUP"].includes(question.question_code)) {
                                        //     return (
                                        //         <ActivitiesQuestionsItem key={`question-${question.id}`} question={tmp_question} product={product} error={error} editProduct={editProduct} location={viatorLocation} arrivalMode={arrivalMode} departureMode={departureMode}/>
                                        //     )
                                        // }
                                    }
                                } else {
                                    return (
                                        <ActivitiesQuestionsItem key={`question-${question.id}`} question={tmp_question} product={product} error={error} editProduct={editProduct} location={viatorLocation} arrivalMode={arrivalMode} departureMode={departureMode} />
                                    )
                                }
                            } else if (!["SPECIAL_REQUIREMENTS", "TRANSFER_DEPARTURE_MODE", "TRANSFER_ARRIVAL_MODE", "TRANSFER_ARRIVAL_TIME", "TRANSFER_ARRIVAL_DROP_OFF", "TRANSFER_PORT_CRUISE_SHIP"].includes(question.question_code)) {
                                if (tmp_departure_mode !== null) {
                                    if (tmp_departure_mode.answer_text !== null) {
                                        // let tmp_answer = typeof tmp_departure_mode.answer_text === "string" ? JSON.parse(tmp_departure_mode.answer_text) : tmp_mode.answer_text.value;
                                        if (typeof tmp_departure_mode.answer_text === "string") {
                                            tmp_departure_mode.answer_text = JSON.parse(tmp_departure_mode.answer_text);
                                        } else {
                                            tmp_departure_mode.answer_text = tmp_departure_mode.answer_text;
                                        }
                                        if (tmp_departure_mode.answer_text.value === 'AIR' && ["TRANSFER_AIR_DEPARTURE_AIRLINE", "TRANSFER_AIR_DEPARTURE_FLIGHT_NO", "TRANSFER_DEPARTURE_TIME", "TRANSFER_DEPARTURE_DROP_OFF", "PICKUP_POINT"].includes(question.question_code)) {
                                            return (
                                                <ActivitiesQuestionsItem key={`question-${question.id}`} question={tmp_question} product={product} error={error} editProduct={editProduct} location={viatorLocation} arrivalMode={arrivalMode} departureMode={departureMode}/>
                                            )
                                        }
                                        if (tmp_departure_mode.answer_text.value === 'RAIL' && ["TRANSFER_RAIL_DEPARTURE_LINE", "TRANSFER_RAIL_DEPARTURE_STATION", "TRANSFER_DEPARTURE_TIME", "TRANSFER_DEPARTURE_DROP_OFF"].includes(question.question_code)) {
                                            return (
                                                <ActivitiesQuestionsItem key={`question-${question.id}`} question={tmp_question} product={product} error={error} editProduct={editProduct} location={viatorLocation} arrivalMode={arrivalMode} departureMode={departureMode}/>
                                            )
                                        }
                                        if (tmp_departure_mode.answer_text.value === 'SEA' && ["TRANSFER_PORT_DEPARTURE_TIME", "TRANSFER_DEPARTURE_DROP_OFF", "PICKUP_POINT"].includes(question.question_code)) {
                                            return (
                                                <ActivitiesQuestionsItem key={`question-${question.id}`} question={tmp_question} product={product} error={error} editProduct={editProduct} location={viatorLocation} arrivalMode={arrivalMode} departureMode={departureMode}/>
                                            )
                                        }
                                        if (["TRANSFER_DEPARTURE_PICKUP"].includes(question.question_code)) {
                                            return (
                                                <ActivitiesQuestionsItem key={`question-${question.id}`} question={tmp_question} product={product} error={error} editProduct={editProduct} location={viatorLocation} arrivalMode={arrivalMode} departureMode={departureMode}/>
                                            )
                                        }
                                    }
                                } else {
                                    return (
                                        <ActivitiesQuestionsItem key={`question-${question.id}`} question={tmp_question} product={product} error={error} editProduct={editProduct} location={viatorLocation} arrivalMode={arrivalMode} departureMode={departureMode} />
                                    )                            }
                            } else {
                                return (
                                    <ActivitiesQuestionsItem key={`question-${question.id}`} question={tmp_question} product={product} error={error} editProduct={editProduct} location={viatorLocation} arrivalMode={arrivalMode} departureMode={departureMode} />
                                )
                            }
                        }
                    })
                }
            </Grid>
            {
                product.questions.find((question: ViatorQuestion) => question.rules_answer !== null) !== undefined &&
                <Grid item style={{position: 'relative', float: 'right'}}>
                    <Grid container direction={'row'} justify={'flex-end'}>
                        <Grid item>
                            <Button variant={'contained'} className={ classes.orangeButton} disabled={loading} onClick={patchActivity}>{t<string>('menu.invoice.save')}</Button>
                            {
                                loading &&
                                <CircularProgress size={24} className={ classes.buttonProgress }/>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}
const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});