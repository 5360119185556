import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { Alert, Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Close, OpenWith, Delete, LockOpen, Lock } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { uniq } from "lodash";
import axios from "axios";
import { ItineraryLockBoxDeleteModal } from "./itineraryLockBoxDeleteModal";
import { ItineraryContext } from "./utils/itineraryContext";
import { transformStepInputsToGroups } from "./utils/transformStepInputsToGroups";
import { getCircuit } from "./utils/getCircuit";
import { getCircuitPeriods } from "./utils/getCircuitPeriods";
import { isProductPackaged } from "../CartMaterial/utils/isProductPackaged";
import {
    makeDeletePackagePriceRequest,
    makeDeletePackageStackPriceRequest
} from "../CartMaterial/network/cartPackagesUpdate";
import { isProductSamePackage } from "../CartMaterial/utils/isProductSamePackage";
import GetCookie from "../Common/Functions/GetCookie";
import CheckBeforeRequest from "../Common/CheckBeforeRequest";
import { useItineraryTripUpdate } from "./network/itineraryTripUpdate";
import { useShowError } from "../Utils/showError";
import { useCircuitCorrespondingPricePeriod } from "./utils/circuitCorrespondingPricePeriod";
import { useCartProducts } from "./network/cartProducts";
import { useDeleteProduct } from "../CartMaterial/utils/deleteProduct";
import { deleteStepsBlock, setLoading, ungroupBlock } from "./redux/reducer";
import { SetDates, SetTripData } from "../../Actions/Trip";
import { Trip } from "../Menu/MaterialTripList/objects/trip";
import { ItineraryStepDragObject } from "./objects/itineraryStepDragObject";
import { ItineraryStepItemProps } from "./itineraryStepItem";
import { LockBox } from "./objects/lockBox";
import { AppState } from "../../Reducers/Reducers";

type Props = LockBox & {
    firstStep: ItineraryStepItemProps,
    onUpdatedTrip: (trip: Trip) => void
}

export function ItineraryLockBox(props: Props): JSX.Element {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const isUserTO = useSelector((state: AppState) => state.user.user?.client_full?.type !== 2);
    const tripVersion = useSelector((state: AppState) => state.trip.data_trip);
    const stepsInputs = useSelector((state: AppState) => state.itinerarySlice.stepsInputs);
    const trip = useSelector((state: AppState) => state.trip.all_data);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [hasNoPeriod, setHasNoPeriod] = useState(false);
    //eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [dragCollected, dragHandle, previewHandle] = useDrag(() => ({
        type: 'block',
        item: {
            drag: 'reorder',
            data: { ...props.firstStep, isBloc: true }
        } as ItineraryStepDragObject,
        collect(monitor) {
            return {
                isDragging: monitor.isDragging()
            };
        }
    }), [props]);
    const context = useContext(ItineraryContext);
    const showError = useShowError();
    const updateTrip = useItineraryTripUpdate({
        onTrigger() {
            dispatch(setLoading(true));
        },
        onSuccess(trip) {
            dispatch({
                type: "TRIP_SET_ALL_DATA",
                payload: { data: trip }
            });
            dispatch({
                type: "TRIP_SET_DATA_TRIP",
                payload: { data_trip: { ...tripVersion, trip, is_locked: trip.lock_itinerary } }
            });
            props.onUpdatedTrip(trip);
            enqueueSnackbar(
                t('itinerary.itinerary-unlock-success'),
                { variant: 'success' }
            );
        },
        onError(error) {
            showError(error);
        },
        onFinally() {
            dispatch(setLoading(false));
        }
    });
    const products = useCartProducts();
    const getCorrespondingPricePeriod = useCircuitCorrespondingPricePeriod();
    const {
        loading,
        onDeleteAccommodation,
        onDeleteAssistance,
        onDeleteCar,
        onDeleteFlight,
        onDeleteManualProduct,
        onDeletePoi,
        onDeleteTransfer
    } = useDeleteProduct();
    const block = useMemo(() => {
        return transformStepInputsToGroups(stepsInputs)[props.group] ?? [];
    }, [stepsInputs, props.group]);
    const isLocked = (
        props.firstStep.type === 'STEP' &&
        props.firstStep.locked
    );

    const onToggleItineraryLock = () => {
        if (props.type !== 'typical-trip') {
            if (props.circuit.trip?.id) {
                updateTrip(props.circuit.trip.id, { lockItinerary: !isLocked });
            }
        } else {
            updateTrip(props.trip.id, { lockItinerary: !isLocked });
        }
    };

    const onUngroupBlock = () => {
        dispatch(ungroupBlock({ group: props.group }));
    };

    const onDelete = async () => {
        const startDate = window.moment.utc(block[0]?.start_date);
        const endDate = window.moment.utc(block[block.length - 1]?.end_date);
        const accommodations = products.accommodations.filter((item) => {
            return window.moment.utc(item.accommodation.start_date).isBetween(
                startDate,
                endDate,
                'days',
                '[]'
            ) &&
                isProductPackaged({
                    product: item.accommodation,
                    stackInfos: tripVersion?.stack_info ?? null
                });
        });

        for (const item of accommodations) {
            if (item.type === 'normal') {
                await onDeleteAccommodation(item.accommodation);
            } else {
                await onDeleteManualProduct(item.accommodation);
            }
        }

        const cars = products.cars.filter((item) => {
            return window.moment.utc(item.car.start_date).isBetween(
                startDate,
                endDate,
                'minutes',
                '[]'
            ) &&
                isProductPackaged({
                    product: item.car,
                    stackInfos: tripVersion?.stack_info ?? null
                });
        });

        for (const item of cars) {
            if (item.type === 'normal') {
                await onDeleteCar(item.car);
            } else {
                await onDeleteManualProduct(item.car);
            }
        }

        const transfers = products.transfers.filter((item) => {
            return window.moment.utc(item.transfer.start_date).isBetween(
                startDate,
                endDate,
                'minutes',
                '[]'
            ) &&
                isProductPackaged({
                    product: item.transfer,
                    stackInfos: tripVersion?.stack_info ?? null
                });
        });

        for (const item of transfers) {
            if (item.type === 'normal') {
                await onDeleteTransfer(item.transfer);
            } else {
                await onDeleteManualProduct(item.transfer);
            }
        }

        const pois = products.pois.filter((item) => {
            return window.moment.utc(item.poi.start_date).isBetween(
                startDate,
                endDate,
                'minutes',
                '[]'
            ) &&
                isProductPackaged({
                    product: item.poi,
                    stackInfos: tripVersion?.stack_info ?? null
                });
        });

        for (const item of pois) {
            if (item.type === 'normal') {
                await onDeletePoi(item.poi);
            } else {
                await onDeleteManualProduct(item.poi);
            }
        }

        const flights = products.flights.filter((item) => {
            return window.moment.utc(item.flight.start_date).isBetween(
                startDate,
                endDate,
                'minutes',
                '[]'
            ) &&
                isProductPackaged({
                    product: item.flight,
                    stackInfos: tripVersion?.stack_info ?? null
                });
        });

        for (const item of flights) {
            if (item.type === 'normal') {
                await onDeleteFlight(item.flight);
            } else {
                await onDeleteManualProduct(item.flight);
            }
        }

        const assistances = products.assistances.filter((item) => {
            return window.moment.utc(item.assistance.start_date).isBetween(
                startDate,
                endDate,
                'minutes',
                '[]'
            ) &&
                isProductPackaged({
                    product: item.assistance,
                    stackInfos: tripVersion?.stack_info ?? null
                });
        });

        for (const item of assistances) {
            if (item.type === 'normal') {
                await onDeleteAssistance(item.assistance);
            } else {
                await onDeleteManualProduct(item.assistance);
            }
        }

        const cruises = products.cruises.filter((item) => {
            return window.moment.utc(item.cruise.start_date).isBetween(
                startDate,
                endDate,
                'minutes',
                '[]'
            ) &&
                isProductPackaged({
                    product: item.cruise,
                    stackInfos: tripVersion?.stack_info ?? null
                });
        });

        for (const item of cruises) {
            await onDeleteManualProduct(item.cruise);
        }


        const ferries = products.ferries.filter((item) => {
            return window.moment.utc(item.ferry.start_date).isBetween(
                startDate,
                endDate,
                'minutes',
                '[]'
            ) &&
                isProductPackaged({
                    product: item.ferry,
                    stackInfos: tripVersion?.stack_info ?? null
                });
        });

        for (const item of ferries) {
            await onDeleteManualProduct(item.ferry);
        }

        const trains = products.trains.filter((item) => {
            return window.moment.utc(item.train.start_date).isBetween(
                startDate,
                endDate,
                'minutes',
                '[]'
            ) &&
                isProductPackaged({
                    product: item.train,
                    stackInfos: tripVersion?.stack_info ?? null
                });
        });

        for (const item of trains) {
            await onDeleteManualProduct(item.train);
        }

        const blockInfo = accommodations.map((item) => ({
            stackNumber: item.accommodation.stack_number,
            stackInfoId: item.accommodation.stack_info_id
        })).concat(
            cars.map((item) => ({
                stackNumber: item.car.stack_number,
                stackInfoId: item.car.stack_info_id
            }))
        ).concat(
            transfers.map((item) => ({
                stackNumber: item.transfer.stack_number,
                stackInfoId: item.transfer.stack_info_id
            }))
        ).concat(
            pois.map((item) => ({
                stackNumber: item.poi.stack_number,
                stackInfoId: item.poi.stack_info_id
            }))
        ).concat(
            flights.map((item) => ({
                stackNumber: item.flight.stack_number,
                stackInfoId: item.flight.stack_info_id
            }))
        ).concat(
            assistances.map((item) => ({
                stackNumber: item.assistance.stack_number,
                stackInfoId: item.assistance.stack_info_id
            }))
        ).concat(
            cruises.map((item) => ({
                stackNumber: item.cruise.stack_number,
                stackInfoId: item.cruise.stack_info_id
            }))
        ).concat(
            ferries.map((item) => ({
                stackNumber: item.ferry.stack_number,
                stackInfoId: item.ferry.stack_info_id
            }))
        ).concat(
            trains.map((item) => ({
                stackNumber: item.train.stack_number,
                stackInfoId: item.train.stack_info_id
            }))
        )[0];

        const stackPricePromise = makeDeletePackageStackPriceRequest({
            tripId: parseInt(GetCookie('trip_id') ?? '0'),
            version: parseInt(GetCookie('trip_id_version') ?? '0'),
        });

        const { headers } = CheckBeforeRequest();

        if (blockInfo) {
            const terrestrialPrices = tripVersion?.prices_terrestrial?.filter((item) => {
                return isProductSamePackage(
                    {
                        stack_info_id: blockInfo.stackInfoId,
                        stack_number: blockInfo.stackNumber
                    },
                    item
                );
            });
            const flightPrices = tripVersion?.prices_flight?.filter((item) => {
                return isProductSamePackage(
                    {
                        stack_info_id: blockInfo.stackInfoId,
                        stack_number: blockInfo.stackNumber
                    },
                    item
                );
            });
            const providers = uniq(
                (terrestrialPrices ?? []).map((item) => {
                    return item.provider;
                }).concat(
                    flightPrices?.map((item) => {
                        return item.provider;
                    }) ?? []
                )
            );

            try {
                await Promise.all(
                    providers.map((provider) => {
                        return makeDeletePackagePriceRequest({
                            tripId: parseInt(GetCookie('trip_id') ?? '0'),
                            version: parseInt(GetCookie('trip_id_version') ?? '0'),
                            package: {
                                provider,
                                stackInfoId: blockInfo.stackInfoId,
                                stackNumber: blockInfo.stackNumber
                            }
                        });
                    }).concat(stackPricePromise)
                );
            } catch (error) {
                showError(error as Error);
            }

            // delete stack info
            await axios.patch(
                `${API_HREF}client/${window.id_owner}/trip/${GetCookie('trip_id')}/versions/${GetCookie('trip_id_version')}/`,
                {
                    stack_info: tripVersion?.stack_info?.filter((item) => {
                        return item.id !== blockInfo.stackInfoId || item.stack_number !== blockInfo.stackNumber;
                    }).map((item) => ({
                        id: item.id
                    }))
                },
                { headers }
            );
        }

        try {
            await stackPricePromise;
        } catch (error) {
            console.error(error);
        }

        // refresh trip version
        try {
            const tripVersionResponse = await axios.get(
                `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/?token=${GetCookie("trip_token")}`,
                { headers }
            );
            const data = tripVersionResponse.data;
            console.log(data);
            /*
            if (parseInt(tripInfo?.tripVersion ?? GetCookie("trip_id_version")) !== data.trip.current_version) {
                //TODO: notify user that he is not fetching the current version of the trip and ask him if he want to proceed or access the current version
            } else {
            */
            dispatch(SetDates({
                startDate: data.start_date,
                endDate: data.end_date
            }));
            console.log('data.travelers:', data.travelers);

            $.each(data.travelers, function (_, value_travelers: any) {
                let age = window.moment.utc(data.end_date).diff(window.moment.utc(value_travelers.birth_date), "y");
                if (age >= 12) {
                    value_travelers.flight_type = "ADT";
                } else if (age >= 2) {
                    value_travelers.flight_type = "CNN";
                } else {
                    value_travelers.flight_type = "INF";
                }
                value_travelers.passenger_group = null;
            });
            for (let i = 0; i < data.prices.length; i++) {
                if (data.prices[i].selling_price === undefined) {
                    data.prices.splice(i, 1);
                    i--;
                }
            }
            for (let i = 0; i < data.prices_flight.length; i++) {
                if (data.prices_flight[i].selling_price === undefined) {
                    data.prices_flight.splice(i, 1);
                    i--;
                }
            }
            for (let i = 0; i < data.prices_stack_product.length; i++) {
                if (data.prices_stack_product[i].selling_price === undefined) {
                    data.prices_stack_product.splice(i, 1);
                    i--;
                }
            }
            for (let i = 0; i < data.prices_terrestrial.length; i++) {
                if (data.prices_terrestrial[i].selling_price === undefined) {
                    data.prices_terrestrial.splice(i, 1);
                    i--;
                }
            }
            dispatch(SetTripData(data, data.travelers, (data.budget === null ? 0 : parseInt(data.budget)), data.trip.currency, data.contact_traveler, data.contact_traveler_detail, data.trip.trip_reference));
        } catch (error) {
            console.error(error);
        }

        dispatch(deleteStepsBlock({ group: props.group }));
    };

    useEffect(() => {
        previewHandle(getEmptyImage(), { captureDraggingState: false });
    }, []);

    useEffect(() => {
        if (
            !trip?.circuit_type &&
            !trip?.package_type &&
            (
                props.type === 'circuit' ||
                props.type === 'package'
            ) &&
            block[0]
        ) {
            (async () => {
                try {
                    const circuit = await getCircuit(props.circuit.id);
                    const periods = await getCircuitPeriods(props.circuit.id);
                    if (
                        circuit?.forfait_price === false &&
                        !block[0]?.circuit_start_date &&
                        periods.length > 0
                    ) {
                        const period = await getCorrespondingPricePeriod({
                            circuitId: props.circuit.id,
                            circuitVersion: props.circuitVersion,
                            circuitVariants: props.circuit?.variant_name.filter((item) => {
                                return item.version === props.circuitVersion;
                            }).map((item) => {
                                return item.id;
                            }),
                            periods,
                            circuitDate: block[0]?.circuit_start_date ?? 'PERIOD',
                            step: block[0]!,
                            block
                        });
                        setHasNoPeriod(!period);
                    }
                } catch (error) {
                    showError(error as Error);
                }
            })();
        }
    }, [
        props.type,
        props.type === 'circuit' ||
            props.type === 'package' ?
            props.circuit :
            null,
        props.type === 'circuit' ||
            props.type === 'package' ?
            props.circuitVersion :
            null,
        block,
        getCorrespondingPricePeriod
    ]);

    return (
        <>
            <Box
                key={
                    props.type !== 'typical-trip' ?
                        `${props.type}-${props.circuit.id}` :
                        `${props.type}-${props.trip.id}`
                }
                sx={(theme) => ({
                    "width": 'calc(100% - 15px)',
                    "height": props.height,
                    "position": 'absolute',
                    "top": props.top,
                    "left": 0,
                    "border": `1px solid ${hasNoPeriod ? theme.palette.error.main : '#2C98F0'}`,
                    "transform": 'translateX(7.5px)',
                    "display": context.isDragging ? 'none' : 'block',
                    "pointerEvents": hasNoPeriod ? 'all' : 'none',
                    "cursor": hasNoPeriod ? 'not-allowed' : 'auto',
                    '.block-dates-error': {
                        opacity: 0
                    },
                    '&:hover .block-dates-error': {
                        opacity: 1
                    }
                })}
            >
                {
                    hasNoPeriod &&
                    <Alert severity="error" className="block-dates-error">
                        {t('itinerary.add-block-dates-error')}
                    </Alert>
                }
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={(theme) => ({
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        transform: 'translateY(-100%)',
                        zIndex: theme.zIndex.tooltip + 1,
                        width: '100%'
                    })}
                    spacing={0.5}
                >
                    <Tooltip
                        title={t('itinerary.drag-to-reorder')}
                        disableHoverListener={context.isDragging}
                    >
                        <IconButton
                            size="small"
                            ref={dragHandle}
                            sx={{
                                color: '#2C98F0',
                                cursor: 'move',
                                pointerEvents: 'auto'
                            }}
                            disableRipple
                        >
                            <OpenWith color="inherit" sx={{ fontSize: 16 }} />
                        </IconButton>
                    </Tooltip>
                    <Typography
                        variant="caption"
                        color="#2C98F0"
                        sx={{
                            marginLeft: 'auto !important',
                            width: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            textAlign: 'right'
                        }}
                    >
                        {
                            props.type !== 'typical-trip' ?
                                props.circuit.localization?.find((item) => {
                                    return item.locale === locale;
                                })?.name ?? props.circuit.name :
                                props.trip.name
                        }
                    </Typography>
                    {
                        (
                            !isLocked ||
                            (
                                props.type !== 'typical-trip' &&
                                props.circuit.source !== 'OFFLINE'
                            )
                        ) &&
                        <Tooltip title={t('itinerary.delete-block')}>
                            <IconButton
                                size="small"
                                sx={{
                                    color: '#2C98F0',
                                    margin: '0 !important',
                                    padding: 0,
                                    paddingBottom: '5px',
                                    pointerEvents: 'auto'
                                }}
                                onClick={() => setOpenDeleteModal(true)}
                                disableRipple
                            >
                                <Delete color="inherit" sx={{ fontSize: 16 }} />
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        isUserTO &&
                        (
                            props.type === 'typical-trip' ||
                            props.circuit.source === 'OFFLINE'
                        ) &&
                        <Tooltip
                            title={
                                isLocked ?
                                    t('itinerary.unlock') :
                                    t('itinerary.lock')
                            }
                        >
                            <IconButton
                                size="small"
                                sx={{
                                    color: '#2C98F0',
                                    margin: '0 !important',
                                    padding: 0,
                                    paddingBottom: '5px',
                                    pointerEvents: 'auto'
                                }}
                                onClick={onToggleItineraryLock}
                            >
                                {
                                    isLocked &&
                                    <Lock
                                        fontSize="inherit"
                                        color="inherit"
                                        sx={{ fontSize: 16 }}
                                    />
                                }
                                {
                                    !isLocked &&
                                    <LockOpen
                                        fontSize="inherit"
                                        color="inherit"
                                        sx={{ fontSize: 16 }}
                                    />
                                }
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        !isLocked && isUserTO &&
                        <Tooltip title={t('itinerary.remove-lock-box')}>
                            <IconButton
                                size="small"
                                sx={{
                                    color: '#2C98F0',
                                    margin: '0 !important',
                                    padding: 0,
                                    paddingBottom: '5px',
                                    pointerEvents: 'auto'
                                }}
                                onClick={onUngroupBlock}
                            >
                                <Close
                                    fontSize="inherit"
                                    color="inherit"
                                    sx={{ fontSize: 16 }}
                                />
                            </IconButton>
                        </Tooltip>
                    }
                </Stack>
            </Box>
            {
                openDeleteModal &&
                <ItineraryLockBoxDeleteModal
                    loading={loading}
                    onDelete={onDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            }
        </>
    );
}
