import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    Alert,
    Box,
    Chip,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography
} from "@mui/material";
import { Moment } from "moment";
import { differenceInCalendarYears } from 'date-fns';
import { getCircuit } from "../Itinerary/utils/getCircuit";
import { useGetAdalteRoomTypes } from "./Functions/getAdalteRoomTypes";
import { AdalteRoomTypes } from "./objects/AdalteRoomTypes";
import { Traveler } from "../../Reducers/objects/Traveler";
import { Circuit } from "../Itinerary/objects/circuit";
import { AppState } from "../../Reducers/Reducers";

export type SelectedRoom = AdalteRoomTypes['rooms'][number]['options'][number] & {
    index: string,
    pickup_code: string | null,
    code: string | null,
    chosen_language_code: string,
    group_passenger?: number
}

export type AdaltePackageData = {
    stackInfoId?: number,
    circuitId: number,
    version: number,
    startDate: Moment,
    pickups?: string,
    languageCode?: string,
    rooms: SelectedRoom[],
    errors: {
        rooms: {
            [index: number]: string
        },
        pickup?: string,
        language?: string,
    },
}

type Props = {
    index: number,
    value: AdaltePackageData,
    adultTravelers: Traveler[] | null,
    childTravelers: Traveler[] | null,
    groups: {
        id?: number,
        travelers: number[],
        travelers_list: Traveler[]
    }[] | null,
    onChange: React.Dispatch<React.SetStateAction<AdaltePackageData[]>>,
    includeAge: boolean,
    forVersionUpdate?: boolean
}

export function RoomTypePicker(props: Props): JSX.Element {
    const { t, i18n } = useTranslation();
    const locale = useSelector((state: AppState) => state.user.locales?.find((item) => {
        return item.language_code === i18n.language;
    })?.id ?? 1);
    const [circuit, setCircuit] = useState<Circuit | null>(null);
    console.log("props.groups : ", props.groups);
    const options = useMemo((): Parameters<typeof useGetAdalteRoomTypes>[0] => {
        return {
            forVersionUpdate: props.forVersionUpdate ?? false,
            version: props.value.version,
            groups: props.groups?.map((group) => {
                return group.travelers_list.map((traveler) => {
                    return traveler.birth_date ?
                        differenceInCalendarYears(new Date(), new Date(traveler.birth_date)) :
                        30;
                });
            }) ?? [],
            startDate: props.value.startDate.format('YYYY-MM-DD') ?? '',
            skip: !props.groups || !props.value.startDate
        };
    }, [
        props.forVersionUpdate,
        props.value.version,
        props.adultTravelers,
        props.groups,
        props.value.startDate
    ]);
    const fetchedRoomTypes = useGetAdalteRoomTypes(options);
    const pickups = useMemo(() => {
        return fetchedRoomTypes?.pickups;
    }, [fetchedRoomTypes]);


    useEffect(() => {
        (async () => {
            setCircuit(await getCircuit(props.value.circuitId));
        })();
    }, [props.value.circuitId]);

    return (
        <Box sx={{ textAlign: 'left' }}>
            <Typography
                variant="h6"
                color="GrayText"
                fontWeight={700}
                component="div"
                gutterBottom
            >
                {t('home.modal.room-type-choice')}
            </Typography>
            {
                (fetchedRoomTypes?.rooms?.length ?? 0) === 0 &&
                <Alert severity="warning">
                    {t('home.modal.room-type-choice-no-offers-hint')}
                </Alert>
            }
            {
                (fetchedRoomTypes?.rooms?.length ?? 0) > 0 &&
                <Stack alignItems="flex-start" spacing={2}>
                    {
                        props.groups?.map((group, index) => {
                            const types = fetchedRoomTypes!.rooms.find((item) => {
                                return parseInt(item.index) === index + 1;
                            });
                            const rooms = types?.options;
                            return (
                                <Stack
                                    key={props.value.rooms[index]?.pickup_code ?? index}
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <FormControl
                                        size="small"
                                        sx={{ maxWidth: 'unset' }}
                                    >
                                        <InputLabel shrink>
                                            {t('home.modal.room-type-choice-room-no', { no: index + 1 })}
                                        </InputLabel>
                                        <Select
                                            value={
                                                props.value.rooms[index]?.code ??
                                                ''
                                            }
                                            label={t('home.modal.room-type-choice-room-no', { no: index + 1 })}
                                            renderValue={(selected) => {
                                                const code = selected as string;

                                                if (!code) {
                                                    return <em>{t('home.modal.room-type-choice-hint')}</em>;
                                                }

                                                return rooms?.find((room) => {
                                                    return room.code === code;
                                                })?.name;
                                            }}
                                            MenuProps={{
                                                disablePortal: true
                                            }}
                                            onChange={(event) => {
                                                const room = rooms?.find((item) => {
                                                    return item.code === event.target.value;
                                                });

                                                if (room) {
                                                    props.onChange((state) => {
                                                        return state.map((item, packageIndex) => {
                                                            if (props.index === packageIndex) {
                                                                return {
                                                                    ...item,
                                                                    errors: {
                                                                        ...item.errors,
                                                                        rooms: {
                                                                            ...item.errors.rooms,
                                                                            [index]: ''
                                                                        }
                                                                    }
                                                                };
                                                            }
                                                            return item;
                                                        })
                                                    });
                                                }

                                                props.onChange((state) => {
                                                    return state.map((item, packageIndex) => {
                                                        if (props.index === packageIndex) {
                                                            return {
                                                                ...item,
                                                                rooms: [
                                                                    ...item.rooms.slice(0, index),
                                                                    room ?
                                                                        {
                                                                            ...room,
                                                                            index: types!.index,
                                                                            pickup_code: props.value.pickups ?? null,
                                                                            chosen_language_code: props.value.languageCode ?? '',
                                                                            group_passenger: group.id,
                                                                            travelers_age: props.includeAge ?
                                                                                group.travelers_list.map((traveler) => {
                                                                                    return window.moment.utc().diff(
                                                                                        window.moment.utc(traveler.birth_date, 'YYYY-MM-DD'),
                                                                                        'years'
                                                                                    );
                                                                                }) :
                                                                                undefined
                                                                        } :
                                                                        null,
                                                                    ...item.rooms.slice(index + 1)
                                                                ].filter((item): item is NonNullable<typeof item> => {
                                                                    return !!item;
                                                                })
                                                            }
                                                        }
                                                        return item;
                                                    })
                                                });
                                            }}
                                            error={!!props.value.errors.rooms[index]}
                                            notched
                                            displayEmpty
                                        >
                                            {
                                                rooms?.map((room) => (
                                                    <MenuItem key={room.name} value={room.code}>
                                                        {room.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                        {
                                            props.value.errors.rooms[index] &&
                                            <FormHelperText error>
                                                {props.value.errors.rooms[index]}
                                            </FormHelperText>
                                        }
                                    </FormControl>
                                    {
                                        props.value.rooms[index] &&
                                        <>
                                            <Typography>
                                                {
                                                    t(
                                                        'home.modal.room-type-price',
                                                        {
                                                            price: new Intl.NumberFormat(
                                                                i18n.language,
                                                                {
                                                                    style: 'currency',
                                                                    currency: props.value.rooms[index]?.currency ?? 'EUR'
                                                                }
                                                            ).format(props.value.rooms[index]?.price ?? 0)
                                                        }
                                                    )
                                                }
                                            </Typography>
                                            {
                                                props.value.rooms[index]?.on_demand === 'true' &&
                                                <Chip
                                                    label={t('shared.stock-type-on-demand')}
                                                />
                                            }
                                        </>
                                    }
                                </Stack>
                            );
                        })
                    }
                </Stack>
            }
            <Typography
                variant="h6"
                color="GrayText"
                fontWeight={700}
                component="div"
                sx={{ marginTop: 1.5 }}
                gutterBottom
            >
                {t('home.modal.package-language-choice')}
            </Typography>
            {
                circuit?.guide_languages.length === 0 &&
                <Alert severity="warning">
                    {t('home.modal.package-no-language-warning')}
                </Alert>
            }
            {
                (circuit?.guide_languages.length ?? 0) > 0 &&
                <FormControl
                    size="small"
                    sx={{ minWidth: 175 }}
                >
                    <Select
                        value={
                            props.value.languageCode ?? ''
                        }
                        MenuProps={{
                            disablePortal: true
                        }}
                        onChange={(event) => {
                            props.onChange((state) => {
                                return state.map((item, packageIndex) => {
                                    if (props.index === packageIndex) {
                                        return {
                                            ...item,
                                            rooms: item.rooms.map((item) => ({
                                                ...item,
                                                chosen_language_code: event.target.value
                                            })),
                                            languageCode: event.target.value
                                        };
                                    }
                                    return item;
                                })
                            });
                        }}
                        error={!!props.value.errors.language}
                        notched
                        displayEmpty
                    >
                        {
                            circuit?.guide_languages.map((language) => (
                                <MenuItem key={language.id} value={language.language_code}>
                                    {
                                        language.LocalTranslation?.find((item) => {
                                            return item.localeTranslate === locale;
                                        })?.translated_name ??
                                        language.full_name
                                    }
                                </MenuItem>
                            ))
                        }
                    </Select>
                    {
                        props.value.errors.language &&
                        <FormHelperText error>
                            {props.value.errors.language}
                        </FormHelperText>
                    }
                </FormControl>
            }
            <Typography
                variant="h6"
                color="GrayText"
                fontWeight={700}
                component="div"
                sx={{ marginTop: 1.5 }}
                gutterBottom
            >
                {t('home.modal.package-pickup-choice')}
            </Typography>
            {
                pickups && pickups.length > 0 &&
                <FormControl
                    size="small"
                    sx={{ minWidth: 175, maxWidth: 175 }}
                >
                    <Select
                        value={
                            props.value.pickups ?? ''
                        }
                        MenuProps={{
                            disablePortal: true
                        }}
                        onChange={(event) => {
                            props.onChange((state) => {
                                return state.map((item, packageIndex) => {
                                    if (props.index === packageIndex) {
                                        return {
                                            ...item,
                                            rooms: item.rooms.map((item) => ({
                                                ...item,
                                                pickup_code: event.target.value
                                            })),
                                            pickups: event.target.value
                                        };
                                    }
                                    return item;
                                })
                            });
                        }}
                        error={!!props.value.errors.pickup}
                        notched
                        displayEmpty
                    >
                        {
                            pickups.map((pickup) => (
                                <MenuItem key={pickup.code} value={pickup.code}>
                                    {
                                        [
                                            pickup.name,
                                            pickup.city
                                        ].filter((item) => !!item).join(', ')
                                    }
                                </MenuItem>
                            ))
                        }
                    </Select>
                    {
                        props.value.errors.pickup &&
                        <FormHelperText error>
                            {props.value.errors.pickup}
                        </FormHelperText>
                    }
                </FormControl>
            }
        </Box>
    );
}
