import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";
import React, { Fragment, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import ReactDOMServer from 'react-dom/server';
import Workbook from "react-excel-workbook";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import XLSX from 'xlsx';

import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import ConvertPrice from "../../Cart/Functions/Manual/ConvertPrice.js";
import GetPricesId from "../../Cart/Functions/Margin/GetPricesId.js";
import GetAllCart from "../TripList/Functions/GetAllCart";
import CheckCustomCurrency from "./Functions/CheckCustomCurrency.js";
import GetMargin from "./Functions/GetMargin.js";
import CheckBeforeRequest from "../../Common/CheckBeforeRequest";
import RenderItem from "./RenderItem";
import { RenderPackage } from "./RenderPackage.tsx";

import { SetCartDone } from "../../../Actions/Menu";
import GetProvider from "../../Common/Functions/GetProvider";
import GetProductType from "../TripList/Functions/GetProductType";
import GetCookie from "../../Common/Functions/GetCookie.js";
import { useGetTaxesPrice } from "../../CartMaterial/utils/getTaxesPrice";
import { usePackagedProducts } from "../../CartMaterial/utils/packagedProducts";
import { isProductPackaged } from "../../CartMaterial/utils/isProductPackaged";
import { isProductSamePackage } from "../../CartMaterial/utils/isProductSamePackage.ts";
import { isNumber, isString } from 'lodash';
import { useGetPrice } from "../../CartMaterial/utils/getPrice.ts";

const useStyles = makeStyles({
    padding60: {
        padding: 60
    },
    marginTop20: {
        marginTop: 20
    },
    genericText: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        color: "#0000008A"
    },
    textAlightRight: {
        textAlign: "right"
    },
    bold: {
        fontWeight: "bold"
    },
    paddingCell: {
        "& .mui-jss-MuiTableCell-root": {
            fontSize: 12
        }
    },
    csvButton: {
        "color": "#3f51b5 !important",
        "border": "1px solid rgba(63, 81, 181, 0.5)",
        "padding": "8px 13px",
        "fontFamily": "Roboto",
        "fontStyle": "normal",
        "borderRadius": 5,
        "cursor": "pointer",
        "&:hover": {
            border: "1px solid #3f51b5",
            backgroundColor: "rgba(63, 81, 181, 0.04)",
            textDecoration: "none"
        }
    },
    fontSize11: {
        fontSize: 11
    }
});

const TripListFinance = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const downDesktop = useMediaQuery(theme.breakpoints.down('sm'));
    const getPrice = useGetPrice();

    const packagedProducts = usePackagedProducts();
    const trip_info = useSelector(store => store.menu.trip_info);
    const user = useSelector(store => store.user.user);
    const providers = useSelector(store => store.trip.providers);
    const manual_providers = useSelector(store => store.trip.manual_providers);
    const list_currency = useSelector(store => store.base.currency_list);
    const currency = useSelector(store => store.trip.currency);
    const language = useSelector(store => store.header.tmp_language);
    const get_cart_done = useSelector(store => store.menu.get_cart_done);
    const big_cart = useSelector(store => store.menu.big_cart);
    const trip_start_date = useSelector(store => store.trip.start_date);
    const trip_end_date = useSelector(store => store.trip.end_date);
    const to_show_version = useSelector(store => store.menu.to_show_version);
    const manual_cart = useSelector(store => store.trip.manual_cart);
    const data_trip = useSelector(store => store.trip.data_trip);

    const owner_id = useState(user ? user.client : null);
    const [get_total_price, setGetTotalPrice] = useState(false);
    const [total_price, setTotalPrice] = useState(0);
    const [full_buying_price, setFullBuyingPrice] = useState(0);
    const [only_main_currency_price, setOnlyMainCurrencyPrice] = useState(0);
    const [not_same_currency_cart, setNotSameCurrencyCart] = useState([]);
    const [to_margin, setToMargin] = useState(0);
    const [total_surcom, setTotalSurcom] = useState(0);
    const [total_network, setTotalNetwork] = useState(0);
    const [total_margin, setTotalMargin] = useState(0);
    const [total_margin_agency, setTotalMarginAgency] = useState(0);
    const [cashBack, setCashBack] = useState(0);
    const [exportData, setExportData] = useState([]);
    const [exportDataCSV, setExportDataCSV] = useState([]);
    const [big_total_margin_agency, setBigtotalMarginAgency] = useState(0);
    const [total_taxe, setTotalTaxe] = useState(0);
    const [custom_currencies, setCustomCurrency] = useState(null);
    const [has_custom_currency, setHasCustomCurrency] = useState(false);
    const [custom_factor, setCustomFactor] = useState(null);
    const [currency_base, setCurrencyBase] = useState(null);
    const [tvaValue, setTvaValue] = useState(0);
    const getTaxesPrice = useGetTaxesPrice();
    const columns = [t("global.date"), t("menu.trip_list.product"), t("filters.providers"), t("global.description"), t("menu.trip_list.reference"), t("cart.edit_booking.booking_status"), t("menu.trip_list.amount_purchase"), t("global.in"), t("menu.trip_list.rate"), t("menu.trip_list.amount"), t("global.in"), t("menu.trip_list.amount_selling"), t("global.in")];

    const quotation_code = JSON.parse(localStorage.getItem("config")).quotation_code;
    const id = trip_info.trip_reference;
    useEffect(() => {
        let version = to_show_version !== null ? to_show_version : trip_info.current_version;
        GetAllCart(dispatch, trip_info.id, version, user);
    }, [to_show_version]);
    useEffect(() => {
        if (get_cart_done) {
            //if (user.client_full.type !== 2) {
            //setData();
            //}
            let tmp_only_main_currency_price = 0;
            let tmp_full_price = 0;
            let tmp_full_buying_price = 0;
            let total_surcom = 0;
            let to_margin = 0;
            let total_network = 0;
            let total_taxe = 0;
            let tmp_cash_back = 0;
            let tva = 0;
            let tmp_not_same_currency_cart = [];
            if (big_cart !== null) {
                for (let i = 0; i < big_cart.length; i++) {
                    if (!isProductPackaged({ product: big_cart[i], stackInfos: data_trip?.stack_info ?? null }) && !big_cart[i].is_optional && !big_cart[i].is_hidden_for_traveler && big_cart[i].is_available && (big_cart[i].booking_status === null || big_cart[i].booking_status.status_booking !== 'CANCELLED' || big_cart[i].booking_process_state === "CANCEL")) {
                        let price = big_cart[i].prices.find((price) => price.is_tva);
                        if (price === undefined) {
                            price = big_cart[i].prices.find((price) => price.master_price);
                        } else if (price.is_tva) {
                            tva += parseFloat(price.selling_price) - parseFloat(price.purchase_price);
                        }
                        if (price === undefined) {
                            price = big_cart[i].prices.find((price) => !price.master_price && !price.is_commissioned && !price.is_network && !price.is_surcom);
                        }
                        tmp_full_price += parseFloat(price.selling_price);
                        if (big_cart[i].product_type === 6) {
                            const provider = providers.concat(manual_providers).find((item) => {
                                return item.provider.id === big_cart[i].provider;
                            })?.provider;
                            let seatsCost = 0;
                            let option_cost = 0;
                            let otherOptionsCost = 0;
                            let option_on_flight = [];
                            if (provider?.code !== 'amadeus_rest') {
                                let outbounds = big_cart[i].segments || big_cart[i].outbounds || [];
                                outbounds.map(outbound => {
                                    let option_on_group = [];
                                    if (outbound.legs !== undefined) {
                                        outbound.legs.map(leg => {
                                            for (const seat of leg.seats ?? []) {
                                                let seatPrice = 0;
                                                if ((seat.prices?.length ?? 0) > 0) {
                                                    seatPrice = getPrice(seat.prices).cost;
                                                } else {
                                                    seatPrice = parseFloat(seat.price ?? '0');
                                                }
                                                seatsCost += seatPrice;
                                            }
                        
                                            if (leg.paid_options && leg.paid_options.length > 0) {
                                                leg.paid_options.map(option => {
                                                    let optionPrice = 0;
                                                    if ((option.prices?.length ?? 0) > 0) {
                                                        optionPrice = getPrice(option.prices).cost;
                                                    } else {
                                                        optionPrice = parseFloat(option.price);
                                                    }
                                                    if (option.option_on_flight) {
                                                        if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                            option_cost += optionPrice;
                                                            option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                                        }
                                                    } else if (option.option_on_group) {
                                                        if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                            option_cost += optionPrice;
                                                            option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                                        }
                                                    } else {
                                                        option_cost += optionPrice;
                                                    }
                                                }); 
                                            }
                                        });
                                    } else {
                                        for (const seat of outbound.seats ?? []) {
                                            let seatPrice = 0;
                                            if ((seat.prices?.length ?? 0) > 0) {
                                                seatPrice = getPrice(seat.prices).cost;
                                            } else {
                                                seatPrice = parseFloat(seat.price ?? '0');
                                            }
                                            seatsCost += seatPrice;
                                        }

                                        if (outbound.paid_options && outbound.paid_options.length > 0) {
                                            outbound.paid_options.map(option => {
                                                let optionPrice = 0;
                                                if ((option.prices?.length ?? 0) > 0) {
                                                    optionPrice = getPrice(option.prices).cost;
                                                } else {
                                                    optionPrice = parseFloat(option.price);
                                                }
                                                if (option.option_on_flight) {
                                                    if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                        option_cost += optionPrice;
                                                        option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                                    }
                                                } else if (option.option_on_group) {
                                                    if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                        option_cost += optionPrice;
                                                        option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                                    }
                                                } else {
                                                    option_cost += optionPrice;
                                                }
                                            }); 
                                        }
                                    }
                                });
                            }
                            else {
                                seatsCost = big_cart[i].paid_option_price.filter((item) => {
                                    return item.service_type === 'SEATS';
                                }).reduce((prev, current) => {
                                    return prev + getPrice(current.prices).cost;
                                }, 0);
                                otherOptionsCost = big_cart[i].paid_option_price.filter((item) => {
                                    return item.service_type !== 'SEATS';
                                }).reduce((prev, current) => {
                                    return prev + getPrice(current.prices).cost;
                                }, 0);
                            }
                            tmp_full_price += parseFloat(seatsCost) + parseFloat(otherOptionsCost) + parseFloat(option_cost);
                            // if (big_cart[i].segments !== undefined && big_cart[i].segments !== null) {
                            //     let group = [];
                            //     big_cart[i].segments.map((segment) => {
                            //         if (segment.paid_options.length > 0 && !group.includes(segment.group)) {
                            //             group.push(segment.group);
                            //             segment.paid_options.map((option) => {
                            //                 tmp_full_price += parseFloat(option.price);
                            //             });
                            //         }
                            //         if (segment.seats.length > 0) {
                            //             segment.seats.map((seat) => {
                            //                 tmp_full_price += parseFloat(seat.price);
                            //             });
                            //         }
                            //     });
                            // }
                            if (big_cart[i].taxes !== undefined && big_cart[i].taxes !== null) {
                                const master_price = big_cart[i].prices.find((price) => price.master_price);
                                tmp_full_price += getTaxesPrice(big_cart[i].taxes) * parseFloat(master_price.factor_used);
                            }
                        }
                        if (big_cart[i].product_type === 18) {
                            tmp_cash_back += parseFloat(price.selling_price);
                        }
                        if (big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_currency === currency.id) {
                            // tmp_only_main_currency_price += parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price);
                            const price = big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)];
                            if (quotation_code === "continentsinsolites" && price.purchase_price === price.selling_price) {
                                tmp_full_buying_price += price.purchase_price - (price.purchase_price * (price.percentage_between / 100));
                                tmp_only_main_currency_price += price.purchase_price - (price.purchase_price * (price.percentage_between / 100));
                            } else {
                                tmp_only_main_currency_price += parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price);
                                tmp_full_buying_price += parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price);
                            }
                            if (big_cart[i].product_type === 6) {
                                if (big_cart[i].paid_option_price !== undefined && big_cart[i].paid_option_price !== null && big_cart[i].paid_option_price.length !== 0) {
                                    big_cart[i].paid_option_price.map((option) => {
                                        let master_price = option.prices.find((price) => price.master_price);
                                        tmp_full_buying_price += parseFloat(master_price.purchase_price);
                                        tmp_only_main_currency_price += parseFloat(master_price.purchase_price);
                                        // tmp_full_buying_price += parseFloat(master_price.selling_price);
                                        // tmp_only_main_currency_price += parseFloat(master_price.selling_price);
                                    })
                                } else if (big_cart[i].segments !== undefined && big_cart[i].segments !== null) {
                                    let group = [];
                                    big_cart[i].segments.map((segment) => {
                                        if (segment.paid_options.length > 0 && !group.includes(segment.group)) {
                                            group.push(segment.group);
                                            segment.paid_options.map((option) => {
                                                tmp_full_buying_price += parseFloat(option.price);
                                                tmp_only_main_currency_price += parseFloat(option.price);
                                                total_taxe += parseFloat(option.price);
                                            });
                                        }
                                    });
                                }
                                if (big_cart[i].taxes !== undefined && big_cart[i].taxes !== null) {
                                    if (isString(big_cart[i].taxes)) {
                                        tmp_full_buying_price += getTaxesPrice(big_cart[i].taxes);
                                        tmp_only_main_currency_price += getTaxesPrice(big_cart[i].taxes);
                                    } else {
                                        let master_price = big_cart[i].taxes.find((price) => price.master_price);
                                        tmp_full_buying_price += parseFloat(master_price.purchase_price);
                                        tmp_only_main_currency_price += parseFloat(master_price.purchase_price);
                                    }
                                    total_taxe += getTaxesPrice(big_cart[i].taxes);
                                }
                            }
                        } else {
                            let currency_buy = list_currency.find((currency) => {
                                return currency.id === big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_currency;
                            });
                            // if (tmp_not_same_currency_cart.length === 0) {
                                let tmp_price;
                                let tmp_obj = {};
                                tmp_obj.currency = currency_buy;
                                tmp_obj.factor_used = parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].factor_used);
                                tmp_price = parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price);
                                tmp_obj.converted_price = parseFloat(ConvertPrice(tmp_price, currency, currency_buy, parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].factor_used)));
                                if (big_cart[i].product_type === 6) {
                                    if (big_cart[i].taxes !== undefined && big_cart[i].taxes !== null) {
                                        const master_price = big_cart[i].prices.find((price) => price.master_price);
                                        tmp_price += getTaxesPrice(big_cart[i].taxes);
                                        tmp_obj.converted_price += getTaxesPrice(big_cart[i].taxes) * parseFloat(master_price.factor_used);
                                        total_taxe += getTaxesPrice(big_cart[i].taxes) * parseFloat(master_price.factor_used);
                                    }
                                }
                                tmp_obj.price = tmp_price;
                                tmp_not_same_currency_cart.push(tmp_obj);
                            // } else {
                            //     let found = false;
                            //     for (let j = 0; j < tmp_not_same_currency_cart.length; j++) {
                            //         if (tmp_not_same_currency_cart[j].currency.id === currency_buy.id) {
                            //             found = true;
                            //             tmp_not_same_currency_cart[j].price += parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price);
                            //             tmp_not_same_currency_cart[j].converted_price += parseFloat(ConvertPrice(parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price), currency, currency_buy, parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].factor_used)));
                            //         }
                            //         //if (j === tmp_not_same_currency_cart.length && found === false) {
                            //         if (found === false) {
                            //             let tmp_obj = {};
                            //             let tmp_price;
                            //             tmp_obj.currency = currency_buy;
                            //             tmp_obj.factor_used = parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].factor_used);
                            //             tmp_price = parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price);
                            //             tmp_obj.converted_price = parseFloat(ConvertPrice(parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price), currency, currency_buy, parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].factor_used)));
                            //             if (big_cart[i].product_type === 6) {
                            //                 if (big_cart[i].taxes !== undefined && big_cart[i].taxes !== null) {
                            //                     console.log('taxes 4 : ');
                            //                     tmp_price += getTaxesPrice(big_cart[i].taxes);
                            //                     tmp_obj.converted_price += getTaxesPrice(big_cart[i].taxes);
                            //                     total_taxe += getTaxesPrice(big_cart[i].taxes);
                            //                 }
                            //             }
                            //             tmp_obj.price = tmp_price;
                            //             tmp_not_same_currency_cart.push(tmp_obj);
                            //             break;
                            //         }
                            //         found = false;
                            //     }
                            // }
                            tmp_full_buying_price += parseFloat(ConvertPrice(parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].purchase_price), currency_buy, currency, parseFloat(big_cart[i].prices[GetPricesId(big_cart[i].prices, owner_id, user, true)].factor_used)));
                            if (big_cart[i].product_type === 6) {
                                if (big_cart[i].segments !== undefined && big_cart[i].segments !== null) {
                                    let group = [];
                                    big_cart[i].segments.map((segment) => {
                                        if (segment.paid_options.length > 0 && !group.includes(segment.group)) {
                                            group.push(segment.push);
                                            segment.paid_options.map((option) => {
                                                tmp_full_buying_price += parseFloat(ConvertPrice(parseFloat(option.price), currency, currency_buy, null));
                                                total_taxe += parseFloat(ConvertPrice(parseFloat(option.price), currency, currency_buy, null));
                                            });
                                        }
                                    });
                                }
                                if (big_cart[i].taxes !== undefined && big_cart[i].taxes !== null) {
                                    const master_price = big_cart[i].prices.find((price) => price.master_price);
                                    tmp_full_buying_price += getTaxesPrice(big_cart[i].taxes) * parseFloat(master_price.factor_used);
                                }
                            }
                        }
                        CheckCustomCurrency(big_cart[i], custom_currencies, trip_start_date, trip_end_date, user, owner_id, setCustomCurrency, setHasCustomCurrency, setCustomFactor, setCurrencyBase);
                        let my_margin = GetMargin(big_cart[i], big_cart[i].type, i, list_currency, currency, user, owner_id);
                        total_surcom += my_margin.value_surcom !== null ? parseFloat(my_margin.value_surcom) : 0;
                        to_margin += my_margin.item.margin_value !== null ? parseFloat(my_margin.item.margin_value) : 0;
                        total_network += my_margin.network ? parseFloat(my_margin.network.selling_price) - parseFloat(my_margin.network.purchase_price) : 0;
                    }
                }
            }
            if (manual_cart) {
                if (data_trip.flight_taxes !== null) {
                    tmp_full_price += parseFloat(data_trip.flight_taxes);
                }
                if (data_trip.prices_flight.length > 0) {
                    tmp_full_price += parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user)].selling_price);

                    if (data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_currency === currency.id) {
                        tmp_only_main_currency_price += parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_price);
                        tmp_full_buying_price += parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_price);
                        if (data_trip.flight_taxes !== null) {
                            tmp_full_buying_price += parseFloat(data_trip.flight_taxes);
                            tmp_only_main_currency_price += parseFloat(data_trip.flight_taxes);
                            total_taxe += parseFloat(data_trip.flight_taxes);
                        }
                    } else {
                        let currency_buy = list_currency.find((currency) => {
                            return currency.id === data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_currency;
                        });
                        // if (tmp_not_same_currency_cart.length === 0) {
                            let tmp_price;
                            let tmp_obj = {};
                            tmp_obj.currency = currency_buy;
                            tmp_obj.factor_used = parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].factor_used);
                            tmp_price = parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_price);
                            tmp_obj.converted_price = parseFloat(ConvertPrice(tmp_price, currency, currency_buy, parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].factor_used)));
                            if (data_trip.flight_taxes !== null) {
                                tmp_price += parseFloat(data_trip.flight_taxes);
                                tmp_obj.converted_price += parseFloat(data_trip.flight_taxes);
                                total_taxe += parseFloat(data_trip.flight_taxes);
                            }
                            tmp_obj.price = tmp_price;
                            tmp_not_same_currency_cart.push(tmp_obj);
                        // } else {
                        //     let found = false;
                        //     for (let j = 0; j < tmp_not_same_currency_cart.length; j++) {
                        //         if (tmp_not_same_currency_cart[j].currency.id === currency_buy.id) {
                        //             found = true;
                        //             tmp_not_same_currency_cart[j].price += parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_price);
                        //             tmp_not_same_currency_cart[j].converted_price += parseFloat(ConvertPrice(parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_price), currency, currency_buy, parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].factor_used)));
                        //         }
                        //         if (j === tmp_not_same_currency_cart.length && found === false) {
                        //             let tmp_obj = {};
                        //             let tmp_price;
                        //             tmp_obj.currency = currency_buy;
                        //             tmp_obj.factor_used = parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].factor_used);
                        //             tmp_price = parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_price);
                        //             tmp_obj.converted_price = parseFloat(ConvertPrice(parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_price), currency, currency_buy, parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].factor_used)));
                        //             if (data_trip.flight_taxes !== null) {
                        //                 tmp_price += parseFloat(data_trip.flight_taxes);
                        //                 tmp_obj.converted_price += parseFloat(data_trip.flight_taxes);
                        //                 total_taxe += parseFloat(data_trip.flight_taxes);
                        //             }
                        //             tmp_obj.price = tmp_price;
                        //             tmp_not_same_currency_cart.push(tmp_obj);
                        //         }
                        //     }
                        // }
                        tmp_full_buying_price += parseFloat(ConvertPrice(parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].purchase_price), currency_buy, currency, parseFloat(data_trip.prices_flight[GetPricesId(data_trip.prices_flight, owner_id, user, true)].factor_used)));
                    }
                    CheckCustomCurrency(data_trip, custom_currencies, trip_start_date, trip_end_date, user, owner_id, setCustomCurrency, setHasCustomCurrency, setCustomFactor, setCurrencyBase);
                    let my_margin = GetMargin(data_trip, "prices_flight", null, list_currency, currency, user, owner_id, 0);
                    total_surcom += my_margin.value_surcom !== null ? parseFloat(my_margin.value_surcom) : 0;
                    to_margin += parseFloat(my_margin.item.flight.margin_value);
                    total_network += my_margin.network ? parseFloat(my_margin.network.selling_price) - parseFloat(my_margin.network.purchase_price) : 0;
                }
                if (data_trip.prices_terrestrial.length > 0) {
                    tmp_full_price += parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user)].selling_price);

                    if (data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_currency === currency.id) {
                        tmp_only_main_currency_price += parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_price);
                        tmp_full_buying_price += parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_price);
                    } else {
                        let currency_buy = list_currency.find((currency) => {
                            return currency.id === data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_currency;
                        });
                        // if (tmp_not_same_currency_cart.length === 0) {
                            let tmp_price;
                            let tmp_obj = {};
                            tmp_obj.currency = currency_buy;
                            tmp_obj.factor_used = parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].factor_used);
                            tmp_price = parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_price);
                            tmp_obj.converted_price = parseFloat(ConvertPrice(tmp_price, currency, currency_buy, parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].factor_used)));
                            tmp_obj.price = tmp_price;
                            tmp_not_same_currency_cart.push(tmp_obj);
                        // } else {
                        //     let found = false;
                        //     for (let j = 0; j < tmp_not_same_currency_cart.length; j++) {
                        //         if (tmp_not_same_currency_cart[j].currency.id === currency_buy.id) {
                        //             found = true;
                        //             tmp_not_same_currency_cart[j].price += parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_price);
                        //             tmp_not_same_currency_cart[j].converted_price += parseFloat(ConvertPrice(parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_price), currency, currency_buy, parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].factor_used)));
                        //         }
                        //         if (j === tmp_not_same_currency_cart.length && found === false) {
                        //             let tmp_obj = {};
                        //             let tmp_price;
                        //             tmp_obj.currency = currency_buy;
                        //             tmp_obj.factor_used = parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].factor_used);
                        //             tmp_price = parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_price);
                        //             tmp_obj.converted_price = parseFloat(ConvertPrice(parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_price), currency, currency_buy, parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].factor_used)));
                        //             tmp_obj.price = tmp_price;
                        //             tmp_not_same_currency_cart.push(tmp_obj);
                        //         }
                        //     }
                        // }
                        tmp_full_buying_price += parseFloat(ConvertPrice(parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].purchase_price), currency_buy, currency, parseFloat(data_trip.prices_terrestrial[GetPricesId(data_trip.prices_terrestrial, owner_id, user, true)].factor_used)));
                    }
                    CheckCustomCurrency(data_trip, custom_currencies, trip_start_date, trip_end_date, user, owner_id, setCustomCurrency, setHasCustomCurrency, setCustomFactor, setCurrencyBase);
                    let my_margin = GetMargin(data_trip, "prices_terrestrial", null, list_currency, currency, user, owner_id, 1);
                    total_surcom += my_margin.value_surcom !== null ? parseFloat(my_margin.value_surcom) : 0;
                    to_margin += parseFloat(my_margin.item.terrestrial.margin_value);
                    total_network += my_margin.network ? parseFloat(my_margin.network.selling_price) - parseFloat(my_margin.network.purchase_price) : 0;
                }
                if (data_trip.prices_stack_product.length > 0) {
                    tmp_full_price += parseFloat(data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user)].selling_price);

                    if (data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].purchase_currency === currency.id) {
                        tmp_only_main_currency_price += parseFloat(data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].purchase_price);
                        tmp_full_buying_price += parseFloat(data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].purchase_price);
                    } else {
                        let currency_buy = list_currency.find((currency) => {
                            return currency.id === data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].purchase_currency;
                        });
                        let tmp_price;
                        let tmp_obj = {};
                        tmp_obj.currency = currency_buy;
                        tmp_obj.factor_used = parseFloat(data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].factor_used);
                        tmp_price = parseFloat(data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].purchase_price);
                        tmp_obj.converted_price = parseFloat(ConvertPrice(tmp_price, currency, currency_buy, parseFloat(data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].factor_used)));
                        tmp_obj.price = tmp_price;
                        tmp_not_same_currency_cart.push(tmp_obj);
                        
                        tmp_full_buying_price += parseFloat(ConvertPrice(parseFloat(data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].purchase_price), currency_buy, currency, parseFloat(data_trip.prices_stack_product[GetPricesId(data_trip.prices_stack_product, owner_id, user, true)].factor_used)));
                    }
                    CheckCustomCurrency(data_trip, custom_currencies, trip_start_date, trip_end_date, user, owner_id, setCustomCurrency, setHasCustomCurrency, setCustomFactor, setCurrencyBase);
                    let my_margin = GetMargin(data_trip, "prices_stack_product", null, list_currency, currency, user, owner_id, 2);
                    total_surcom += my_margin.value_surcom !== null ? parseFloat(my_margin.value_surcom) : 0;
                    to_margin += parseFloat(my_margin.item.terrestrial.margin_value);
                    total_network += my_margin.network ? parseFloat(my_margin.network.selling_price) - parseFloat(my_margin.network.purchase_price) : 0;

                }
            }
            let agency_margin = tmp_full_price - tmp_full_buying_price;
            setGetTotalPrice(true);
            setTotalPrice(tmp_full_price);
            if (!['cercledesvoyages'].includes(quotation_code)) {
                setFullBuyingPrice(tmp_full_buying_price);
            } else {
                setFullBuyingPrice(tmp_full_buying_price + parseFloat(total_surcom) + parseFloat(tva));
            }
            let group_not_same_currency = [];
            tmp_not_same_currency_cart.map((item) => {
                if (group_not_same_currency.length === 0 || group_not_same_currency.find(el => el.currency.id === item.currency.id) === undefined) {
                    group_not_same_currency.push(item);
                }
                else if (group_not_same_currency.find(el => el.currency.id === item.currency.id) !== undefined) {
                    group_not_same_currency.map(el => {
                        if (el.currency.id === item.currency.id) {
                            el.price += item.price;
                            el.converted_price += item.converted_price;
                        }
                    });
                }
            });
            setNotSameCurrencyCart(group_not_same_currency);
            setOnlyMainCurrencyPrice(tmp_only_main_currency_price);
            setTotalSurcom(total_surcom);
            setToMargin(to_margin);
            setTotalNetwork(total_network);
            setCashBack(tmp_cash_back);
            setTvaValue(tva);
            if (!['cercledesvoyages'].includes(quotation_code)) {
                setTotalMargin(parseFloat(to_margin) + parseFloat(total_network) + parseFloat(total_surcom));
            } else {
                setTotalMargin(parseFloat(to_margin) + parseFloat(total_network));
            }
            setTotalMarginAgency(agency_margin);
            setBigtotalMarginAgency(agency_margin + total_network);
            setTotalTaxe(total_taxe);
        }
    }, [get_cart_done]);
    useEffect(() => {
        if (user.client_full.type !== 2 && providers.length !== 0 && get_cart_done) {
            setData();
        }
    }, [providers, manual_providers, get_cart_done]);
    const setData = () => {
        let tmp_data = [];
        let tmp_data1 = [[]];
        tmp_data1[0].push(t("menu.file_nb"));
        tmp_data1[0].push(t("global.date"));
        tmp_data1[0].push(t("menu.trip_list.product"));
        tmp_data1[0].push(t("filters.providers"));
        tmp_data1[0].push(t("global.description"));
        tmp_data1[0].push(t("global.room"));
        tmp_data1[0].push(t("menu.trip_list.reference"));
        tmp_data1[0].push(t("cart.edit_booking.booking_status"));
        tmp_data1[0].push(t("menu.trip_list.amount_purchase"));
        tmp_data1[0].push(t("global.in"));
        tmp_data1[0].push(t("menu.trip_list.rate"));
        tmp_data1[0].push(t("menu.trip_list.amount"));
        tmp_data1[0].push(t("global.in"));
        tmp_data1[0].push(t("menu.trip_list.amount_selling"));
        tmp_data1[0].push(t("global.in"));
        if (big_cart !== null) {
            let id = (trip_info.accounting_reference !== undefined && trip_info.accounting_reference !== null) ? trip_info.trip_reference + "-" + trip_info.accounting_reference : trip_info.trip_reference;
            if (manual_cart) {
                Object.keys(packagedProducts).map((key) => {
                    const stackNumber = isNumber(key.split('-')[0]) ? parseInt(key.split('-')[0]) : null;
                    const stackInfoId = isNumber(key.split('-')[1]) ? parseInt(key.split('-')[1]) : null;
                    const flightPrices = data_trip?.prices_flight?.filter((item) => {
                        return isProductSamePackage(
                            item,
                            {
                                stack_number: stackNumber,
                                stack_info_id: stackInfoId
                            }
                        );
                    }) ?? [];
                    const terrestrialPrices = data_trip?.prices_terrestrial?.filter((item) => {
                        return isProductSamePackage(
                            item,
                            {
                                stack_number: stackNumber,
                                stack_info_id: stackInfoId
                            }
                        );
                    }) ?? [];
                    const stackPricesProduct = data_trip?.prices_stack_product ?? []
                    let purchase_price_flight = 0;
                    let selling_price_flight = 0;
                    let currency_buy_flight = null;
                    let converted_price_flight = 0;
                    let currency_sell_flight = null;
                    let factor_used_flight = null;

                    let purchase_price_terrestrial = 0;
                    let selling_price_terrestrial = 0;
                    let currency_buy_terrestrial = null;
                    let currency_sell_terrestrial = null;
                    let converted_price_terrestrial = 0;
                    let factor_used_terrestrial = null;

                    let purchase_price_stack = 0;
                    let selling_price_stack = 0;
                    let currency_buy_stack = null;
                    let currency_sell_stack = null;
                    let converted_price_stack = 0;
                    let factor_used_stack = null;
                    
                    let tmp_provider = null;
                    
                    if (stackPricesProduct !== undefined && stackPricesProduct.length !== 0) {
                        let tmp_obj = {
                            product: null,
                            provider: null,
                            description: null,
                            room_name: null,
                            reference: null,
                            booking_status: null,
                            amount_1: null,
                            in_1: null,
                            rate: null,
                            amount_2: null,
                            in_2: null,
                            amount_3: null,
                            in_3: null,
                            date: null,
                            id: null
                        };
                        let tmp_arr = [];
                        purchase_price_stack = parseFloat(stackPricesProduct[GetPricesId(stackPricesProduct, owner_id, user, true)].purchase_price);
                        selling_price_stack = parseFloat(stackPricesProduct[GetPricesId(stackPricesProduct, owner_id, user, quotation_code !== "americavoyages")].selling_price);
                        currency_buy_stack = list_currency.find((currency) => {
                            return currency.id === stackPricesProduct[GetPricesId(stackPricesProduct, owner_id, user, true)].purchase_currency; 
                        });
                        currency_sell_stack = list_currency.find((currency) => {
                            return currency.id === stackPricesProduct[GetPricesId(stackPricesProduct, owner_id, user, quotation_code !== "americavoyages")].selling_currency; 
                        });
                        factor_used_stack = stackPricesProduct[GetPricesId(stackPricesProduct, owner_id, user, true)].factor_used;
                        if (purchase_price_stack !== 0 && selling_price_stack !== 0) {
                            tmp_arr.push(id);
                            tmp_arr.push("-");
                            tmp_arr.push(t("providers.package"));
                            tmp_arr.push('-');
                            tmp_arr.push('-');
                            tmp_arr.push('');
                            tmp_arr.push('-');
                            tmp_arr.push('-');
                            tmp_arr.push(parseFloat(purchase_price_stack).toFixed(2).replace(".", ","));
                            tmp_arr.push(currency_buy_stack.symbol);
                            tmp_arr.push(parseFloat(factor_used_stack));
                            tmp_arr.push(parseFloat(converted_price_stack).toFixed(2).replace(".", ","));
                            tmp_arr.push(currency.symbol);
                            tmp_arr.push(parseFloat(selling_price_stack).toFixed(2).replace(".", ","));
                            tmp_arr.push(currency_sell_stack.symbol);
    
                            tmp_data1.push(tmp_arr);
    
                            tmp_obj.id = id;
                            tmp_obj.product = t("providers.package");
                            tmp_obj.provider = '-';
                            tmp_obj.description = '-';
                            tmp_obj.booking_status = '-';
                            tmp_obj.reference = '-';
                            tmp_obj.amount_1 = parseFloat(purchase_price_stack).toFixed(2).replace(".", ",");
                            tmp_obj.in_1 = currency_buy_stack.symbol;
                            tmp_obj.rate = parseFloat(factor_used_stack);
                            tmp_obj.amount_2 = parseFloat(converted_price_stack).toFixed(2).replace(".", ",");
                            tmp_obj.in_2 = currency.symbol;
                            tmp_obj.date = '-';
                            tmp_obj.amount_3 = parseFloat(selling_price_stack).toFixed(2).replace(".", ",");
                            tmp_obj.in_3 = currency_sell_stack.symbol;
                            tmp_data.push(tmp_obj);
                        }
                    }
                    if (flightPrices !== undefined && flightPrices.length !== 0) {
                        let tmp_obj = {
                            product: null,
                            provider: null,
                            description: null,
                            room_name: null,
                            reference: null,
                            booking_status: null,
                            amount_1: null,
                            in_1: null,
                            rate: null,
                            amount_2: null,
                            in_2: null,
                            amount_3: null,
                            in_3: null,
                            date: null,
                            id: null
                        };
                        let tmp_arr = [];
                        purchase_price_flight = parseFloat(flightPrices[GetPricesId(flightPrices, owner_id, user, true)].purchase_price);
                        selling_price_flight = parseFloat(flightPrices[GetPricesId(flightPrices, owner_id, user, quotation_code !== "americavoyages")].selling_price);
                        currency_buy_flight = list_currency.find((currency) => {
                            return currency.id === flightPrices[GetPricesId(flightPrices, owner_id, user, true)].purchase_currency; 
                        });
                        currency_sell_flight = list_currency.find((currency) => {
                            return currency.id === flightPrices[GetPricesId(flightPrices, owner_id, user, quotation_code !== "americavoyages")].selling_currency; 
                        });
                        converted_price_flight = parseFloat(ConvertPrice(purchase_price_flight, currency, currency_buy_flight, parseFloat(flightPrices[GetPricesId(flightPrices, owner_id, user, true)].factor_used)));
                        factor_used_flight = parseFloat(flightPrices[GetPricesId(flightPrices, owner_id, user, true)].factor_used).toFixed(2);
                        tmp_provider = GetProvider(providers, terrestrialPrices[0].provider);
                        if (tmp_provider === null) {
                            tmp_provider = GetProvider(manual_providers, terrestrialPrices[0].provider);
                        }
                        if (purchase_price_flight !== 0 && selling_price_flight !== 0) {
                            tmp_arr.push(id);
                            tmp_arr.push("-");
                            tmp_arr.push(t("providers.package"));
                            tmp_arr.push(tmp_provider !== null ? tmp_provider.provider.name : '-');
                            tmp_arr.push(t(
                                'cart-material.cart-construction-package-name',
                                { name: stackNumber }
                            ) + ' - ' + t('cart-material.flight'));
                            tmp_arr.push('');
                            tmp_arr.push('-');
                            tmp_arr.push('-');
                            tmp_arr.push(parseFloat(purchase_price_flight).toFixed(2).replace(".", ","));
                            tmp_arr.push(currency_buy_flight.symbol);
                            tmp_arr.push(parseFloat(factor_used_flight));
                            tmp_arr.push(parseFloat(converted_price_flight).toFixed(2).replace(".", ","));
                            tmp_arr.push(currency.symbol);
                            tmp_arr.push(parseFloat(selling_price_flight).toFixed(2).replace(".", ","));
                            tmp_arr.push(currency_sell_flight.symbol);
    
                            tmp_data1.push(tmp_arr);
    
                            tmp_obj.id = id;
                            tmp_obj.product = t("providers.package");
                            tmp_obj.provider = tmp_provider !== null ? tmp_provider.provider.name : '-';
                            tmp_obj.description = t(
                                'cart-material.cart-construction-package-name',
                                { name: stackNumber }
                            ) + ' - ' + t('cart-material.flight');
                            tmp_obj.booking_status = '-';
                            tmp_obj.reference = '-';
                            tmp_obj.amount_1 = parseFloat(purchase_price_flight).toFixed(2).replace(".", ",");
                            tmp_obj.in_1 = currency_buy_flight.symbol;
                            tmp_obj.rate = parseFloat(factor_used_flight);
                            tmp_obj.amount_2 = parseFloat(converted_price_flight).toFixed(2).replace(".", ",");
                            tmp_obj.in_2 = currency.symbol;
                            tmp_obj.date = '-';
                            tmp_obj.amount_3 = parseFloat(selling_price_flight).toFixed(2).replace(".", ",");
                            tmp_obj.in_3 = currency_sell_flight.symbol;
                            tmp_data.push(tmp_obj);
                        }
                    }
                    if (terrestrialPrices !== undefined && terrestrialPrices.length !== 0) {
                        let tmp_obj = {
                            product: null,
                            provider: null,
                            description: null,
                            room_name: null,
                            reference: null,
                            booking_status: null,
                            amount_1: null,
                            in_1: null,
                            rate: null,
                            amount_2: null,
                            in_2: null,
                            amount_3: null,
                            in_3: null,
                            date: null,
                            id: null
                        };
                        let tmp_arr = [];
                        purchase_price_terrestrial = parseFloat(terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, true)].purchase_price);
                        selling_price_terrestrial = parseFloat(terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, quotation_code !== "americavoyages")].selling_price);
                        currency_buy_terrestrial = list_currency.find((currency) => {
                            return currency.id === terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, true)].purchase_currency; 
                        });
                        currency_sell_terrestrial = list_currency.find((currency) => {
                            return currency.id === terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, quotation_code !== "americavoyages")].selling_currency; 
                        });
                        converted_price_terrestrial = parseFloat(ConvertPrice(purchase_price_terrestrial, currency, currency_buy_terrestrial, parseFloat(terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, true)].factor_used)));
                        factor_used_terrestrial = parseFloat(terrestrialPrices[GetPricesId(terrestrialPrices, owner_id, user, true)].factor_used).toFixed(2);
                        tmp_provider = GetProvider(providers, terrestrialPrices[0].provider);
                        if (tmp_provider === null) {
                            tmp_provider = GetProvider(manual_providers, terrestrialPrices[0].provider);
                        }
                        if (purchase_price_terrestrial !== 0 && selling_price_terrestrial !== 0) {
                            tmp_arr.push(id);
                            tmp_arr.push("-");
                            tmp_arr.push(t("providers.package"));
                            tmp_arr.push(tmp_provider !== null ? tmp_provider.provider.name : '-');
                            tmp_arr.push(t(
                                'cart-material.cart-construction-package-name',
                                { name: stackNumber }
                            ) + ' - ' + t('cart-material.terrestrial'));
                            tmp_arr.push('');
                            tmp_arr.push('-');
                            tmp_arr.push('-');
                            tmp_arr.push(parseFloat(purchase_price_terrestrial).toFixed(2).replace(".", ","));
                            tmp_arr.push(currency_buy_terrestrial.symbol);
                            tmp_arr.push(parseFloat(factor_used_terrestrial));
                            tmp_arr.push(parseFloat(converted_price_terrestrial).toFixed(2).replace(".", ","));
                            tmp_arr.push(currency.symbol);
                            tmp_arr.push(parseFloat(selling_price_terrestrial).toFixed(2).replace(".", ","));
                            tmp_arr.push(currency_sell_terrestrial.symbol);
    
                            tmp_data1.push(tmp_arr);
    
                            tmp_obj.id = id;
                            tmp_obj.product = t("providers.package");
                            tmp_obj.provider = tmp_provider !== null ? tmp_provider.provider.name : '-';
                            tmp_obj.description = t(
                                'cart-material.cart-construction-package-name',
                                { name: stackNumber }
                            ) + ' - ' + t('cart-material.terrestrial');
                            tmp_obj.booking_status = '-';
                            tmp_obj.reference = '-';
                            tmp_obj.amount_1 = parseFloat(purchase_price_terrestrial).toFixed(2).replace(".", ",");
                            tmp_obj.in_1 = currency_buy_terrestrial.symbol;
                            tmp_obj.rate = parseFloat(factor_used_terrestrial);
                            tmp_obj.amount_2 = parseFloat(converted_price_terrestrial).toFixed(2).replace(".", ",");
                            tmp_obj.in_2 = currency.symbol;
                            tmp_obj.date = '-';
                            tmp_obj.amount_3 = parseFloat(selling_price_terrestrial).toFixed(2).replace(".", ",");
                            tmp_obj.in_3 = currency_sell_terrestrial.symbol;
                            tmp_data.push(tmp_obj);
                        }
                    }
                });
            }
            big_cart.map((cart, cart_index) => {
                if (!cart.is_optional && !isProductPackaged({ product: cart, stackInfos: data_trip?.stack_info ?? null })) {
                    let tmp_obj = {
                        product: null,
                        provider: null,
                        description: null,
                        room_name: null,
                        reference: null,
                        booking_status: null,
                        amount_1: null,
                        in_1: null,
                        rate: null,
                        amount_2: null,
                        in_2: null,
                        amount_3: null,
                        in_3: null,
                        date: null,
                        id: null
                    };
                    let tmp_arr = [];
                    let product_type = GetProductType(cart.product_type);
                    let provider = null;
                    if (cart.provider !== undefined && cart.provider !== null && providers.length !== 0 && (manual_providers.length !== 0 || ['marcovasco'].includes(quotation_code))) {
                        let tmp_provider = GetProvider(providers, cart.provider);
                        if (tmp_provider === null) {
                            tmp_provider = GetProvider(manual_providers, cart.provider);
                        }
                        provider = tmp_provider.provider.name;
                    }
                    if (cart.provider_name !== undefined && cart.provider_name !== null) {
                        provider = cart.provider_name;
                    }
                    let booking_ref = "-";
                    let booking_status = "-";
                    if (cart.booking_reference !== undefined && cart.booking_reference !== null) {
                        booking_ref = cart.booking_reference;
                    }
                    if (cart.booking_status !== undefined && cart.booking_status !== null) {
                        booking_ref = cart.booking_status.item_reference;
                        booking_status = (cart.booking_status.status_booking).toLowerCase();
                    }
                    let name = "-";
                    let room_name = "";
                    if (cart.name !== undefined && cart.name !== null) {
                        name = cart.name;
                    } else {
                        if (cart.product_type === 0) {
                            name = t("accommodation.hotel") + " : " + cart.hotel[0].name;
                            room_name = cart.description;
                        }
                        if (cart.product_type === 6) {
                            if (cart.segments !== undefined && cart.segments !== null) {
                                name = cart.segments[0].marketing_airline.commercial_name + " : " + (cart.segments[0].origin !== null && cart.segments[0].destination !== null ? cart.segments[0].origin.airport_code + " - " + cart.segments[0].destination.airport_code : cart.segments[0].origin_station.station_code + " - " + cart.segments[0].destination_station.station_code);
                            }
                        }
                        if (cart.product_type === 11 || cart.product_type === 12) {
                            if (cart.custom_product !== undefined) {
                                name = cart.custom_product.title;
                            }
                        }
                        if (cart.product_type === 8) {
                            name = cart.contract_name ? cart.contract_name : t('cart-material.assistance');
                        }
                    }
                    let price = parseFloat(cart.prices[GetPricesId(cart.prices, owner_id, user, true)].purchase_price);
                    let selling_price = parseFloat(cart.prices[GetPricesId(cart.prices, owner_id, user, quotation_code !== "americavoyages")].selling_price);
                    let currency_buy = list_currency.find((currency) => {
                        return currency.id === cart.prices[GetPricesId(cart.prices, owner_id, user, true)].purchase_currency; 
                    });
                    let currency_sell = list_currency.find((currency) => {
                        return currency.id === cart.prices[GetPricesId(cart.prices, owner_id, user, true)].selling_currency; 
                    });
                    let converted_price = parseFloat(ConvertPrice(parseFloat(cart.prices[GetPricesId(cart.prices, owner_id, user, true)].purchase_price), currency, currency_buy, parseFloat(cart.prices[GetPricesId(cart.prices, owner_id, user, true)].factor_used)));
                    if (quotation_code === "continentsinsolites" && selling_price === converted_price) {
                        converted_price = converted_price - (converted_price * (cart.prices[GetPricesId(cart.prices, owner_id, user, true)].percentage_between / 100));
                    }
                    if (cart.product_type === 6) {
                        const provider = providers.concat(manual_providers).find((item) => {
                            return item.provider.id === cart.provider;
                        })?.provider;
                        let seatsCost = 0;
                        let option_cost = 0;
                        let otherOptionsCost = 0;
                        let option_on_flight = [];
                        if (provider?.code !== 'amadeus_rest') {
                            let outbounds = cart.segments || cart.outbounds || [];
                            outbounds.map(outbound => {
                                let option_on_group = [];
                                if (outbound.legs !== undefined) {
                                    outbound.legs.map(leg => {
                                        for (const seat of leg.seats ?? []) {
                                            let seatPrice = 0;
                                            if ((seat.prices?.length ?? 0) > 0) {
                                                seatPrice = getPrice(seat.prices).cost;
                                            } else {
                                                seatPrice = parseFloat(seat.price ?? '0');
                                            }
                                            seatsCost += seatPrice;
                                        }
                    
                                        if (leg.paid_options && leg.paid_options.length > 0) {
                                            leg.paid_options.map(option => {
                                                let optionPrice = 0;
                                                if ((option.prices?.length ?? 0) > 0) {
                                                    optionPrice = getPrice(option.prices).cost;
                                                } else {
                                                    optionPrice = parseFloat(option.price);
                                                }
                                                if (option.option_on_flight) {
                                                    if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                        option_cost += optionPrice;
                                                        option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                                    }
                                                } else if (option.option_on_group) {
                                                    if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                        option_cost += optionPrice;
                                                        option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                                    }
                                                } else {
                                                    option_cost += optionPrice;
                                                }
                                            }); 
                                        }
                                    });
                                } else {
                                    for (const seat of outbound.seats ?? []) {
                                        let seatPrice = 0;
                                        if ((seat.prices?.length ?? 0) > 0) {
                                            seatPrice = getPrice(seat.prices).cost;
                                        } else {
                                            seatPrice = parseFloat(seat.price ?? '0');
                                        }
                                        seatsCost += seatPrice;
                                    }

                                    if (outbound.paid_options && outbound.paid_options.length > 0) {
                                        outbound.paid_options.map(option => {
                                            let optionPrice = 0;
                                            if ((option.prices?.length ?? 0) > 0) {
                                                optionPrice = getPrice(option.prices).cost;
                                            } else {
                                                optionPrice = parseFloat(option.price);
                                            }
                                            if (option.option_on_flight) {
                                                if (!option_on_flight.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                    option_cost += optionPrice;
                                                    option_on_flight.push(`${option.key_optional_service}-${option.traveler}`);
                                                }
                                            } else if (option.option_on_group) {
                                                if (!option_on_group.includes(`${option.key_optional_service}-${option.traveler}`)) {
                                                    option_cost += optionPrice;
                                                    option_on_group.push(`${option.key_optional_service}-${option.traveler}`);
                                                }
                                            } else {
                                                option_cost += optionPrice;
                                            }
                                        }); 
                                    }
                                }
                            });
                        }
                        else {
                            seatsCost = cart.paid_option_price.filter((item) => {
                                return item.service_type === 'SEATS';
                            }).reduce((prev, current) => {
                                return prev + getPrice(current.prices).cost;
                            }, 0);
                            otherOptionsCost = cart.paid_option_price.filter((item) => {
                                return item.service_type !== 'SEATS';
                            }).reduce((prev, current) => {
                                return prev + getPrice(current.prices).cost;
                            }, 0);
                        }
                        price += parseFloat(seatsCost) + parseFloat(otherOptionsCost) + parseFloat(option_cost);
                        selling_price += parseFloat(seatsCost) + parseFloat(otherOptionsCost) + parseFloat(option_cost);
                        converted_price += parseFloat(seatsCost) + parseFloat(otherOptionsCost) + parseFloat(option_cost);

                        if (cart.taxes !== undefined && cart.taxes !== null) {
                            const master_price = cart.prices.find((price) => price.master_price);
                            price += getTaxesPrice(cart.taxes) * parseFloat(master_price.factor_used);
                            converted_price += getTaxesPrice(cart.taxes) * parseFloat(master_price.factor_used);
                            selling_price += getTaxesPrice(cart.taxes) * parseFloat(master_price.factor_used);
                        }
                    }
                    let start_date = cart.start_date !== undefined && cart.start_date !== null ? (moment.utc(cart.start_date).format("DD-MM-YYYY") + " " + t("global.to")) : "-";
                    let end_date = cart.end_date !== undefined && cart.end_date !== null ? moment.utc(cart.end_date).format("DD-MM-YYYY") : "";
                    tmp_arr.push(id);
                    tmp_arr.push(start_date + " " + end_date);
                    tmp_arr.push(t("providers." + product_type));
                    tmp_arr.push(provider);
                    tmp_arr.push(name);
                    tmp_arr.push(room_name);
                    tmp_arr.push(booking_ref);
                    tmp_arr.push(booking_status !== "-" ? t(`cart.edit_booking.${booking_status}`) : "-");
                    tmp_arr.push(parseFloat(price).toFixed(2).replace(".", ","));
                    tmp_arr.push(currency_buy.symbol);
                    tmp_arr.push(parseFloat(cart.prices[GetPricesId(cart.prices, owner_id, user, true)].factor_used));
                    tmp_arr.push(parseFloat(converted_price).toFixed(2).replace(".", ","));
                    tmp_arr.push(currency.symbol);
                    tmp_arr.push(parseFloat(selling_price).toFixed(2).replace(".", ","));
                    tmp_arr.push(currency_sell.symbol);

                    tmp_data1.push(tmp_arr);

                    tmp_obj.id = id;
                    tmp_obj.product = t("providers." + product_type);
                    tmp_obj.provider = provider;
                    tmp_obj.description = name;
                    tmp_obj.room_name = room_name;
                    tmp_obj.reference = booking_ref;
                    tmp_obj.booking_status = booking_status !== "-" ? t(`cart.edit_booking.${booking_status}`) : "-";
                    tmp_obj.amount_1 = parseFloat(price).toFixed(2).replace(".", ",");
                    tmp_obj.in_1 = currency_buy.symbol;
                    tmp_obj.rate = parseFloat(cart.prices[GetPricesId(cart.prices, owner_id, user, true)].factor_used);
                    tmp_obj.amount_2 = parseFloat(converted_price).toFixed(2).replace(".", ",");
                    tmp_obj.in_2 = currency.symbol;
                    tmp_obj.date = start_date + " " + end_date;
                    tmp_obj.amount_3 = parseFloat(selling_price).toFixed(2).replace(".", ",");
                    tmp_obj.in_3 = currency_sell.symbol;
                    tmp_data.push(tmp_obj);
                }
            });
        }
        setExportData(tmp_data);
        setExportDataCSV(tmp_data1);
        dispatch(SetCartDone(false));
    };
    const exportDataFile = () => {
        let price_column = [t("menu.file_nb"), t("global.date"), t("menu.trip_list.product"), t("cars.filter.provider"), t("global.description"), t("global.room"), t("menu.trip_list.reference"), t("cart.edit_booking.booking_status"), t("menu.trip_list.amount_purchase"), t("global.in"), t("menu.trip_list.rate"), t("menu.trip_list.amount"), t("global.in"), , t("menu.trip_list.amount_selling"), t("global.in")];
        let adults = 0;
        let children = 0;
        for (let i = 0; i < data_trip.travelers.length; i++) {
            if (moment.utc(trip_end_date).diff(moment.utc(data_trip.travelers[i].birth_date), "y") >= 12) {
                adults++;
            } else {
                children++;
            }
        }
        let tmp_data = (
            <table id="tableData">
                <tr>
                    <th>REFERENCE</th>
                    <th>DATE DEPART / REGION</th>
                    <th>AGENCE</th>
                    <th>NOMS CLIENTS</th>
                    <th>NB PAXS</th>
                    <th>VENDEUR</th>
                </tr>
                <tr>
                    <td>{trip_info.trip_reference}</td>
                    <td>{moment(data_trip.start_date).format("DD/MM/YYYY")}</td>
                    <td rowSpan="2">{trip_info.target_client.name}</td>
                    <td rowSpan="2">{data_trip.contact_traveler_detail.first_name + " " + data_trip.contact_traveler_detail.last_name}</td>
                    <td rowSpan="2">
                        {
                            adults > 0 && (
                                <p>adt : {adults}</p>
                            )
                        }
                        {
                            children > 0 && (
                                <p>Enf - 12 ans : {children}</p>
                            )
                        }
                    </td>
                    <td rowSpan="2">{trip_info.user_ta !== null ? trip_info.user_ta.first_name + " " + trip_info.user_ta.last_name : trip_info.user_to !== null ? trip_info.user_to.first_name + " " + trip_info.user_to.last_name : ''}</td>
                </tr>
                <tr>
                    <td>{trip_info.accounting_reference !== undefined && trip_info.accounting_reference !== null ? trip_info.accounting_reference : ""}</td>
                    <td>{data_trip.destination.data.name}</td>
                </tr>
                <tr>
                    <td colSpan="6">{trip_info.name}</td>
                </tr>
                <tr/>
                <tr/>
                <tr>
                    {
                        price_column.map((column, index) => {
                            return (
                                <th key={index}>{column}</th>
                            );
                        })
                    }
                </tr>
                {
                    exportData.map((data, index) => {
                        return (
                            <tr key={index}>
                                <td>{data.id}</td>
                                <td>{data.date}</td>
                                <td>{data.product}</td>
                                <td>{data.provider}</td>
                                <td>{data.description}</td>
                                <td>{data.room_name}</td>
                                <td>{data.reference}</td>
                                <td>{data.booking_status}</td>
                                <td>{data.amount_1.replace(",", ".")}</td>
                                <td>{data.in_1}</td>
                                <td>{data.rate}</td>
                                <td>{data.amount_2.replace(",", ".")}</td>
                                <td>{data.in_2}</td>
                                <td>{data.amount_3.replace(",", ".")}</td>
                                <td>{data.in_3}</td>

                            </tr>
                        );
                    })
                }
                <tr/>
                <tr/>
                <tr>
                    <td colSpan="2">SUIVI DOSSIER</td>
                    <td/>
                    <td>MEMO CV</td>
                    <td/>
                    <td colSpan="2">REMARQUES</td>
                </tr>
                <tr>
                    <td>CONFIRMATION</td>
                    <td/>
                    <td/>
                    <td>ROADBOOK DIGITAL</td>
                    <td/>
                    <td rowSpan="13"/>
                </tr>
                <tr>
                    <td>ACHATS PGI</td>
                    <td/>
                    <td/>
                    <td>ROADBOOK PHHYSIQUE</td>
                    <td/>
                </tr>
                <tr>
                    <td>FACTURE PGI</td>
                    <td/>
                    <td/>
                    <td>VOLS</td>
                    <td/>
                </tr>
                <tr>
                    <td>SUIVI CO  PGI</td>
                    <td/>
                    <td/>
                    <td>VOUCHERS HOTELS</td>
                    <td/>
                </tr>
                <tr>
                    <td>APIS</td>
                    <td/>
                    <td/>
                    <td>VOUCHERS ACTIVITES</td>
                    <td/>
                </tr>
                <tr>
                    <td>ROBOT F4</td>
                    <td/>
                    <td/>
                    <td>VOITURE</td>
                    <td/>
                </tr>
                <tr>
                    <td>NUM CLIENT F5</td>
                    <td/>
                    <td/>
                    <td>ONSPOT</td>
                    <td/>
                </tr>
                <tr>
                    <td>DATE EMISSION</td>
                    <td/>
                    <td/>
                    <td>CARNET ACCOMPAGNT</td>
                    <td/>
                </tr>
                <tr>
                    <td>TABLEAU COTATIONS "OUI" :</td>
                    <td/>
                    <td/>
                    <td>GUIDES</td>
                    <td/>
                </tr>
                <tr>
                    <td>AJOUT D'EXCURSIONS</td>
                    <td/>
                    <td/>
                    <td>ETIQUETTES BAGAGES</td>
                    <td/>
                </tr>
                <tr>
                    <td>MISE A JOUR FT</td>
                    <td/>
                    <td/>
                    <td>GOODIES</td>
                    <td/>
                </tr>
                <tr>
                    <td>FACTURE COMPL. PGI</td>
                    <td/>
                    <td/>
                    <td>FICHIERS PGI + MAIL</td>
                    <td/>
                </tr>
            </table>
        );
        let workbook = XLSX.utils.book_new();
        let string_data = ReactDOMServer.renderToStaticMarkup(tmp_data);
        let template = document.createElement('template');
        string_data = string_data.trim();
        template.innerHTML = string_data;
        let dataToExport = template.content.firstChild;
        let ws1 = XLSX.utils.table_to_sheet(dataToExport);
        XLSX.utils.book_append_sheet(workbook, ws1, id.toString());
        XLSX.writeFile(workbook, id + " " + t("menu.trip_finance_file_name") + ".xlsx");
        recordExport(`${id} ${t("menu.trip_finance_file_name")}.xlsx`);
    };
    const recordExport = (name) => {
        const { pass_check, headers } = CheckBeforeRequest();
        if (pass_check) {
            axios({
                method: "POST",
                headers: headers,
                url: `${API_HREF}client/${window.id_owner}/trip/${GetCookie("trip_id")}/versions/${GetCookie("trip_id_version")}/add_export_historic/?additional_info=${name}`
            }).catch((error) => {
                console.log('error record export:', error);
            });
        }
    };
    return (
        <Fragment>
            <Grid container style={{ marginTop: 20 }}>
                <Grid item container direction={"column"} justify={"center"} alignItems={"center"}>
                    <Grid item>
                        <Typography variant={"h4"} className={classes.genericText} style={{ textAlign: "center" }}>{t("menu.trip_list.finance_synthesis")}</Typography>
                    </Grid>
                </Grid>
                {
                    get_total_price && (
                        <Fragment>
                            {
                                quotation_code === "verdie" && (
                                    <Grid item xs={12} container direction={"row"} justify={"space-between"} alignItems={"center"} className={clsx(classes.genericText, { [classes.marginTop20]: downDesktop, [classes.padding60]: !downDesktop })}>
                                        <Grid item sm={6} xs={12} container direction={"row"}>
                                            <Grid item xs={6}>
                                                <span className={classes.bold}>
                                                    {t("menu.trip_list.selling_price") + " "}
                                                </span>
                                                <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                    { t("global.in") + " " + currency.symbol }
                                                </span>
                                            </Grid>
                                            <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_price).toFixed(2)) }
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className={classes.bold}>
                                                    {t("menu.trip_list.total_buy") + " "}
                                                </span>
                                                <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                    { t("global.in") + " " + currency.symbol }
                                                </span>
                                            </Grid>
                                            <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(full_buying_price).toFixed(2)) }
                                            </Grid>
                                            {
                                                not_same_currency_cart.length > 0 && not_same_currency_cart.map((cart, cart_index) => (
                                                    <Fragment key={cart_index}>
                                                        <Grid item xs={12} container direction={"row"}>
                                                            <Grid item sm={6} xs={5}>
                                                                <div className={ "ft-font-12" }>{ cart_index === 0 ? "=" : "+" }<span className={ "ft-pl15 ft-font-12"}>{ t("menu.trip_list.currency_buy") }</span></div>
                                                            </Grid>
                                                            <Grid item sm={6} xs={7} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                                <div className={ "ft-font-12" }>{ new Intl.NumberFormat(language, { style: 'currency', currency: cart.currency.iso_code }).format(parseFloat(cart.price).toFixed(2)) + " -> " + new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(cart.converted_price).toFixed(2)) }</div>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} container direction={"row"}>
                                                            <Grid item sm={6} xs={10}>
                                                                <div className={ "ft-font-12" }><span className={ "ft-pl21 ft-font-12"}>{ t("menu.trip_list.price_factor") + " " }<span className={ "ft-font-10" }>{ cart.currency.iso_code + " -> " + " " + currency.iso_code}</span></span></div>
                                                            </Grid>
                                                            <Grid item sm={6} xs={2} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                                <div className={ "ft-font-12" }>{ new Intl.NumberFormat(language).format(parseFloat(cart.factor_used)) }</div>
                                                            </Grid>
                                                        </Grid>
                                                    </Fragment>
                                                ))
                                            }
                                            <Grid item xs={6}>
                                                <div className={ "ft-font-12" }>{not_same_currency_cart.length > 0 ? "+" : "=" }<span className={ "ft-pl15 ft-font-12" }>{ t("menu.trip_list.buy") }<span className={ "italic ft-font-11" }>{" " + t("global.in") + " " + currency.symbol }</span></span></div>
                                            </Grid>
                                            <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                <div className={ "ft-font-12" }>{ new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(only_main_currency_price).toFixed(2)) }</div>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={6} xs={12} container direction={"row"} className={clsx({ [classes.textAlightRight]: !downDesktop })}>
                                            <Grid item xs={6}>
                                                <span className={classes.bold}>
                                                    { t("menu.trip_list.cashback") + " "}
                                                </span>
                                                <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                    { t("global.in") + " " + currency.symbol }
                                                </span>
                                            </Grid>
                                            <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                {Math.abs(cashBack) + " " + currency.symbol }
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span className={classes.bold}>
                                                    { t("menu.trip_list.TO_margin_verdie") + " "}
                                                </span>
                                                <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                    { t("global.in") + " " + currency.symbol }
                                                </span>
                                            </Grid>
                                            <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(to_margin).toFixed(2)) }
                                            </Grid>
                                            {
                                                total_surcom !== null && total_surcom !== undefined && total_surcom !== 0 && (
                                                    <Fragment>
                                                        <Grid item xs={6}>
                                                            <span className={classes.bold}>
                                                                { t("menu.trip_list.surcom_assistance") + " "}
                                                            </span>
                                                            <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                { t("global.in") + " " + currency.symbol }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                            { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_surcom).toFixed(2)) }
                                                        </Grid>
                                                    </Fragment>
                                                )
                                            }
                                            <Grid item xs={6}>
                                                <span className={classes.bold}>
                                                    { t("menu.trip_list.total_margin") + " "}
                                                </span>
                                                <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                    { t("global.in") + " " + currency.symbol }
                                                </span>
                                            </Grid>
                                            <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_margin).toFixed(2)) }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                            {
                            // quotation_code !== "verdie" && (
                            //     <Fragment>
                            //         {
                            //             user.client_full.type === 2 && (
                            //                 <Grid item xs={12} container direction={"row"} justify={"center"} alignItems={"center"} className={clsx(classes.genericText, { [classes.marginTop20]: downDesktop, [classes.padding60]: !downDesktop })}>
                            //                     <Grid item sm={6} xs={12} container direction={"row"}>
                            //                         <Grid item xs={6}>
                            //                             <span className={classes.bold}>
                            //                                 {t("menu.trip_list.net_TO") + " "}
                            //                             </span>
                            //                             <span style={{ fontStyle: "italic", fontSize: 13 }}>
                            //                                 { t("global.in") + " " + currency.symbol }
                            //                             </span>
                            //                         </Grid>
                            //                         <Grid item xs={6} className={classes.textAlightRight}>
                            //                             { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format((total_network !== null && total_network !== undefined ? parseFloat(full_buying_price) - parseFloat(total_network) : parseFloat(full_buying_price)).toFixed(2)) }
                            //                         </Grid>
                            //                         {
                            //                             total_network !== null && total_network !== undefined && (
                            //                                 <Fragment>
                            //                                     <Grid item xs={6}>
                            //                                         <span className={classes.bold}>
                            //                                             { t("menu.trip_list.total_network_agency") + " "}
                            //                                         </span>
                            //                                         <span style={{ fontStyle: "italic", fontSize: 13 }}>
                            //                                             { t("global.in") + " " + currency.symbol }
                            //                                         </span>
                            //                                     </Grid>
                            //                                     <Grid item xs={6} className={classes.textAlightRight}>
                            //                                         { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_network).toFixed(2)) }
                            //                                     </Grid>
                            //                                     <Grid item xs={6}>
                            //                                         <span className={classes.bold}>
                            //                                             { t("menu.trip_list.total_buy_agency") + " "}
                            //                                         </span>
                            //                                         <span style={{ fontStyle: "italic", fontSize: 13 }}>
                            //                                             { t("global.in") + " " + currency.symbol }
                            //                                         </span>
                            //                                     </Grid>
                            //                                     <Grid item xs={6} className={classes.textAlightRight}>
                            //                                         { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(full_buying_price).toFixed(2)) }
                            //                                     </Grid>
                            //                                 </Fragment>
                            //                             )
                            //                         }
                            //                         <Grid item xs={6}>
                            //                             <span className={classes.bold}>
                            //                                 {t("menu.trip_list.total_margin_agency") + " "}
                            //                             </span>
                            //                             <span style={{ fontStyle: "italic", fontSize: 13 }}>
                            //                                 { t("global.in") + " " + currency.symbol }
                            //                             </span>
                            //                         </Grid>
                            //                         <Grid item xs={6} className={classes.textAlightRight}>
                            //                             { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_margin_agency).toFixed(2)) }
                            //                         </Grid>
                            //                         <Grid item xs={6}>
                            //                             <span className={classes.bold}>
                            //                                 {t("menu.trip_list.client_selling_price") + " "}
                            //                             </span>
                            //                             <span style={{ fontStyle: "italic", fontSize: 13 }}>
                            //                                 { t("global.in") + " " + currency.symbol }
                            //                             </span>
                            //                         </Grid>
                            //                         <Grid item xs={6} className={classes.textAlightRight}>
                            //                             { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format((parseFloat(full_buying_price) + parseFloat(total_margin_agency)).toFixed(2)) }
                            //                         </Grid>
                            //                     </Grid>
                            //                 </Grid>
                            //             )
                            //         }
                            //         {
                            //             user.client_full.type !== 2 && (
                            //                 <Grid item xs={12} container direction={"row"} justify={"space-between"} alignItems={"center"} className={clsx(classes.genericText, { [classes.marginTop20]: downDesktop, [classes.padding60]: !downDesktop })}>
                            //                     <Grid item sm={6} xs={12} container direction={"row"}>
                            //                         <Grid item xs={6}>
                            //                             <span className={classes.bold}>
                            //                                 {t("menu.trip_list.selling_price") + " "}
                            //                             </span>
                            //                             <span style={{ fontStyle: "italic", fontSize: 13 }}>
                            //                                 { t("global.in") + " " + currency.symbol }
                            //                             </span>
                            //                         </Grid>
                            //                         <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                            //                             { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_price).toFixed(2)) }
                            //                         </Grid>
                            //                         <Grid item xs={6}>
                            //                             <span className={classes.bold}>
                            //                                 {!['cercledesvoyages'].includes(quotation_code) ? t("menu.trip_list.total_buy") + " " : t("menu.trip_list.total_buy_fee")}
                            //                             </span>
                            //                             <span style={{ fontStyle: "italic", fontSize: 13 }}>
                            //                                 { t("global.in") + " " + currency.symbol }
                            //                             </span>
                            //                         </Grid>
                            //                         <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                            //                             { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(full_buying_price).toFixed(2)) }
                            //                         </Grid>
                            //                         {
                            //                             not_same_currency_cart.length > 0 && not_same_currency_cart.map((cart, cart_index) => (
                            //                                 <Fragment key={cart_index}>
                            //                                     <Grid item xs={12} container direction={"row"}>
                            //                                         <Grid item sm={6} xs={5}>
                            //                                             <div className={ "ft-font-12" }>{ cart_index === 0 ? "=" : "+" }<span className={ "ft-pl15 ft-font-12"}>{ t("menu.trip_list.currency_buy") }</span></div>
                            //                                         </Grid>
                            //                                         <Grid item sm={6} xs={7} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                            //                                             <div className={ "ft-font-12" }>{ new Intl.NumberFormat(language, { style: 'currency', currency: cart.currency.iso_code }).format(parseFloat(cart.price).toFixed(2)) + " -> " + new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(cart.converted_price).toFixed(2)) }</div>
                            //                                         </Grid>
                            //                                     </Grid>
                            //                                     <Grid item xs={12} container direction={"row"}>
                            //                                         <Grid item sm={6} xs={10}>
                            //                                             <div className={ "ft-font-12" }><span className={ "ft-pl21 ft-font-12"}>{ t("menu.trip_list.price_factor") + " " }<span className={ "ft-font-10" }>{ cart.currency.iso_code + " -> " + " " + currency.iso_code}</span></span></div>
                            //                                         </Grid>
                            //                                         <Grid item sm={6} xs={2} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                            //                                             <div className={ "ft-font-12" }>{ new Intl.NumberFormat(language).format(parseFloat(cart.factor_used).toFixed(2)) }</div>
                            //                                         </Grid>
                            //                                     </Grid>
                            //                                 </Fragment>
                            //                             ))
                            //                         }
                            //                         <Grid item xs={6}>
                            //                             <div className={ "ft-font-12" }>{not_same_currency_cart.length > 0 ? "+" : "=" }<span className={ "ft-pl15 ft-font-12" }>{ t("menu.trip_list.buy") }<span className={ "italic ft-font-11" }>{" " + t("global.in") + " " + currency.symbol }</span></span></div>
                            //                         </Grid>
                            //                         <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                            //                             <div className={ "ft-font-12" }>{ new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(only_main_currency_price).toFixed(2)) }</div>
                            //                         </Grid>
                            //                     </Grid>
                            //                     <Grid item sm={6} xs={12} container direction={"row"} className={clsx({ [classes.textAlightRight]: !downDesktop })}>
                            //                         {
                            //                             cashBack !== 0 && (
                            //                                 <Fragment>
                            //                                     <Grid item xs={6}>
                            //                                         <span className={classes.bold}>
                            //                                             { t("menu.trip_list.cashback") + " "}
                            //                                         </span>
                            //                                         <span style={{ fontStyle: "italic", fontSize: 13 }}>
                            //                                             { t("global.in") + " " + currency.symbol }
                            //                                         </span>
                            //                                     </Grid>
                            //                                     <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                            //                                         {Math.abs(cashBack) + " " + currency.symbol }
                            //                                     </Grid>
                            //                                 </Fragment>
                            //                             )
                            //                         }
                            //                         <Grid item xs={6}>
                            //                             <span className={classes.bold}>
                            //                                 { (quotation_code !== "cercledesvoyages" && quotation_code !== "continentsinsolites" ? t("menu.trip_list.TO_margin") : t("menu.trip_list.TO_margin_verdie")) + " "}
                            //                             </span>
                            //                             <span style={{ fontStyle: "italic", fontSize: 13 }}>
                            //                                 { t("global.in") + " " + currency.symbol }
                            //                             </span>
                            //                         </Grid>
                            //                         <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                            //                             { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(to_margin).toFixed(2)) }
                            //                         </Grid>
                            //                         {
                            //                             ['cercledesvoyages'].includes(quotation_code) && (
                            //                                 <Fragment>
                            //                                     <Grid item xs={6}>
                            //                                         <span className={classes.bold}>
                            //                                             { t("menu.trip_list.TO_margin_verdie") + " "}
                            //                                         </span>
                            //                                         <span style={{ fontStyle: "italic", fontSize: 13 }}>
                            //                                             { t("global.in") + " % " }
                            //                                         </span>
                            //                                     </Grid>
                            //                                     <Grid item xs={6} className={classes.textAlightRight}>
                            //                                         {new Intl.NumberFormat(language).format(parseFloat(((to_margin / total_price) * 100).toFixed(2)).toFixed(2)) + '%' }
                            //                                     </Grid>
                            //                                     {
                            //                                         tvaValue !== 0 && (
                            //                                             <Fragment>
                            //                                                 <Grid item xs={6}>
                            //                                                     <span className={classes.bold}>
                            //                                                         { t('cart-material.manual-product-margin-vat-value') + " "}
                            //                                                     </span>
                            //                                                     <span style={{ fontStyle: "italic", fontSize: 13 }}>
                            //                                                         { t("global.in") + " " + currency.symbol }
                            //                                                     </span>
                            //                                                 </Grid>
                            //                                                 <Grid item xs={6} className={classes.textAlightRight}>
                            //                                                     { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(tvaValue).toFixed(2)) }
                            //                                                 </Grid>
                            //                                             </Fragment>
                            //                                         )
                            //                                     }
                            //                                 </Fragment>
                            //                             )
                            //                         }
                            //                         {
                            //                             total_surcom !== null && total_surcom !== undefined && total_surcom !== 0 && quotation_code !== "marcovasco" && ((quotation_code !== "cercledesvoyages" && quotation_code !== "continentsinsolites") || user.groups.some(group => group.is_admin === true)) && (
                            //                                 <Fragment>
                            //                                     <Grid item xs={6}>
                            //                                         <span className={classes.bold}>
                            //                                             { (!['cercledesvoyages'].includes(quotation_code) ? t("menu.trip_list.total_surcom") : t("menu.trip_list.surcom")) + " "}
                            //                                         </span>
                            //                                         <span style={{ fontStyle: "italic", fontSize: 13 }}>
                            //                                             { t("global.in") + " " + currency.symbol }
                            //                                         </span>
                            //                                     </Grid>
                            //                                     <Grid item xs={6} className={classes.textAlightRight}>
                            //                                         {new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_surcom).toFixed(2)) }
                            //                                     </Grid>
                            //                                 </Fragment>
                            //                             )
                            //                         }
                            //                         {
                            //                             total_network !== null && total_network !== undefined && total_network !== 0 && quotation_code !== "marcovasco" && ((quotation_code !== "cercledesvoyages" && quotation_code !== "continentsinsolites") || user.groups.some(group => group.is_admin === true)) && (
                            //                                 <Fragment>
                            //                                     <Grid item xs={6}>
                            //                                         <span className={classes.bold}>
                            //                                             { t("menu.trip_list.total_network") + " "}
                            //                                         </span>
                            //                                         <span style={{ fontStyle: "italic", fontSize: 13 }}>
                            //                                             { t("global.in") + " " + currency.symbol }
                            //                                         </span>
                            //                                     </Grid>
                            //                                     <Grid item xs={6} className={classes.textAlightRight}>
                            //                                         { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_network).toFixed(2)) }
                            //                                     </Grid>
                            //                                 </Fragment>
                            //                             )
                            //                         }
                            //                         {
                            //                             ((quotation_code !== "cercledesvoyages" && quotation_code !== "continentsinsolites") || user.groups.some(group => group.is_admin === true)) && (
                            //                                 <Fragment>
                            //                                     <Grid item xs={6}>
                            //                                         <span className={classes.bold}>
                            //                                             { (!['cercledesvoyages'].includes(quotation_code) ? t("menu.trip_list.total_margin") : t("menu.trip_list.total_margin_trip")) + " "}
                            //                                         </span>
                            //                                         <span style={{ fontStyle: "italic", fontSize: 13 }}>
                            //                                             { t("global.in") + " " + currency.symbol }
                            //                                         </span>
                            //                                     </Grid>
                            //                                     <Grid item xs={6} className={classes.textAlightRight}>
                            //                                         { !['cercledesvoyages'].includes(quotation_code) ?
                            //                                             new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_margin).toFixed(2))
                            //                                             :
                            //                                             new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format((parseFloat(total_margin) + parseFloat(total_surcom)).toFixed(2))
                            //                                         }
                            //                                     </Grid>
                            //                                 </Fragment>
                            //                             )
                            //                         }
                            //                     </Grid>
                            //                 </Grid>
                            //             )
                            //         }
                            //         <Grid item xs={6}>
                            //             <span className={classes.bold}>
                            //                 { t("menu.trip_list.total_margin") + " "}
                            //             </span>
                            //             <span style={{fontStyle: "italic", fontSize: 13}}>
                            //                 { t("global.in") + " " + currency.symbol }
                            //             </span>
                            //         </Grid>
                            //         <Grid item xs={6} className={clsx({[classes.textAlightRight]: downDesktop})}>
                            //             { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_margin).toFixed(2)) }
                            //         </Grid>
                            //     </Fragment>
                            // )
                            }
                            {
                                quotation_code !== "verdie" && (
                                    <Fragment>
                                        {
                                            user.client_full.type === 2 && (
                                                <Grid item xs={12} container direction={"row"} justify={"center"} alignItems={"center"} className={clsx(classes.genericText, { [classes.marginTop20]: downDesktop, [classes.padding60]: !downDesktop })}>
                                                    <Grid item sm={6} xs={12} container direction={"row"}>
                                                        <Grid item xs={6}>
                                                            <span className={classes.bold}>
                                                                {t("menu.trip_list.net_TO") + " "}
                                                            </span>
                                                            <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                { t("global.in") + " " + currency.symbol }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.textAlightRight}>
                                                            { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format((total_network !== null && total_network !== undefined ? parseFloat(full_buying_price) - parseFloat(total_network) : parseFloat(full_buying_price)).toFixed(2)) }
                                                        </Grid>
                                                        {
                                                            total_network !== null && total_network !== undefined && (
                                                                <Fragment>
                                                                    <Grid item xs={6}>
                                                                        <span className={classes.bold}>
                                                                            { t("menu.trip_list.total_network_agency") + " "}
                                                                        </span>
                                                                        <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                            { t("global.in") + " " + currency.symbol }
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid item xs={6} className={classes.textAlightRight}>
                                                                        { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_network).toFixed(2)) }
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <span className={classes.bold}>
                                                                            { t("menu.trip_list.total_buy_agency") + " "}
                                                                        </span>
                                                                        <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                            { t("global.in") + " " + currency.symbol }
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid item xs={6} className={classes.textAlightRight}>
                                                                        { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(full_buying_price).toFixed(2)) }
                                                                    </Grid>
                                                                </Fragment>
                                                            )
                                                        }
                                                        <Grid item xs={6}>
                                                            <span className={classes.bold}>
                                                                {t("menu.trip_list.total_margin_agency") + " "}
                                                            </span>
                                                            <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                { t("global.in") + " " + currency.symbol }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.textAlightRight}>
                                                            { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_margin_agency).toFixed(2)) }
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <span className={classes.bold}>
                                                                {t("menu.trip_list.client_selling_price") + " "}
                                                            </span>
                                                            <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                { t("global.in") + " " + currency.symbol }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6} className={classes.textAlightRight}>
                                                            { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format((parseFloat(full_buying_price) + parseFloat(total_margin_agency)).toFixed(2)) }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                        {
                                            user.client_full.type !== 2 && (
                                                <Grid item xs={12} container direction={"row"} justify={"space-between"} alignItems={"center"} className={clsx(classes.genericText, { [classes.marginTop20]: downDesktop, [classes.padding60]: !downDesktop })}>
                                                    <Grid item sm={6} xs={12} container direction={"row"}>
                                                        <Grid item xs={6}>
                                                            <span className={classes.bold}>
                                                                {t("menu.trip_list.selling_price") + " "}
                                                            </span>
                                                            <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                { t("global.in") + " " + currency.symbol }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                            { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_price).toFixed(2)) }
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <span className={classes.bold}>
                                                                {!['cercledesvoyages'].includes(quotation_code) ? t("menu.trip_list.total_buy") + " " : t("menu.trip_list.total_buy_fee")}
                                                            </span>
                                                            <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                { t("global.in") + " " + currency.symbol }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                            { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(full_buying_price).toFixed(2)) }
                                                        </Grid>
                                                        {
                                                            not_same_currency_cart.length > 0 && not_same_currency_cart.map((cart, cart_index) => (
                                                                <Fragment key={cart_index}>
                                                                    <Grid item xs={12} container direction={"row"}>
                                                                        <Grid item sm={6} xs={5}>
                                                                            <div className={ "ft-font-12" }>{ cart_index === 0 ? "=" : "+" }<span className={ "ft-pl15 ft-font-12"}>{ t("menu.trip_list.currency_buy") }</span></div>
                                                                        </Grid>
                                                                        <Grid item sm={6} xs={7} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                                            <div className={ "ft-font-12" }>{ new Intl.NumberFormat(language, { style: 'currency', currency: cart.currency.iso_code }).format(parseFloat(cart.price).toFixed(2)) + " -> " + new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(cart.converted_price).toFixed(2)) }</div>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={12} container direction={"row"}>
                                                                        <Grid item sm={6} xs={10}>
                                                                            <div className={ "ft-font-12" }><span className={ "ft-pl21 ft-font-12"}>{ t("menu.trip_list.price_factor") + " " }<span className={ "ft-font-10" }>{ cart.currency.iso_code + " -> " + " " + currency.iso_code}</span></span></div>
                                                                        </Grid>
                                                                        <Grid item sm={6} xs={2} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                                            <div className={ "ft-font-12" }>{ new Intl.NumberFormat(language).format(parseFloat(cart.factor_used)) }</div>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Fragment>
                                                            ))
                                                        }
                                                        <Grid item xs={6}>
                                                            <div className={ "ft-font-12" }>{not_same_currency_cart.length > 0 ? "+" : "=" }<span className={ "ft-pl15 ft-font-12" }>{ t("menu.trip_list.buy") }<span className={ "italic ft-font-11" }>{" " + t("global.in") + " " + currency.symbol }</span></span></div>
                                                        </Grid>
                                                        <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                            <div className={ "ft-font-12" }>{ new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(only_main_currency_price).toFixed(2)) }</div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item sm={6} xs={12} container direction={"row"} className={clsx({ [classes.textAlightRight]: !downDesktop })}>
                                                        {
                                                            cashBack !== 0 && (
                                                                <Fragment>
                                                                    <Grid item xs={6}>
                                                                        <span className={classes.bold}>
                                                                            { t("menu.trip_list.cashback") + " "}
                                                                        </span>
                                                                        <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                            { t("global.in") + " " + currency.symbol }
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                                        {Math.abs(cashBack) + " " + currency.symbol }
                                                                    </Grid>
                                                                </Fragment>
                                                            )
                                                        }
                                                        <Grid item xs={6}>
                                                            <span className={classes.bold}>
                                                                { (!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code) ? t("menu.trip_list.TO_margin") : t("menu.trip_list.TO_margin_verdie")) + " "}
                                                            </span>
                                                            <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                { t("global.in") + " " + currency.symbol }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6} className={clsx({ [classes.textAlightRight]: downDesktop })}>
                                                            { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(to_margin).toFixed(2)) }
                                                        </Grid>
                                                        {
                                                            ['cercledesvoyages'].includes(quotation_code) && (
                                                                <Fragment>
                                                                    <Grid item xs={6}>
                                                                        <span className={classes.bold}>
                                                                            { t("menu.trip_list.TO_margin_verdie") + " "}
                                                                        </span>
                                                                        <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                            { t("global.in") + " % " }
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid item xs={6} className={classes.textAlightRight}>
                                                                        {new Intl.NumberFormat(language).format(parseFloat(((to_margin / total_price) * 100).toFixed(2)).toFixed(2)) + '%' }
                                                                    </Grid>
                                                                    {
                                                                        tvaValue !== 0 && (
                                                                            <Fragment>
                                                                                <Grid item xs={6}>
                                                                                    <span className={classes.bold}>
                                                                                        { t('cart-material.manual-product-margin-vat-value') + " "}
                                                                                    </span>
                                                                                    <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                                        { t("global.in") + " " + currency.symbol }
                                                                                    </span>
                                                                                </Grid>
                                                                                <Grid item xs={6} className={classes.textAlightRight}>
                                                                                    { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(tvaValue).toFixed(2)) }
                                                                                </Grid>
                                                                            </Fragment>
                                                                        )
                                                                    }
                                                                </Fragment>
                                                            )
                                                        }
                                                        {
                                                            total_surcom !== null && total_surcom !== undefined && total_surcom !== 0 && quotation_code !== "marcovasco" && (!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code) || user.groups.some(group => group.is_admin === true)) && (
                                                                <Fragment>
                                                                    <Grid item xs={6}>
                                                                        <span className={classes.bold}>
                                                                            { (!['cercledesvoyages'].includes(quotation_code) ? t("menu.trip_list.total_surcom") : t("menu.trip_list.surcom")) + " "}
                                                                        </span>
                                                                        <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                            { t("global.in") + " " + currency.symbol }
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid item xs={6} className={classes.textAlightRight}>
                                                                        {new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_surcom).toFixed(2)) }
                                                                    </Grid>
                                                                </Fragment>
                                                            )
                                                        }
                                                        {
                                                            total_network !== null && total_network !== undefined && total_network !== 0 && quotation_code !== "marcovasco" && (!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code) || user.groups.some(group => group.is_admin === true)) && (
                                                                <Fragment>
                                                                    <Grid item xs={6}>
                                                                        <span className={classes.bold}>
                                                                            { t("menu.trip_list.total_network") + " "}
                                                                        </span>
                                                                        <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                            { t("global.in") + " " + currency.symbol }
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid item xs={6} className={classes.textAlightRight}>
                                                                        { new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_network).toFixed(2)) }
                                                                    </Grid>
                                                                </Fragment>
                                                            )
                                                        }
                                                        {
                                                            (!["cercledesvoyages", "continentsinsolites", "tropicalementvotre", 'terreslointaines', 'touristforum'].includes(quotation_code) || user.groups.some(group => group.is_admin === true)) && (
                                                                <Fragment>
                                                                    <Grid item xs={6}>
                                                                        <span className={classes.bold}>
                                                                            { (!['cercledesvoyages'].includes(quotation_code) ? t("menu.trip_list.total_margin") : t("menu.trip_list.total_margin_trip")) + " "}
                                                                        </span>
                                                                        <span style={{ fontStyle: "italic", fontSize: 13 }}>
                                                                            { t("global.in") + " " + currency.symbol }
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid item xs={6} className={classes.textAlightRight}>
                                                                        { !['cercledesvoyages'].includes(quotation_code) ?
                                                                            new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format(parseFloat(total_margin).toFixed(2))
                                                                            :
                                                                            new Intl.NumberFormat(language, { style: 'currency', currency: currency.iso_code }).format((parseFloat(total_margin) + parseFloat(total_surcom)).toFixed(2))
                                                                        }
                                                                    </Grid>
                                                                </Fragment>
                                                            )
                                                        }
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </Fragment>
                                )
                            }
                        </Fragment>
                    )
                }
            </Grid>
            {
                !get_total_price && exportData.length === 0 && exportDataCSV.length === 0 && (
                    <div className={clsx({ [classes.marginTop20]: downDesktop, [classes.padding60]: !downDesktop })}>
                        <LinearProgress />
                    </div>
                )
            }
            {
                big_cart !== null && big_cart.length > 0 && user.client_full.type !== 2 && (
                    <Fragment>
                        <Divider/>
                        <Grid container style={{ marginTop: 20 }}>
                            <Grid item container direction={"row"} justify={"center"} alignItems={"center"}>
                                <Grid item>
                                    <Typography variant={"h4"} className={classes.genericText} style={{ textAlign: "center" }}>{t("menu.trip_list.buy_detail")}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container direction={"row"} justify={!downDesktop ? "flex-end" : "center"} alignItems={"center"} spacing={1} style={{ marginBottom: 20, marginTop: 10 }}>
                                {
                                    exportDataCSV.length !== 0 && (
                                        <Grid item>
                                            <CSVLink
                                                data={exportDataCSV}
                                                filename={ id + " " + t("menu.trip_finance_file_name") + ".csv" }
                                                className={`${classes.csvButton} ${classes.fontSize11}`}
                                                target={"_blank"}
                                                onClick={() => recordExport(`${id} ${t("menu.trip_finance_file_name")}.csv`)}
                                            >Csv</CSVLink>
                                        </Grid>
                                    )
                                }
                                {
                                    quotation_code !== "visiteurs" && exportData.length !== 0 && (
                                        <Grid item>
                                            <Workbook
                                                filename={ id + " " + t("menu.trip_finance_file_name") + ".xlsx"}
                                                element={
                                                    <a className={`${classes.csvButton} ${classes.fontSize11}`} onClick={() => recordExport(`${id} ${t("menu.trip_finance_file_name")}.xlsx`)}>Excel</a>
                                                }>
                                                <Workbook.Sheet data={exportData} name={ id.toString() }>
                                                    <Workbook.Column label={t("menu.file_nb")} value={"id"}/>
                                                    <Workbook.Column label={t("global.date")} value={"date"}/>
                                                    <Workbook.Column label={t("menu.trip_list.product")} value={"product"}/>
                                                    <Workbook.Column label={t("filters.providers")} value={"provider"}/>
                                                    <Workbook.Column label={t("global.description")} value={"description"}/>
                                                    <Workbook.Column label={t("global.room")} value={"room_name"}/>
                                                    <Workbook.Column label={t("menu.trip_list.reference")} value={"reference"}/>
                                                    <Workbook.Column label={t("cart.edit_booking.booking_status")} value={"booking_status"}/>
                                                    <Workbook.Column label={t("menu.trip_list.amount_purchase")} value={"amount_1"}/>
                                                    <Workbook.Column label={t("global.in")} value={"in_1"}/>
                                                    <Workbook.Column label={t("menu.trip_list.rate")} value={"rate"}/>
                                                    <Workbook.Column label={t("menu.trip_list.amount")} value={"amount_2"}/>
                                                    <Workbook.Column label={t("global.in")} value={"in_2"}/>
                                                    <Workbook.Column label={t("menu.trip_list.amount_selling")} value={"amount_3"}/>
                                                    <Workbook.Column label={t("global.in")} value={"in_3"}/>
                                                </Workbook.Sheet>
                                            </Workbook>
                                        </Grid>
                                    )
                                }
                                {
                                    quotation_code === "visiteurs" && exportData.length !== 0 && (
                                        <Grid>
                                            <a className={`${classes.csvButton} ${classes.fontSize11}`} onClick={exportDataFile}>Excel</a>
                                        </Grid>
                                    )
                                }
                            </Grid>
                            {
                                !downDesktop && (
                                    <TableContainer component={Paper}>
                                        <Table id={"buy-detail"} size="small" className={classes.genericText}>
                                            <TableHead>
                                                <TableRow className={classes.paddingCell}>
                                                    {
                                                        columns.map((column, column_index) => {
                                                            return (
                                                                <TableCell align={"left"} key={column_index}>
                                                                    {column}
                                                                </TableCell>
                                                            );
                                                        })
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    manual_cart &&
                                                    <RenderPackage cart={packagedProducts}/>
                                                }
                                                {
                                                    big_cart.map((item, item_index) => {
                                                        if (!item.is_optional && !isProductPackaged({ product: item, stackInfos: data_trip?.stack_info ?? null })) {
                                                            return (
                                                                <RenderItem
                                                                    key={item_index}
                                                                    item={item}
                                                                    providers={providers}
                                                                    manual_providers={manual_providers}
                                                                    language={language}
                                                                    list_currency={list_currency}
                                                                    owner_id={owner_id}
                                                                    user={user}
                                                                    currency={currency}
                                                                />
                                                            );
                                                        }
                                                    })
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )
                            }
                        </Grid>
                    </Fragment>
                )
            }
        </Fragment>

    );
};
export default React.memo(TripListFinance);
