import { useMemo } from "react";
import { useSelector } from "react-redux";
import { chain, flatten, isNumber, mergeWith, uniq, uniqBy } from "lodash";
import { useFindFlightsBoParams } from "./findFlightsBoParams";
import { FlightBoParams } from "../objects/flightBoParams";
import { FlightSearchItem } from "../objects/flightSearchItem";
import { AppState } from "../../../Reducers/Reducers";

type Callback = (flights: FlightSearchItem[], params: FlightBoParams[]) => FlightSearchItem[]

export function useFilterFlightsWithBoParams(): Callback {
    const user = useSelector((state: AppState) => state.user.user);
    const flightSearchAirports = useSelector((state: AppState) => state.flight_search.airports);
    const airports = useMemo(() => {
        return Object.values(flightSearchAirports ?? {});
    }, [flightSearchAirports]);
    const flightSearchAirportsWithParents = useMemo(() => {
        return chain(airports).groupBy(
            (airport) => airport.iata_city.id
        ).mapValues((airports) => ({
            parents: airports.filter((airport) => {
                return airport.code.startsWith('c')
            }).map((airport) => {
                return airport.id;
            }),
            children: airports.filter((airport) => {
                return !airport.code.startsWith('c')
            }).map((airport) => {
                return airport.id;
            }),
        })).value();
    }, [airports]);
    const findBoParams = useFindFlightsBoParams();

    return (flights, params) => {
        const hasOfflineFlights = flights.some((flight) => {
            return flight.flight_source === 'CUSTOM_FLIGHT'
        });
        return flights.filter((flight) => {
            const lastOutbound = flight.outbounds[flight.outbounds.length - 1];
            const lastLeg = lastOutbound?.legs[lastOutbound.legs.length - 1];
            const boParams = findBoParams(
                {
                    startDate: flight.outbounds[0]?.legs[0]?.departure_datetime_lt ?? '',
                    endDate: lastLeg?.arrival_datetime_lt ?? ''
                },
                params
            );

            // treat facilitatrip users as TO users
            const clientType = user?.client_full?.type === 3 ?
                1 :
                user?.client_full?.type;

            // do not apply any filter if there is no bo params or if user client type is not in allowed_client_type
            if (!boParams || (isNumber(clientType) && !boParams.allowed_client_type?.includes(clientType))) {
                return true;
            }

            const boParamsAirports = uniqBy(
                (boParams.allowed_arrival_airport ?? []).concat(
                    boParams.allowed_departure_airport ?? []
                ).concat(
                    boParams.allowed_stopovers_airport ?? []
                ).concat(
                    boParams.disallowed_arrival_airport ?? []
                ).concat(
                    boParams.disallowed_departure_airport ?? []
                ).concat(
                    boParams.disallowed_stopovers_airport ?? []
                ),
                (airport) => airport.id
            );
            let airportsWithParents = chain(boParamsAirports).groupBy(
                (airport) => airport.iata_city.id
            ).mapValues((airports) => ({
                parents: airports.filter((airport) => {
                    return airport.airport_code.startsWith('c')
                }).map((airport) => {
                    return airport.id;
                }),
                children: airports.filter((airport) => {
                    return !airport.airport_code.startsWith('c')
                }).map((airport) => {
                    return airport.id;
                }),
            })).value();
            airportsWithParents = mergeWith(
                flightSearchAirportsWithParents,
                airportsWithParents,
                (a, b) => {
                    return {
                        parents: (a?.parents ?? []).concat(b?.parents ?? []),
                        children: (a?.children ?? []).concat(b?.children ?? [])
                    };
                }
            );

            if (boParams.disallowed_flight) {
                return false;
            }

            // check if manual flights are allowed
            if (
                boParams.allow_number_of_allotment === false &&
                flight.flight_source === 'CUSTOM_FLIGHT'
            ) {
                return false;
            }

            // check hide_gds_response_on_manual_response
            if (
                boParams.allow_number_of_allotment &&
                boParams.hide_gds_response_on_manual_response &&
                hasOfflineFlights &&
                flight.flight_source !== 'CUSTOM_FLIGHT'
            ) {
                return false;
            }

            const cabins = boParams.cabin?.map((item) => {
                return item.toLowerCase();
            }) ?? [];
            // check if at least one leg has a cabin that is not allowed
            if (
                cabins.length > 0 &&
                flight.outbounds.some((outbound) => {
                    return outbound.legs.some((leg) => {
                        return cabins.length > 0 &&
                            (
                                !leg.cabin_class ||
                                !cabins.includes(leg.cabin_class.toLowerCase())
                            );
                    });
                })
            ) {
                return false;
            }

            const classOfServices = boParams.tariff_letters?.map((item) => {
                return item.toLowerCase();
            }) ?? [];
            // check if at least one leg has a class of service that is not allowed
            if (
                classOfServices.length > 0 &&
                flight.outbounds.some((outbound) => {
                    return outbound.legs.some((leg) => {
                        return classOfServices.length > 0 &&
                            (
                                !leg.booking_code ||
                                !classOfServices.includes(leg.booking_code.toLowerCase())
                            );
                    })
                })
            ) {
                return false;
            }

            // check if at least one outbound has an incorrect number of allowed luggages
            if (
                (boParams.number_of_bags?.length ?? 0) > 0 &&
                flight.outbounds.some((outbound) => {
                    let temp_carry_on = 0;
                    let temp_check = 0;
                    let paid_carry_on = 0;
                    let paid_check = 0;
                    let paid_options = {};
                    if (outbound.legs[0]?.baggage) {
                        outbound.legs[0].baggage.map((luggage) => {
                            if (luggage.is_carry_on) {
                                temp_carry_on += luggage.quantity;
                            } else {
                                temp_check += luggage.quantity;
                            }
                        });
                    } else {
                        temp_check = outbound.legs[0]?.baggage_allowance?.number_of_pieces ?? 0;
                    }

                    const totalLuggages = temp_check + temp_carry_on + paid_check + paid_carry_on;

                    return !boParams.number_of_bags!.includes(totalLuggages) &&
                        (
                            totalLuggages < 2 ||
                            !boParams.number_of_bags!.includes(2)
                        );
                })
            ) {
                return false;
            }

            // check gds providers
            if (
                boParams.gds_source &&
                flight.flight_source !== 'CUSTOM_FLIGHT' &&
                (boParams.providers?.length ?? 0) > 0 &&
                !boParams.providers?.some((provider) => {
                    return provider.id === flight.provider_id;
                })
            ) {
                return false;
            }

            // check ndc private price
            if (
                boParams.ndc_TO_offer === false &&
                flight.fare_type !== 'PUBLISHED' &&
                flight.flight_source === 'NDC'
            ) {
                return false;
            }

            // check ndc public price
            if (
                boParams.public_ndc_offer === false &&
                flight.fare_type === 'PUBLISHED' &&
                flight.flight_source === 'NDC'
            ) {
                return false;
            }

            // check private standard price
            if (
                boParams.standard_TO_offer === false &&
                flight.fare_type !== 'PUBLISHED' &&
                flight.flight_source !== 'NDC'
            ) {
                return false;
            }

            // check public standard price
            if (
                boParams.public_standard_offer === false &&
                flight.fare_type === 'PUBLISHED' &&
                flight.flight_source !== 'NDC'
            ) {
                return false;
            }

            // check if stopovers are allowed
            if (
                !boParams.allow_stopovers &&
                flight.outbounds.some((outbound) => {
                    return outbound.legs.length > 1;
                })
            ) {
                return false;
            }

            // check stopover parameters
            const allowedStopoverAirports = uniq(
                (
                    boParams.allowed_stopovers_airport?.map((airport) => {
                        return airport.id;
                    }) ?? []
                ).concat(
                    flatten(
                        boParams.allowed_stopovers_airport?.filter((airport) => {
                            return airport.airport_code.startsWith('c');
                        }).map((airport) => {
                            return airportsWithParents[airport.iata_city.id]?.parents.includes(airport.id) ?
                                airportsWithParents[airport.iata_city.id]?.children ?? [] :
                                [];
                        }) ?? []
                    )
                )
            );
            const disallowedStopoverAirports = uniq(
                (
                    boParams.disallowed_stopovers_airport?.map((airport) => {
                        return airport.id;
                    }) ?? []
                ).concat(
                    flatten(
                        boParams.disallowed_stopovers_airport?.filter((airport) => {
                            return airport.airport_code.startsWith('c');
                        }).map((airport) => {
                            return airportsWithParents[airport.iata_city.id]?.parents.includes(airport.id) ?
                                airportsWithParents[airport.iata_city.id]?.children ?? [] :
                                [];
                        }) ?? []
                    )
                )
            );

            if (
                boParams.allow_stopovers &&
                (
                    (
                        boParams.max_stops &&
                        flight.outbounds.some((outbound) => {
                            return outbound.legs.length - 1 > parseInt(boParams.max_stops!);
                        })
                    ) ||
                    (
                        boParams.max_hour_spent_on_stopover &&
                        flight.outbounds.some((outbound) => {
                            return outbound.legs.reduce((prev, current) => {
                                return {
                                    arrival: current.arrival_datetime_lt,
                                    count: prev.count + (
                                        prev.arrival ?
                                            window.moment.utc(current.departure_datetime_lt).diff(
                                                window.moment.utc(prev.arrival),
                                                'hours'
                                            ) :
                                            0
                                    )
                                };
                            }, {
                                arrival: null,
                                count: 0
                            } as {
                                arrival: string | null,
                                count: number
                            }).count >= parseFloat(boParams.max_hour_spent_on_stopover!)
                        })
                    ) ||
                    (
                        allowedStopoverAirports.length > 0 &&
                        flight.outbounds.some((outbound) => {
                            const stopoverAirports = outbound.legs.slice(0, outbound.legs.length - 1).map((item) => {
                                return item.destination_airport;
                            }).filter(isNumber);
                            return stopoverAirports.some((airport) => {
                                return !allowedStopoverAirports.includes(airport);
                            });
                        })
                    ) ||
                    (
                        disallowedStopoverAirports.length > 0 &&
                        flight.outbounds.some((outbound) => {
                            const stopoverAirports = outbound.legs.slice(0, outbound.legs.length - 1).map((item) => {
                                return item.destination_airport;
                            }).filter(isNumber);
                            return stopoverAirports.some((airport) => {
                                return disallowedStopoverAirports.includes(airport);
                            });
                        })
                    )
                )
            ) {
                return false;
            }

            const allowedDepartureAirports = uniq(
                (
                    boParams.allowed_departure_airport?.map((airport) => {
                        return airport.id;
                    }) ?? []
                ).concat(
                    flatten(
                        boParams.allowed_departure_airport?.filter((airport) => {
                            return airport.airport_code.startsWith('c');
                        }).map((airport) => {
                            return airportsWithParents[airport.iata_city.id]?.parents.includes(airport.id) ?
                                airportsWithParents[airport.iata_city.id]?.children ?? [] :
                                [];
                        }) ?? []
                    )
                )
            );
            // check if departure airport is allowed
            if (
                allowedDepartureAirports.length > 0 &&
                flight.outbounds[0]?.legs[0]?.origin_airport &&
                !allowedDepartureAirports.includes(flight.outbounds[0].legs[0].origin_airport)
            ) {
                return false;
            }

            const disallowedDepartureAirports = uniq(
                (
                    boParams.disallowed_departure_airport?.map((airport) => {
                        return airport.id;
                    }) ?? []
                ).concat(
                    flatten(
                        boParams.disallowed_departure_airport?.filter((airport) => {
                            return airport.airport_code.startsWith('c');
                        }).map((airport) => {
                            return airportsWithParents[airport.iata_city.id]?.parents.includes(airport.id) ?
                                airportsWithParents[airport.iata_city.id]?.children ?? [] :
                                [];
                        }) ?? []
                    )
                )
            );
            // check if departure airport is disallowed
            if (
                disallowedDepartureAirports.length > 0 &&
                flight.outbounds[0]?.legs[0]?.origin_airport &&
                disallowedDepartureAirports.includes(flight.outbounds[0].legs[0].origin_airport)
            ) {
                return false;
            }

            const arrivalLeg = flight.outbounds[0]?.legs[flight.outbounds[0].legs.length - 1];

            const allowedArrivalAirports = uniq(
                (
                    boParams.allowed_arrival_airport?.map((airport) => {
                        return airport.id;
                    }) ?? []
                ).concat(
                    flatten(
                        boParams.allowed_arrival_airport?.filter((airport) => {
                            return airport.airport_code.startsWith('c');
                        }).map((airport) => {
                            return airportsWithParents[airport.iata_city.id]?.parents.includes(airport.id) ?
                                airportsWithParents[airport.iata_city.id]?.children ?? [] :
                                [];
                        }) ?? []
                    )
                )
            );
            // check if arrival airport is allowed
            if (
                allowedArrivalAirports.length > 0 &&
                arrivalLeg?.destination_airport &&
                !allowedArrivalAirports.includes(arrivalLeg.destination_airport)
            ) {
                return false;
            }

            const disallowedArrivalAirports = uniq(
                (
                    boParams.disallowed_arrival_airport?.map((airport) => {
                        return airport.id;
                    }) ?? []
                ).concat(
                    flatten(
                        boParams.disallowed_arrival_airport?.filter((airport) => {
                            return airport.airport_code.startsWith('c');
                        }).map((airport) => {
                            return airportsWithParents[airport.iata_city.id]?.parents.includes(airport.id) ?
                                airportsWithParents[airport.iata_city.id]?.children ?? [] :
                                [];
                        }) ?? []
                    )
                )
            );
            // check if arrival airport is disallowed
            if (
                disallowedArrivalAirports.length > 0 &&
                arrivalLeg?.destination_airport &&
                disallowedArrivalAirports.includes(arrivalLeg.destination_airport)
            ) {
                return false;
            }

            // check if airports change is not allowed
            if (
                !boParams.change_airport &&
                flight.outbounds[0]?.legs[0]?.origin_airport &&
                flight.outbounds[0]?.legs[0]?.destination_airport &&
                lastLeg?.destination_airport &&
                lastLeg?.origin_airport &&
                (
                    flight.outbounds[0]?.legs[0]?.origin_airport !== lastLeg?.destination_airport ||
                    flight.outbounds[0]?.legs[0]?.destination_airport !== lastLeg?.origin_airport
                )
            ) {
                return false;
            }

            const allowedAirlines = boParams.allowed_airline?.map((airline) => {
                return airline.id;
            }) ?? [];
            // check for allowed airlines
            if (
                allowedAirlines.length > 0 &&
                flight.outbounds.some((outbound) => {
                    return outbound.legs.some((leg) => {
                        return leg.marketing_airline && !allowedAirlines.includes(leg.marketing_airline as unknown as number);
                    });
                })
            ) {
                return false;
            }

            const disallowedAirlines = boParams.disallowed_airline?.map((airline) => {
                return airline.id;
            }) ?? [];
            // check for disallowed airlines
            if (
                disallowedAirlines.length > 0 &&
                flight.outbounds.some((outbound) => {
                    return outbound.legs.some((leg) => {
                        return leg.marketing_airline && disallowedAirlines.includes(leg.marketing_airline as unknown as number);
                    });
                })
            ) {
                return false;
            }

            // check for minimum departure time in hours for the departure outbound
            if (
                boParams.start_departure_time &&
                flight.outbounds[0]?.legs[0]?.departure_datetime_lt &&
                (
                    window.moment.utc(flight.outbounds[0]?.legs[0]?.departure_datetime_lt).get('hours') * 3600 +
                    window.moment.utc(flight.outbounds[0]?.legs[0]?.departure_datetime_lt).get('minutes') * 60
                ) < boParams.start_departure_time
            ) {
                return false;
            }

            // check for maximum departure time in hours for the departure outbound
            if (
                boParams.end_departure_time &&
                (
                    window.moment.utc(flight.outbounds[0]?.legs[0]?.departure_datetime_lt).get('hours') * 3600 +
                    window.moment.utc(flight.outbounds[0]?.legs[0]?.departure_datetime_lt).get('minutes') * 60
                ) > boParams.end_departure_time
            ) {
                return false;
            }

            const lastDepartureLeg = flight.outbounds[0]?.legs[flight.outbounds[0]?.legs.length - 1];

            // check for minimum arrival time in hours for the departure outbound
            if (
                boParams.start_arrival_time &&
                lastDepartureLeg?.arrival_datetime_lt &&
                (
                    window.moment.utc(lastDepartureLeg?.arrival_datetime_lt).get('hours') * 3600 +
                    window.moment.utc(lastDepartureLeg?.arrival_datetime_lt).get('minutes') * 60
                ) < boParams.start_arrival_time
            ) {
                return false;
            }

            // check for maximum arrival time in hours for the departure outbound
            if (
                boParams.end_arrival_time &&
                (
                    window.moment.utc(lastDepartureLeg?.arrival_datetime_lt).get('hours') * 3600 +
                    window.moment.utc(lastDepartureLeg?.arrival_datetime_lt).get('minutes') * 60
                ) > boParams.end_arrival_time
            ) {
                return false;
            }

            // check for minimum departure time in hours for the return outbound
            if (
                boParams.start_departure_time_inbound &&
                lastOutbound?.legs[0]?.departure_datetime_lt &&
                (
                    window.moment.utc(lastOutbound?.legs[0]?.departure_datetime_lt).get('hours') * 3600 +
                    window.moment.utc(lastOutbound?.legs[0]?.departure_datetime_lt).get('minutes') * 60
                ) < boParams.start_departure_time_inbound
            ) {
                return false;
            }

            // check for maximum departure time in hours for the return outbound
            if (
                boParams.end_departure_time_inbound &&
                lastOutbound?.legs[0]?.departure_datetime_lt &&
                (
                    window.moment.utc(lastOutbound?.legs[0]?.departure_datetime_lt).get('hours') * 3600 +
                    window.moment.utc(lastOutbound?.legs[0]?.departure_datetime_lt).get('minutes') * 60
                ) > boParams.end_departure_time_inbound
            ) {
                return false;
            }

            const lastReturnLeg = lastOutbound?.legs[lastOutbound?.legs.length - 1];

            // check for minimum arrival time in hours for the return outbound
            if (
                boParams.start_arrival_time_inbound &&
                lastReturnLeg?.arrival_datetime_lt &&
                (
                    window.moment.utc(lastReturnLeg?.arrival_datetime_lt).get('hours') * 3600 +
                    window.moment.utc(lastReturnLeg?.arrival_datetime_lt).get('minutes') * 60
                ) < boParams.start_arrival_time_inbound
            ) {
                return false;
            }

            // check for maximum arrival time in hours for the return outbound
            if (
                boParams.end_arrival_time_inbound &&
                lastReturnLeg?.arrival_datetime_lt &&
                (
                    window.moment.utc(lastReturnLeg?.arrival_datetime_lt).get('hours') * 3600 +
                    window.moment.utc(lastReturnLeg?.arrival_datetime_lt).get('minutes') * 60
                ) > boParams.end_arrival_time_inbound
            ) {
                return false;
            }

            // check for minimum flight duration
            if (
                boParams.flight_duration_min &&
                flight.outbounds.some((outbound) => {
                    return outbound.flight_duration && outbound.flight_duration < boParams.flight_duration_min!;
                })
            ) {
                return false;
            }

            // check for maximum flight duration
            if (
                boParams.flight_duration_max &&
                flight.outbounds.some((outbound) => {
                    return outbound.flight_duration && outbound.flight_duration > boParams.flight_duration_max!;
                })
            ) {
                return false;
            }

            const departureDay = parseInt(window.moment.utc(flight.outbounds[0]?.legs[0]?.departure_datetime_lt).format('d'));
            // check for allowed week departure day
            if (
                (boParams.departure_dow?.length ?? 0) > 0 &&
                !boParams.departure_dow!.includes(departureDay)
            ) {
                return false;
            }

            const returnDay = parseInt(window.moment.utc(lastLeg?.arrival_datetime_lt).format('d'));
            // check for allowed week arrival day
            if (
                (boParams.arrival_dow?.length ?? 0) > 0 &&
                !boParams.arrival_dow!.includes(returnDay)
            ) {
                return false;
            }

            return true;
        }).map((flight) => {
            if (flight.flight_source === 'CUSTOM_FLIGHT') {
                const lastOutbound = flight.outbounds[flight.outbounds.length - 1];
                const lastLeg = lastOutbound?.legs[lastOutbound.legs.length - 1];
                const boParams = findBoParams(
                    {
                        startDate: flight.outbounds[0]?.legs[0]?.departure_datetime_lt ?? '',
                        endDate: lastLeg?.arrival_datetime_lt ?? ''
                    },
                    params
                );
                if (
                    boParams?.allow_number_of_allotment &&
                    boParams?.display_manual_response_first
                ) {
                    return {
                        ...flight,
                        order: 0
                    };
                }
            }
            return {
                ...flight,
                order: 1
            };
        }).sort((a, b) => {
            return a.order - b.order;
        }).map((flight) => {
            const { order, ...rest } = flight;
            return rest;
        });
    };
}